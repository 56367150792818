import { useCallback, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import useToggle from '~/src/hooks/useToggle';
import useDeleteProject from '../useDeleteProject';
import { isUserOwnerSelector, ProjectMenuActions, selectedMenuActionState, selectedProjectIdState } from '../../store';
import useShareProject from '~/src/ShareDialog/hooks/useShareProject';
import { authenticatedUserEmailState } from '~/src/Auth/store';
import { useResetProjectMenuDialogs } from './useResetProjectMenuDialogs';

export const useProjectGridDeleteDialog = () => {
  const selectedProjectId = useRecoilValue(selectedProjectIdState);
  const isOwner = useRecoilValue(isUserOwnerSelector(selectedProjectId));
  const menuAction = useRecoilValue(selectedMenuActionState);
  const [isConfirmDeleteModalOpen, showConfirmDelete, hideConfirmDelete] = useToggle();
  const [deleteProject] = useDeleteProject();
  const { unshareProject } = useShareProject();
  const authenticatedUserEmail = useRecoilValue(authenticatedUserEmailState);
  const resetProjectMenuDialogs = useResetProjectMenuDialogs();

  useEffect(() => {
    if (menuAction === ProjectMenuActions.DeleteProject) {
      showConfirmDelete();
    }
  }, [menuAction]);

  const handleHideConfirmDelete = useCallback(() => {
    hideConfirmDelete();
    resetProjectMenuDialogs();
  }, []);

  const handleConfirmProjectDelete = useCallback(async () => {
    if (isOwner) {
      await deleteProject(selectedProjectId);
    } else {
      await unshareProject(selectedProjectId, authenticatedUserEmail);
    }
    handleHideConfirmDelete();
  }, [selectedProjectId, authenticatedUserEmail, isOwner]);

  return {
    isConfirmDeleteModalOpen,
    showConfirmDelete,
    hideConfirmDelete: handleHideConfirmDelete,
    handleConfirmProjectDelete,
  };
};

export default useProjectGridDeleteDialog;
