import { atom, selectorFamily } from 'recoil';
import { PivotData, PivotField } from '~/src/Pivot/pivot-type';
import { FilterVariant } from '~/src/TableFilters/store';
import { VariableValueType } from '~/src/TrainingFile/store';
import { resultsTableDataSelector } from './prediction-table.store';

export const pendingGetPivotTableDataState = atom({
  key: 'Pivot/pendingGetPivotTableDataState',
  default: false,
});

export const fieldsState = atom<PivotField[]>({
  key: 'Pivot/fieldsState',
  default: null,
});

export const pivotRowsState = atom<PivotField[]>({
  key: 'Pivot/pivotRowsState',
  default: [],
});

export const pivotColumnsState = atom<PivotField[]>({
  key: 'Pivot/pivotColumnsState',
  default: [],
});

export const pivotValuesState = atom<PivotField[]>({
  key: 'Pivot/pivotValuesState',
  default: [],
});

export const pivotResultFieldsSelector = selectorFamily<PivotField[], number>({
  key: 'Pivot/pivotResultFieldsSelector',
  get: (projectId) => ({ get }) => {
    const { columns } = get(resultsTableDataSelector(projectId));
    if (!columns) return null;

    return columns.map((item) => ({
      id: item?.field,
      name: item?.headerName,
      type: item?.filterParams?.type || VariableValueType.Real,
      variant: (item?.filterParams?.probability && FilterVariant.PROBABILITIES)
      || (item?.filterParams?.target && FilterVariant.PREDICTION)
      || FilterVariant.VALUES,
      filters: [],
    })) as PivotField[];
  },
});

export const pivotTableDataState = atom<PivotData[]>({
  key: 'Pivot/pivotTableDataState',
  default: null,
});
