import { useEffect } from 'react';
import { useSetRecoilState, useRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { firestoreTokenState, getTokenPendingState } from '../store';
import useApiClient from '~/src/hooks/useApiClient';
import { snackbarMessageSelector } from '~/src/Snackbar/store';
import { isAuthenticatedState, isAuthorizedState } from '~/src/Auth/store';
import useLogout from '~/src/Auth/hooks/useLogout';

const useFirestoreToken = () => {
  const { t } = useTranslation('projects');
  const setSnackbarMessage = useSetRecoilState(snackbarMessageSelector);
  const apiClient = useApiClient();
  const [token, setToken] = useRecoilState(firestoreTokenState);
  const setPending = useSetRecoilState(getTokenPendingState);
  const authenticated = useRecoilValue(isAuthenticatedState);
  const isAuthorised = useRecoilValue(isAuthorizedState);
  const [logout] = useLogout();

  useEffect(() => {
    const getFirestoreToken = async () => {
      try {
        setPending(true);
        const { data } = await apiClient.get('/notifications/credentials');
        setToken(data.customToken);
      } catch (err) {
        console.error(err);
        setSnackbarMessage({
          message: t('firestore.errors.getToken'),
          severity: 'error',
        });
        logout();
      } finally {
        setPending(false);
      }
    };

    if (!token && authenticated && isAuthorised) {
      getFirestoreToken();
    }
  }, [authenticated, isAuthorised]);
};

export default useFirestoreToken;
