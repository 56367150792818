/* eslint-disable consistent-return */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import useApiClient from '~/src/hooks/useApiClient';
import { snackbarMessageSelector } from '~/src/Snackbar/store';
import { Job, projectHistoryState } from '../store';

const useProjectHistory = (projectId) => {
  const apiClient = useApiClient();
  const { t } = useTranslation('projects');
  const [projectHistory, setProjectHistory] = useRecoilState(projectHistoryState(projectId));
  const resetHistories = useResetRecoilState(projectHistoryState(projectId));
  const [isHistoryLoading, setIsLoading] = useState(false);
  const setSnackbarMessage = useSetRecoilState(snackbarMessageSelector);

  const getHistory = async (page = 0, job?: Job, perPage = 8) => {
    const params = { page, perPage } as { page: number, perPage: number, job?: Job };

    if (job) {
      params.job = job;
    }
    setIsLoading(true);
    try {
      const { data } = await apiClient.get(`/automodeler/${projectId}/history`, { params });
      setProjectHistory(data);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      setSnackbarMessage({
        message: t('projectHistory.errors.getHistory'),
        severity: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return { getHistory, isHistoryLoading, projectHistory, resetHistories };
};

export default useProjectHistory;
