import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useRecoilState } from 'recoil';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import NavigationDrawer from './NavigationDrawer';
import { navigationDrawerOpen } from '../store';
import Header from './Header';
import Footer from './Footer';

const useStyles = makeStyles(({ spacing, transitions }) => ({
  content: {
    transition: transitions.create(['margin-left'], {
      easing: transitions.easing.easeInOut,
      duration: transitions.duration.leavingScreen,
    }),
  },
  openDrawer: {
    marginLeft: spacing(58),
  },
  closedDrawer: {
    marginLeft: spacing(17),
  },
}));

const HomeLayout = ({ children }) => {
  const { openDrawer, closedDrawer, content } = useStyles();
  const [open] = useRecoilState(navigationDrawerOpen);

  return (
    <Fragment>
      <NavigationDrawer />
      <Box flex="auto" className={cx(content, { [openDrawer]: open, [closedDrawer]: !open })} display="flex" flexDirection="column">
        <Header />
        <Box flex="auto" display="flex" position="relative">
          {children}
        </Box>
        <Footer />
      </Box>
    </Fragment>
  );
};

HomeLayout.propTypes = {
  children: PropTypes.node,
};

HomeLayout.defaultProps = {
  children: null,
};

export default HomeLayout;
