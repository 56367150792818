import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import BlurCircularIcon from '@material-ui/icons/BlurCircular';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import InputBase from '@material-ui/core/InputBase';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles(({ spacing, palette, shape }) => ({
  inputContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    padding: spacing(1, 2),
    border: `1px solid ${palette.custom.border}`,
    borderRadius: shape.borderRadius,
    '& .MuiInputBase-root': {
      flex: 1,
    },
  },
  actionBtn: {    
    marginLeft: spacing(1),
  },
  isDisabledAction: {
    color: 'rgba(0, 0, 0, 0.26)',
  },
}));

interface InputFieldWithActionProps {
  [name: string]: any;
  debounce?: number;
  value?: string;
  actionIcon?: React.FC<any>;
  actionTooltip?: string;
  onActionClick: (...args: any[]) => void;
  className?: string;
  showActionOnChanges?: boolean;
  disableActionWithoutChanges?: boolean;
  disableActionOnEmpty?: boolean;
}

const InputFieldWithAction: React.FC<InputFieldWithActionProps> = React.forwardRef<any, InputFieldWithActionProps>(
  (
    {
      className,
      actionIcon,
      actionTooltip,
      onActionClick,
      onChange,
      debounce,
      value,
      showActionOnChanges,
      disableActionWithoutChanges,
      disableActionOnEmpty,
      ...restProps
    },
    ref
  ) => {
    const [state, setState] = useState(value || '');
    const classes = useStyles();
    const timerId = useRef(null);

    useEffect(() => {
      if (!value) {
        setState('');
      }
    }, [value]);

    const emitChange = (searchTerm = '') => {
      clearTimeout(timerId.current);
      if (!debounce) {
        onChange(searchTerm);
        return;
      }
      timerId.current = setTimeout(() => {
        onChange(searchTerm);
      }, debounce);
    };

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const searchTerm = e.target.value;
      setState(searchTerm);
      if (onChange) {
        emitChange(searchTerm);
      }
    };

    const handleActionClick = () => {
      if (disableActionWithoutChanges && state === value) {
        return;
      }
      onActionClick(state);
    };

    const Icon = actionIcon;

    return (
      <div className={cx(classes.inputContainer, className)} ref={ref}>
        <InputBase onChange={handleChange} autoComplete="off" type="text" value={state || ''} {...restProps} />
        {(state !== value && showActionOnChanges) ||
          (!showActionOnChanges && (
            <Tooltip title={actionTooltip || ''} arrow>
              <IconButton
                className={cx(classes.actionBtn, {
                  [classes.isDisabledAction]: (disableActionOnEmpty && !state) || (disableActionWithoutChanges && state.trim() === value),
                })}
                onClick={handleActionClick}
              >
                <Icon color="inherit" />
              </IconButton>
            </Tooltip>
          ))}
      </div>
    );
  }
);

InputFieldWithAction.propTypes = {
  onChange: PropTypes.func,
  className: PropTypes.string,
  value: PropTypes.string,
  debounce: PropTypes.number,
  showActionOnChanges: PropTypes.bool,
  disableActionWithoutChanges: PropTypes.bool,
  disableActionOnEmpty: PropTypes.bool,
  actionIcon: PropTypes.any,
  actionTooltip: PropTypes.string,
  onActionClick: PropTypes.func,
};

InputFieldWithAction.defaultProps = {
  className: '',
  value: '',
  debounce: 0,
  actionIcon: BlurCircularIcon,
  actionTooltip: '',
  onActionClick: () => {},
  onChange: () => {},
  showActionOnChanges: false,
  disableActionWithoutChanges: false,
  disableActionOnEmpty: false,
};

export default InputFieldWithAction;
