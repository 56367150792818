import { useSetRecoilState } from 'recoil';
import useApiClient from '~/src/hooks/useApiClient';
import { entriesState, Entry, findError, pendingFind } from '../store';

const useFindOne = (projectId?: number) => {
  const apiClient = useApiClient();
  const setFindPending = useSetRecoilState(pendingFind);
  const setFindError = useSetRecoilState(findError);
  const setEntries = useSetRecoilState(entriesState);

  const findOne = async (id?: number) => {
    projectId = id ?? projectId;
    try {
      setFindError(null);
      setFindPending(true);
      const params = { filter: btoa(JSON.stringify({ project: projectId })) };
      const { data } = await apiClient.get<Entry[]>('/search', { params });

      if (data.length) {
        setEntries((state) => {
          const projectInEntries = state.find((item) => item.project?._id === data[0].project?._id);
          if (projectInEntries) return state.map((item) => (item.project?._id === data[0].project?._id ? data[0] : item));
          return [...state, data[0]];
        });
      }
      return data[0];
    } catch (err) {
      setFindError(err);
      throw err;
    } finally {
      setFindPending(false);
    }
  };

  return [findOne];
};

export default useFindOne;
