import React, { useState } from 'react';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

const OutdatedWarning = () => {
  const [open, setOpen] = useState(true);
  const { t } = useTranslation('projects');

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Collapse in={open}>
      <Box mb={6}>
        <Alert onClose={handleClose} severity="warning">
          <AlertTitle>{t('outdatedProjectWarning.title')}</AlertTitle>
          <Typography variant='subtitle1'>{t('outdatedProjectWarning.greeting')}</Typography>
          <Typography variant='subtitle2'>{t('outdatedProjectWarning.pros')}</Typography>
          <Typography variant='body2'>{t('outdatedProjectWarning.prosText')}</Typography>
          <Typography variant='subtitle2'>{t('outdatedProjectWarning.cons')}</Typography>
          <Typography variant='body2'>
            {t('outdatedProjectWarning.consText1')}
            <Link href="https://info.squarkai.com/hubfs/Videos/squark_quick_demo_vid.mp4">
              {t('outdatedProjectWarning.videoLintText')}
            </Link>
            {t('outdatedProjectWarning.consText2')}
          </Typography>
        </Alert>
      </Box>
    </Collapse>
  );
};

export default React.memo(OutdatedWarning);
