import { Auth } from '@aws-amplify/auth';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useHistory } from 'react-router';
import {
  emailState,
  passwordState,
  isLoadingState,
  errorAuthState,
  firstNameState,
  lastNameState,
  companyNameState,
  positionState,
  industryState,
} from '../store';

const useSignup = () => {
  const user = useRecoilValue(emailState);
  const pass = useRecoilValue(passwordState);
  const firstName = useRecoilValue(firstNameState);
  const lastName = useRecoilValue(lastNameState);
  const companyName = useRecoilValue(companyNameState);
  const industry = useRecoilValue(industryState);
  const position = useRecoilValue(positionState);

  const setIsLoading = useSetRecoilState(isLoadingState);
  const setError = useSetRecoilState(errorAuthState);
  const { push } = useHistory();

  const signup = async () => {
    try {
      setIsLoading(true);
      await Auth.signUp({
        username: user,
        password: pass,
        attributes: {
          name: firstName,
          family_name: lastName,
          'custom:company_name': companyName,
          'custom:company_industry': industry,
          'custom:company_position': position,
        },
      });
      push('/confirm-account');
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return [signup];
};

export default useSignup;
