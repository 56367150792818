import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Paper,
  Switch,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import React from 'react';
import TabPanel from '../../Files/components/SelectFileTabPanel';
import Scheduller from '~/src/Schedulling/components/Scheduller';
import { defaultSchedulePattern } from '../store';
import { localConnectors } from '~/src/Connectors/stores/store';

const useStyles = makeStyles(({ spacing }) => ({
  paper: {
    padding: spacing(2),
    minHeight: '100%',
  },
  applyButton: {
    marginTop: spacing(4),
    minWidth: spacing(14),
    marginRight: spacing(4),
  },
  root: {
    '& > *': {
      margin: spacing(1),
    },
  },
}));

const PredictSchedulerTabPanel = ({
  value,
  onChange,
  onEmailSubscribe,
  isReady,
  onOptingPredictSchedule,
  handlePredictScheduleProject,
  doPredictFilter,
  predictSchedulerValue,
  existingFileSchedule,
  connType,
}) => {
  const { applyButton, paper, root } = useStyles();
  const { t } = useTranslation('projects');

  const TabContent = (
    <Box>
      {!localConnectors.includes(connType) ? (
        <Box>
          {isReady ? (
            <Box>
              {predictSchedulerValue && (
                <Scheduller
                  onChange={onChange}
                  cronPattern={predictSchedulerValue}
                />
              )}
              <Box>
                <FormGroup className={root} row>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={doPredictFilter}
                        onChange={onEmailSubscribe}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label={t('email.predict')}
                  />
                </FormGroup>
              </Box>
            </Box>
          ) : (
            <Box>
              <Typography>
                {t('scheduler.tabs.predictionInfo.fileAbsent')}
              </Typography>
            </Box>
          )}
        </Box>
      ) : (
        <Box>
          <Typography>{t('scheduler.tabs.predictInfo.csv')}</Typography>
        </Box>
      )}
    </Box>
  );

  return (
    <Box display="flex" alignItems="center">
      <TabPanel title={t('scheduler.schedulerDesc.predict')} value={value}>
        <Paper className={paper}>{TabContent}</Paper>
        {isReady && (
          <Box>
            <Button
              variant="contained"
              onClick={handlePredictScheduleProject}
              className={applyButton}
              color="primary"
            >
              {t('scheduler.saveButton')}
            </Button>
            <Button
              variant="contained"
              onClick={onOptingPredictSchedule}
              className={applyButton}
              color="primary"
              disabled={!existingFileSchedule}
            >
              {t('scheduler.deleteButton')}
            </Button>
          </Box>
        )}
      </TabPanel>
    </Box>
  );
};

PredictSchedulerTabPanel.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onEmailSubscribe: PropTypes.func.isRequired,
  isReady: PropTypes.bool.isRequired,
  onOptingPredictSchedule: PropTypes.func.isRequired,
  handlePredictScheduleProject: PropTypes.func.isRequired,
  doPredictFilter: PropTypes.bool,
  predictSchedulerValue: PropTypes.string,
  existingFileSchedule: PropTypes.string,
  connType: PropTypes.string.isRequired,
};

PredictSchedulerTabPanel.defaultProps = {
  value: '',
  doPredictFilter: true,
  predictSchedulerValue: defaultSchedulePattern,
  existingFileSchedule: null,
};

export default PredictSchedulerTabPanel;
