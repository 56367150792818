import { useResetRecoilState } from 'recoil';
import { denoisingAfterChartDataState, denoisingBeforeChartDataState, hotLinearChartDataState } from '~/src/Charts/store';
import { tsAutomodelerState } from '~/src/ProjectResults/store';
import { postCleaningTrainingDataState, tsPredictionDataState } from '../time-series.store';

const useTimeSeriesResultsReset = () => {
  const resetPostCleaningTraininData = useResetRecoilState(postCleaningTrainingDataState);
  const resetTSPredictionData = useResetRecoilState(tsPredictionDataState);
  const resetTSAutomodelerState = useResetRecoilState(tsAutomodelerState);
  const resetDenoisingBeforeChartData = useResetRecoilState(denoisingBeforeChartDataState);
  const resetDenoisingAfterChartData = useResetRecoilState(denoisingAfterChartDataState);
  const resetHotLinearChartData = useResetRecoilState(hotLinearChartDataState);

  const resetTimeSeriesStates = () => {
    resetPostCleaningTraininData();
    resetTSPredictionData();
    resetTSAutomodelerState();
    resetDenoisingBeforeChartData();
    resetDenoisingAfterChartData();
    resetHotLinearChartData();
  };

  return resetTimeSeriesStates;
};

export default useTimeSeriesResultsReset;
