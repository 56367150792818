// import React from 'react';
import { Auth } from '@aws-amplify/auth';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { emailState, isLoadingState, errorAuthState, passwordState, resetPasswordActiveState } from '../store';
import { snackbarMessageSelector } from '~/src/Snackbar/store';

const useForgotPassword: () => ((arg01?: string) => Promise<void>)[] = () => {
  const user = useRecoilValue(emailState);
  const pass = useRecoilValue(passwordState);
  const { t } = useTranslation('auth');
  const setIsLoading = useSetRecoilState(isLoadingState);
  const setError = useSetRecoilState(errorAuthState);
  const setResetPasswordActive = useSetRecoilState(resetPasswordActiveState);
  const setSnackbarMessage = useSetRecoilState(snackbarMessageSelector);
  const { push } = useHistory();

  const resetPassword = async () => {
    try {
      setIsLoading(true);
      await Auth.forgotPassword(user);
      setResetPasswordActive(true);
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const completePasswordReset = async (code: string) => {
    try {
      setIsLoading(true);
      await Auth.forgotPasswordSubmit(user, code, pass);
      setSnackbarMessage({ message: t('forgotPassword.infoMessage.resetPasswordDone'), severity: 'success' });
      push('/login');
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return [resetPassword, completePasswordReset];
};

export default useForgotPassword;
