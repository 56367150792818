import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { entryProjectSelector } from '~/src/Projects/store';
import { projectIdState, ScriptNames, ScriptStatus } from '~/src/Project/store';

const useScriptStatus = (scriptName: ScriptNames, id?: number) => {
  const projectId = useRecoilValue(projectIdState);
  const project = useRecoilValue(entryProjectSelector(id || projectId));

  return useMemo(() => {
    if (!project?.[scriptName]) return {};
    const { step, error, errorCode, state, ...data } = project?.[scriptName] || {};
    const isScriptFailed = state === ScriptStatus.Failed;
    return {
      error: error || errorCode || (isScriptFailed && 'defaultError'),
      state,
      step,
      data,
      isScriptPending: state === ScriptStatus.Pending,
      isScriptDone: state === ScriptStatus.Ready,
      isScriptFailed,
    };
  }, [project]);
};

export default useScriptStatus;
