import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TableMUI from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Pagination from '@material-ui/lab/Pagination';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { HistoryState } from '../store';

const useStyles = makeStyles(() => ({
  pagination: {
    '& ul': {
      justifyContent: 'flex-end',
    },
  },
}));

interface TableProps {
    className?: string,
    onPageChange: (arg: number)=>void,
    data: HistoryState,
    [key:string]: any
}

const historyColumns = ['_id', 'updatedAt', 'job', 'result'];

const HistoryTable: React.FC<TableProps> = ({ className = '', onPageChange, data, ...restProps }) => {
  const { t } = useTranslation('projects');
  const classes = useStyles();

  const handlePageChange = (e, newPage) => {
    onPageChange(newPage - 1);
  };

  return (
    <>
      <TableMUI className={className} {...restProps}>
        <TableHead>
          <TableRow>
            {historyColumns.map((key) => key !== '_id' && <TableCell key={key}>{t(`history.${key}`)}</TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.entries.map((row) => (
            <TableRow key={row._id}>
              {historyColumns.map((key) => key !== '_id' && (
                <TableCell key={key}>{
                  (key === 'updatedAt' && (new Date(row[key])).toLocaleString())
              || (key === 'job' && t(`history.jobs.${row[key]}`))
              || row[key]
                }
                </TableCell>))}
            </TableRow>
          ))}
        </TableBody>
      </TableMUI>
      {data.total > +data.perPage
      && (
        <Box py={2}>
          <Pagination
            className={classes.pagination}
            count={Math.ceil(data.total / +data.perPage)}
            page={+data.page + 1}
            onChange={handlePageChange}
            boundaryCount={1}
            shape="rounded"
            variant="outlined"
          />
        </Box>)}
    </>);
};

HistoryTable.propTypes = {
  className: PropTypes.string,
  data: PropTypes.exact({
    page: PropTypes.number.isRequired,
    perPage: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    entries: PropTypes.array.isRequired,
  }).isRequired,
  onPageChange: PropTypes.func.isRequired,
};

HistoryTable.defaultProps = {
  className: '',
};

export default HistoryTable;
