import { atom } from 'recoil';

export enum FilterVariant {
  VALUES = 'values',
  PREDICTION = 'prediction',
  PROBABILITIES = 'probabilities',
}

export interface TableFilterOperators {
  $eq?: string | number;
  $ne?: string | number;
  $gt?: number;
  $gte?: number;
  $lt?: number;
  $lte?: number;
  $in?: string[];
  $nin?: string[];
  $regex?: string;
}

export interface TableFilter extends TableFilterOperators {
  variant: FilterVariant;
  name: string;
}

export interface PredictionFileStats {
  column_levels: { [variableName: string]: string[] };
  column_stats: { [variableName: string]: number[] };
}

export const productionFileStatsState = atom<PredictionFileStats>({
  key: 'TableFilters/productionFileStatsState',
  default: null,
});

export const pendingProductionFileStatsState = atom({
  key: 'TableFilters/pendingProductionFileStatsState',
  default: false,
});

export const activeFiltersState = atom<TableFilter[]>({
  key: 'TableFilters/activeFiltersState',
  default: [],
});
