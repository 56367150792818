import { selectorFamily } from 'recoil';
import { localConnectors } from '~/src/Connectors/stores/store';
import { projectSelector, projectListState } from './store';

interface FileInfo {
  projectId?: number;
  trainingFile?: any;
  productionFile?: any;
}

interface ScheduleInfo {
  projectId?: number;
  trainingSchedule?: any;
  productionSchedule?: any;
}

export const fileSelector = selectorFamily({
  key: 'Projects/fileSelector',
  get:
    (projectId: number) => ({ get }) => {
      const projectFiles = get(projectSelector(projectId))?.files;
      if (!projectFiles) return null;
      return {
        projectId,
        trainingFile: projectFiles.training,
        productionFile: projectFiles.production,
      };
    },
});

export const entryFileSelector = selectorFamily({
  key: 'Projects/entryTrainingFile',
  get:
    (projectId: number) => ({ get }) => get(fileSelector(projectId)),
  set:
    (projectId: number) => ({ get, set }, newValue: FileInfo) => {
      const current = get(projectListState);
      set(projectListState, {
        ...current,
        entries: current.entries.map((item) => (item.project?._id !== projectId
          ? item
          : {
            ...item,
            project: {
              ...item.project,
              files: {
                training: {
                  ...(item.project.files.training || {}),
                  ...(newValue.trainingFile || {}),
                },
                production: {
                  ...(item.project.files.production || {}),
                  ...(newValue.productionFile || {}),
                },
              },
            },
          }
        )),
      });
    },
});

export const isTrainingConnectorUsedSelector = selectorFamily({
  key: 'Projects/isTrainingConnectorUsedSelector',
  get: (projectId: number) => ({ get }) => {
    const entryFile = get(entryFileSelector(projectId));
    const project = get(projectSelector(projectId));
    const entryFileTrainingConnectorType = entryFile?.trainingFile?.connectorType;
    const { training: { connectorType: projectTrainingConnectorType } } = project.files;

    let conUsed = false;

    if (
      (entryFileTrainingConnectorType && !localConnectors.includes(entryFileTrainingConnectorType))
      || (projectTrainingConnectorType && !localConnectors.includes(projectTrainingConnectorType))
    ) {
      conUsed = true;
    }

    return conUsed;
  },
});

export const isProductionConnectorUsedSelector = selectorFamily({
  key: 'Projects/isProductionConnectorUsedSelector',
  get: (projectId: number) => ({ get }) => {
    const entryFile = get(entryFileSelector(projectId));
    const project = get(projectSelector(projectId));
    const entryFileProductionConnectorType = entryFile?.productionFile?.connectorType;
    const { production: { connectorType: projectProductionConnectorType } } = project.files;

    let conUsed = false;

    if (
      (entryFileProductionConnectorType && !localConnectors.includes(entryFileProductionConnectorType))
      || (projectProductionConnectorType && !localConnectors.includes(projectProductionConnectorType))
    ) {
      conUsed = true;
    }

    return conUsed;
  },
});

export const isConnectorUsedSelector = selectorFamily({
  key: 'Projects/isConnectorUsedSelector',
  get: (projectId: number) => ({ get }) => get(isTrainingConnectorUsedSelector(projectId))
    || get(isProductionConnectorUsedSelector(projectId)),
});

export const scheduleSelector = selectorFamily({
  key: 'Projects/scheduleSelector',
  get:
    (projectId: number) => ({ get }) => {
      const projectFiles = get(projectSelector(projectId))?.files;
      if (!projectFiles) return null;
      return {
        projectId,
        trainingSchedule: projectFiles.training?.schedule,
        productionSchedule: projectFiles.production?.schedule,
      };
    },
});

export const entryScheduleSelector = selectorFamily({
  key: 'Projects/entryScheduleSelector',
  get:
    (projectId: number) => ({ get }) => get(scheduleSelector(projectId)),
  set:
    (projectId: number) => ({ get, set }, newValue: ScheduleInfo) => {
      const current = get(projectListState);
      set(projectListState, {
        ...current,
        entries: current.entries.map((item) => (item.project?._id !== projectId
          ? item
          : {
            ...item,
            project: {
              ...item.project,
              files: {
                training: { ...(item.project.files.training || {}), schedule: newValue.trainingSchedule },
                production: { ...(item.project.files.production || {}), schedule: newValue.productionSchedule },
              },
            },
          }
        )),
      });
    },
});
