import { useRecoilValue } from 'recoil';
import ConnectorsEnum from '~/src/Connectors/enums/ConnectorsEnum';
import useConnectorUpload from '~/src/Connectors/hooks/useConnectorUpload';
import { FileType } from '~/src/Files/store';
import { entryFileSelector } from '../store';

const uploadUrlMap = {
  [ConnectorsEnum.S3AssumeRole]: 'assumerole',
};

const useExecuteProjectPrediction = (projectId) => {
  const entryFile = useRecoilValue(
    entryFileSelector(projectId),
  );

  const [upload] = useConnectorUpload(projectId);

  const executeProjectPrediction = async () => {
    let { connectorConfig } = entryFile?.productionFile;
    const { connectorType, name } = entryFile?.productionFile;
    connectorConfig = { ...(connectorConfig || {}), uploadType: 'execute' };

    await upload(
      connectorConfig,
      `${uploadUrlMap[connectorType] || connectorType}/upload`,
      name?.substring(0, name.indexOf('.')) || '',
      FileType.Production,
    );
  };
  return [executeProjectPrediction];
};

export default useExecuteProjectPrediction;
