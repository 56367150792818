import { Auth } from '@aws-amplify/auth';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import useApiClient from '~/src/hooks/useApiClient';
import {
  emailState, passwordState, isLoadingState, errorAuthState, previousUrlState,
} from '../store';
import { isDomoFrame } from '~/src/Connectors/Domo/stores/domoData';
import useValidateDomoAccount from '~/src/Connectors/Domo/hooks/useValidateDomoAccount';

const useLogin = () => {
  const user = useRecoilValue(emailState);
  const apiClient = useApiClient();
  const pass = useRecoilValue(passwordState);
  const setIsLoading = useSetRecoilState(isLoadingState);
  const setError = useSetRecoilState(errorAuthState);
  const isDomo = useRecoilValue(isDomoFrame);
  const [previousUrl, setPreviousUrl] = useRecoilState(previousUrlState);
  const [isValidateDomoAccount] = useValidateDomoAccount();
  const { t } = useTranslation('connectors');

  const { push } = useHistory();

  const login = async () => {
    try {
      setIsLoading(true);
      await Auth.signIn(user, pass);
      if (isDomo) {
        const isStatus = await isValidateDomoAccount();
        if (!isStatus) {
          throw new Error(t('domo.errors.invalidDomoAccount'));
        }
      }
      await apiClient.post('/projects/users');
      const path = previousUrl || '/';
      setPreviousUrl(null);
      push(path);
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return [login];
};

export default useLogin;
