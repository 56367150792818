import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CloseButton from '~/src/components/DialogCloseButton';

const useStyles = makeStyles(({ palette, spacing }) => ({
  cancelButton: {
    backgroundColor: palette.info.main,
  },
  actions: {
    padding: spacing(2, 6, 4),
  },
}));

const ConfirmationDialog = ({
  className,
  open,
  onClose,
  onConfirm,
  content,
  title,
  closeButtonLabel,
  confirmButtonLabel,
  primaryButtonColor,
}) => {
  const { cancelButton, actions } = useStyles();

  return (
    <Dialog onClose={onClose} open={open} className={className}>
      {title && (
        <DialogTitle disableTypography>
          <Typography variant="h5">{title}</Typography>
          <CloseButton onClick={onClose} />
        </DialogTitle>
      )}
      <DialogContent>{content}</DialogContent>
      <DialogActions className={actions}>
        <Button variant="contained" autoFocus onClick={onConfirm} color={primaryButtonColor}>
          {confirmButtonLabel}
        </Button>
        <Button onClick={onClose} className={cancelButton}>
          {closeButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmationDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  closeButtonLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  confirmButtonLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  className: PropTypes.string,
  primaryButtonColor: PropTypes.string,
};

ConfirmationDialog.defaultProps = {
  open: false,
  content: 'Are you sure?',
  closeButtonLabel: 'Cancel',
  confirmButtonLabel: 'Yes',
  title: null,
  className: '',
  primaryButtonColor: 'primary',
};

export default React.memo(ConfirmationDialog);
