import { Color } from '@material-ui/lab/Alert';
import { atom, selector } from 'recoil';

export const snackbarMessage = atom({
  key: 'Snackbar/snackbarMessage',
  default: '',
});

export const prevSnackbarMessage = atom({
  key: 'Snackbar/prevMessage',
  default: '',
});

export const snackbarSeverity = atom<Color>({
  key: 'Snackbar/snackbarSeverity',
  default: 'info',
});

export const snackbarTitleState = atom({
  key: 'Snackbar/snackbarTitle',
  default: null,
});

export const snackbarMessageSelector = selector({
  key: 'Snackbar/snackbarMessageSelector',
  get: ({ get }) => {
    const message = get(snackbarMessage);
    const severity = get(snackbarSeverity);
    const title = get(snackbarTitleState);
    return { message, severity, title };
  },
  set: ({ set }, { message, severity, title }: { message: string; severity: Color; title?: string }) => {
    set(snackbarMessage, message);
    set(snackbarSeverity, severity);
    set(snackbarTitleState, title);
  },
});
