import React from 'react';
import PropType from 'prop-types';
import { useRecoilState } from 'recoil';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import { helperState } from '../store';
import Switcher from '~/src/components/Switcher';

const useStyles = makeStyles(({ palette, spacing, shape }) => ({
  helperWrapper: {
    position: 'fixed',
    zIndex: 2000,
    bottom: spacing(7),
    right: spacing(7),
    '& .Mui-checked + .MuiSwitch-track': {
      backgroundColor: palette.secondary.dark,
    },
    padding: spacing(2, 2, 2, 3),
    backgroundColor: (props: { state: boolean }) => (props.state ? palette.secondary.main : palette.common.white),
    display: 'flex',
    alignItems: 'center',
    borderRadius: shape.borderRadius * 25,
    color: (props: { state: boolean }) => (props.state ? palette.common.white : palette.custom.darkText),
  },
  swithcer: {
    margin: '0 0 0 auto',
  },
  label: {
    color: (props: { state: boolean }) => (props.state ? palette.common.white : palette.text.primary),
    margin: spacing(0, 1),
  },
}));

const HelperButton: React.FC<{ [x: string]: any }> = ({ className, ...restProps }) => {
  const [state, setState] = useRecoilState(helperState);
  const classes = useStyles({ state });

  return (
    <Paper elevation={3} className={cx(classes.helperWrapper, className)} {...restProps}>
      <LiveHelpIcon />
      <Typography component="span" className={classes.label}>
        Helper
      </Typography>
      <Switcher
        className={classes.swithcer}
        checked={state}
        onChange={() => {
          setState((currentState) => !currentState);
        }}
      />
    </Paper>
  );
};

HelperButton.propTypes = {
  className: PropType.string,
};

HelperButton.defaultProps = {
  className: '',
};

export default HelperButton;
