/* eslint-disable prefer-destructuring */
import { selector, atom } from 'recoil';
import { PREDICTION_COLUMN_CLASS_NAME } from '../constants';
import { getFractationFormatterForArray } from '../results-helpers';

export enum TSResultsSlides {
  PredictionTable = 'PredictionTable',
  ConfidenceIntervalChart = 'ConfidenceIntervalChart',
  ConfidenceWithTheBestChartData = 'ConfidenceWithTheBestChartData',
  HistogramChart = 'HistogramChart',
  MovingAverageChart = 'MovingAverageChart',
  AutoCorrelation = 'AutoCorrelation',
}

export interface TSHistoricalData {
  dates: string[];
  valueName: string;
  values: [string, number];
}

export const resultPendingState = atom({
  key: 'ResultsTS/resultPendingState',
  default: false,
});

export const postCleaningTrainingDataPendingState = atom({
  key: 'ResultsTS/postCleaningTrainingDataPendingState',
  default: false,
});

export const postCleaningTrainingDataState = atom<TSHistoricalData>({
  key: 'ResultsTS/postCleaningTrainingDataState',
  default: null,
});

export const tsPredictionDataState = atom({
  key: 'ResultsTS/tsPredictionDataState',
  default: null,
});

export const pendingTSPredictionDataState = atom({
  key: 'ResultsTS/pendingTSPredictionDataState',
  default: false,
});

export const predictionTableDataSelector = selector({
  key: 'ResultsTS/predictionTableDataSelector',
  get: ({ get }) => {
    const inputData = get(tsPredictionDataState);
    if (!inputData) return null;
    const columns = [
      { field: 'index' },
      { field: 'Prediction', cellClass: PREDICTION_COLUMN_CLASS_NAME, headerClass: PREDICTION_COLUMN_CLASS_NAME },
      { field: 'Lower_bound_95' },
      { field: 'Upper_bound_95' },
      { field: 'Lower_bound_68' },
      { field: 'Upper_bound_68' },
    ];

    const formatter = getFractationFormatterForArray(inputData.map((item) => +item.Prediction));
    const rows = inputData.map((item) => Object.entries(item).reduce((acc, [key, value]) => {
      if (key === 'index') {
        acc[key] = value;
      } else {
        acc[key] = formatter.format(+value);
      }
      return acc;
    }, {} as any));

    return { rows, columns };
  },
});
