import { useResetRecoilState } from 'recoil';
import { isReadySelectedProjectState, selectedProjectIdState, selectedMenuActionState, selectedProjectNameState } from '../../store';

export const useResetProjectMenuDialogs = () => {
  const resetIsReadySelectedProject = useResetRecoilState(isReadySelectedProjectState);
  const resetSelectedProjectId = useResetRecoilState(selectedProjectIdState);
  const resetSelectedMenuAction = useResetRecoilState(selectedMenuActionState);
  const resetSelectedProjectName = useResetRecoilState(selectedProjectNameState);

  const resetProjectMenuDialogs = () => {
    resetIsReadySelectedProject();
    resetSelectedProjectId();
    resetSelectedMenuAction();
    resetSelectedProjectName();
  };

  return resetProjectMenuDialogs;
};

export default useResetProjectMenuDialogs;
