import { useEffect, useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import useToggle from '~/src/hooks/useToggle';
import { ProjectMenuActions, selectedMenuActionState } from '../../store';
import { useResetProjectMenuDialogs } from './useResetProjectMenuDialogs';

export const useProjectGridTrainingDialog = () => {
  const menuAction = useRecoilValue(selectedMenuActionState);
  const [isTrainingDialogOpen, showTrainingDialog, hideTrainingDialog] = useToggle();
  const resetProjectMenuDialogs = useResetProjectMenuDialogs();

  useEffect(() => {
    if (menuAction === ProjectMenuActions.ExecuteTraining) {
      showTrainingDialog();
    }
  }, [menuAction]);

  const handleHideTrainingDialog = useCallback(() => {
    hideTrainingDialog();
    resetProjectMenuDialogs();
  }, []);

  return { isTrainingDialogOpen, showTrainingDialog, hideTrainingDialog: handleHideTrainingDialog };
};

export default useProjectGridTrainingDialog;
