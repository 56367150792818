import React from 'react';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Layout from './Layout';
import useLogout from '../hooks/useLogout';
import { useClearLogout } from '~/src/hooks/useClearLogout';

const useStyles = makeStyles(({ spacing }) => ({
  messageContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: spacing(110),
    maxWidth: `calc(100% - ${spacing(5)}px)`,
    margin: '0 auto',
  },
}));

const UnAuthorized = () => {
  const { messageContainer } = useStyles();
  const { t } = useTranslation('auth');
  const clearDataOnLogout = useClearLogout();
  const [logout] = useLogout();

  const handleLogout = async () => {
    await clearDataOnLogout();
    await logout();
  };

  return (
    <Layout>
      <Box className={messageContainer} flexDirection="column">
        <Box mb={4}>
          <Typography variant="h5">{t('unAuthorized.message')}</Typography>
        </Box>
        <Box>
          <Button variant="contained" color="primary" size="large" onClick={handleLogout}>
            {t('unAuthorized.logoutBtn')}
          </Button>
        </Box>
      </Box>
    </Layout>
  );
};

export default UnAuthorized;
