/* eslint-disable consistent-return */
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { FileType } from '~/src/Files/store';
import useApiClient from '~/src/hooks/useApiClient';
import { entryFileSelector, entryProjectSelector, entryScheduleSelector, JobType } from '~/src/Projects/store';
import { snackbarMessageSelector } from '~/src/Snackbar/store';
import { defaultSchedulePattern, isScheduleActionState } from '../store';
import ConnectorsEnum from '~/src/Connectors/enums/ConnectorsEnum';

const useScheduleProject = (projectId) => {
  const apiClient = useApiClient();
  const { t } = useTranslation('projects');
  const entryFile = useRecoilValue(entryFileSelector(projectId));
  const setSnackbarMessage = useSetRecoilState(snackbarMessageSelector);
  const setSchedulerActionLoading = useSetRecoilState(isScheduleActionState);
  const [entrySchedule, setEntrySchedule] = useRecoilState(entryScheduleSelector(projectId));
  const [projectEntry, setEntry] = useRecoilState(entryProjectSelector(projectId));

  const scheduleProject = async (type, pattern, projectName) => {
    try {
      const isTrainingFileType = type === FileType.Training;

      const trainingSchedule = entrySchedule?.trainingSchedule;
      const productionSchedule = entrySchedule?.productionSchedule;

      const currentEntryFile = entryFile?.[`${type}File`] || {};

      const { connectorType } = currentEntryFile;
      let jsonPayload = currentEntryFile.connectorConfig;
      const job = isTrainingFileType ? JobType.Analyze : JobType.Predict;
      const schedule = isTrainingFileType ? trainingSchedule : productionSchedule;
      if ((connectorType === ConnectorsEnum.Domo || connectorType === ConnectorsEnum.DomoLarge) && !jsonPayload?.userEmail) {
        jsonPayload = projectEntry.files[type].connectorConfig;
      }

      delete jsonPayload.uploadType;

      setSchedulerActionLoading(true);

      const schedulePattern = pattern || schedule || defaultSchedulePattern;
      if (connectorType) {
        await apiClient.post(`${connectorType}/schedule/${job}`, {
          projectId,
          schedule: isTrainingFileType ? schedulePattern : schedulePattern.replace(/^\w/, 3),
          job,
          connector: connectorType,
          jsonPayload,
        });

        const newTrainingSchedule = isTrainingFileType ? schedulePattern : trainingSchedule;
        const newProductionSchedule = isTrainingFileType ? productionSchedule : schedulePattern;

        setEntrySchedule({
          projectId,
          trainingSchedule: newTrainingSchedule,
          productionSchedule: newProductionSchedule,
        });

        setEntry((entry) => ({
          ...entry,
          files: {
            training: {
              ...(entry?.files?.training || {}),
              schedule: newTrainingSchedule,
            },
            production: {
              ...(entry?.files?.production || {}),
              schedule: newProductionSchedule,
            },
          },
        }));

        setSnackbarMessage({
          message: t(`scheduler.successMessage.${isTrainingFileType ? 'analyze' : 'predict'}`, {
            name: projectName,
          }),
          severity: 'info',
        });
      }
      return schedulePattern;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    } finally {
      setSchedulerActionLoading(false);
    }
  };

  return [scheduleProject];
};

export default useScheduleProject;
