import {
  Box, Button, FormControl, FormLabel, makeStyles, TextField, Typography, useTheme,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useRecoilValue } from 'recoil';
import Delayed from '~/src/components/Delayed';
import LoaderOverlay from '~/src/components/LoaderOverlay';
import useConfirmSignup from '../hooks/useConfirmSignup';
import useClearEffect from '../hooks/useClearEffect';
import { isLoadingState, errorKeySelector, encodedEmailSelector } from '../store';
import Layout from './Layout';

const useStyles = makeStyles(({ spacing }) => ({
  container: {
    width: '320px',
    maxWidth: `calc(100% - ${spacing(5)}px)`,
    margin: '0 auto',
  },
  resendButton: {
    fontWeight: 600,
    fontSize: '1rem',
    lineHeight: 1.5,
  },
}));

const ConfirmAccount: React.FC = () => {
  const { container, resendButton } = useStyles();
  const { spacing } = useTheme();
  const { t } = useTranslation('auth');
  const { push } = useHistory();
  const [confirmSignup, resendConfirmationCode] = useConfirmSignup();
  const user = useRecoilValue(encodedEmailSelector);
  const signUpPending = useRecoilValue(isLoadingState);
  const errorKey = useRecoilValue(errorKeySelector);
  const [confirmationCodeTouched, setConfirmationCodeTouched] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState('');

  useEffect(() => {
    if (!user) {
      push('/login');
    }
  }, []);

  useClearEffect();

  const canConfirmSignUp = useMemo(() => user && !!confirmationCode, [user, confirmationCode]);

  const handleConfirmCodeChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmationCodeTouched(true);
    setConfirmationCode(e.target.value);
  };

  const handleConfirmSignup = () => { confirmSignup(confirmationCode); };

  const handleEnterKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && canConfirmSignUp) {
      handleConfirmSignup();
    }
  };

  return (
    <Layout>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        width={spacing(80)}
        className={container}
        position="relative"
      >
        {signUpPending && (
          <Delayed>
            <LoaderOverlay />
          </Delayed>
        )}
        <Box flex="auto">
          <Box pb={6}>
            <Box pb={3}>
              <Typography variant="h4">{t('confirmSignUp.title')}</Typography>
            </Box>
            <Typography variant="caption" color="textSecondary">
              {t('confirmSignUp.subtitle', { email: user })}
            </Typography>
          </Box>
          {!!errorKey && (
            <Box pb={6}>
              <Alert severity="error">{t(errorKey)}</Alert>
            </Box>
          )}
          <Box mb={6}>
            <FormControl fullWidth required>
              <FormLabel>{t('confirmSignUp.confirmCode.label')}</FormLabel>
              <TextField
                autoComplete="off"
                type="text"
                variant="filled"
                placeholder={t('confirmSignUp.confirmCode.placeholder')}
                hiddenLabel
                InputProps={{ disableUnderline: true }}
                FormHelperTextProps={{ variant: 'standard' }}
                value={confirmationCode}
                onChange={handleConfirmCodeChanged}
                onKeyPress={handleEnterKeyPress}
                error={confirmationCodeTouched && !confirmationCode}
                helperText={confirmationCodeTouched && !confirmationCode && t('confirmSignUp.confirmCode.required')}
              />
            </FormControl>
          </Box>
          <Box mb={10} display="flex">
            <Box flex="auto">
              <Button
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                disabled={!canConfirmSignUp}
                onClick={handleConfirmSignup}
              >
                {t('confirmSignUp.buttons.signUp')}
              </Button>
            </Box>
          </Box>
          <Box mb={6} display="flex" alignItems="center">
            <Typography component="span">{t('confirmSignUp.resendCode.message')}</Typography>
            <Button color="secondary" onClick={resendConfirmationCode} className={resendButton}>
              {t('confirmSignUp.resendCode.link')}
            </Button>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default React.memo(ConfirmAccount);
