import { useCallback, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import useToggle from '~/src/hooks/useToggle';
import { ProjectMenuActions, selectedMenuActionState, selectedProjectIdState } from '../../store';
import useExecuteProjectPrediction from '../useExecuteProjectPrediction';
import { useResetProjectMenuDialogs } from './useResetProjectMenuDialogs';

export const useProjectGridPredictionExecute = () => {
  const menuAction = useRecoilValue(selectedMenuActionState);
  const selectedProjectId = useRecoilValue(selectedProjectIdState);
  const [executeProjectPrediction] = useExecuteProjectPrediction(selectedProjectId);
  const [isConfirmExecutePredictionModalOpen, showConfirmExecutePrediction, hideConfirmExecutePrediction] = useToggle();
  const resetProjectMenuDialogs = useResetProjectMenuDialogs();

  useEffect(() => {
    if (menuAction === ProjectMenuActions.ExecutePrediction) {
      showConfirmExecutePrediction();
    }
  }, [menuAction]);

  const handleHideConfirmExecutePrediction = useCallback(() => {
    hideConfirmExecutePrediction();
    resetProjectMenuDialogs();
  }, []);

  const handleConfirmExecutePrediction = useCallback(async () => {
    await executeProjectPrediction();
    handleHideConfirmExecutePrediction();
  }, [selectedProjectId]);

  return {
    isConfirmExecutePredictionModalOpen,
    showConfirmExecutePrediction,
    hideConfirmExecutePrediction: handleHideConfirmExecutePrediction,
    executePrediction: handleConfirmExecutePrediction,
  };
};

export default useProjectGridPredictionExecute;
