import { useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import useApiClient from '~/src/hooks/useApiClient';
import { snackbarMessageSelector } from '~/src/Snackbar/store';
import { Folder, pendingUpdateFolderState } from '../store';
import { Entry, ProjectListResults, projectListState } from '~/src/Projects/store';

const useFolderUpdate = () => {
  const { t } = useTranslation('folders');
  const apiClient = useApiClient();
  const setPending = useSetRecoilState(pendingUpdateFolderState);
  const setSnackbarMessage = useSetRecoilState(snackbarMessageSelector);
  const setEntries = useSetRecoilState(projectListState);

  const updateFolder = async (folderId: number, body: Partial<Folder>, errorMessage?: string) => {
    setPending(true);
    try {
      await apiClient.patch<Folder>(`/folders/${folderId}`, body);
      const params = { filter: btoa(JSON.stringify({ folder: folderId })) };
      const { data } = await apiClient.get<Entry[]>('/search', { params });
      setEntries((resultsState) => {
        return {
          ...resultsState,
          entries: resultsState.entries.reduce((acc, item) => {
            if (item.project || item.folder?._id !== folderId) {
              acc.push(item);
              return acc;
            }
            acc.push(data[0]);
            return acc;
          }, []),
        } as ProjectListResults;
      });
    } catch (err) {
      console.error(err);
      setSnackbarMessage({
        message: errorMessage || t('errors.defaultError'),
        severity: 'error',
      });
      throw err;
    } finally {
      setPending(false);
    }
  };

  return [updateFolder];
};

export default useFolderUpdate;
