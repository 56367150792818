/* eslint-disable no-case-declarations */
// Export Connector Stores ===================================================================

import { jsonToCSV } from 'react-papaparse';
import { atom, selector, selectorFamily } from 'recoil';
import csv from '~/src/assets/connectors/csv.svg';
import domo from '~/src/assets/connectors/domo.svg';
import bigquery from '~/src/assets/connectors/googlebigqueryservice-320px.png';
import googlesheets from '~/src/assets/connectors/googlesheets.png';
import snowflake from '~/src/assets/connectors/snowflake.png';
import sqlserver from '~/src/assets/connectors/sqlserver.png';
import s3assumeroleIcon from '~/src/assets/connectors/s3assumerole.png';
import {
  autocorrelationChartDataState,
  confidenceWithTheBestChartDataState,
  forcePlotChartDataState,
  histogramChartDataState,
  movingAverageChartDataState,
} from '~/src/Charts/store';
import ConnectorsEnum from '~/src/Connectors/enums/ConnectorsEnum';
import { projectIdState } from '~/src/Project/store';
import { projectSelector } from '~/src/Projects/store';
import { outliersState } from '~/src/TrainingFile/TimeSeries/time-series.store';
import ExportType from '../components/ExportConnectors/Enum/ExportType.enum';
import { convertForcePlotDataToArray } from '../results-helpers';
import { allLeaderbordState, selectedPredictionsTableRowIdState } from './results.store';

export const openExportDialog = atom({
  key: 'ExportConnector/openDialoglist',
  default: false,
});

export const exportConnectorPanelTileData = [
  { img: googlesheets, title: ConnectorsEnum.GoogleSheets },
  { img: snowflake, title: ConnectorsEnum.Snowflake },
  // { img: salesforce, title: ConnectorsEnum.Salesforce },
  // { img: totango, title: ConnectorsEnum.Totango },
  { img: csv, title: ConnectorsEnum.CSV },
  { img: domo, title: ConnectorsEnum.Domo },
  // { img: excel, title: ConnectorsEnum.Excel },
  { img: sqlserver, title: ConnectorsEnum.SQLServer },
  { img: bigquery, title: ConnectorsEnum.BigQuery },
  { img: s3assumeroleIcon, title: ConnectorsEnum.S3AssumeRole },
  // {img: googleanalytics, title: ConnectorsEnum.GoogleAnalytics},
  // {img: hubspot, title: ConnectorsEnum.Hubspot},
];

export const openExportConnector = atom({
  key: 'ExportConnector/openExportConnectorDialog',
  default: false,
});

export const exportConnectorTypeState = atom<ExportType>({
  key: 'ExportConnector/exportType',
  default: ExportType.prediction,
});

export const predictionFileUrlState = atom({
  key: 'ExportConnector/predictionFileUrlState',
  default: null,
});

export const forcePlotFileUrlState = atom<{ [key: number]: string }>({
  key: 'ExportConnector/forcePlotFileUrlState',
  default: {},
});

const convertArrayToCSVString = (arr: (string | number)[][]) => arr && arr.map((row) => row.join(',')).join('\n');

export const localFileDownloadState = selector<string>({
  key: 'ExportConnector/localFileDownloadState',
  get: ({ get }) => {
    const exportType = get(exportConnectorTypeState);

    switch (exportType) {
      case ExportType.prediction:
      case ExportType.tsPrediction:
        return get(predictionFileUrlState);
      case ExportType.forcePlot:
        const rowId = get(selectedPredictionsTableRowIdState);
        if (!rowId) return null;
        const forcePlotData = get(forcePlotChartDataState)[rowId];
        if (!forcePlotData) return null;
        const forceDataArray = convertForcePlotDataToArray(forcePlotData);
        const fileContent = convertArrayToCSVString(forceDataArray);
        return fileContent;
      case ExportType.leaderboard:
        const { headers, ...rows } = get(allLeaderbordState);
        const result: string[][] = [];
        result.push(headers);
        Object.keys(rows).forEach((model) => {
          result.push(...rows[model]);
        });
        return convertArrayToCSVString(result);
      case ExportType.confidenceIntervalBest:
        return convertArrayToCSVString(get(confidenceWithTheBestChartDataState));
      case ExportType.histogram:
        return convertArrayToCSVString(get(histogramChartDataState));
      case ExportType.movingAverage:
        return convertArrayToCSVString(get(movingAverageChartDataState));
      case ExportType.autocorrelation:
        return convertArrayToCSVString(get(autocorrelationChartDataState));
      case ExportType.outliers:
        const outliers = get(outliersState);
        const outliersCSVString = jsonToCSV(outliers, { header: true });
        return outliersCSVString;
      default:
        return null;
    }
  },
});

export const lastExportConnectorSelector = selectorFamily({
  key: 'ExportConnector/lastExportConnectorSelector',
  get:
    (exportType: ExportType) => ({ get }) => {
      const projectId = get(projectIdState);
      const project = get(projectSelector(projectId));
      return project?.export?.[exportType];
    },
});

export const lastExportConnectorIconSelector = selectorFamily({
  key: 'ExportConnector/lastExportConnectorIconSelector',
  get:
    (exportType: ExportType) => ({ get }) => {
      const currentExportConfig = get(lastExportConnectorSelector(exportType));
      if (!currentExportConfig) return null;
      const exportConnectorPanelTile = exportConnectorPanelTileData.find((item) => item.title === currentExportConfig.connectorType);
      return exportConnectorPanelTile.img;
    },
});

export const hideLastExportConnectorState = atom({
  key: 'ExportConnector/hideLastExportConnectorState',
  default: false,
});

export const connectorNameState = atom<ConnectorsEnum>({
  key: 'ExportConnector/connectorNameState',
  default: null,
});
