import { Box, Button, FormControl, FormLabel, makeStyles, TextField, Typography, Link, useTheme } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { Alert } from '@material-ui/lab';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import cx from 'classnames';
import Delayed from '~/src/components/Delayed';
import LoaderOverlay from '~/src/components/LoaderOverlay';
import useLogin from '../hooks/useLogin';
import useClearEffect from '../hooks/useClearEffect';
import {
  emailState,
  emailValidSelector,
  isLoadingState,
  passwordState,
  errorKeySelector,
  isUnconfirmedAccountSelector,
  loginConfigState,
} from '../store';
import Layout from './Layout';
import useSocialLogin from '../hooks/useSocialLogin';
import { customerLinks, customerLogos, loginProvidersLogos } from '../constants';

const useStyles = makeStyles(({ spacing, palette }) => ({
  container: {
    width: '320px',
    maxWidth: `calc(100% - ${spacing(5)}px)`,
    margin: '0 auto',
    padding: `${spacing(8)}px 0`,
  },
  withLogo: {
    paddingTop: spacing(18),
  },
  signUpLink: {
    color: palette.secondary.main,
    fontWeight: 600,
    lineHeight: 1.5,
    marginLeft: '5px',
  },
  confirmAccountLink: {
    color: palette.secondary.main,
    fontWeight: 600,
    lineHeight: 1.5,
  },
  providersLogo: {
    height: '1.5rem',
    marginRight: '10px',
  },
  providerSignIn: {
    marginBottom: spacing(2),
  },
  customerLogoClassName: {
    display: 'block',
    marginBottom: spacing(3),
    textAlign: 'center',
    '& img': {
      height: 'auto',
      maxWidth: '50%',
    },
  },
}));

const Login = () => {
  const { container, signUpLink, confirmAccountLink, providersLogo, providerSignIn, customerLogoClassName, withLogo } = useStyles();
  const { spacing } = useTheme();
  const { t } = useTranslation('auth');
  const [login] = useLogin();
  const socialLogin = useSocialLogin();
  const [user, setUser] = useRecoilState(emailState);
  const [pass, setPass] = useRecoilState(passwordState);
  const userValid = useRecoilValue(emailValidSelector);
  const loginPending = useRecoilValue(isLoadingState);
  const errorKey = useRecoilValue(errorKeySelector);
  const isUnconfirmedAccount = useRecoilValue(isUnconfirmedAccountSelector);
  const [userTouched, setUserTouched] = useState(false);
  const [passTouched, setPassTouched] = useState(false);
  const canLogin = useMemo(() => user && pass && userValid, [user, pass, userValid]);
  const { allowSignUp, allowLoginPassword, providers, customerLogo, customer } = useRecoilValue(loginConfigState);

  useClearEffect();

  const handleUserChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserTouched(true);
    setUser(e.target.value);
  };

  const handlePassChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassTouched(true);
    setPass(e.target.value);
  };

  const handleEnterKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && canLogin) {
      login();
    }
  };

  return (
    <Layout>
      {loginPending && (
        <Delayed>
          <LoaderOverlay />
        </Delayed>
      )}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        width={spacing(80)}
        className={cx(container, { [withLogo]: customerLogo && customerLogos[customer] })}
        position="relative"
      >
        <Box flex="auto">
          <Box pb={6}>
            {allowLoginPassword && (
              <>
                <Box pb={3}>
                  <Typography variant="h4">{t('signIn.title')}</Typography>
                </Box>

                <Typography variant="caption" color="textSecondary">
                  {t('signIn.subtitle')}
                </Typography>
              </>
            )}
          </Box>
          {!!errorKey && (
            <>
              <Box mb={isUnconfirmedAccount ? 3 : 6}>
                <Alert severity="error">{t(errorKey)}</Alert>
              </Box>
              {isUnconfirmedAccount && (
                <Box mb={6} display="flex" justifyContent="flex-end">
                  <Typography>
                    <Link to="/confirm-account" component={RouterLink} className={confirmAccountLink}>
                      {t('signIn.confirmAccount')}
                    </Link>
                  </Typography>
                </Box>
              )}
            </>
          )}
          {allowLoginPassword && (
            <>
              <Box mb={6}>
                <FormControl fullWidth required>
                  <FormLabel>{t('signIn.email.label')}</FormLabel>
                  <TextField
                    autoComplete="off"
                    type="email"
                    variant="filled"
                    hiddenLabel
                    placeholder={t('signIn.email.placeholder')}
                    InputProps={{ disableUnderline: true }}
                    FormHelperTextProps={{ variant: 'standard' }}
                    value={user}
                    onChange={handleUserChanged}
                    error={(userTouched && !user) || (userTouched && user && !userValid)}
                    helperText={
                      (userTouched && !user && t('signUp.email.required'))
                      || (userTouched && user && !userValid && t('signUp.email.validation'))
                    }
                  />
                </FormControl>
              </Box>
              <Box mb={6}>
                <FormControl fullWidth required>
                  <FormLabel>{t('signIn.password.label')}</FormLabel>
                  <TextField
                    autoComplete="off"
                    type="password"
                    variant="filled"
                    placeholder={t('signIn.password.placeholder')}
                    hiddenLabel
                    InputProps={{ disableUnderline: true }}
                    FormHelperTextProps={{ variant: 'standard' }}
                    value={pass}
                    onChange={handlePassChanged}
                    onKeyPress={handleEnterKeyPress}
                    error={passTouched && !pass}
                    helperText={passTouched && !pass && t('signIn.password.required')}
                  />
                </FormControl>
              </Box>
              <Box mb={10} display="flex">
                <Box flex="auto">
                  <Button fullWidth variant="contained" color="primary" size="large" disabled={!canLogin} onClick={login}>
                    {t('signIn.buttons.signIn')}
                  </Button>
                </Box>
                <Box flex="0 1 auto" ml={6}>
                  <Button to="/forgot-password" fullWidth variant="contained" size="large" component={RouterLink}>
                    {t('signIn.buttons.forgotPassword')}
                  </Button>
                </Box>
              </Box>
            </>
          )}
          {customerLogo && customerLogos[customer] && (
            <a href={customerLinks[customer] || ''} target="_blanc" className={customerLogoClassName}>
              <img src={customerLogos[customer]} alt={`${customer} logo`} />
            </a>
          )}
          <Box mb={10} display="flex">
            <Box flex="auto">
              {providers?.map(({ provider, text, iconUrl }) => (
                <Button
                  key={provider}
                  fullWidth
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => socialLogin(provider)}
                  className={providerSignIn}
                >
                  {(iconUrl || loginProvidersLogos[provider]) && (
                    <img src={iconUrl || loginProvidersLogos[provider]} alt="Google G logo" className={providersLogo} />
                  )}
                  {t(text)}
                </Button>
              ))}
            </Box>
          </Box>
          {allowSignUp && (
            <Box mb={6} display="flex">
              <Typography component="span">{t('signIn.signUp.message')}</Typography>
              <Typography>
                <Link to="/signup" component={RouterLink} className={signUpLink}>
                  {t('signIn.signUp.link')}
                </Link>
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Layout>
  );
};

export default React.memo(Login);
