/* eslint-disable no-useless-computed-key */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Box, Typography, Tooltip, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  titleClass: {
    fontWeight: 600,
    fontSize: '1rem',
    lineHeight: '1.375rem',
    marginBottom: '0.5rem',
    color: '#1A1D26',
  },
  contentClass: {
    fontWeight: 500,
    fontSize: '.75rem',
    lineHeight: '1.125rem',
  },
  container: {
    '&.MuiBox-root': {
      border: 0,
    },
  },
}));

const useTooltipStyles = makeStyles(() => ({
  tooltip: {
    padding: '24px',
    backgroundColor: '#fff',
    color: '#868994',
    borderRadius: '8px',
    boxShadow: '0px 4px 40px rgba(0, 0, 0, 0.2)',
  },
  arrow: {
    color: '#fff',
    '&::before': {
      border: '1px solid #E2E3E7',
    },
  },
}));

const TooltipWithTitle: React.FC<any> = ({ title, content, children, className, ...rest }) => {
  const tooltipClasses = useTooltipStyles();
  const { titleClass, contentClass, container } = useStyles();

  return (
    <Tooltip
      classes={tooltipClasses}
      className={className}
      title={(
        <Box display="flex" flexDirection="column" className={cx(container)}>
          <Typography variant="h6" className={titleClass}>
            {title}
          </Typography>
          <Typography className={contentClass}>{content}</Typography>
        </Box>
      )}
      arrow
      placement="top"
      {...rest}
    >
      {children}
    </Tooltip>
  );
};

TooltipWithTitle.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

TooltipWithTitle.defaultProps = {
  className: '',
};

export default TooltipWithTitle;
