import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  makeStyles,
  TextField,
  Typography,
  Link,
  useTheme,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import Delayed from '~/src/components/Delayed';
import LoaderOverlay from '~/src/components/LoaderOverlay';
import useForgotPassword from '../hooks/useForgotPassword';
import useClearEffect from '../hooks/useClearEffect';
import {
  emailState,
  emailValidSelector,
  isLoadingState,
  passwordState,
  errorKeySelector,
  resetPasswordActiveState,
  confirmPasswordState,
  passwordValidSelector,
  passwordsMatchSelector,
  canSetNewPasswordSelector,
  encodedEmailSelector,
} from '../store';
import Layout from './Layout';

const useStyles = makeStyles(({ spacing, palette }) => ({
  container: {
    width: '360px',
    maxWidth: `calc(100% - ${spacing(5)}px)`,
    margin: '0 auto',
  },
  signLink: {
    color: palette.secondary.main,
    fontWeight: 600,
    lineHeight: 1.5,
    marginLeft: '5px',
  },
}));

const ForgotPassword = () => {
  const { container, signLink } = useStyles();
  const { spacing } = useTheme();
  const { t } = useTranslation('auth');
  const [resetPassword, completePasswordReset] = useForgotPassword();
  const [user, setUser] = useRecoilState(emailState);
  const [pass, setPass] = useRecoilState(passwordState);
  const [confirmPass, setConfirmPass] = useRecoilState(confirmPasswordState);
  const encodedEmail = useRecoilValue(encodedEmailSelector);
  const userValid = useRecoilValue(emailValidSelector);
  const isPasswordValid = useRecoilValue(passwordValidSelector);
  const doPasswordsMatch = useRecoilValue(passwordsMatchSelector);
  const loginPending = useRecoilValue(isLoadingState);
  const errorKey = useRecoilValue(errorKeySelector);
  const resetPasswordActive = useRecoilValue(resetPasswordActiveState);
  const canSetNewPassword = useRecoilValue(canSetNewPasswordSelector);
  const [userTouched, setUserTouched] = useState(false);
  const [confirmationCodeTouched, setConfirmationCodeTouched] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState('');
  const [passTouched, setPassTouched] = useState(false);
  const [confirmPassTouched, setConfirmPassTouched] = useState(false);

  useClearEffect();

  const handleUserChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserTouched(true);
    setUser(e.target.value);
  };

  const handleConfirmCodeChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmationCodeTouched(true);
    setConfirmationCode(e.target.value);
  };

  const handlePassChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassTouched(true);
    setPass(e.target.value);
  };

  const handleConfirmPassChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassTouched(true);
    setConfirmPass(e.target.value);
  };

  const handleEnterKeyPressOnResetPassword = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (!resetPasswordActive && userValid) {
        resetPassword();
      } else if (resetPasswordActive && canSetNewPassword) {
        completePasswordReset();
      }
    }
  };

  return (
    <Layout>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        width={spacing(80)}
        className={container}
        position="relative"
      >
        {loginPending && (
          <Delayed>
            <LoaderOverlay />
          </Delayed>
        )}
        <Box flex="auto">
          <Box pb={6}>
            <Box pb={3}>
              <Typography variant="h4">{t('forgotPassword.title')}</Typography>
            </Box>
            {resetPasswordActive && (
              <Typography variant="caption" color="textSecondary">
                {t('forgotPassword.subtitle', { email: encodedEmail })}
              </Typography>
            )}
          </Box>
          {!!errorKey && (
            <>
              <Box mb={6}>
                <Alert severity="error">{t(errorKey)}</Alert>
              </Box>
            </>
          )}
          {!resetPasswordActive && (
            <Box mb={6}>
              <FormControl fullWidth required>
                <FormLabel>{t('forgotPassword.email.label')}</FormLabel>
                <TextField
                  autoComplete="off"
                  type="email"
                  variant="filled"
                  hiddenLabel
                  placeholder={t('forgotPassword.email.placeholder')}
                  InputProps={{ disableUnderline: true }}
                  FormHelperTextProps={{ variant: 'standard' }}
                  value={user}
                  onChange={handleUserChanged}
                  onKeyPress={handleEnterKeyPressOnResetPassword}
                  error={(userTouched && !user) || (userTouched && user && !userValid)}
                  helperText={
                    (userTouched && !user && t('signUp.email.required'))
                    || (userTouched && user && !userValid && t('signUp.email.validation'))
                  }
                />
              </FormControl>
            </Box>
          )}
          {resetPasswordActive && (
            <>
              <Box mb={6}>
                <FormControl fullWidth required>
                  <FormLabel>{t('confirmSignUp.confirmCode.label')}</FormLabel>
                  <TextField
                    autoComplete="off"
                    type="text"
                    variant="filled"
                    placeholder={t('confirmSignUp.confirmCode.placeholder')}
                    hiddenLabel
                    InputProps={{ disableUnderline: true }}
                    FormHelperTextProps={{ variant: 'standard' }}
                    value={confirmationCode}
                    onChange={handleConfirmCodeChanged}
                    error={confirmationCodeTouched && !confirmationCode}
                    helperText={confirmationCodeTouched && !confirmationCode && t('confirmSignUp.confirmCode.required')}
                  />
                </FormControl>
              </Box>
              <Box mb={6}>
                <FormControl fullWidth required>
                  <FormLabel>{t('forgotPassword.password.label')}</FormLabel>
                  <TextField
                    autoComplete="off"
                    type="password"
                    variant="filled"
                    placeholder={t('forgotPassword.password.placeholder')}
                    hiddenLabel
                    InputProps={{ disableUnderline: true }}
                    FormHelperTextProps={{ variant: 'standard' }}
                    value={pass}
                    onChange={handlePassChanged}
                    error={(passTouched && !pass) || (!!pass && !isPasswordValid)}
                    helperText={
                      (passTouched && !pass && t('forgotPassword.password.required'))
                      || (passTouched && pass && !isPasswordValid && t('signUp.password.validation'))
                    }
                  />
                </FormControl>
              </Box>
              <Box mb={6}>
                <FormControl fullWidth required>
                  <FormLabel>{t('forgotPassword.confirmPassword.label')}</FormLabel>
                  <TextField
                    autoComplete="off"
                    type="password"
                    variant="filled"
                    placeholder={t('forgotPassword.confirmPassword.placeholder')}
                    hiddenLabel
                    InputProps={{ disableUnderline: true }}
                    FormHelperTextProps={{ variant: 'standard' }}
                    value={confirmPass}
                    onChange={handleConfirmPassChanged}
                    onKeyPress={handleEnterKeyPressOnResetPassword}
                    error={(confirmPassTouched && !pass) || (confirmPassTouched && !!pass && !doPasswordsMatch)}
                    helperText={
                      (confirmPassTouched && !confirmPass && t('forgotPassword.confirmPassword.required'))
                      || (confirmPassTouched && confirmPass && !doPasswordsMatch && t('signUp.confirmPassword.validation'))
                    }
                  />
                </FormControl>
              </Box>
            </>
          )}
          <Box mb={10} display="flex">
            <Box flex="auto">
              <Button
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                disabled={resetPasswordActive ? !canSetNewPassword : !userValid}
                onClick={resetPasswordActive ? () => completePasswordReset(confirmationCode) : () => resetPassword()}
              >
                {resetPasswordActive ? t('forgotPassword.buttons.newPassword') : t('forgotPassword.buttons.reset')}
              </Button>
            </Box>
          </Box>
          <Box mb={6} display="flex" justifyContent="space-between">
            <Typography>
              <Link to="/signup" component={RouterLink} className={signLink}>
                {t('forgotPassword.signUp.link')}
              </Link>
            </Typography>
            <Typography>
              <Link to="/login" component={RouterLink} className={signLink}>
                {t('forgotPassword.signIn.link')}
              </Link>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default React.memo(ForgotPassword);
