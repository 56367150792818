import { atom } from 'recoil';
import { ProjectMenuActions } from './store';

export const selectedProjectIdState = atom<number>({
  key: 'Projects/selectedProjectIdState',
  default: null,
});

export const selectedProjectNameState = atom<string>({
  key: 'Projects/selectedProjectNameState',
  default: null,
});

export const selectedMenuActionState = atom<ProjectMenuActions>({
  key: 'Projects/selectedMenuActionState',
  default: null,
});

export const isReadySelectedProjectState = atom({
  key: 'Projects/isReadySelectedProjectState',
  default: false,
});
