/* eslint-disable react/forbid-prop-types */
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import ScheduleIcon from '@material-ui/icons/Schedule';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import StopIcon from '@material-ui/icons/Stop';
import Delete from '@material-ui/icons/Delete';
import HistoryIcon from '@material-ui/icons/History';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ShareIcon from '@material-ui/icons/Share';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import FormatIndentIncreaseIcon from '@material-ui/icons/FormatIndentIncrease';
import { ProjectMenuActions } from '../store';

const useStyles = makeStyles(({ spacing, palette, shape }) => ({
  menu: {
    '& .MuiMenu-paper': {
      borderRadius: shape.borderRadius * 2,
      backgroundColor: palette.text.primary,
    },
    '& .MuiMenu-list': {
      padding: spacing(1),
      color: palette.common.white,
      '& svg': {
        color: palette.common.white,
      },
      '& $deleteIcon': {
        color: palette.secondary.main,
      },
    },
    '& .MuiListItem-button:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.16)',
      borderRadius: shape.borderRadius,
    },
    '& .MuiListItemIcon-root': {
      minWidth: spacing(10),
    },
  },
  deleteIcon: {},
}));

const menuIcons = {
  // [ProjectMenuActions.EditTags]: LabelIcon,
  [ProjectMenuActions.ShowResults]: AssessmentOutlinedIcon,
  [ProjectMenuActions.StopProcess]: StopIcon,
  [ProjectMenuActions.DeleteProject]: Delete,
  [ProjectMenuActions.ScheduleJob]: ScheduleIcon,
  [ProjectMenuActions.ExecuteTraining]: FormatIndentIncreaseIcon,
  [ProjectMenuActions.ExecutePrediction]: TrendingUpIcon,
  [ProjectMenuActions.ProjectHistory]: HistoryIcon,
  [ProjectMenuActions.CopyProject]: FileCopyIcon,
  [ProjectMenuActions.ShareProject]: ShareIcon,
};

const folderMenuOptions = [
  // ProjectMenuActions.EditTags,
  ProjectMenuActions.DeleteProject,
];

const forbiddenOptionsForReadOnlyProject = [
  ProjectMenuActions.ShareProject,
  ProjectMenuActions.ScheduleJob,
  ProjectMenuActions.ExecuteTraining,
  ProjectMenuActions.ExecutePrediction,
  // ProjectMenuActions.CopyProject,
  ProjectMenuActions.StopProcess,
];

const ProjectMenu = ({
  className,
  onClick,
  isProcessRunning,
  isStoppingProcess,
  isFolder,
  isScheduleAvailable,
  isTrainingExecutionAvailable,
  isPredictionExecutionAvailable,
  isShareAvailable,
}) => {
  const { t } = useTranslation('projects');
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const ref = useRef();

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleItemClick = (
    e: React.MouseEvent<HTMLLIElement>,
    action: ProjectMenuActions,
  ) => {
    e.stopPropagation();
    onClick(action);
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton disabled={isStoppingProcess} onClick={handleClick} className={className}>
        <MoreIcon />
      </IconButton>
      <Menu
        ref={ref}
        className={cx(classes.menu, 'seer-project-prevent-default')}
        open={open}
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {Object.values(ProjectMenuActions).reduce((acc, item) => {
          const isStopProcessItem = item === ProjectMenuActions.StopProcess;
          const isDeleteItem = item === ProjectMenuActions.DeleteProject;
          const isScheduleItem = item === ProjectMenuActions.ScheduleJob;
          const isExecuteTraining = item === ProjectMenuActions.ExecuteTraining;
          const isExecutePrediction = item === ProjectMenuActions.ExecutePrediction;
          if (!(isDeleteItem || isStopProcessItem) && isProcessRunning) return acc;
          if (isStopProcessItem && !isProcessRunning) return acc;
          if (isFolder && !folderMenuOptions.includes(item)) return acc;
          if (!isScheduleAvailable && isScheduleItem) return acc;
          if (!isTrainingExecutionAvailable && isExecuteTraining) return acc;
          if (!isPredictionExecutionAvailable && isExecutePrediction) { return acc; }
          if (!isShareAvailable && forbiddenOptionsForReadOnlyProject.includes(item)) { return acc; }
          const Icon = menuIcons[item];
          const labelKey = isFolder && isDeleteItem ? 'deleteFolder' : item;
          acc.push(
            <MenuItem
              key={item}
              onClick={(e) => {
                handleItemClick(e, item);
              }}
            >
              <ListItemIcon>
                <Icon className={isDeleteItem ? classes.deleteIcon : ''} />
              </ListItemIcon>
              <ListItemText
                primary={t(`projectMenu.${labelKey}`)}
                primaryTypographyProps={{ variant: 'body2' }}
              />
            </MenuItem>,
          );
          return acc;
        }, [])}
      </Menu>
    </>
  );
};

ProjectMenu.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  isProcessRunning: PropTypes.bool,
  isFolder: PropTypes.bool,
  isScheduleAvailable: PropTypes.bool,
  isTrainingExecutionAvailable: PropTypes.bool,
  isPredictionExecutionAvailable: PropTypes.bool,
  isShareAvailable: PropTypes.bool,
};

ProjectMenu.defaultProps = {
  className: '',
  isProcessRunning: false,
  isFolder: false,
  isScheduleAvailable: false,
  isTrainingExecutionAvailable: false,
  isPredictionExecutionAvailable: false,
  isShareAvailable: true,
};

export default ProjectMenu;
