import { useEffect, useState } from 'react';
import { SchedulerIntervalOptions } from '../store';

const EVERY_DAY_PATTERN = '0 0 * * *';

const parseExpression = (pattern: string) => {
  const [minute, hour, dayOfMonth, month, dayOfWeek] = (pattern || EVERY_DAY_PATTERN).split(' ');
  return { minute, hour, dayOfMonth, month, dayOfWeek };
};

const getIntervalOption = ({ hour, dayOfMonth, dayOfWeek }) => {
  switch (true) {
    case dayOfWeek === '*' && dayOfMonth === '*' && (hour === '*' || hour.split('-').length > 1):
      return SchedulerIntervalOptions.Hour;
    case dayOfWeek === '*' && dayOfMonth === '*':
      return SchedulerIntervalOptions.Day;
    case dayOfWeek === '1-5':
      return SchedulerIntervalOptions.Weekday;
    case dayOfWeek !== '1-5' && dayOfWeek !== '*':
      return SchedulerIntervalOptions.Week;
    case dayOfMonth !== '*':
      return SchedulerIntervalOptions.Month;
    default:
      return SchedulerIntervalOptions.Day;
  }
};

const useCronParser = (initialPattern = EVERY_DAY_PATTERN) => {
  const [pattern, setPattern] = useState(initialPattern);
  const [fields, setFields] = useState(parseExpression(pattern));
  const [selectedInterval, setSelectedInterval] = useState(getIntervalOption(fields));

  const selectInterval = (interval: SchedulerIntervalOptions) => {
    const hours = fields.hour.split('-');
    const startHour = hours[0] === '*' ? '0' : hours[0];
    const map = {
      hour: () => {
        setFields((state) => ({ ...state, hour: startHour === '0' ? '*' : startHour, dayOfWeek: '*', dayOfMonth: '*' }));
      },
      day: () => {
        setFields((state) => ({ ...state, hour: startHour, dayOfWeek: '*', dayOfMonth: '*' }));
      },
      week: () => {
        setFields((state) => ({ ...state, hour: startHour, dayOfMonth: '*', dayOfWeek: '1' }));
      },
      weekday: () => {
        setFields((state) => ({ ...state, hour: startHour, dayOfWeek: '1-5', dayOfMonth: '*' }));
      },
      month: () => {
        setFields((state) => ({ ...state, hour: startHour, dayOfMonth: '1', dayOfWeek: '*', month: '*' }));
      },
    };

    map[interval]?.();
    setSelectedInterval(interval);
  };

  const changeField = (value: string, field: string) => {
    setFields((state) => ({ ...state, [field]: value }));
  };

  useEffect(() => {
    if (pattern !== initialPattern) {
      setPattern(initialPattern);
      const newFields = parseExpression(initialPattern);
      setFields(newFields);
      setSelectedInterval(getIntervalOption(newFields));
    }
  }, [initialPattern]);

  useEffect(() => {
    if (pattern !== initialPattern) {
      setPattern(initialPattern);
    }
  }, [selectedInterval]);

  useEffect(() => {
    const { minute, hour, dayOfMonth, month, dayOfWeek } = fields;
    const newPattern = [minute, hour, dayOfMonth, month, dayOfWeek].join(' ');
    if (pattern !== newPattern) {
      setPattern(newPattern);
    }
  }, [fields]);

  return { pattern, fields, selectedInterval, selectInterval, changeField };
};

export default useCronParser;
