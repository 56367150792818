import { useSetRecoilState } from 'recoil';
import { loginConfigState } from '../store';

const useLoginConfig = () => {
  const setLoginConfig = useSetRecoilState(loginConfigState);

  const getLoginConfig = async () => {
    const { allowSignUp, allowLoginPassword, providers, customerLogo, customer } = window.appConfig.Auth;
    if (!allowLoginPassword && !providers?.length) throw new Error("Can not get login's config");
    setLoginConfig({ allowSignUp, allowLoginPassword, providers, customerLogo, customer });
  };

  return getLoginConfig;
};

export default useLoginConfig;
