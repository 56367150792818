import { useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import useRefreshSession from '~/src/Auth/hooks/useRefreshSession';
import useApiClient from '~/src/hooks/useApiClient';
import useProjectId from '~/src/hooks/useProjectId';
import { ExportConfig } from '~/src/Project/store';
import ExportType from '~/src/ProjectResults/components/ExportConnectors/Enum/ExportType.enum';
import { exportConfigSelector } from '~/src/Projects/store';
import { snackbarMessageSelector } from '~/src/Snackbar/store';

const useSaveExportConfig = (projectId?: number) => {
  const { t } = useTranslation('exportconnectors');
  const [refreshSession] = useRefreshSession();
  const apiClient = useApiClient();
  const id = useProjectId();
  const setMessage = useSetRecoilState(snackbarMessageSelector);
  const setEntryExportConfig = useSetRecoilState(exportConfigSelector(projectId || id));

  const saveExportConfig = async (
    configs: Array<{ type: ExportType, exportConfig: ExportConfig }>,
  ) => {
    try {
      await refreshSession();
      await apiClient.patch(`projects/${projectId || id}/export`, configs);
      setEntryExportConfig((state) => configs.reduce((acc, item) => {
        if (state) {
          acc[item.type] = { ...(state[item.type] || {}), ...item.exportConfig };
        } else {
          acc[item.type] = item.exportConfig;
        }
        return acc;
      }, { ...(state || {}) }));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setMessage({ message: t('errors.saveExportConfig'), severity: 'error' });
      throw error;
    }
  };

  return saveExportConfig;
};

export default useSaveExportConfig;
