import { useEffect } from 'react';
import { useSetRecoilState, useRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';

import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { firestoreTokenState, firestoreAuthPendingState, isFirestoreAuthenticatedState } from '../store';
import { snackbarMessageSelector } from '~/src/Snackbar/store';

const useFirestoreAuth = () => {
  const { t } = useTranslation('projects');
  const setSnackbarMessage = useSetRecoilState(snackbarMessageSelector);
  const token = useRecoilValue(firestoreTokenState);
  const [isFirestoreAuthenticated, setIsFirestoreAuthenticated] = useRecoilState(isFirestoreAuthenticatedState);
  const setPending = useSetRecoilState(firestoreAuthPendingState);

  useEffect(() => {
    const firestoreAuth = async () => {
      try {
        setPending(true);
        const auth = getAuth();

        await signInWithCustomToken(auth, token);
        setIsFirestoreAuthenticated(true);
      } catch (err) {
        console.error(err);
        setSnackbarMessage({
          message: t('firestore.errors.auth'),
          severity: 'error',
        });
      } finally {
        setPending(false);
      }
    };

    if (token && !isFirestoreAuthenticated) {
      firestoreAuth();
    }
  }, [token, isFirestoreAuthenticated]);
};

export default useFirestoreAuth;
