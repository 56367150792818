export type ObjectWithNumbers = Record<string, number>;
export type ObjectOfObjectWithNumbers = Record<string, ObjectWithNumbers>;
export type ImportanceCoefficients = ObjectWithNumbers | ObjectOfObjectWithNumbers;
export type VarimpArray = [string, number, number, number];
export type VarimpData = VarimpArray[] | Record<string, VarimpArray[]>;

export interface ImportanceData {
  algo: string;
  coef_on_training_file?: ImportanceCoefficients;
  metalearner_coef_norm_on_cross_val?: ImportanceCoefficients;
  varimp?: VarimpData;
  [key: string]: any;
}

export enum VarImportanceColumns {
  Attribute = 'attribute',
  Category = 'category',
  Coefficients = 'coefficients',
  Varimp = 'varimp',
  Model = 'model',
}
