import React, { useEffect } from 'react';
import { Box, Container, Typography } from '@material-ui/core';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import useTitle from '~/src/hooks/useTitle';
import ProjectNameAndType from '../../ProjectNameAndType/components/ProjectNameAndType';
import { displayName, projectType, displayNameTouched, projectState, ProjectState, ProjectType } from '~/src/Project/store';
import useCreate from '../hooks/useCreate';
import { pendingCreate } from '../store';
import LoaderOverlay from '~/src/components/LoaderOverlay';
import Delayed from '~/src/components/Delayed';
import Header from '~/src/Project/components/Header';
import { selectedFileNameState, selectedProductionFileNameState } from '~/src/Files/store';
import StepPanel from '~/src/StepPanel/components/StepPanel';

const NewProject = () => {
  const { push } = useHistory();
  const { t } = useTranslation('layout');
  useTitle(t('navigation.new'));

  const createPending = useRecoilValue(pendingCreate);  
  const resetDisplayName = useResetRecoilState(displayName);
  const resetDisplayNameTouched = useResetRecoilState(displayNameTouched);
  const resetProjectType = useResetRecoilState(projectType);
  const resetProjectState = useResetRecoilState(projectState);
  const resetTrainingFileName = useResetRecoilState(selectedFileNameState);
  const resetProductionFileName = useResetRecoilState(selectedProductionFileNameState);

  const [create] = useCreate();

  useEffect(() => {
    resetDisplayName();
    resetDisplayNameTouched();
    resetProjectType();
    resetProjectState();
    resetTrainingFileName();
    resetProductionFileName();
  }, []);

  const handleCreate = async () => {
    await create();
  };

  const handleClose = () => {
    push('/');
  };

  return (
    <Box display="flex" flexDirection="column" position="relative" flex="auto">
      {createPending && (
        <Delayed>
          <LoaderOverlay />
        </Delayed>
      )}
      <Container>
        <Header onClose={handleClose}>
          <Typography variant="h4">{t('navigation.new')}</Typography>
        </Header>
        <StepPanel
          projectState={ProjectState.PendingProjectName}
          realState={ProjectState.PendingProjectName}
          onCancel={handleClose}
          type={ProjectType.Binary}
        />
        <ProjectNameAndType onNext={handleCreate} />
      </Container>
    </Box>
  );
};

export default NewProject;
