import React from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew';
import Assessment from '@material-ui/icons/Assessment';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useRecoilValue } from 'recoil';

import { navigationDrawerOpen } from '../store';
import NewProjectsButton from '~/src/HomeLayout/components/NewProjectsButton';
import Logo from './Logo';
import useLogout from '~/src/Auth/hooks/useLogout';
import { useClearLogout } from '~/src/hooks/useClearLogout';
import { useFolderSelect } from '~/src/Projects/hooks/useFolderSelect';

const useStyles = makeStyles(({ spacing, transitions }) => ({
  content: {
    transition: transitions.create(['margin-left'], {
      easing: transitions.easing.easeInOut,
      duration: transitions.duration.leavingScreen,
    }),
  },
  openDrawer: {
    width: spacing(50),
    paddingLeft: spacing(6),
    paddingRight: spacing(6),
  },
  closedDrawer: {
    width: spacing(11),
    paddingLeft: spacing(3),
    paddingRight: spacing(3),
    boxSizing: 'content-box',
  },
  navigationButton: {
    justifyContent: 'flex-start',
  },
}));

const NavigationDrawer = () => {
  const { t } = useTranslation('layout');
  const open = useRecoilValue(navigationDrawerOpen);
  const { content, openDrawer, closedDrawer, navigationButton } = useStyles();
  const { location } = useHistory();
  const [logout] = useLogout();
  const clearDataOnLogout = useClearLogout();
  const [handleFolderSelect] = useFolderSelect();

  const handleLogout = async () => {
    await clearDataOnLogout();
    await logout();
  };

  return (
    <Drawer variant="permanent">
      <Box className={cx(content, { [openDrawer]: open, [closedDrawer]: !open })}>
        <Box display="flex" justifyContent="center" pt={open ? 10 : 8}>
          <Link to="projects">
            <Logo />
          </Link>
        </Box>
        <Box pt={8}>
          <NewProjectsButton large={open} />
        </Box>
        <Box pt={7}>
          <Button
            className={navigationButton}
            component={Link}
            to="/projects"
            size="large"
            fullWidth
            onClick={() => {
              handleFolderSelect(null);
            }}
            variant={location.pathname.startsWith('/projects') ? 'contained' : 'text'}
            startIcon={
              <Box color={location.pathname.startsWith('/projects') ? 'text.primary' : 'inherit'} display="flex" alignItems="center">
                <Assessment />
              </Box>
            }
          >
            {open && t('navigation.projects')}
          </Button>
        </Box>
        {/* <Box mt={4}>
          <Button
            className={navigationButton}
            component={Link}
            to="/account"
            size="large"
            fullWidth
            variant={location.pathname.startsWith('/account') ? 'contained' : 'text'}
            startIcon={
              <Box color={location.pathname.startsWith('/account') ? 'secondary.main' : 'inherit'} display="flex" alignItems="center">
                <AccountCircle />
              </Box>
            }
          >
            {open && t('navigation.account')}
          </Button>
        </Box> */}
        <Box pt={1} mt={2}>
          <Button
            className={navigationButton}
            size="large"
            fullWidth
            onClick={handleLogout}
            startIcon={
              <Box color="inherit" display="flex" alignItems="center">
                <PowerSettingsNew />
              </Box>
            }
          >
            {open && t('navigation.logout')}
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default NavigationDrawer;
