import { useRecoilValue } from 'recoil';
import ConnectorsEnum from '~/src/Connectors/enums/ConnectorsEnum';
import { ConnectorsDisplayEnum } from '~/src/Connectors/stores/store';
import { logo } from '~/src/store';

const useConnectorIcon = () => {
  const {
    bigquery,
    csv,
    domo,
    excel,
    googlesheets,
    hubspot,
    s3assumerole,
    salesforce,
    snowflake,
    sqlserver,
    totango,
    existing,
  } = useRecoilValue(logo);

  const selectIcon = (stageType) => {
    let connIcon;
    let displayConnectorName;
    switch (stageType) {
      case ConnectorsEnum.S3AssumeRole:
        connIcon = s3assumerole;
        displayConnectorName = ConnectorsDisplayEnum.S3AssumeRole;
        break;
      case ConnectorsEnum.Snowflake:
        connIcon = snowflake;
        displayConnectorName = ConnectorsDisplayEnum.Snowflake;
        break;
      case ConnectorsEnum.GoogleSheets:
        connIcon = googlesheets;
        displayConnectorName = ConnectorsDisplayEnum.GoogleSheets;
        break;
      case ConnectorsEnum.Salesforce:
        connIcon = salesforce;
        displayConnectorName = ConnectorsDisplayEnum.Salesforce;
        break;
      case ConnectorsEnum.Hubspot:
        connIcon = hubspot;
        displayConnectorName = ConnectorsDisplayEnum.Hubspot;
        break;
      case ConnectorsEnum.SQLServer:
        connIcon = sqlserver;
        displayConnectorName = ConnectorsDisplayEnum.SQLServer;
        break;
      case ConnectorsEnum.BigQuery:
        connIcon = bigquery;
        displayConnectorName = ConnectorsDisplayEnum.BigQuery;
        break;
      case ConnectorsEnum.Totango:
        connIcon = totango;
        displayConnectorName = ConnectorsDisplayEnum.Totango;
        break;
      case ConnectorsEnum.Excel:
        connIcon = excel;
        displayConnectorName = ConnectorsDisplayEnum.Excel;
        break;
      case ConnectorsEnum.Domo:
      case ConnectorsEnum.DomoLarge:
        connIcon = domo;
        displayConnectorName = ConnectorsDisplayEnum.Domo;
        break;
      case ConnectorsEnum.CSV:
        connIcon = csv;
        displayConnectorName = ConnectorsDisplayEnum.CSV;
        break;
      case ConnectorsEnum.PreviousFiles:
        connIcon = existing;
        displayConnectorName = ConnectorsDisplayEnum.PreviousFiles;
        break;
      default:
        connIcon = '';
        displayConnectorName = '';
    }
    return { connIcon, displayConnectorName };
  };

  return selectIcon;
};

export default useConnectorIcon;
