/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { Snackbar as MuiSnackbar, makeStyles } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { useRecoilState, useRecoilValue } from 'recoil';
import { snackbarMessage, prevSnackbarMessage, snackbarSeverity, snackbarTitleState } from './store';

const defaultProps = {
  autoHideDuration: 10000,
};

const useStyles = makeStyles(({ palette }) => ({
  infoMessage: {
    backgroundColor: palette.primary.main,
  },
}));

const Snackbar = () => {
  const { infoMessage } = useStyles();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useRecoilState(snackbarMessage);
  const [snackbarTitle, setSnackbarTitle] = useRecoilState(snackbarTitleState);
  const severity = useRecoilValue(snackbarSeverity);
  const [prevMessage, setPrevMessage] = useRecoilState(prevSnackbarMessage);

  const handleClose = (e?, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setMessage('');
    setSnackbarTitle(null);
  };

  useEffect(() => {
    if (message !== prevMessage) {
      setPrevMessage(message);
    }

    if (message && !open) {
      setOpen(true);
      return;
    }

    if (!message) {
      setOpen(false);
    }
  }, [message, open]);

  return (
    <MuiSnackbar open={open} onClose={handleClose} {...defaultProps}>
      <Alert onClose={handleClose} variant="filled" severity={severity} className={severity === 'info' ? infoMessage : ''}>
        {snackbarTitle && <AlertTitle>{snackbarTitle}</AlertTitle>}
        {message}
      </Alert>
    </MuiSnackbar>
  );
};

export default Snackbar;
