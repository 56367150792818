import { useEffect, useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import useToggle from '~/src/hooks/useToggle';
import { ProjectMenuActions, selectedMenuActionState } from '../../store';
import { useResetProjectMenuDialogs } from './useResetProjectMenuDialogs';

export const useProjectGridShareDialog = () => {
  const menuAction = useRecoilValue(selectedMenuActionState);
  const [isShareDialogOpen, showShareDialog, hideShareDialog] = useToggle();
  const resetProjectMenuDialogs = useResetProjectMenuDialogs();

  useEffect(() => {
    if (menuAction === ProjectMenuActions.ShareProject) {
      showShareDialog();
    }
  }, [menuAction]);

  const handleHideShareDialog = useCallback(() => {
    hideShareDialog();
    resetProjectMenuDialogs();
  }, []);

  return { isShareDialogOpen, showShareDialog, hideShareDialog: handleHideShareDialog };
};

export default useProjectGridShareDialog;
