import React from 'react';
import PropTypes from 'prop-types';
import YouTube from 'react-youtube';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import CloseButton from '~/src/components/DialogCloseButton';

const youtubeOptions = {
  height: '396',
  width: '704',
};

const useStyles = makeStyles(({ spacing }) => ({
  paper: {
    maxWidth: spacing(190),
    margin: spacing(1),
  },
  dialogTitle: {
    minHeight: spacing(11),
  },
  dialogContent: {
    paddingBottom: spacing(12),
  },
}));

const VideoDialog = ({ className, title, open, onClose, videoId }) => {
  const { dialogTitle, dialogContent, ...classes } = useStyles();

  return (
    <Dialog onClose={onClose} open={open} classes={classes} className={cx(className)}>
      <DialogTitle disableTypography className={dialogTitle}>
        {title && <Typography variant="h5">{title}</Typography>}
        <CloseButton onClick={onClose} />
      </DialogTitle>
      <DialogContent className={dialogContent}>
        <YouTube videoId={videoId} opts={youtubeOptions} />
      </DialogContent>
    </Dialog>
  );
};

VideoDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  className: PropTypes.string,
  videoId: PropTypes.string.isRequired,
};

VideoDialog.defaultProps = {
  open: false,
  title: null,
  className: '',
};

export default VideoDialog;
