import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { queryFilterSelector, SearchParams, searchStringState } from '~/src/FilterProjects/store';
import useApiClient from '~/src/hooks/useApiClient';
import { currentFolderState } from '~/src/ProjectFolder/store';
import { snackbarMessageSelector } from '~/src/Snackbar/store';
import { ENTRIES_PER_PAGE, ROOT_FOLDER_DEFAULT_FILTER } from '../constants';
import { pendingLoadMore, projectListState } from '../store';

const useLoadMore = () => {
  const { t } = useTranslation('projects');
  const apiClient = useApiClient();
  const setPending = useSetRecoilState(pendingLoadMore);
  const setProjects = useSetRecoilState(projectListState);
  const filters = useRecoilValue(queryFilterSelector);
  const searchString = useRecoilValue(searchStringState);
  const currentFolder = useRecoilValue(currentFolderState);
  const setSnackbarMessage = useSetRecoilState(snackbarMessageSelector);

  const LOAD_MORE_PER_PAGE = 14;

  const loadMore = async (page: number) => {
    try {
      setPending(true);
      const currentFolderFilter = { project: { $exists: true }, folder: currentFolder?._id };
      const params: SearchParams = {
        limit: LOAD_MORE_PER_PAGE,
        skip: (page - 1) * LOAD_MORE_PER_PAGE + ENTRIES_PER_PAGE,
      };

      let requestFilters;

      switch (true) {
        case !!filters && !currentFolder:
          requestFilters = filters;
          break;
        case !filters && !!currentFolder:
          requestFilters = currentFolderFilter;
          break;
        case !!filters && !!currentFolder:
          requestFilters = {
            $and: [currentFolderFilter, filters],
          };
          break;
        default:
          requestFilters = ROOT_FOLDER_DEFAULT_FILTER;
      }

      params.filter = btoa(JSON.stringify(requestFilters));

      if (searchString) {
        params.query = searchString;
      }

      const { data } = await apiClient.get('/search', { params });
      setProjects((state) => ({
        entries: [...state.entries, ...data],
        hasMore: data.length === LOAD_MORE_PER_PAGE,
      }));
    } catch (err) {
      console.error(err);
      setSnackbarMessage({
        message: t('getProjects.errors.loadMore'),
        severity: 'error',
      });
    } finally {
      setPending(false);
    }
  };

  return [loadMore];
};

export default useLoadMore;
