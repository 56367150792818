import { atom, selector } from 'recoil';
import { Tag } from '~/src/Tags/store';

export interface Folder {
  displayName: string;
  _id: number;
  tags?: Tag[];
}

export const currentFolderState = atom<Folder>({
  key: 'ProjectFolder/currentFolderState',
  default: null,
});

export const folderNameState = atom({
  key: 'ProjectFolder/folderNameState',
  default: '',
});

export const folderNameValidSelector = selector({
  key: 'ProjectFolder/folderNameValidSelector',
  get: ({ get }) => /^[\w \d]+$/.test(get(folderNameState)),
});

export const pendingCreateFolderState = atom({
  key: 'ProjectFolder/pendingCreateFolderState',
  default: false,
});

export const pendingUpdateFolderState = atom({
  key: 'ProjectFolder/pendingUpdateFolderState',
  default: false,
});

export const pendingDeleteFolderState = atom({
  key: 'ProjectFolder/pendingDeleteFolderState',
  default: false,
});
