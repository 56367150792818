enum ConnectorsEnum {
  S3AssumeRole = 's3_assumerole',
  Snowflake = 'snowflake',
  GoogleSheets = 'google-sheet',
  SQLServer = 'sql-server',
  Salesforce = 'salesforce',
  Totango = 'totango',
  Domo = 'domo',
  DomoLarge = 'domo-large',
  OneDrive = 'onedrive',
  Dropbox = 'dropbox',
  Hubspot = 'hubspot',
  Aws = 'aws',
  Excel = 'excel',
  OracleCloud = 'oracle-cloud',
  IBMCloud = 'ibm-cloud',
  AlibabaCloud = 'alibaba-cloud',
  ICloud = 'iCloud',
  GoogleAnalytics = 'google-analytics',
  BigQuery = 'bigquery',
  CSV = 'csv',
  PreviousFiles = 'previousFiles',
}

export default ConnectorsEnum;
