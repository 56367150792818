/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { Views } from '../store';

const useStyles = makeStyles(({ shape, spacing, palette }) => ({
  wrapper: {
    position: 'relative',
    height: spacing(12),
    marginBottom: spacing(15),
  },
  steps: {
    height: '100%',
    border: `1px solid ${palette.custom.border}`,
    borderRadius: shape.borderRadius * 2,
  },
  viewItem: {
    height: '100%',
    borderRadius: shape.borderRadius * 2,
  },
}));

const ViewSwitcher = ({ onSelect, selected }) => {
  const { t } = useTranslation('results');
  const { wrapper, steps, viewItem } = useStyles();

  return (
    <div className={wrapper}>
      <Box display="flex" alignItems="center" className={cx(steps)}>
        {Object.entries(Views).map(([key, value]) => (
          <Button
            size="large"
            key={key}
            variant={selected === value ? 'contained' : 'text'}
            color={selected === value ? 'primary' : 'default'}
            className={viewItem}
            onClick={() => {
              onSelect(value);
            }}
          >
            {t(`views.switcher.${value}`)}
          </Button>
        ))}
      </Box>
    </div>
  );
};

ViewSwitcher.propTypes = {
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.oneOf(Object.values(Views)),
};

ViewSwitcher.defaultProps = {
  selected: Views.Business,
};

export default ViewSwitcher;
