/* eslint-disable no-nested-ternary */
const getTimeZoneAbbreviation = () => {
  const date = new Date();
  const timeString = date.toTimeString();
  const timeZoneName = timeString.match(/\((.+?)\)/)?.[1];
  if (!timeZoneName) return '';
  return timeZoneName.includes(' ')
    ? timeZoneName
        .split(' ')
        .map((item) => item[0])
        .join('')
        .toUpperCase()
    : timeZoneName;
};

export const timeZoneAbbreviation = getTimeZoneAbbreviation();

// https://stackoverflow.com/a/57102881
const weekStart = (region, language) => {
  const regionSat = 'AEAFBHDJDZEGIQIRJOKWLYOMQASDSY'.match(/../g);
  // eslint-disable-next-line max-len
  const regionSun =
    'AGARASAUBDBRBSBTBWBZCACNCODMDOETGTGUHKHNIDILINJMJPKEKHKRLAMHMMMOMTMXMZNINPPAPEPHPKPRPTPYSASGSVTHTTTWUMUSVEVIWSYEZAZW'.match(/../g);
  const languageSat = ['ar', 'arq', 'arz', 'fa'];
  const languageSun = 'amasbndzengnguhehiidjajvkmknkolomhmlmrmtmyneomorpapssdsmsnsutatethtnurzhzu'.match(/../g);

  return region
    ? regionSun.includes(region)
      ? 0
      : regionSat.includes(region)
      ? 6
      : 1
    : languageSun.includes(language)
    ? 0
    : languageSat.includes(language)
    ? 6
    : 1;
};

export const weekStartLocale = (locale) => {
  const parts = locale.match(/^([a-z]{2,3})(?:-([a-z]{3})(?=$|-))?(?:-([a-z]{4})(?=$|-))?(?:-([a-z]{2}|\d{3})(?=$|-))?/i);
  return weekStart(parts[4], parts[1]);
};

export const getIn12HoursFormat = (hourNumber: number) => {
  const hour = hourNumber % 12 || 12;
  const ampm = hourNumber < 12 ? 'AM' : 'PM';
  return hour + ampm;
};
