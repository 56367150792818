import { atom, selector } from 'recoil';
import { EMAIL_REGEX } from '~/src/constants';

export interface ICognitoUserError {
  code: string;
  message: string;
  name: string;
}

export const loginConfigState = atom({
  key: 'Auth/loginConfigState',
  default: null,
});

export const appConfigState = atom({
  key: 'Auth/appConfigState',
  default: null,
});

export const userConfigState = atom({
  key: 'Auth/userConfigState',
  default: null,
});

export const isPaidUserSelector = selector({
  key: 'Auth/isPaidUserSelector',
  get: ({ get }) => {
    const user = get(userConfigState);
    return user?.isPaid ?? false;
  },
});

export const isPendingUserConfigState = atom({
  key: 'Auth/isPendingUserConfigState',
  default: true,
});

export const isAuthenticatedState = atom({
  key: 'Auth/isAuthenticatedState',
  default: false,
});

export const previousUrlState = atom<string>({
  key: 'Auth/previousUrlState',
  default: null,
});

export const isAuthorizedState = atom({
  key: 'Auth/isAuthorizedState',
  default: false,
});

export const authenticatedUserEmailState = atom({
  key: 'Auth/authenticatedUserEmailState',
  default: null,
});

export const emailState = atom({
  key: 'Auth/email',
  default: '',
});

export const emailValidSelector = selector({
  key: 'Auth/emailValid',
  get: ({ get }) => EMAIL_REGEX.test(get(emailState)),
});

export const passwordState = atom({
  key: 'Auth/password',
  default: '',
});

export const confirmPasswordState = atom({
  key: 'Auth/confirmPassword',
  default: '',
});

export const passwordValidSelector = selector({
  key: 'Auth/passwordValid',
  get: ({ get }) => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/.test(get(passwordState)),
});

export const passwordsMatchSelector = selector({
  key: 'Auth/passwordsMatch',
  get: ({ get }) => get(passwordState) === get(confirmPasswordState),
});

export const firstNameState = atom({
  key: 'Auth/firstNameState',
  default: '',
});

export const lastNameState = atom({
  key: 'Auth/lastNameState',
  default: '',
});

export const companyNameState = atom({
  key: 'Auth/companyNameState',
  default: '',
});

export const industryState = atom({
  key: 'Auth/industryState',
  default: '',
});

export const positionState = atom({
  key: 'Auth/positionState',
  default: '',
});

export const canSignUpSelector = selector({
  key: 'Auth/canSignUp',
  get: ({ get }) => {
    const user = get(emailState);
    const pass = get(passwordState);
    const firstName = get(firstNameState);
    const lastName = get(lastNameState);
    const companyName = get(companyNameState);
    const confirmPass = get(confirmPasswordState);
    const userValid = get(emailValidSelector);
    const isPasswordValid = get(passwordValidSelector);
    const doPasswordsMatch = get(passwordsMatchSelector);

    return user && pass && confirmPass && userValid && isPasswordValid && doPasswordsMatch && firstName && lastName && companyName;
  },
});

export const isLoadingState = atom({
  key: 'Auth/isLoading',
  default: false,
});

export const encodedEmailSelector = selector({
  key: 'Auth/selectEncodedSelector',
  get: ({ get }) => {
    const userEmail = get(emailState);
    if (!userEmail) return '';
    if (!userEmail.match('@')) return userEmail;
    const splittedEmail = userEmail.split('@');
    const secondPartSplitted = splittedEmail[1].split('.');
    const end = secondPartSplitted[secondPartSplitted.length - 1];
    return `${splittedEmail[0][0]}***@${secondPartSplitted[0][0]}***.${end}`;
  },
});

export const passwordOrEmailErrorKey = 'signIn.errors.alert';
export const notConfirmedAccountKey = 'signIn.errors.notCongirmedAccount';
export const wrongCodeKey = 'confirmSignUp.errors.wrongCode';
export const usernameExistKey = 'signUp.errors.usernameExist';
export const limitExceeded = 'forgotPassword.errors.limitExceeded';

export const errorAuthState = atom({
  key: 'Auth/errorMessage',
  default: null,
});

export const errorKeySelector = selector({
  key: 'Auth/errorKeySelector',
  get: ({ get }) => {
    const error = get(errorAuthState);

    const messages = {
      UserNotConfirmedException: notConfirmedAccountKey,
      NotAuthorizedException: passwordOrEmailErrorKey,
      UsernameExistsException: usernameExistKey,
      CodeMismatchException: wrongCodeKey,
      LimitExceededException: limitExceeded,
    };

    return messages[error?.code] || error?.message;
  },
});

export const isUnconfirmedAccountSelector = selector({
  key: 'Auth/isUnconfirmedAccountSelector',
  get: ({ get }) => {
    const error = get(errorKeySelector);
    return error && error === notConfirmedAccountKey;
  },
});

export const resetPasswordActiveState = atom({
  key: 'Auth/resetPasswordActive',
  default: null,
});

export const canSetNewPasswordSelector = selector({
  key: 'Auth/canSetNewPasswordSelector',
  get: ({ get }) => {
    const pass = get(passwordState);
    const confirmPass = get(confirmPasswordState);
    const isPasswordValid = get(passwordValidSelector);
    const doPasswordsMatch = get(passwordsMatchSelector);
    return pass && confirmPass && isPasswordValid && doPasswordsMatch;
  },
});
