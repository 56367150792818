/* eslint-disable no-nested-ternary */
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { DragSourceMonitor, useDrag } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import Loader from '~/src/components/Loader';
import SelectableCard from '~/src/components/SelectableCard';
import useCopyState from '~/src/Project/hooks/useCopyState';
import useProjectState from '~/src/Project/hooks/useProjectState';
import { getStepInfo, Project, ProjectState } from '~/src/Project/store';
import { Folder } from '~/src/ProjectFolder/store';
import ProjectTags from '~/src/Tags/components/ProjectTags';
import { SCROLL_CONTAINER_ID } from '../constants';
import useProjectUpdate from '../hooks/useProjectUpdate';
import useFindOneOnUpdate from '../hooks/useFindOneOnUpdate';
import dndScroll from '../react-dnd-scroll';
import {
  entryFileSelector,
  isParsingSelector,
  isPredictingSelector,
  isUserEditorSelector,
  pendingMoveToFolder,
  ProjectMenuActions,
  ready,
  stoppingProcessState,
  isTrainingAvailableSelector,
  isConnectorUsedSelector,
} from '../store';
import ProjectMenu from './ProjectMenu';
import ProjectCardIcons from './ProjectCardIcons';
import ProjectCardConnectorIcons from './ProjectCardConnectorIcons';
import ProjectCardProjectName from './ProjectCardProjectName';
import ProjectCardLastRunDate from './ProjectCardLastRunDate';

const useStyles = makeStyles(({ spacing, palette }) => ({
  type: {
    color: palette.text.secondary,
    textTransform: 'uppercase',
    fontWeight: 700,
  },
  runningProjectType: {
    color: palette.common.white,
  },
  menuButton: {
    marginLeft: 'auto',
    padding: 4,
  },
  runningMenuButton: {
    color: palette.common.white,
  },
  wrapper: {
    position: 'relative',
    padding: spacing(2),
  },
  cardContent: {
    position: 'relative',
    width: spacing(52),
    height: spacing(43),
    padding: spacing(5),
    display: 'flex',
    flexDirection: 'column',
    '&:hover': {
      '& $dragIndicator': {
        opacity: 1,
      },
    },
  },
  isDragging: {
    visibility: 'hidden',
  },
  dragIndicator: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: 0,
    opacity: 0,
    width: spacing(5),
    height: spacing(5),
    color: palette.text.secondary,
  },
  runningDragIndicator: {
    color: palette.common.white,
  },
  lastRunDate: {
    position: 'absolute',
    bottom: '2px',
    left: '5px',
  },
}));

export interface MenuSelectHandlerParams {
  action: ProjectMenuActions,
  project: Project,
  isReady: boolean
}

interface ProjectCardProps {
  project: Project;
  onClick: (project: Project, isReady: boolean) => void;
  onMenuSelect: (arg: MenuSelectHandlerParams) => void;
  trainingConnector: string;
  productionConnector: string;
  currTrainingSchedule: string;
  currProductionSchedule: string;
}

const ProjectCard: React.FC<ProjectCardProps> = ({
  project,
  onClick,
  onMenuSelect,
  trainingConnector,
  productionConnector,
  currTrainingSchedule,
  currProductionSchedule,
}) => {
  const { t } = useTranslation(['projectTypes', 'projects']);
  const classes = useStyles();
  const isReady = useRecoilValue(ready(project._id));
  const isParsing = useRecoilValue(isParsingSelector(project._id));
  const isPredicting = useRecoilValue(isPredictingSelector(project._id));
  const pendingChangeFolder = useRecoilValue(pendingMoveToFolder);
  const isUserEditor = useRecoilValue(isUserEditorSelector(project._id));
  const isTrainingAvailable = useRecoilValue(isTrainingAvailableSelector(project._id));
  const isConnectorUsed = useRecoilValue(isConnectorUsedSelector(project._id));

  const step = useMemo(
    () => project.preprocessor?.step
      || project.automodeler?.step
      || project.tsa_preprocessor?.step
      || project.prediction?.step
      || project.tsa_automodeler?.step
      || project.tsa_prediction?.step,
    [project],
  );
  const stepInfo = useMemo(() => getStepInfo(step), [step]);
  const [updateProject] = useProjectUpdate();
  const entryFile = useRecoilValue(entryFileSelector(project._id));
  const stoppingProcesses = useRecoilValue(stoppingProcessState);
  const isStopping = stoppingProcesses[project._id];
  const productionFilePresent = useMemo(
    () => (
      isReady && entryFile?.productionFile?.connectorConfig && entryFile.productionFile.name
    ),
    [isReady, entryFile],
  );

  const [{ isDragging }, drag] = useDrag({
    type: project.users.length === 1 ? 'project' : 'sharedProject',
    item: () => {
      dndScroll.addEventListenerForSidebar(SCROLL_CONTAINER_ID);
      return {
        projectId: project._id,
        displayName: project.displayName,
      };
    },
    end: (item, monitor: DragSourceMonitor) => {
      if (project.users.length !== 1) return;
      const dropResult: Folder = monitor.getDropResult();
      if (item && dropResult) {
        const folderName = dropResult.displayName || t('projects:updateProject.rootFolderName');
        try {
          updateProject(
            project._id,
            { folder: dropResult._id },
            t('projects:updateProject.errors.changeFolder', {
              projectName: project.displayName,
              folderName,
            }),
          );
        } catch (error) { /* empty */ }
      }
      dndScroll.removeEventListenerForSidebar();
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const handleProjectSelect = (e) => {
    if (e.target.closest('.seer-project-prevent-default')) return;
    onClick(project, isReady || isParsing || isPredicting);
  };

  const handleMenuClick = (action: ProjectMenuActions) => {
    onMenuSelect({ action, project, isReady: isReady || isParsing || isPredicting });
  };

  useProjectState(project._id, true);
  useCopyState(project._id);
  useFindOneOnUpdate(project._id);

  return (
    <div
      id={`${project._id}`}
      className={cx(classes.wrapper, {
        [classes.isDragging]: isDragging || pendingChangeFolder === project._id,
      })}
      ref={drag}
    >
      <ProjectCardIcons
        projectId={project._id}
        currTrainingSchedule={currTrainingSchedule}
        currProductionSchedule={currProductionSchedule}
        isReady={isReady}
        projectName={project.displayName}
      />
      <SelectableCard selectable={isReady} selected={!isReady}>
        <Box className={classes.cardContent} onClick={handleProjectSelect}>
          <Box flex="auto" color={isReady ? 'text.primary' : 'primary.contrastText'}>
            <Typography
              variant="caption"
              className={cx(classes.type, {
                [classes.runningProjectType]: !isReady,
              })}
            >
              {t(`${project.type}.name`)}
            </Typography>
            <ProjectCardProjectName projectId={project._id} projectName={project.displayName} isReady={isReady} />
          </Box>
          <ProjectCardConnectorIcons
            trainingConnector={trainingConnector}
            productionConnector={productionConnector}
            projectName={project.displayName}
          />
          <Box display="flex" alignItems="center">
            {!isReady && (
              <Box display="flex" alignItems="center">
                <Loader size={32} />
                <Box flex="auto" display="flex" flexDirection="column" color="common.white" pl={3}>
                  <Typography variant="caption" color="inherit">
                    {t(`projects:process.${isStopping ? 'stopping' : isPredicting ? ProjectState.Processing : project.state}`)} ...
                  </Typography>
                  {!isStopping && (project.automodeler?.step
                    || project.preprocessor?.step
                    || project.tsa_automodeler?.step
                    || project.tsa_prediction?.step
                    || project.prediction?.step) && (
                      <Typography variant="caption" color="inherit">
                        {t('projects:stepNumber', stepInfo)}
                      </Typography>
                    )}
                </Box>
              </Box>
            )}
            {isReady && !!project.tags?.length && <ProjectTags tags={project.tags} />}
            <ProjectMenu
              className={cx(classes.menuButton, {
                [classes.runningMenuButton]: !isReady,
              })}
              onClick={handleMenuClick}
              isProcessRunning={!isReady}
              isStoppingProcess={isStopping}
              isScheduleAvailable={isTrainingAvailable && isConnectorUsed}
              isTrainingExecutionAvailable={isTrainingAvailable}
              isPredictionExecutionAvailable={!!productionFilePresent}
              isShareAvailable={isUserEditor}
            />
          </Box>
          <DragIndicatorIcon
            className={cx(classes.dragIndicator, {
              [classes.runningDragIndicator]: !isReady,
            })}
          />
          {isReady && project.reports && <ProjectCardLastRunDate reports={project.reports} className={classes.lastRunDate}/>}
        </Box>
      </SelectableCard>
    </div>
  );
};

ProjectCard.propTypes = {
  project: PropTypes.any.isRequired,
  onClick: PropTypes.func.isRequired,
  onMenuSelect: PropTypes.func.isRequired,
  trainingConnector: PropTypes.string,
  productionConnector: PropTypes.string,
  currTrainingSchedule: PropTypes.string,
  currProductionSchedule: PropTypes.string,
};

ProjectCard.defaultProps = {
  trainingConnector: null,
  productionConnector: null,
  currTrainingSchedule: null,
  currProductionSchedule: null,
};

export default React.memo(ProjectCard);
