import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import FilterListIcon from '@material-ui/icons/FilterList';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import Box from '@material-ui/core/Box';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(({ spacing, palette, shape }) => ({
  searchContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    padding: spacing(2, 3, 2, 10),
    border: `1px solid ${palette.custom.border}`,
    borderRadius: shape.borderRadius,
    '& .MuiInputBase-root': {
      flex: 1,
    },
  },
  searchIcon: {
    position: 'absolute',
    top: '50%',
    left: spacing(3),
    transform: 'translateY(-50%)',
    width: spacing(5),
  },
  clearBtn: {
    padding: 0,
  },
  filterBtn: {
    padding: 0,
  },
  divider: {
    margin: spacing(0, 2, 0, 2),
  },
  isFiltered: {
    color: palette.secondary.main,
  },
}));

interface SearchFieldProps {
  [name: string]: any;
  debounce?: number;
  value?: string | number;
  onChange: (searchTerm: string) => void;
  onFiltersClick?: () => void;
  className?: string;
  isFiltered?: boolean;
}

const SearchField: React.FC<SearchFieldProps> = ({
  className,
  onChange,
  debounce,
  value,
  onFiltersClick,
  filterLabel,
  isFiltered,
  disableClearButton,
  ...restProps
}) => {
  const [state, setState] = useState(value);
  const classes = useStyles();
  const timerId = useRef(null);

  useEffect(() => {
    if (!value) {
      setState('');
    }
  }, [value]);

  const emitChange = (searchTerm = '') => {
    clearTimeout(timerId.current);
    if (!debounce) {
      onChange(searchTerm);
      return;
    }
    timerId.current = setTimeout(() => {
      onChange(searchTerm);
    }, debounce);
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const searchTerm = e.target.value;
    setState(searchTerm);
    emitChange(searchTerm);
  };

  const clearSearch = () => {
    setState('');
    emitChange();
  };

  const handleFiltersClick = () => {
    if (onFiltersClick) {
      onFiltersClick();
    }
  };

  return (
    <Box className={cx(className, classes.searchContainer)}>
      <SearchIcon className={classes.searchIcon} />

      <InputBase onChange={handleChange} autoComplete="off" type="text" value={state || ''} {...restProps} />
      {state && !disableClearButton && (
        <IconButton className={classes.clearBtn} onClick={clearSearch}>
          <CloseIcon />
        </IconButton>
      )}

      {onFiltersClick && (
        <>
          <Divider className={classes.divider} orientation="vertical" />
          <Tooltip title={filterLabel} arrow>
            <IconButton className={cx(classes.filterBtn, { [classes.isFiltered]: isFiltered })} onClick={handleFiltersClick}>
              <FilterListIcon color="inherit" />
            </IconButton>
          </Tooltip>
        </>
      )}
    </Box>
  );
};

SearchField.propTypes = {
  onChange: PropTypes.func.isRequired,
  onFiltersClick: PropTypes.func,
  className: PropTypes.string,
  filterLabel: PropTypes.string,
  value: PropTypes.string,
  debounce: PropTypes.number,
  isFiltered: PropTypes.bool,
  disableClearButton: PropTypes.bool,
};

SearchField.defaultProps = {
  onFiltersClick: null,
  className: '',
  filterLabel: 'filters',
  value: '',
  debounce: 0,
  isFiltered: false,
  disableClearButton: false,
};

export default SearchField;
