import { constSelector } from 'recoil';
import { weekStartLocale, timeZoneAbbreviation, getIn12HoursFormat } from './scheduler-helpers';

export enum SchedulerIntervalOptions {
  Hour = 'hour',
  Day = 'day',
  Weekday = 'weekday',
  Week = 'week',
  Month = 'month',
}

export const getTimeOptions = (startFrom = 0) => {
  const result = [...Array(24).keys()].map((item) => {
    const date = new Date();
    date.setUTCHours(item);
    const is12HoursFormat = date.toLocaleTimeString().match(/pm|am/i);
    const localHour = !is12HoursFormat ? `${date.getHours()}` : getIn12HoursFormat(date.getHours());
    const utcHour = `${date.getUTCHours()}`;
    return { label: `${localHour} ${timeZoneAbbreviation}    ${utcHour} UTC`, value: `${item}` };
  });

  return startFrom ? result.slice(startFrom) : result;
};

export const timeOptionsSelector = constSelector(getTimeOptions());

const getWeekdays = () => {
  const firstDayOfWeek = navigator?.language ? weekStartLocale(navigator?.language) : 0;
  const formatter = new Intl.DateTimeFormat(navigator?.language || 'en-US', { weekday: 'long' });
  let weekdaysNumbers = [...Array(7).keys()];
  if (firstDayOfWeek) {
    weekdaysNumbers = [...weekdaysNumbers.slice(firstDayOfWeek), ...weekdaysNumbers.slice(0, firstDayOfWeek)];
  }
  const date = new Date();
  return weekdaysNumbers.map((dayNumber) => {
    const day = date.getDay();
    date.setDate(date.getDate() + (dayNumber - day));
    return { label: formatter.format(date), value: dayNumber };
  });
};

export const weekdaysOptionsSelector = constSelector(getWeekdays());

export const daysOfMonthOptionsSelector = constSelector([
  { label: 'first', value: '1' },
  { label: 'firstAndFifteents', value: '1,15' },
  { label: 'last', value: 'L' },
]);
