import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Box, makeStyles, Typography, useTheme } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { logo } from '~/src/store';
import loginBG from '~/src/assets/login-bg.svg';

interface LayoutProps {
  children: ReactNode;
}

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  background: {
    padding: `${spacing(5)}px ${spacing(30)}px`,
    background: `url(${loginBG}) no-repeat`,
    backgroundPosition: 'top left',
    backgroundSize: 'cover',
    [breakpoints.down('md')]: {
      padding: spacing(5),
    },
    flex: '0 1 auto',
  },
  signUpSection: {
    position: 'relative',
    flex: '1 0 60%',
    overflow: 'auto',
  },
  title: {
    fontWeight: 700,
    lineHeight: 1.2,
  },
  caption: {
    fontSize: '1rem',
    lineHeight: 2,
    marginBottom: spacing(3),
  },
}));

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const { t } = useTranslation('auth');
  const { largeWhite } = useRecoilValue(logo);
  const { spacing } = useTheme();
  const { background, signUpSection, title, caption } = useStyles();

  return (
    <Box display="flex" flex="auto">
      <Box flex="auto" className={background} display="flex" alignItems="center">
        <Box display="flex" flexDirection="column">
          <Box pb={20} display="flex" alignItems="center" justifyContent="space-between">
            <Link to="/">
              <img src={largeWhite} alt="logo" height={spacing(16)} />
            </Link>
          </Box>
          <Box pb={5} color="common.white">
            <Typography variant="h5" className={title}>
              {t('layout.title')}
            </Typography>
          </Box>
          <Box color="common.white">
            <Typography className={caption}>{t('layout.subtitle')}</Typography>
            <Typography className={caption}>{t('layout.farewell')}</Typography>
          </Box>
        </Box>
      </Box>
      <Box display="flex" className={signUpSection}>
        {children}
      </Box>
    </Box>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};

Layout.defaultProps = {
  children: null,
};

export default Layout;
