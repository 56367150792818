/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import { Box, makeStyles, Tooltip, useTheme } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import ScheduleIcon from '@material-ui/icons/Schedule';
import OfflineBoltOutlinedIcon from '@material-ui/icons/OfflineBoltOutlined';
import { useTranslation } from 'react-i18next';
import { ConditionControl, isFastTrainedProjectSelector, projectErrorsSelector } from '../store';
import { ScriptNames } from '~/src/Project/store';
import useScriptStatus from '~/src/Project/hooks/useScriptStatus';

const useStyles = makeStyles(({ spacing, palette }) => ({
  iconWrapper: {
    position: 'absolute',
    top: 10,
    left: 10,
    zIndex: 1,
    color: palette.secondary.main,
    display: 'flex',
    alignItems: 'center',
    gap: spacing(0.5),
    width: `calc(100% - ${spacing(5)}px)`,
  },
  error: {
    color: palette.secondary.main,
  },
  fastTraining: {
    color: palette.success.main,
  },
  scheduleIcon: {
    width: spacing(6),
    height: spacing(6),
    borderRadius: '50%',
  },
  scheduleIconsContainer: {
    width: spacing(6),
    height: spacing(6),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 'auto',
  },
}));

const ProjectCardIcons = ({ projectId, currTrainingSchedule, currProductionSchedule, isReady, projectName }) => {
  const classes = useStyles();
  const { palette } = useTheme();
  const { t } = useTranslation('projects');
  const fastTrainedProject = useRecoilValue(isFastTrainedProjectSelector(projectId));
  const projectErrors = useRecoilValue(projectErrorsSelector(projectId));
  const automodelerStatus = useScriptStatus(ScriptNames.Automodeler, projectId);

  return (
    <Box className={classes.iconWrapper}>
      {(fastTrainedProject === ConditionControl.Enabled) && automodelerStatus.isScriptDone && (
        <Tooltip
          title={
            <Box>
              {t('projectIcons.fastTraining')}
            </Box>
          }
          arrow
          placement="bottom-start"
        >
          <OfflineBoltOutlinedIcon className={classes.fastTraining} />
        </Tooltip>
      )}
      {projectErrors && (
        <Tooltip
          title={
            <Box>
              {t('projectIcons.error')}
              {Object.values(projectErrors).map(
                (errorCode, i, arr) => `${t(`errorName.${errorCode}`)}${i + 1 === arr.length ? '.' : ', '}`,
              )}
            </Box>
          }
          arrow
          placement="bottom-start"
        >
          <ErrorIcon className={classes.error} />
        </Tooltip>
      )}
      {(currTrainingSchedule || currProductionSchedule) && (
        <Tooltip
          title={t(`projects:projectIcons.${currTrainingSchedule && currProductionSchedule
            ? 'bothScheduled'
            : currTrainingSchedule
              ? 'training.schedule'
              : 'production.schedule'}`, { projectName })}
          arrow
          placement="bottom"
        >
          <Box className={classes.scheduleIconsContainer} color={!isReady ? palette.common.white : palette.text.secondary}>
            <ScheduleIcon className={classes.scheduleIcon} />
          </Box>
        </Tooltip>
      )}
    </Box>);
};

ProjectCardIcons.propTypes = {
  projectId: PropTypes.number.isRequired,
  currTrainingSchedule: PropTypes.string,
  currProductionSchedule: PropTypes.string,
  isReady: PropTypes.bool,
  projectName: PropTypes.string,
};

ProjectCardIcons.defaultProps = {
  currTrainingSchedule: null,
  currProductionSchedule: null,
  isReady: false,
  projectName: '',
};

export default React.memo(ProjectCardIcons);
