import { Auth } from '@aws-amplify/auth';
import { useSetRecoilState } from 'recoil';
import { useHistory } from 'react-router';
import { isLoadingState, errorAuthState } from '../store';

const useLogout = () => {
  const setIsLoading = useSetRecoilState(isLoadingState);
  const setError = useSetRecoilState(errorAuthState);
  const { push } = useHistory();

  const signOut = async () => {
    try {
      setIsLoading(true);
      await Auth.signOut();
      push('/login');
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return [signOut];
};

export default useLogout;
