import { constSelector } from 'recoil';
import { ProjectType } from '~/src/Project/store';
import binary from '~/src/assets/projectTypes/binary.svg';
import binaryActive from '~/src/assets/projectTypes/binaryActive.svg';
import regression from '~/src/assets/projectTypes/regression.svg';
import regressionActive from '~/src/assets/projectTypes/regressionActive.svg';
import multiClass from '~/src/assets/projectTypes/multiClass.svg';
import multiClassActive from '~/src/assets/projectTypes/multiClassActive.svg';
import timeSeries from '~/src/assets/projectTypes/timeSeries.svg';
import timeSeriesActive from '~/src/assets/projectTypes/timeSeriesActive.svg';

export const projectTypeImages = constSelector({
  [ProjectType.Binary]: {
    default: binary,
    active: binaryActive,
  },
  [ProjectType.Regression]: {
    default: regression,
    active: regressionActive,
  },
  [ProjectType.MultiClass]: {
    default: multiClass,
    active: multiClassActive,
  },
  [ProjectType.TimeSeriesForecast]: {
    default: timeSeries,
    active: timeSeriesActive,
  },
});
