import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, makeStyles } from '@material-ui/core';
import cx from 'classnames';

const useStyles = makeStyles(({ spacing, palette }) => ({
  step: {
    display: 'flex',
    alignItems: 'center',
    color: palette.custom.darkText,
  },
  completed: {
    '& .seer-panel-step-icon': {
      backgroundColor: palette.success.main,
      color: palette.common.white,
    },
  },
  active: {
    '& .seer-panel-step-icon': {
      backgroundColor: palette.secondary.main,
      color: palette.common.white,
    },
    '& .seer-panel-step-icon-wrapper': {
      borderColor: palette.secondary.main,
    },
  },
  iconWrapper: {
    display: 'flex',
    margin: `0 ${spacing(2)}px 0 ${spacing(3)}px`,
    padding: `${spacing(3) - 1}px 0 ${spacing(3) - 3}px`,
    borderBottom: '2px solid transparent',
  },
  stepIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: spacing(6),
    height: spacing(6),
    borderRadius: '50%',
    backgroundColor: palette.custom.border,
    textAlign: 'center',
  },
  stepLabel: {
    padding: `0 ${spacing(3)}px 0 0`,
  },
  linkStep: {
    cursor: 'pointer',
    '&:hover $stepLabel': {
      color: palette.text.primary,
    },
  },
}));

const StepItem = ({ isActive, isCompleted, stepNumber, label, onClick, isSkipped }) => {
  const { step, stepIcon, stepLabel, completed, active, iconWrapper, linkStep } = useStyles();
  return (
    <Box
      className={cx(step, {
        [completed]: isCompleted,
        [active]: isActive,
        [linkStep]: (!isActive && stepNumber !== 1 && isCompleted) || isSkipped,
      })}
      onClick={onClick}
    >
      <Box className={cx('seer-panel-step-icon-wrapper', iconWrapper)}>
        <Typography variant="body2" component="span" className={cx('seer-panel-step-icon', stepIcon)}>
          {stepNumber}
        </Typography>
      </Box>
      <Typography variant="body2" component="span" className={cx(stepLabel)}>
        {label}
      </Typography>
    </Box>
  );
};

StepItem.propTypes = {
  isActive: PropTypes.bool.isRequired,
  isCompleted: PropTypes.bool.isRequired,
  isSkipped: PropTypes.bool,
  stepNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

StepItem.defaultProps = {
  isSkipped: false,
};

export default StepItem;
