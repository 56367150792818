import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ns: [
      'layout',
      'projects',
      'projectTypes',
      'translation',
      'results',
      'folders',
      'files',
      'auth',
      'translation',
      'connectors',
      'helpers',
      'develop',
    ],
    fallbackLng: 'en-US',
    debug: false,
    load: 'currentOnly',
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: true,
    },
    returnObjects: false,
  });

export default i18n;
