import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import { FilterOptions } from '../store';

const useStyles = makeStyles(({ spacing }) => ({
  wrapper: {},
  paper: {
    padding: spacing(3),
  },
}));

const Filters = ({ className, open, onClose, onChange, selected }) => {
  const { t } = useTranslation('projects');
  const classes = useStyles();

  if (!open) return null;

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Box className={cx(classes.wrapper, className)}>
        <Paper elevation={open ? 3 : 0} className={cx(classes.paper)}>
          <RadioGroup
            aria-label="filters"
            name="filtersState"
            value={selected}
            onChange={(e) => {
              onChange(e.target.value);
            }}
          >
            {Object.values(FilterOptions).map((item) => (
              <FormControlLabel key={item} value={item} control={<Radio />} label={t(`filterProjects.options.${item}`)} />
            ))}
          </RadioGroup>
        </Paper>
      </Box>
    </ClickAwayListener>
  );
};

Filters.propTypes = {
  className: PropTypes.string,
  selected: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

Filters.defaultProps = {
  className: '',
  selected: 'all',
  open: false,
};

export default Filters;
