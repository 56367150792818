/* eslint-disable consistent-return */
import useApiClient from '~/src/hooks/useApiClient';
import { SubscriptionType } from '../store';

const useEmailAddSubscription = () => {
  const apiClient = useApiClient();

  const handleAddSubscription = async (projectId, subscriptionType: SubscriptionType) => {
    try {
      const { data } = await apiClient.get(
        `/emails/${projectId}/subscriptions/${subscriptionType}`
      );
      if (!data) {
        await apiClient.post(
          `/emails/${projectId}/subscriptions/${subscriptionType}`
        );
      }
      return data;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };
  return [handleAddSubscription];
};

export default useEmailAddSubscription;
