/* eslint-disable @typescript-eslint/indent */
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { SearchParams } from '~/src/FilterProjects/store';
import useApiClient from '~/src/hooks/useApiClient';
import { currentFolderState } from '~/src/ProjectFolder/store';
import { ENTRIES_PER_PAGE, ROOT_FOLDER_DEFAULT_FILTER } from '../constants';
import { Entry, findError, pendingFind, projectListState, wereProjectsLoadedState } from '../store';

const useFind = () => {
  const apiClient = useApiClient();
  const setFindPending = useSetRecoilState(pendingFind);
  const setResults = useSetRecoilState(projectListState);
  const setFindError = useSetRecoilState(findError);
  const setProjectsLoaded = useSetRecoilState(wereProjectsLoadedState);
  const currentFolder = useRecoilValue(currentFolderState);

  const find = async () => {
    try {
      setFindError(null);
      setFindPending(true);

      const params: SearchParams = {
        limit: ENTRIES_PER_PAGE,
      };

      if (currentFolder) {
        params.filter = btoa(JSON.stringify({ project: { $ne: null }, folder: currentFolder._id }));
      } else {
        params.filter = btoa(JSON.stringify(ROOT_FOLDER_DEFAULT_FILTER));
      }

      const { data } = await apiClient.get<Entry[]>('/search', { params });
      setResults({
        entries: data,
        hasMore: data.length === ENTRIES_PER_PAGE,
      });
      setProjectsLoaded(true);
      return data;
    } catch (err) {
      setFindError(err);
      throw err;
    } finally {
      setFindPending(false);
    }
  };

  return [find];
};

export default useFind;
