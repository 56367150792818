import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core';
import useCronParser from '../hooks/useCronParser';
import { daysOfMonthOptionsSelector, SchedulerIntervalOptions, weekdaysOptionsSelector } from '../store';
import SelectHour from './SelectHour';
import ActiveHours from './ActiveHours';

interface SchedullerProps {
  cronPattern?: string;
  onChange?: (data) => void;
  disabled?: boolean;
  [key: string]: any;
}

const useStyles = makeStyles(({ spacing }) => ({
  scheduleContainer: {
    display: 'flex',
    margin: spacing(4, 0),
  },
  selectField: {
    '& .MuiSelect-root': {
      padding: spacing(2.5, 8, 2.5, 3),
      lineHeight: `${spacing(5)}px`,
      minWidth: spacing(30),
    },
  },
  item: {
    marginLeft: spacing(3),
  },
}));

const Scheduller: React.FC<SchedullerProps> = ({ cronPattern, onChange, disabled, className, ...rest }) => {
  const classes = useStyles();
  const { t } = useTranslation('translation');
  const { pattern, fields, selectedInterval, selectInterval, changeField } = useCronParser(cronPattern);
  const weekdaysOptions = useRecoilValue(weekdaysOptionsSelector);
  const daysOfMonthOptions = useRecoilValue(daysOfMonthOptionsSelector);

  const handleSelectInterval = (e: React.ChangeEvent<HTMLInputElement>) => {
    const interval = e.target.value as SchedulerIntervalOptions;
    selectInterval(interval);
  };

  useEffect(() => {
    if (cronPattern !== pattern) {
      onChange(pattern);
    }
  }, [pattern]);

  return (
    <Box className={cx(classes.scheduleContainer, className)} {...rest}>
      <FormControl>
        <FormLabel>{t('scheduling.intervalLabel')}</FormLabel>
        <Select
          disabled={disabled}
          className={classes.selectField}
          variant="outlined"
          value={selectedInterval}
          onChange={handleSelectInterval}
        >
          {Object.values(SchedulerIntervalOptions).map((item) => (
            <MenuItem key={item} value={item}>
              {t(`scheduling.intervalOptions.${item}`)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {selectedInterval === SchedulerIntervalOptions.Week && (
        <FormControl className={classes.item}>
          <FormLabel>{t('scheduling.selectDayOfWeekLabel')}</FormLabel>
          <Select
            className={classes.selectField}
            disabled={disabled}
            variant="outlined"
            value={fields.dayOfWeek}
            onChange={(e) => {
              changeField(e.target.value as string, 'dayOfWeek');
            }}
          >
            {weekdaysOptions.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {selectedInterval === SchedulerIntervalOptions.Month && (
        <FormControl className={classes.item}>
          <FormLabel>{t('scheduling.selectDayOfMonthLabel')}</FormLabel>
          <Select
            className={classes.selectField}
            variant="outlined"
            disabled={disabled}
            value={fields.dayOfMonth}
            onChange={(e) => {
              changeField(e.target.value as string, 'dayOfMonth');
            }}
          >
            {daysOfMonthOptions.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {t(`scheduling.dayOfMonthOptions.${item.label}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {[
        SchedulerIntervalOptions.Day,
        SchedulerIntervalOptions.Weekday,
        SchedulerIntervalOptions.Week,
        SchedulerIntervalOptions.Month,
      ].includes(selectedInterval) && (
        <SelectHour
          disabled={disabled}
          value={fields.hour}
          className={classes.item}
          label={t('scheduling.startHourLabel')}
          onChange={(data) => {
            changeField(data, SchedulerIntervalOptions.Hour);
          }}
        />
      )}
      {selectedInterval === SchedulerIntervalOptions.Hour && (
        <ActiveHours
          disabled={disabled}
          hour={fields.hour}
          className={classes.item}
          onChange={(data) => {
            changeField(data, SchedulerIntervalOptions.Hour);
          }}
        />
      )}
    </Box>
  );
};

Scheduller.propTypes = {
  cronPattern: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

Scheduller.defaultProps = {
  className: '',
  cronPattern: '0 0 * * *',
  disabled: false,
  onChange: () => {},
};

export default Scheduller;
