import { atom, constSelector } from 'recoil';
import large from './assets/logo-large.svg';
import small from './assets/logo-small.svg';
import community from './assets/logo-community.svg';
import largeWhite from './assets/logo-white.svg';
import s3assumerole from './assets/connectors/s3assumerole.png';
import snowflake from './assets/connectors/snowflake.png';
import sqlserver from './assets/connectors/sqlserver.png';
import salesforce from './assets/connectors/salesforce.png';
import googlesheets from './assets/connectors/googlesheets.png';
import hubspot from './assets/connectors/hubspot.png';
import totango from './assets/connectors/totango.png';
import bigquery from './assets/connectors/googlebigqueryservice-320px.png';
import excel from './assets/connectors/excel.png';
import domo from './assets/connectors/domo.svg';
import csv from './assets/connectors/csv.svg';
import existing from './assets/connectors/existed-file-icon.svg';

export const pageTitle = atom({
  key: 'App/pageTitle',
  default: 'projects',
});

export enum SchedulerSources {
  Training = 'training',
  Production = 'production',
  Export = 'export',
}

export interface ObjectIndexedStrings {
  [key:string]: string
}

export interface ObjectIndexedNumbers {
  [key:string]: number
}

export const logo = constSelector({
  large,
  small,
  community,
  largeWhite,
  s3assumerole,
  snowflake,
  sqlserver,
  salesforce,
  googlesheets,
  hubspot,
  bigquery,
  totango,
  excel,
  domo,
  csv,
  existing,
});
