import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import MenuOpen from '@material-ui/icons/MenuOpen';
import Menu from '@material-ui/icons/Menu';
import CreateNewFolder from '@material-ui/icons/CreateNewFolder';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { pageTitle } from '~/src/store';
import { navigationDrawerOpen } from '../store';
import { currentFolderState } from '~/src/ProjectFolder/store';
import useToggle from '~/src/hooks/useToggle';
import CreateFolderDialog from '~/src/ProjectFolder/components/CreateFolderDialog';
import SearchProjects from '~/src/FilterProjects/components/SearchProjects';
import { useFolderSelect } from '~/src/Projects/hooks/useFolderSelect';

const useStyles = makeStyles(({ breakpoints, palette, typography, spacing }) => ({
  title: {
    [breakpoints.down('md')]: {
      fontSize: '1.75rem',
    },
  },
  li: {
    '& .MuiTypography-root, button': {
      fontSize: '1.5rem',
    },
    '& .MuiTypography-root': {
      fontWeight: typography.fontWeightBold,
    },
  },
  separator: { fontSize: '1.5rem' },
  createFolderBtn: {
    marginLeft: 'auto',
    fontWeight: 600,
    '& svg': {
      color: palette.text.primary,
    },
    [breakpoints.down('md')]: {
      padding: '5px',
    },
    '& .MuiButton-startIcon': {
      [breakpoints.down('md')]: {
        marginRight: 0,
      },

      '& svg': {
        fontSize: spacing(6),
      },
    },
  },
  createFolderButtonLabel: {
    [breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

const Header = () => {
  const { t } = useTranslation('layout');
  const { li, separator, ...classes } = useStyles();
  const [open, setOpen] = useRecoilState(navigationDrawerOpen);
  const title = useRecoilValue(pageTitle);
  const currentFolder = useRecoilValue(currentFolderState);
  const location = useLocation();
  const [isOpenCreateFolderDialog, showCreateFolderDialog, hideCreateFolderDialog] = useToggle();
  const [handleFolderSelect] = useFolderSelect();

  const isProjectsPage = useMemo(() => !!location.pathname.match(/\/projects(\/)?$/), [location]);

  const toggleDrawer = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const handleBreadcrumbClick = useCallback(() => {
    handleFolderSelect(null);
  }, [open]);

  return (
    <Box display="flex" px={10} py={6} pl={2} alignItems="center">
      <Box pr={4}>
        <IconButton onClick={toggleDrawer}>{open ? <MenuOpen /> : <Menu />}</IconButton>
      </Box>
      {!currentFolder && (
        <Typography variant="h4" className={classes.title}>
          {title}
        </Typography>
      )}
      {currentFolder && isProjectsPage && (
        <Breadcrumbs classes={{ li, separator }}>
          <Button onClick={handleBreadcrumbClick}>{title}</Button>
          <Typography color="textPrimary" component="span">
            {currentFolder.displayName}
          </Typography>
        </Breadcrumbs>
      )}
      {isProjectsPage && <SearchProjects />}
      {isProjectsPage && !currentFolder && (
        <>
          <Button
            className={classes.createFolderBtn}
            variant="outlined"
            startIcon={<CreateNewFolder color="inherit" />}
            onClick={showCreateFolderDialog}
          >
            <Typography component="span" className={classes.createFolderButtonLabel}>
              {t('header.createFolder')}
            </Typography>
          </Button>
          <CreateFolderDialog open={isOpenCreateFolderDialog} onClose={hideCreateFolderDialog} />
        </>
      )}
    </Box>
  );
};

export default Header;
