import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Divider, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useTranslation } from 'react-i18next';
import { UserRole } from '~/src/Project/store';

const useStyles = makeStyles(() => ({
  list: {
    overflow: 'auto',
    maxHeight: 260,
  },
}));

const SharedUsersList = ({ onUnshare, users }) => {
  const { t } = useTranslation('projects');
  const classes = useStyles();

  return (
    <Box >
      <Typography variant="subtitle1">{t('shareProject.listTitle')}</Typography>
      <List dense className={classes.list}>
        {users.map((user) => (
          <React.Fragment key={user.email + user.role}>
            <ListItem >
              <ListItemText
                primary={user.email}
                secondary={t(`shareProject.roles.${user.role}`)}
              />
              {user.role !== UserRole.Owner && <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="delete" onClick={() => onUnshare(user.email)}>
                  <DeleteIcon color="secondary" />
                </IconButton>
              </ListItemSecondaryAction>}
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
};

SharedUsersList.propTypes = {
  onUnshare: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({
    email: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
  })),
};

SharedUsersList.defaultProps = {
  users: [],
};

export default React.memo(SharedUsersList);
