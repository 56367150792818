import React, { useEffect, useRef } from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { FilterOptions, filtersState, queryFilterSelector, searchStringState } from '../store';
import SearchField from '~/src/components/SearchField';
import useProjectsSearch from '../hooks/useProjectsSearch';
import useToggle from '~/src/hooks/useToggle';
import Filters from './Filters';

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  searchWrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: `0 ${spacing(0.5)}px 0 auto`,
    flexGrow: 1,
  },
  search: {
    width: spacing(90),
    [breakpoints.down('sm')]: {
      width: '100%',
      minWidth: spacing(50),
      maxWidth: spacing(90),
    },
    marginLeft: spacing(2),
    marginRight: spacing(2),
    height: spacing(9),
  },
  searchButton: {
    marginLeft: spacing(2),
    [breakpoints.down('md')]: {
      padding: '5px',
    },
    '& .MuiButton-startIcon': {
      display: 'none',
      marginRight: 0,
      [breakpoints.down('md')]: {
        display: 'flex',
      },
      '& svg': {
        fontSize: spacing(6),
      },
    },
  },
  searchButtonLabel: {
    [breakpoints.down('md')]: {
      display: 'none',
    },
  },
  filters: {
    position: 'absolute',
    top: '100%',
    left: '50%',
    transform: 'translateX(-50%)',
    width: `calc(100% - ${spacing(4)}px)`,
    maxWidth: spacing(90),
    zIndex: 100,
  },
}));

const SearchProjects = () => {
  const { t } = useTranslation('projects');
  const classes = useStyles();
  const [searchString, setSearchString] = useRecoilState(searchStringState);
  const filters = useRecoilValue(queryFilterSelector);
  const [selectedFilter, setSelectedFilter] = useRecoilState(filtersState);
  const [searchProjects] = useProjectsSearch();
  const [isOpenFilters, _, hideFilters, toggleFiltersView] = useToggle();
  const previousFilters = useRef(FilterOptions.All);

  useEffect(() => {
    if (previousFilters.current !== selectedFilter) {
      searchProjects(searchString);
      previousFilters.current = selectedFilter;
    }
  }, [filters]);

  return (
    <Box className={classes.searchWrapper}>
      <SearchField
        className={classes.search}
        debounce={500}
        value={searchString}
        onChange={(text) => {
          setSearchString(text);
          searchProjects(text);
        }}
        onFiltersClick={() => {
          toggleFiltersView();
        }}
        isFiltered={selectedFilter !== FilterOptions.All}
        placeholder={t('filterProjects.searchPlaceholder')}
      />
      <Filters
        selected={selectedFilter}
        className={classes.filters}
        open={isOpenFilters}
        onClose={hideFilters}
        onChange={setSelectedFilter}
      />
    </Box>
  );
};

export default SearchProjects;
