import { useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import useApiClient from '~/src/hooks/useApiClient';
import { snackbarMessageSelector } from '~/src/Snackbar/store';
import { Entry, pendingMoveToFolder, pendingProjectUpdateState, ProjectListResults, projectListState } from '../store';
import { Project } from '~/src/Project/store';

const useProjectUpdate = () => {
  const { t } = useTranslation('projects');
  const apiClient = useApiClient();
  const setPendingFolderChange = useSetRecoilState(pendingMoveToFolder);
  const setPending = useSetRecoilState(pendingProjectUpdateState);
  const setSnackbarMessage = useSetRecoilState(snackbarMessageSelector);
  const setEntries = useSetRecoilState(projectListState);

  const updateProject = async (projectId: number, body: Partial<Project>, errorMessage?: string) => {
    setPending(true);
    if (body.folder === null || body.folder) {
      setPendingFolderChange(projectId);
    }
    try {
      await apiClient.patch<Project>(`/projects/${projectId}`, body);
      const params = { filter: btoa(JSON.stringify({ project: projectId })) };
      const { data } = await apiClient.get<Entry[]>('/search', { params });
      setEntries((state) => {
        return {
          ...state,
          entries: state.entries.reduce((acc, item) => {
            if (!item.project || item.project._id !== projectId) {
              acc.push(item);
              return acc;
            }
            if ((body.folder && !item.project.folder) || (!body.folder && item.project.folder)) {
              return acc;
            }
            acc.push(data[0]);
            return acc;
          }, []),
        } as ProjectListResults;
      });
    } catch (err) {
      console.error(err);
      setSnackbarMessage({
        message: errorMessage || t('updateProject.errors.updateFolder'),
        severity: 'error',
      });
      throw err;
    } finally {
      setPending(false);
      setPendingFolderChange(null);
    }
  };

  return [updateProject];
};

export default useProjectUpdate;
