import { atom } from 'recoil';

export const pendingCreate = atom({
  key: 'NewProject/pendingCreate',
  default: false,
});

export const createError = atom({
  key: 'NewProject/createError',
  default: null,
});
