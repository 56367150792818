/* eslint-disable no-empty */
import React, { useEffect, useMemo, Suspense } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import SaveIcon from '@material-ui/icons/SaveOutlined';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useHistory } from 'react-router';
import useTitle from '~/src/hooks/useTitle';
import {
  currentProjectIdState,
  displayName,
  isSkippedProductionFileState,
  panelIndicatorState,
  projectIdState,
  ProjectState,
  projectState,
  ProjectType,
  projectType,
  ScriptNames,
} from '../store';
import LoaderOverlay from '~/src/components/LoaderOverlay';
import Delayed from '~/src/components/Delayed';
import Header from './Header';
import useFindOne from '~/src/Projects/hooks/useFindOne';
import useProjectId from '~/src/hooks/useProjectId';
import StepPanel, { PanelIndicatorSteps } from '~/src/StepPanel/components/StepPanel';
import { ConditionControl, entryFileSelector, entryProjectSelector, isFastTrainedProjectSelector, pendingFind } from '~/src/Projects/store';
import useResetProject from '../hooks/useResetProject';
import useProjectState from '../hooks/useProjectState';
import useFirestoreToken from '~/src/Firestore/hooks/useFirestoreToken';
import useFirestoreAuth from '~/src/Firestore/hooks/useFirestoreAuth';
import useGetProjectFileNames from '~/src/Files/hooks/useGetProjectFileNames';
import { selectedFileNameState, selectedProductionFileNameState, selectedSourceState, Sources } from '~/src/Files/store';
import useToggle from '~/src/hooks/useToggle';
import InputFieldWithAction from '~/src/components/InputFieldWithAction';
import useProjectUpdate from '~/src/Projects/hooks/useProjectUpdate';
import ViewSwitcher from '~/src/ProjectResults/components/ViewSwitcher';
import { selectedViewState } from '~/src/ProjectResults/store';
import HelperButton from '~/src/Helper/components/HelperButton';
import useFind from '~/src/Projects/hooks/useFind';
import useFindOneOnUpdate from '~/src/Projects/hooks/useFindOneOnUpdate';
import OutdatedWarning from './OutdatedWarning';
import useProjectHistory from '~/src/HistoryDialog/hooks/useProjectHistory';
import { Job, isOutdatedProjectSelector } from '~/src/HistoryDialog/store';
import FastTrainingWarning from './FastTrainingWarning';
import useScriptStatus from '../hooks/useScriptStatus';

const BinaryProject = React.lazy(() => import('./BinaryProject', { preload: false } as any));
const TimeSeriesProject = React.lazy(() => import('./TimeSeriesProject', { preload: false } as any));

const useStyles = makeStyles(({ spacing, typography, palette }) => ({
  name: {
    cursor: 'text',
    '&:hover': {
      background: 'rgba(0, 0, 0, .035)',
    },
  },
  editNameInput: {
    padding: spacing(0),
    borderWidth: '0 0 1px',
    borderRadius: 0,

    '& input': {
      paddingTop: 0,
      paddingBottom: 0,
      lineHeight: '1.235rem',
      fontSize: '2.125rem',
      fontWeight: typography.fontWeightRegular,
    },
  },
  type: {
    marginLeft: spacing(2),
    color: palette.text.secondary,
    alignSelf: 'flex-end',
  },
}));

const Project = () => {
  const classes = useStyles();
  const { t } = useTranslation(['projects', 'projectTypes']);
  const { push } = useHistory();
  const projectId = useProjectId();
  const [name, setName] = useRecoilState(displayName);
  const [type, setType] = useRecoilState(projectType);
  const setCurrentProjectId = useSetRecoilState(currentProjectIdState);
  const setSelectedSource = useSetRecoilState(selectedSourceState);
  const setProjectIdState = useSetRecoilState(projectIdState);
  const [state, setState] = useRecoilState(projectState);
  const [selectedView, setSelectedView] = useRecoilState(selectedViewState);
  const projectLoading = useRecoilValue(pendingFind);
  const entryProject = useRecoilValue(entryProjectSelector(projectId));
  const resetProject = useResetProject();
  useTitle(name);
  const trainingFileName = useRecoilValue(selectedFileNameState);
  const productionFileName = useRecoilValue(selectedProductionFileNameState);
  const isSkippedProductionFile = useRecoilValue(isSkippedProductionFileState(projectId));
  const panelIndicator = useRecoilValue(panelIndicatorState);
  const [findOne] = useFindOne(projectId);
  const [getProjectFileNames] = useGetProjectFileNames();
  const [editNameModeEnabled, , disableEditNameMode, toggleEditNameMode] = useToggle(false);
  const [updateProject] = useProjectUpdate();
  const isReady = useMemo(() => [ProjectState.Ready, ProjectState.Done].includes(state), [state]);
  const [find] = useFind();
  const entryFile = useRecoilValue(entryFileSelector(projectId));
  const isOutdatedProject = useRecoilValue(isOutdatedProjectSelector(projectId));
  const fastTrainedProject = useRecoilValue(isFastTrainedProjectSelector(projectId));
  const { getHistory } = useProjectHistory(projectId);
  const automodelerStatus = useScriptStatus(ScriptNames.Automodeler);

  useFirestoreToken();
  useFirestoreAuth();
  useProjectState(projectId);
  useFindOneOnUpdate(projectId);

  const handleClose = () => {
    push('/projects');
    if (
      entryFile?.trainingFile?.connectorType !== entryProject?.files?.training.connectorType
      || entryFile?.productionFile?.connectorType !== entryProject?.files?.production.connectorType
    ) {
      find();
    }
  };

  const handleStepPanelClick = (redirectState: ProjectState) => {
    if (!trainingFileName || (!productionFileName && redirectState === ProjectState.Ready)) {
      getProjectFileNames();
      setSelectedSource(Sources.Connectors);
    }
    setState(redirectState);
  };

  useEffect(() => {
    // the next console log added for debug purpose
    console.log(`%cProject ID: %c${projectId}`, 'color: #00377C; font-size: large', 'color: #F51436; font-size: large');

    const setCurrentProject = (project) => {
      if (project) {
        setCurrentProjectId(project._id);
        setName(project.displayName);
        setType(project.type);
        setState(project.state);
      }
    };

    const getProject = async () => {
      const data = await findOne();
      if (!data) {
        push('/projects');
      } else {
        setCurrentProject(data.project);
      }
    };

    if (!entryProject) {
      getProject();
    } else {
      setCurrentProject(entryProject);
    }

    getHistory(0, Job.Analyzing, 1); // get last analyzing history

    setProjectIdState(projectId);

    return resetProject;
  }, [projectId]);

  useEffect(() => {
    if (!trainingFileName && entryProject && type) {
      getProjectFileNames();
      setSelectedSource(Sources.Connectors);
    }
  }, [entryProject, type]);

  const handleChangeProjectName = async (newName) => {
    try {
      await updateProject(projectId, { displayName: newName }, t('updateProject.errors.changeName'));
      setName(newName);
      toggleEditNameMode();
    } catch (error) { }
  };

  return (
    <Box display="flex" flexDirection="column" position="relative" flex="auto" mb={25}>
      {projectLoading && (
        <Delayed>
          <LoaderOverlay />
        </Delayed>
      )}
      <Container>
        <Header onClose={handleClose}>
          {!editNameModeEnabled && (
            <Tooltip title={t('updateProject.name.clickToEdit')} arrow enterDelay={500} enterNextDelay={500}>
              <Typography variant="h4" onClick={toggleEditNameMode} className={classes.name}>
                {name}
              </Typography>
            </Tooltip>
          )}
          {editNameModeEnabled && (
            <ClickAwayListener onClickAway={disableEditNameMode}>
              <InputFieldWithAction
                autoFocus
                className={cx('seer-project-prevent-default', classes.editNameInput)}
                disableActionWithoutChanges
                value={name}
                actionIcon={SaveIcon}
                disableActionOnEmpty
                actionTooltip={t('projects:updateProject.name.saveButtonTooltip')}
                onActionClick={handleChangeProjectName}
              />
            </ClickAwayListener>
          )}
          <Typography variant="subtitle1" className={classes.type} title={`project ID: ${projectId}`} >
            {t(`projectTypes:${type}.name`)}
          </Typography>
        </Header>
        {isOutdatedProject && <OutdatedWarning />}
        {(fastTrainedProject === ConditionControl.Enabled) && automodelerStatus.isScriptDone && <FastTrainingWarning />}
        <Box display="flex" alignItems="center" justifyContent="space-between">
          {type && (
            <StepPanel
              projectState={state}
              onCancel={handleClose}
              type={type}
              realState={entryProject?.state || state}
              onClick={handleStepPanelClick}
              skippedStep={(isSkippedProductionFile && PanelIndicatorSteps.ProductionFile) || null}
              panelIndicator={panelIndicator}
            />
          )}
          {isReady && <ViewSwitcher onSelect={setSelectedView} selected={selectedView} />}
        </Box>
        <Suspense fallback={<LoaderOverlay />}>
          {type && (type === ProjectType.TimeSeriesForecast ? <TimeSeriesProject /> : <BinaryProject />)}
        </Suspense>
      </Container>
      <HelperButton />
    </Box>
  );
};

export default React.memo(Project);
