/* eslint-disable react/forbid-prop-types */
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import cx from 'classnames';

const useStyles = makeStyles(({ spacing, palette, shape }) => ({
  moreTagsButton: {
    padding: '2px 7px',
  },
  popover: {
    '& .MuiPaper-root': {
      borderRadius: shape.borderRadius * 2,
    },
  },
  tag: {
    maxWidth: spacing(43),
    marginRight: spacing(2),
    marginBottom: spacing(2),
  },
  firstTag: {
    maxWidth: spacing(24.5),
    marginRight: spacing(1),
  },
  tagsContainer: {
    maxWidth: spacing(51),
    display: 'flex',
    flexWrap: 'wrap',
    backgroundColor: palette.text.primary,
    borderRadius: shape.borderRadius * 2,
    padding: `${spacing(4)}px ${spacing(2)}px ${spacing(2)}px ${spacing(4)}px`,    
  },
}));

const ProjectTags = ({ tags, className, moreTagsButtonClassName }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const ref = useRef();

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const [firstTag, ...restTags] = tags;

  return (
    <>
      <Chip label={firstTag.displayName} size="small" className={classes.firstTag} />
      {!!restTags.length && (
        <>
          <Button onClick={handleClick} variant="outlined" className={cx(moreTagsButtonClassName, classes.moreTagsButton)}>
            {`+${restTags.length}`}
          </Button>
          <Popover
            ref={ref}
            className={cx(classes.popover, className, 'seer-project-prevent-default')}
            open={open}
            getContentAnchorEl={null}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Box className={classes.tagsContainer}>
              {restTags.map((tag) => (
                <Chip key={tag.tagId} label={tag.displayName} size="small" className={classes.tag} />
              ))}
            </Box>
          </Popover>
        </>
      )}
    </>
  );
};

ProjectTags.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      tagId: PropTypes.number,
      displayName: PropTypes.string,
    })
  ).isRequired,
  className: PropTypes.string,
  moreTagsButtonClassName: PropTypes.string,
};

ProjectTags.defaultProps = {
  className: '',
  moreTagsButtonClassName: '',
};

export default ProjectTags;
