/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-shadow */
import { atom, atomFamily, constSelector, selector, selectorFamily } from 'recoil';
import { authenticatedUserEmailState } from '~/src/Auth/store';
import {
  ExportOptions, Project, projectIdState, ProjectState, ProjectType, ScriptNames, ScriptsSteps, ScriptStatus, UserRole,
} from '~/src/Project/store';
import { Folder } from '~/src/ProjectFolder/store';

export enum ConditionControl {
  Enabled = 'enabled',
  Disabled = 'disabled',
}

export enum EntryKind {
  Project = 'project',
  Folder = 'folder',
}

export enum JobType {
  Analyze = 'analyze',
  Predict = 'predict',
}

export interface Entry {
  _id?: string;
  folder?: Folder;
  project?: Project;
  export?: ExportOptions;
  [key: string]: any;
}

export interface ProjectListResults {
  entries: Entry[];
  hasMore: boolean;
}

export interface IProjectNotification {
  projectId?: string;
  notificationId: string;
  state: ProjectState;
  step?: ScriptsSteps;
  error?: any;
}

export enum ProjectMenuActions {
  // EditTags = 'editTags',
  ShowResults = 'showResults',
  StopProcess = 'stopProcess',
  DeleteProject = 'deleteProject',
  ScheduleJob = 'scheduleJob',
  ProjectHistory = 'projectHistory',
  ExecuteTraining = 'executeTraining',
  ExecutePrediction = 'executePrediction',
  CopyProject = 'copyProject',
  ShareProject = 'shareProject',
}

export const isTouchDeviceSelector = constSelector(
  'ontouchstart' in window || navigator.maxTouchPoints > 0 || (window.navigator as any).msMaxTouchPoints > 0,
);

export const projectListState = atom<ProjectListResults>({
  key: 'Projects/projectListState',
  default: {
    entries: [],
    hasMore: false,
  },
});

export const pendingFind = atom({
  key: 'Projects/pendingFind',
  default: true,
});

export const pendingLoadMore = atom({
  key: 'Projects/pendingLoadMore',
  default: false,
});

export const findError = atom({
  key: 'Projects/findError',
  default: null,
});

export const entriesState = selector<Entry[]>({
  key: 'Projects/entries',
  get: ({ get }) => get(projectListState).entries,
  set: ({ get, set }, newEntries) => {
    const { entries, ...rest } = get(projectListState);
    set(projectListState, {
      ...rest,
      entries: newEntries,
    });
  },
});

export const hasEntriesSelector = selector({
  key: 'Projects/hasEntriesSelector',
  get: ({ get }) => !!get(projectListState).entries.length,
});

export const wereProjectsLoadedState = atom({
  key: 'Projects/wereProjectsLoadedState',
  default: false,
});

export const projectSelector = selectorFamily({
  key: 'Projects/projectSelector',
  get:
    (projectId: number) => ({ get }) => get(projectListState)?.entries?.find((item) => item?.project?._id === projectId)?.project,
});

export const projectErrorsSelector = selectorFamily({
  key: 'Projects/projectErrorsSelector',
  get:
    (projectId: number) => ({ get }) => {
      const entry = get(projectSelector(projectId));
      return Object.values(ScriptNames).reduce((acc, scriptName) => {
        if (!entry[scriptName]?.error) return acc;
        if (!acc) {
          acc = {} as any;
        }
        acc[scriptName] = entry[scriptName]?.error;
        return acc;
      }, null);
    },
});

export const isPredictingSelector = selectorFamily({
  key: 'Projects/isPredictingSelector',
  get:
    (projectId: number) => ({ get }) => {
      const project = get(projectSelector(projectId));
      if (!project) return false;
      const isPredictionPending = project[ScriptNames.Prediction]?.state === ScriptStatus.Pending;
      return isPredictionPending;
    },
});

export const isTrainingSelector = selectorFamily({
  key: 'Projects/isTrainingSelector',
  get:
    (projectId: number) => ({ get }) => {
      const project = get(projectSelector(projectId));
      if (!project) return false;
      const isTrainingPending = project[ScriptNames.Automodeler]?.state === ScriptStatus.Pending;
      return isTrainingPending;
    },
});

export const ready = selectorFamily({
  key: 'Projects/ready',
  get:
    (projectId: number) => ({ get }) => {
      const project = get(projectSelector(projectId));
      if (!project) return true;
      const isPredictionPending = get(isPredictingSelector(projectId));
      return (
        !isPredictionPending
        && ![
          ProjectState.Processing,
          ProjectState.Training,
          ProjectState.ProcessingTrainingFile,
          ProjectState.TimeSeriesPreprocessing,
          ProjectState.TimeSeriesPrediction,
        ].includes(project.state)
      );
    },
});

export const isParsingSelector = selectorFamily({
  key: 'Projects/isParsingSelector',
  get:
    (projectId: number) => ({ get }) => get(projectSelector(projectId))?.state === ProjectState.ProcessingTrainingFile,
});

export const entryProjectSelector = selectorFamily({
  key: 'Projects/entry',
  get:
    (projectId: number) => ({ get }) => get(projectSelector(projectId)),
  set:
    (projectId: number) => ({ get, set }, newValue: Project) => {
      const current = get(projectListState);
      let { entries: projectEntries } = current;
      const index = projectEntries.findIndex((e) => e.project?._id === projectId);

      if (index >= 0) {
        projectEntries = [...projectEntries];
        projectEntries[index] = { ...projectEntries[index], project: newValue };
      } else {
        projectEntries = [{ _id: `${Math.random()}`, project: newValue }, ...projectEntries];
      }

      set(projectListState, {
        ...current,
        entries: projectEntries,
      });
    },
});

export const exportConfigSelector = selectorFamily({
  key: 'Projects/exportConfigSelector',
  get:
    (projectId: number) => ({ get }) => get(projectSelector(projectId)).export,
  set:
    (projectId: number) => ({ get, set }, newValue: ExportOptions) => {
      const current = get(projectListState);
      const { entries: projectEntries } = current;
      set(projectListState, {
        ...current,
        entries: projectEntries.map((item) => (item.project?._id !== projectId
          ? item
          : { ...item, project: { ...item.project, export: { ...(item.project.export || {}), ...newValue } } })),
      });
    },
});

export const projectUsersSelector = selectorFamily({
  key: 'Projects/projectUsersSelector',
  get:
    (projectId: number) => ({ get }) => {
      const project = get(projectSelector(projectId));
      return project?.users;
    },
});

export const projectCurrentUserSelector = selectorFamily({
  key: 'Projects/projectCurrentUserSelector',
  get:
    (projectId: number) => ({ get }) => {
      const users = get(projectUsersSelector(projectId));
      if (!users) return null;
      const email = get(authenticatedUserEmailState);
      return users.find((user) => user.email === email);
    },
});

export const isUserEditorSelector = selectorFamily({
  key: 'Projects/isUserEditorSelector',
  get:
    (projectId: number) => ({ get }) => {
      const user = get(projectCurrentUserSelector(projectId));
      if (!user) return null;
      return user.role === UserRole.Editor || user.role === UserRole.Owner;
    },
});

export const isReadOnlyProjectSelector = selector({
  key: 'Projects/isReadOnlyProjectSelector',
  get: ({ get }) => {
    const projectId = get(projectIdState);
    const user = get(projectCurrentUserSelector(projectId));
    if (!user) return true;
    return user.role === UserRole.Viewer || user.role === UserRole.Demo;
  },
});

export const isReadOnlyProjectFamilySelector = selectorFamily({
  key: 'Projects/isReadOnlyProjectFamilySelector',
  get: (projectId: number) => ({ get }) => {
    if (!projectId) return get(isReadOnlyProjectSelector);
    const user = get(projectCurrentUserSelector(projectId));
    if (!user) return true;
    return user.role === UserRole.Viewer || user.role === UserRole.Demo;
  },
});

export const isUserOwnerSelector = selectorFamily({
  key: 'Projects/isUserOwnerSelector',
  get:
    (projectId: number) => ({ get }) => {
      const user = get(projectCurrentUserSelector(projectId));
      if (!user) return null;
      return user.role === UserRole.Owner;
    },
});

export const stoppingProcessState = atom<{ [key: number]: boolean }>({
  key: 'Projects/stoppingProcessState',
  default: {},
});

export const pendingDeleteProject = atom({
  key: 'Projects/pendingDeleteProject',
  default: false,
});

export const pendingCopyProjectState = atom({
  key: 'Projects/pendingCopyProjectState',
  default: false,
});

export const pendingMoveToFolder = atom<number>({
  key: 'Projects/pendingMoveToFolder',
  default: null,
});

export const pendingProjectUpdateState = atom({
  key: 'Projects/pendingProjectUpdateState',
  default: false,
});

export const pendingProjectsGridSelector = selector({
  key: 'Projects/pendingProjectsGridSelector',
  get: ({ get }) => get(pendingDeleteProject) || get(pendingCopyProjectState),
});

export const needRestartProjectsPageState = atom({
  key: 'Projects/initialLoadState',
  default: false,
});

// was "fast training" ENABLED or DISABLED during the last training(automodeler) run
// value is from firestore (could be different from project.reports.automodeler)
export const isFastTrainedProjectSelector = selectorFamily({
  key: 'Projects/isFastTrainedProjectSelector',
  get:
    (id?: number) => ({ get }) => {
      const projectId = id || get(projectIdState);
      if (!projectId) return ConditionControl.Disabled;
      const project = get(projectSelector(projectId));
      if (!project) return ConditionControl.Disabled;
      return project[ScriptNames.Automodeler]?.isFastTraining || ConditionControl.Disabled;
    },
});

export const fastTrainingState = atomFamily<ConditionControl, number>({
  key: 'Projects/fastTrainingState',
  default: selectorFamily({
    key: 'Projects/fastTrainedReportSelector',
    get:
      (id?: number) => ({ get }) => {
        const projectId = id || get(projectIdState);
        if (!projectId) return ConditionControl.Disabled;
        const project = get(projectSelector(projectId));
        if (!project) return ConditionControl.Disabled;
        // reports.automodeler.isFastTraining - store (in DB) the last user's selection
        // the next training(automodeler) run will use this value
        return project.reports[ScriptNames.Automodeler]?.isFastTraining || ConditionControl.Disabled;
      },
  }),
});

export const wasFastTrainedChangedSelector = selectorFamily({
  key: 'Projects/wasFastTrainedChangedSelector',
  get:
    (projectId: number = null) => ({ get }) => get(isFastTrainedProjectSelector(projectId)) !== get(fastTrainingState(projectId)),
});

export const isTrainingAvailableSelector = selectorFamily({
  key: 'Projects/isTrainingAvailableSelector',
  get: (projectId: number) => ({ get }) => {
    const project = get(projectSelector(projectId));
    let availability = false;
    // TODO remove the next "if" block below when Time-Series training will be developed
    if (project.type === ProjectType.TimeSeriesForecast) {
      return false;
    }
    if (
      [
        ProjectState.Ready,
        ProjectState.PendingTraining,
        ProjectState.Training,
        ProjectState.PendingProductionFile,
        ProjectState.UploadingProductionFile,
        ProjectState.Processing,
      ].find((currentState) => currentState === project.state)
    ) {
      availability = true;
    }

    return availability;
  },
});

export const isNeedProjectUpdateSelector = atomFamily<boolean, number>({
  key: 'Projects/isNeedProjectUpdateSelector',
  default: false,
});
