import { ColDef } from '@ag-grid-community/core';
import { atom, selector } from 'recoil';
import { configuredVariablesState, ITrainingConfigVariable, variablesState, VariableType } from '../store';

export enum TimeSeriesTrainingStep {
  TrainingFile = 0,
  DependentVariable = 1,
  Timeframe = 2,
  Predicting = 3,
  None = -1,
}

export interface Outliers {
  'Count': string,
  'Outlier Confidence': string;
  [key: string]: string;
}

export enum KeepDuplicates {
  True = 'true',
  False = 'false',
}

export enum AggregateDuplicates {
  Mean = 'mean',
  Addition = 'addition',
  False = 'none',
}

export enum AggregateToHigherLevel {
  True = 'true',
  False = 'false',
}

export enum AggregateToHigherLevelType {
  Mean = 'mean',
  Addition = 'addition',
}

export const timeVariableSelector = selector({
  key: 'TrainingFile/timeVariableSelector',
  get: ({ get }) => {
    const variables = get(variablesState);
    const config = get(configuredVariablesState);
    const timeVariable = config?.find((item) => item.state === VariableType.TimeSeriesDate) || ({} as ITrainingConfigVariable);
    const index = config?.findIndex((item) => item.state === VariableType.TimeSeriesDate);
    const { displayName } = variables?.headers.find((item) => item.variableId === timeVariable.variableId) || {};
    return { ...timeVariable, index, displayName };
  },
});

export const valueVariableSelector = selector({
  key: 'TrainingFile/valueVariableSelector',
  get: ({ get }) => {
    const variables = get(variablesState);
    const config = get(configuredVariablesState);
    const valueVariable = config?.find((item) => item.state === VariableType.TimeSeriesValue) || ({} as ITrainingConfigVariable);
    const index = config?.findIndex((item) => item.state === VariableType.TimeSeriesValue);
    const { displayName } = variables?.headers.find((item) => item.variableId === valueVariable.variableId) || {};
    return { ...valueVariable, index, displayName };
  },
});

export const timeSeriesPreprocessingResultState = atom({
  key: 'TrainingFile/timeSeriesPreprocessingResultState',
  default: null,
});

export const selectedTimeframeState = atom({
  key: 'TrainingFile/selectedTimeframeState',
  default: null,
});

export const predictionStepsState = atom({
  key: 'TrainingFile/predictionStepsState',
  default: 1,
});

export const dateFormatState = atom({
  key: 'TrainingFile/dateFormatState',
  default: null,
});

export const isTimeframeDefaultSelector = selector({
  key: 'TrainingFile/isTimeframeDefaultSelector',
  get: ({ get }) => {
    const timeSeriesPreprocessingResult = get(timeSeriesPreprocessingResultState);
    const selectedTimeframe = get(selectedTimeframeState);
    if (!timeSeriesPreprocessingResult || !selectedTimeframe) return true;
    return timeSeriesPreprocessingResult.periodPredicted.toLowerCase() === selectedTimeframe;
  },
});

export const keepDuplicatesState = atom({
  key: 'TrainingFile/keepDuplicatesState',
  default: KeepDuplicates.False,
});

export const aggregateDuplicatesState = atom({
  key: 'TrainingFile/aggregateDuplicatesState',
  default: AggregateDuplicates.False,
});

export const outliersState = atom<Outliers[]>({
  key: 'TrainingFile/outliersState',
  default: null,
});

export const selectedOutliersState = atom<Outliers[]>({
  key: 'TrainingFile/selectedOutliersState',
  default: null,
});

export const pendingSaveOutliersState = atom({
  key: 'TrainingFile/pendingSaveOutliersState',
  default: false,
});

export const pendingOutliersState = atom({
  key: 'TrainingFile/pendingOutliersState',
  default: false,
});

export const pendingDuplicatesState = atom({
  key: 'TrainingFile/pendingDuplicatesState',
  default: false,
});

export const duplicatesState = atom({
  key: 'TrainingFile/duplicatesState',
  default: null,
});

const numberComparator = (valueA: string | number, valueB: string | number) => +valueA - (+valueB);

export const outliersTableDataSelector = selector({
  key: 'TrainingFile/outliersTableDataSelector',
  get: ({ get }) => {
    const outliers = get(outliersState);
    if (!outliers) return null;
    const valueVariable = get(valueVariableSelector);
    const columns = Object.keys(outliers[0]).reduce((acc, key) => {
      if (key === 'Count') return acc;

      const col: ColDef = { field: key };

      if (valueVariable.displayName === key) {
        col.cellClass = 'squark-ts-value-column';
        col.headerClass = 'squark-ts-value-column';
        col.comparator = numberComparator;
      }

      if (key === 'Outlier Confidence') {
        col.valueFormatter = (params) => `${(+params.value * 100).toFixed(2)} %`;
        col.comparator = numberComparator;
      }

      acc.push(col);
      return acc;
    }, [] as ColDef[]);
    return { columns, rows: outliers };
  },
});

export const duplicatesTableDataSelector = selector({
  key: 'TrainingFile/duplicatesTableDataSelector',
  get: ({ get }) => {
    const duplicates = get(duplicatesState);
    if (!duplicates) return null;
    const columns = Object.keys(duplicates[0]).map((key) => {
      const col: ColDef = { field: key };
      return col;
    });
    return { columns, rows: duplicates };
  },
});

export const aggregateToHigherLevelState = atom({
  key: 'TrainingFile/aggregateToHigherLevelState',
  default: AggregateToHigherLevel.True,
});

export const aggregateToHigherLevelTypeState = atom({
  key: 'TrainingFile/aggregateToHigherLevelTypeState',
  default: AggregateToHigherLevelType.Mean,
});

export const pendingTSPredictionState = atom({
  key: 'TrainingFile/pendingTSPredictionState',
  default: false,
});

export const isAnalyzingDialogOpenState = atom({
  key: 'TrainingFile/isAnalyzingDialogOpenState',
  default: false,
});
