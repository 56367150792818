import { atom, selector, selectorFamily } from 'recoil';
import { ObjectIndexedNumbers, ObjectIndexedStrings } from '~/src/store';

export enum ChartSortDirection {
  Asc = 'ascending',
  Desc = 'descending',
  Alphabetically = 'alphabetically',
}

export enum ChartType {
  Histogram = 'histogram',
  AutoCorrelation = 'autocorrelation',
  MovingAverage = 'movingAverage',
  ConfidenceWithTheBest = 'confidenceWithTheBest',
  DenoisingBefore = 'denoisingBefore',
  DenoisingAfter = 'denoisingAfter',
  HotLinear = 'hotLinear',
  SeasonalResidue = 'seasonalResidue',
}

export interface PredictionDataRow {
  index?: string;
  Prediction: string;
  Lower_bound_68: string;
  Lower_bound_95: string;
  Upper_bound_68: string;
  Upper_bound_95: string;
}

export interface ChartsData {
  categories?: string[];
  values: number[];
  className?: string;
}

export interface ChartDataItem {
  category?: string;
  value: number;
  className?: string;
}

export type ShaplyChartData = Array<Array<ChartDataItem>>;

export const shaplyChartDataState = atom<ShaplyChartData>({
  key: 'Charts/shaplyChartDataState',
  default: null,
});

export const pendingChartDataState = atom({
  key: 'Charts/pendingChartDataState',
  default: false,
});

export const pendingForcePlotChartDataState = atom({
  key: 'Charts/pendingForcePlotChartDataState',
  default: false,
});

export interface ForcePlotData {
  class?: ObjectIndexedStrings;
  base_value: ObjectIndexedNumbers | number;
  calculated_shap: ObjectIndexedNumbers | number;
  feature: ObjectIndexedStrings;
  shap: { [key: string]: ObjectIndexedStrings } | ObjectIndexedStrings
}

interface ForcePlotChartDataState {
  [key: string]: ForcePlotData
}

export const forcePlotChartDataState = atom<ForcePlotChartDataState>({
  key: 'Charts/forcePlotChartDataState',
  default: {} as ForcePlotChartDataState,
});

export const forcePlotChartDataSelector = selectorFamily({
  key: 'Charts/forcePlotChartDataSelector',
  get:
    (rowId: string) => ({ get }) => {
      const data = get(forcePlotChartDataState);
      return data[rowId] || null;
    },
});

export const pendingHistogramChartDataState = atom({
  key: 'Charts/pendingHistogramChartDataState',
  default: false,
});

export const histogramChartDataState = atom<string[][]>({
  key: 'Charts/histogramChartDataState',
  default: null,
});

export const pendingMovingAverageChartDataState = atom({
  key: 'Charts/pendingMovingAverageChartDataState',
  default: false,
});

export const movingAverageChartDataState = atom<string[][]>({
  key: 'Charts/movingAverageChartDataState',
  default: null,
});

export const autocorrelationChartDataState = atom<string[][]>({
  key: 'Charts/autocorrelationChartDataState',
  default: null,
});

export const pendingAutoCorrelationChartDataState = atom({
  key: 'Charts/pendingAutoCorrelationChartDataState',
  default: false,
});

export const confidenceWithTheBestChartDataState = atom<string[][]>({
  key: 'Charts/confidenceWithTheBestChartDataState',
  default: null,
});

export const pendingConfidenceWithTheBestChartDataState = atom({
  key: 'Charts/pendingConfidenceWithTheBestChartDataState',
  default: false,
});

export const denoisingBeforeChartDataState = atom<string[][]>({
  key: 'Charts/denoisingBeforeChartDataState',
  default: null,
});

export const pendingDenoisingBeforeChartDataState = atom({
  key: 'Charts/pendingDenoisingBeforeChartDataState',
  default: false,
});

export const denoisingAfterChartDataState = atom<string[][]>({
  key: 'Charts/denoisingAfterChartDataState',
  default: null,
});

export const pendingDenoisingAfterChartDataState = atom({
  key: 'Charts/pendingDenoisingAfterChartDataState',
  default: false,
});

export const hotLinearChartDataState = atom<string[][]>({
  key: 'Charts/hotLinearChartDataState',
  default: null,
});

export const pendingHotLinearChartDataState = atom({
  key: 'Charts/pendingHotLinearChartDataState',
  default: false,
});

export const seasonalResidueChartDataState = atom<string[][]>({
  key: 'Charts/seasonalResidueChartDataState',
  default: null,
});

export const pendingSeasonalResidueChartDataState = atom({
  key: 'Charts/pendingSeasonalResidueChartDataState',
  default: false,
});

export const isNoiseDataAvailableSelector = selector({
  key: 'Charts/isNoiseDataAvailableSelector',
  get: ({ get }) => !!get(denoisingBeforeChartDataState)
    || !!get(denoisingAfterChartDataState)
    || !!get(hotLinearChartDataState)
    || !!get(seasonalResidueChartDataState),
});

export const isNoiseDataPendingSelector = selector({
  key: 'Charts/isNoiseDataPendingSelector',
  get: ({ get }) => get(pendingDenoisingBeforeChartDataState)
    || get(pendingDenoisingAfterChartDataState)
    || get(pendingHotLinearChartDataState)
    || get(pendingSeasonalResidueChartDataState),
});
