import { atom, selectorFamily } from 'recoil';
import { modelNameMapper } from './results.store';
import { ImportanceCoefficients, VarImportanceColumns, ObjectWithNumbers, VarimpArray, VarimpData } from '../result.types';

export const importanceVariablesState = atom({
  key: 'Results/importanceVariablesState',
  default: null,
});

export const modelsCoefficientsState = atom({
  key: 'Results/modelsCoefficientsState',
  default: null,
});

export const pendingImportanceVariablesState = atom({
  key: 'Results/pendingImportanceVariables',
  default: false,
});

const isStackedEnsemble = (model) => ['Sta', 'sta', 'StackedEnsemble', 'se'].includes(model);
const isGLM = (model) => ['GLM', 'glm'].includes(model);

const getGLMGridData = (data: ImportanceCoefficients) => {
  const keys = Object.keys(data);
  let headers: string[] = [];
  let rows: (string | number)[][];
  if (typeof data[keys[0]] === 'object') {
    // handle multi-class GLM
    rows = keys.reduce((acc, categoryKey) => {
      // "coefs_class_" is a prefix before category(class) name in GLM_stats.json
      const categoryName = categoryKey.split('_class_').pop();
      const { Intercept, ...categoryData } = data[categoryKey] as ObjectWithNumbers;
      const categoryRows = Object
        .entries(categoryData)
        .map((item) => [categoryName, ...item] as [string, string, number]);
      categoryRows.sort((a, b) => b[2] - a[2]);
      acc.push(...categoryRows);
      return acc;
    }, []);
    headers = [VarImportanceColumns.Category, VarImportanceColumns.Attribute, VarImportanceColumns.Coefficients];
  } else {
    const shouldBeThreeColumns = keys.some((item) => item.includes('.'));
    rows = keys.map((key) => {
      if (!shouldBeThreeColumns) {
        return [key, data[key] as number];
      }
      const split: string[] = key.split('.');
      return [split[0], split[1] || '', data[key] as number];
    });
    rows.sort((a, b) => +b[b.length - 1] - +a[a.length - 1]);
    headers = rows[0]?.length === 3
      ? [VarImportanceColumns.Attribute, VarImportanceColumns.Category, VarImportanceColumns.Coefficients]
      : [VarImportanceColumns.Attribute, VarImportanceColumns.Coefficients];
  }
  return [headers, ...rows];
};

const getSEGridClassData = (data: ObjectWithNumbers) => {
  const keys = Object.keys(data);
  const result = keys.map((key) => [modelNameMapper[key.split('_')[0]], data[key]]);
  const indexingHelper: { [name: string]: number } = {};
  result.forEach((item) => {
    const key = item[0];
    if (!indexingHelper[key]) {
      indexingHelper[key] = 2;
    } else {
      item[0] = `${key} ${indexingHelper[key]}`;
      indexingHelper[key] += 1;
    }
  });
  result.sort((a, b) => b[1] - a[1]);
  return [[VarImportanceColumns.Model, VarImportanceColumns.Coefficients], ...result] as (string | number)[][];
};

const getSEGridData = (data: ImportanceCoefficients) => {
  const keys = Object.keys(data);
  if (typeof data[keys[0]] !== 'object') {
    return getSEGridClassData(data as ObjectWithNumbers);
  }

  // handle multi-class SE
  const results = [];
  keys.forEach((category, i) => {
    const { Intercept, ...categoryData } = data[category] as ObjectWithNumbers;
    const gridClassDataResults = getSEGridClassData(categoryData);
    if (!i) {
      gridClassDataResults[0].unshift(VarImportanceColumns.Category);
      results.push(gridClassDataResults[0]);
    }
    results.push(...gridClassDataResults.reduce((acc, row, index) => {
      if (index) {
        // "std_coefs_class_" is a prefix before category(class) name in Sta_stats.json
        row.unshift(category.split('_class_').pop());
        acc.push(row);
      }

      return acc;
    }, [] as (string | number)[][]));
  });
  return results as (string | number)[][];
};

const getGridClassData = (data: VarimpArray[]) => {
  const shouldBeThreeColumns = data.some((item) => item[0].includes('.'));
  const result = data.map((item) => {
    if (!shouldBeThreeColumns) {
      return [item[0], item[3]];
    }
    const split = item[0].split('.');
    return [split[0], split[1] || '', item[3]];
  });
  result.sort((a, b) => +b[b.length - 1] - +a[a.length - 1]);
  const headers = result[0]?.length === 3
    ? [VarImportanceColumns.Attribute, VarImportanceColumns.Category, VarImportanceColumns.Varimp]
    : [VarImportanceColumns.Attribute, VarImportanceColumns.Varimp];

  return [headers, ...result] as (string | number)[][];
};

const getGridData = (data: VarimpData) => {
  if (Array.isArray(data)) {
    return getGridClassData(data);
  }

  // handle multi-class
  const keys = Object.keys(data);
  const results = [];
  keys.forEach((category, i) => {
    const categoryData = data[category];
    const gridClassDataResults = getGridClassData(categoryData);
    if (!i) {
      gridClassDataResults[0].unshift(VarImportanceColumns.Category);
      results.push(gridClassDataResults[0]);
    }
    results.push(...gridClassDataResults.reduce((acc, row, index) => {
      if (index) {
        // "varimp_class_" is a prefix before category(class) name in <Model>_stats.json
        row.unshift(category.split('_class_').pop());
        acc.push(row);
      }

      return acc;
    }, [] as (string | number)[][]));
  });
  return results as (string | number)[][];
};

// getting grid data (for all models) is present in packages\exportConnector\src\Export\services\variablesImportance.service.ts
// if you need to change something in this logic than it should be changed in both files
export const getImportanceGridDataSelector = selectorFamily({
  key: 'Results/getImportanceGridData',
  get:
    (modelId: string) => ({ get }) => {
      const input = get(importanceVariablesState);
      const data = input && input[modelId];
      if (!data) return null;
      const [key] = Object.keys(data);
      const isVarimp = (Array.isArray(data) && Array.isArray(data[0])) || (Array.isArray(data[key]) && Array.isArray(data[key][0]));

      switch (true) {
        case isVarimp:
          return getGridData(data);
        case isGLM(modelId):
          return getGLMGridData(data);
        case isStackedEnsemble(modelId):
          return getSEGridData(data);
        default:
          return null;
      }
    },
});

// getting grid data (for all models) is present in packages\exportConnector\src\Export\services\variablesImportance.service.ts
// if you need to change something in this logic than it should be changed in both files
export const getModelCoefficientsGridDataSelector = selectorFamily({
  key: 'Results/getModelCoefficientsGridDataSelector',
  get:
    (modelId: string) => ({ get }) => {
      const input = get(modelsCoefficientsState);
      const data = input && input[modelId];

      switch (true) {
        case !data:
          return null;
        case isGLM(modelId):
          return getGLMGridData(data);
        case isStackedEnsemble(modelId):
          return getSEGridData(data);
        default:
          return null;
      }
    },
});
