/* eslint-disable consistent-return */
import useApiClient from '~/src/hooks/useApiClient';
import { SubscriptionType } from '../store';

const useEmailGetSubscription = () => {
  const apiClient = useApiClient();

  const getSubscription = async (projectId, subscriptionType: SubscriptionType) => {
    try {
      const { data } = await apiClient.get(`/emails/${projectId}/subscriptions/${subscriptionType}`);

      return data;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  return [getSubscription];
};

export default useEmailGetSubscription;
