import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import { Box, makeStyles, Typography, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CheckCircle } from '@material-ui/icons';
import cx from 'classnames';
import SelectableCard from '~/src/components/SelectableCard';
import { ProjectType } from '~/src/Project/store';
import { projectTypeImages } from '../store';

interface ProjectTypeCardProps {
  type: ProjectType;
  selected: boolean;
  tabIndex?: number
}

const useStyles = makeStyles(({ spacing, palette }) => ({
  checkIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: spacing(5),
    height: spacing(5),
    color: palette.common.white,
    borderRadius: '50%',
    border: `2px solid ${palette.custom.darkText}`,
    marginRight: spacing(4),
    '& svg': {
      width: spacing(5) + 1,
      height: spacing(5) + 1,
    },
  },
  name: {
    marginBottom: spacing(3),
    lineHeight: 1.2,
  },
  selectedCheckIcon: {
    border: 0,
  },
  description: {
    color: palette.text.secondary,
  },
  selectedDescription: {
    color: palette.custom.border,
  },
  checkMarkContainer: {
    margin: `auto ${spacing(6)}px 0`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: spacing(11),
    borderRadius: '100px',
    color: palette.text.primary,
    border: `1px solid ${palette.custom.border}`,
    backgroundColor: palette.common.white,
  },
  selectedCheckMarkContainer: {
    color: palette.common.white,
    border: 0,
    backgroundColor: palette.secondary.main,
  },
}));

const ProjectTypeCard = React.forwardRef<any, ProjectTypeCardProps>(({ type, selected, ...rest }, ref) => {
  const { t } = useTranslation('projectTypes');
  const {
    checkIcon,
    selectedCheckIcon,
    description,
    selectedDescription,
    name,
    checkMarkContainer,
    selectedCheckMarkContainer,
  } = useStyles();
  const { spacing } = useTheme();
  const images = useRecoilValue(projectTypeImages);

  const image = useMemo(() => (selected ? images[type].active : images[type].default), [type, selected]);

  return (
    <SelectableCard selectable={!selected} selected={selected} {...rest}>
      <Box py={6} width={spacing(64)} height={spacing(100)} display="flex" flexDirection="column">
        <Box display="flex" justifyContent="center">
          <img src={image} height={spacing(50)} alt={type} />
        </Box>
        <Box py={2} px={6} color={!selected ? 'text.primary' : 'primary.contrastText'}>
          <div ref={ref}>
            <Typography variant="h6" className={name}>
              {t(`${type}.name`)}
            </Typography>
          </div>
          <Typography variant="subtitle2" className={selected ? selectedDescription : description}>
            {t(`${type}.description`)}
          </Typography>
        </Box>
        <Box className={cx(checkMarkContainer, { [selectedCheckMarkContainer]: selected })}>
          <Typography component="span" className={cx(checkIcon, { [selectedCheckIcon]: selected })}>
            {selected && <CheckCircle color="inherit" />}
          </Typography>
          <Typography variant="subtitle2">{t('selectType')}</Typography>
        </Box>
      </Box>
    </SelectableCard>
  );
});

ProjectTypeCard.propTypes = {
  type: PropTypes.any.isRequired,
  selected: PropTypes.bool.isRequired,
};

export default React.memo(ProjectTypeCard);
