import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import useRefreshSession from '~/src/Auth/hooks/useRefreshSession';
import { authenticatedUserEmailState } from '~/src/Auth/store';
import useFileName from '~/src/Files/hooks/useFileName';
import { FileType, fileTypeState, pendingUploadState } from '~/src/Files/store';
import useApiClient from '~/src/hooks/useApiClient';
import { projectState, ProjectState } from '~/src/Project/store';
import { entryFileSelector, entryProjectSelector } from '~/src/Projects/store';
import { snackbarMessageSelector } from '~/src/Snackbar/store';
import { shouldCollapseFileStepState } from '~/src/TrainingFile/store';
import ConnectorsEnum from '../enums/ConnectorsEnum';

const useConnectorUpload = (projectId) => {
  const [refreshSession] = useRefreshSession();
  const apiClient = useApiClient();
  const fileType = useRecoilValue(fileTypeState);
  const setPending = useSetRecoilState(pendingUploadState);
  const [, setFileName] = useFileName();
  const { t } = useTranslation('connectors');
  const setMessage = useSetRecoilState(snackbarMessageSelector);
  const userEmail = useRecoilValue(authenticatedUserEmailState);
  const entryFile = useRecoilValue(entryFileSelector(projectId));
  const setProjectState = useSetRecoilState(projectState);
  const setShouldCollapseFileStep = useSetRecoilState(shouldCollapseFileStepState);
  const setEntry = useSetRecoilState(entryProjectSelector(projectId));

  const upload = async (parameters, uploadURL: string, fileName: string, type?: FileType) => {
    setPending(true);

    const currentFileType = type || fileType;

    if (!fileName) {
      setMessage({
        message: t('uploadDescription.errors.fileName', { fileType: currentFileType }),
        severity: 'error',
      });
      setPending(false);
      return null;
    }

    if (!(/^[#A-Za-z0-9_-]{1,}$/.test(fileName.split('.csv')[0]))) {
      setMessage({
        message: t('uploadDescription.errors.fileNamePattern'),
        severity: 'error',
      });
      setPending(false);
      return null;
    }

    try {
      await refreshSession();
      const isTrainingFileType = currentFileType === FileType.Training;
      fileName = fileName.endsWith('.csv') ? fileName : `${fileName}.csv`;
      const entryProductionFile = entryFile?.productionFile || {};
      const entryProductionFileConfig = entryProductionFile.connectorConfig;
      const trainingFileName = isTrainingFileType ? fileName : '';
      const productionFileName = isTrainingFileType ? entryProductionFileConfig?.productionFileName : fileName;

      const uploadPayload = {
        ...parameters,
        fileName,
        fileType: currentFileType,
        userEmail,
        trainingFileName,
        productionFileName,
      };

      setFileName(fileName);

      await apiClient.post(`${uploadURL}/${projectId}`, uploadPayload);
      const state = currentFileType === FileType.Training ? ProjectState.UploadingTrainingFile : ProjectState.UploadingProductionFile;
      const connType = uploadURL.match(/^\/?(\w+)\//)?.[1] as ConnectorsEnum;

      setEntry((entry) => {
        entry.files = {
          training: {
            ...(entry.files.training || {}),
            name: isTrainingFileType ? fileName : entry.files.training.name,
            connectorConfig: isTrainingFileType ? uploadPayload : entry.files.training.connectorConfig,
            connectorType: isTrainingFileType ? connType : entry.files.training.connectorType,
          },
          production: {
            ...(entry.files.production || {}),
            name: !isTrainingFileType ? fileName : entry.files.production.name,
            connectorConfig: !isTrainingFileType ? uploadPayload : entry.files.production.connectorConfig,
            connectorType: !isTrainingFileType ? connType : entry.files.production.connectorType,
          },
        };

        return entry;
      });

      setProjectState(state);
      setShouldCollapseFileStep(true);
      return true;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      const errorData: Record<string, any> = err.response?.data || {};
      const errorCode = errorData.errorCode || 'uploadFile';
      setMessage({
        message: t(`uploadDescription.errors.${errorCode}`, errorData),
        severity: 'error',
      });
      return false;
    } finally {
      setPending(false);
    }
  };

  return [upload];
};

export default useConnectorUpload;
