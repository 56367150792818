import { atom } from 'recoil';

export const isDomoFrame = atom({
  key: 'Domo/iframe',
  default: false,
});

export const domoAppId = atom({
  key: 'Domo/appid',
  default: '',
});

export const domoUserId = atom({
  key: 'Domo/userid',
  default: '',
});

export const domoUserEmail = atom({
  key: 'Domo/useremail',
  default: '',
});

export const domoUserName = atom({
  key: 'Domo/username',
  default: '',
});

export const domoDataSetNameState = atom({
  key: 'Domo/domodatasetname',
  default: '',
});

export const domoDataSelectedState = atom({
  key: 'Domo/domodatasetselected',
  default: false,
});

export const domoDatasets = atom({
  key: 'Domo/domodatasets',
  default: [],
});

export const hasMoreDatasetsAtom = atom({
  key: 'Domo/hasMoreDatasetsAtom',
  default: true,
});

export const domoDataSetId = atom({
  key: 'Domo/domodatasetid',
  default: '',
});

export const importFileType = atom({
  key: 'Domo/domoimportfilename',
  default: '',
});

export const newDataSetId = atom({
  key: 'Domo/newDataSetId',
  default: '',
});

export const isImport = atom({
  key: 'Domo/import',
  default: true,
});

export const uploadButton = atom({
  key: 'Domo/uploadbutton',
  default: true,
});

export const validDomoAccount = atom({
  key: 'Domo/validdomoaccount',
  default: false,
});

export const hasDeveloperAccessTokenState = atom<string>({
  key: 'Domo/hasDeveloperAccessTokenState',
  default: null,
});

export const pendingDeveloperAccessTokenState = atom({
  key: 'Domo/pendingDeveloperAccessTokenState',
  default: false,
});

export const newDeveloperAccessTokenState = atom<string | null>({
  key: 'Domo/newDeveloperAccessTokenState',
  default: null,
});

export const enableNewAccessTokenFormState = atom({
  key: 'Domo/enableNewAccessTokenState',
  default: false,
});

export const developerAccessTokenNameState = atom<string>({
  key: 'Domo/developerAccessTokenNameState',
  default: `Access Token - set date: ${(new Date()).toDateString()}`,
});

export const validationAccessTokenState = atom({
  key: 'Domo/validationAccessTokenState',
  default: false,
});

export const isNewTokenValidatedSuccessfullyState = atom({
  key: 'Domo/isNewTokenValidatedSuccessfullyState',
  default: false,
});
