import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import TabPanel from '@material-ui/lab/TabPanel';
import cx from 'classnames';

const useStyles = makeStyles(({ spacing }) => ({
  panel: {
    minHeight: spacing(65),
    flex: 1,
  },
  titleClass: {
    width: '100%',
    paddingBottom: spacing(5),
    marginBottom: spacing(6),
    minHeight: spacing(15),
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    '& h3': {
      margin: 0,
      fontSize: '1.25rem',
      lineHeight: '2rem',
    },
  },
}));

const SelectFileTabPanel = ({ value, title, children, actions, className }) => {
  const { titleClass, panel } = useStyles();

  return (
    <TabPanel value={value} className={cx(panel, className)}>
      {title && (
        <Box display="flex" justifyContent="space-between" alignItems="center" className={titleClass}>
          <Box flex="0 0 auto" component="h3">
            {title}
          </Box>
          {actions && (
            <Box flex="1 0 auto" display="flex" justifyContent="flex-end">
              {actions}
            </Box>
          )}
        </Box>
      )}
      {children}
    </TabPanel>
  );
};

SelectFileTabPanel.propTypes = {
  value: PropTypes.string.isRequired,
  className: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]),
  actions: PropTypes.node,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

SelectFileTabPanel.defaultProps = {
  title: null,
  actions: null,
  className: '',
  children: ' ',
};

export default React.memo(SelectFileTabPanel);
