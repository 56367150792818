import React, { useState } from 'react';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';

const FastTrainingWarning = () => {
  const [open, setOpen] = useState(true);
  const { t } = useTranslation('projects');

  const handleClose = () => { setOpen(false); };

  return (
    <Collapse in={open}>
      <Box mb={6}>
        <Alert onClose={handleClose} severity="warning">
          <Typography variant='body2'>{t('fastTraining.warning.info')}</Typography>
        </Alert>
      </Box>
    </Collapse>
  );
};

export default React.memo(FastTrainingWarning);
