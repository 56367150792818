import React, { Fragment, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import useAuthenticated from '../hooks/useAuthenticated';
import { isFirestoreAuthenticatedState } from '~/src/Firestore/store';
import useFirestoreAuth from '~/src/Firestore/hooks/useFirestoreAuth';
import useFirestoreToken from '~/src/Firestore/hooks/useFirestoreToken';
import useSetUserEmail from '../hooks/useSetUserEmail';
import useUserConfig from '../hooks/useUserConfig';
import UnAuthorized from './UnAuthorized';
import useUserRegistration from '../hooks/useUserRegistration';
import RegistrationLoader from './RegistrationLoader';

interface AuthenticatedProps {
  children: ReactNode;
}

const Authenticated: React.FC<AuthenticatedProps> = ({ children }) => {
  const { authenticated, authorized } = useAuthenticated();
  const isFirestoreAuthenticated = useRecoilValue(isFirestoreAuthenticatedState);
  const pendingRegistration = useUserRegistration();

  useUserConfig();
  useFirestoreToken();
  useFirestoreAuth();
  useSetUserEmail(authenticated);

  if (authenticated && !authorized) {
    return <UnAuthorized />;
  }

  if (authenticated && isFirestoreAuthenticated && pendingRegistration) {
    return <RegistrationLoader />;
  }

  return <Fragment>{authenticated && isFirestoreAuthenticated ? children : null}</Fragment>;
};

Authenticated.propTypes = {
  children: PropTypes.node,
};

Authenticated.defaultProps = {
  children: null,
};

export default Authenticated;
