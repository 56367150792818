import React from 'react';
import { Auth } from '@aws-amplify/auth';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { emailState, isLoadingState, errorAuthState } from '../store';
import { snackbarMessageSelector } from '~/src/Snackbar/store';

type ConfirmSignUpHook = () => [(arg01: string) => Promise<void>, (arg01?: React.MouseEvent<HTMLElement>) => Promise<void>];

const useConfirmSignup: ConfirmSignUpHook = () => {
  const user = useRecoilValue(emailState);
  const { t } = useTranslation('auth');
  const setIsLoading = useSetRecoilState(isLoadingState);
  const setError = useSetRecoilState(errorAuthState);
  const setSnackbarMessage = useSetRecoilState(snackbarMessageSelector);
  const { push } = useHistory();

  const confirmSignup = async (code: string) => {
    try {
      setIsLoading(true);
      await Auth.confirmSignUp(user, code);
      push('/login');
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const resendConfirmationCode = async (event?: React.MouseEvent<HTMLElement>) => {
    event?.preventDefault();
    try {
      setIsLoading(true);
      await Auth.resendSignUp(user);
      setError('');
      setSnackbarMessage({ message: t('confirmSignUp.infoMessage.resendCode'), severity: 'success' });
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return [confirmSignup, resendConfirmationCode];
};

export default useConfirmSignup;
