import { atom, selector } from 'recoil';

export const navigationDrawerOpen = atom({
  key: 'HomeLayout/navigationDrawerOpen',
  default: true,
});

export const folderNameState = atom({
  key: 'HomeLayout/folderNameState',
  default: '',
});

export const folderNameValidSelector = selector({
  key: 'HomeLayout/folderNameValidSelector',
  get: ({ get }) => /[\w\d].+/.test(get(folderNameState)),
});
