import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/core';
import { RecoilRoot } from 'recoil';
import theme from './theme';
import App from './App';
import './i18n';

declare global {
  interface Window {
    appConfig: {
      Auth: any,
      api: any,
      firestore: any,
      env: string
    }
  }
}

ReactDOM.render(
  <RecoilRoot>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </RecoilRoot>,
  document.getElementById('root'),
);
