import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { TabContext, TabPanel } from '@material-ui/lab';
import { Box, Container, Grid, Paper, Tab, Tabs } from '@material-ui/core';
import CloseButton from '~/src/components/DialogCloseButton';
import { Job } from '../store';
import LoaderOverlay from '~/src/components/LoaderOverlay';
import useProjectHistory from '../hooks/useProjectHistory';
import HistoryTable from './HistoryTable';

const useStyles = makeStyles(({ spacing, palette }) => ({
  title: {
    fontWeight: 700,
    justifyContent: 'center',
  },
  modalDialog: {
    '& .MuiContainer-root': {
      padding: spacing(8, 6, 4),
    },
  },
  tab: {
    minHeight: spacing(12),
    marginRight: spacing(1),
    borderRadius: spacing(2, 2),
    '&.Mui-selected': {
      backgroundColor: palette.background.secondary,
    },
    '&:not(.Mui-selected)': {
      '&:hover': {
        backgroundColor: palette.grey[100],
      },
    },
  },
  paper: {
    padding: spacing(2),
    minHeight: spacing(20),
  },
}));

const ALL_JOBS = 'all';
const jobOptions = [ALL_JOBS, ...Object.values(Job)] as const;
type JobOptions = typeof jobOptions[number];

const HistoryDialog = ({ open, onClose, projectId, projectName }) => {
  const { t } = useTranslation('projects');
  const classes = useStyles();
  const [selectedJob, setSelectedJob] = useState<JobOptions>(ALL_JOBS);
  const { getHistory, isHistoryLoading, projectHistory, resetHistories } = useProjectHistory(projectId);

  useEffect(() => {
    getHistory();

    return () => {
      resetHistories();
    };
  }, [projectId]);

  const handleJobChange = (event, newValue: JobOptions) => {
    if (newValue !== selectedJob) {
      setSelectedJob(newValue);
      getHistory(0, newValue !== ALL_JOBS && newValue);
    }
  };

  const pageChange = (newPage: number) => {
    getHistory(newPage, selectedJob !== ALL_JOBS && selectedJob);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" className={classes.modalDialog}>
      <Container maxWidth="md">
        <DialogTitle disableTypography>
          <Grid container spacing={10}>
            <Grid className={classes.title} item>
              <Typography variant="h5">
                {t('history.title')} {`"${projectName}"`}
              </Typography>
            </Grid>
            <Grid item>
              <CloseButton onClick={onClose} />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {isHistoryLoading && <LoaderOverlay />}
          <Box>
            <TabContext value={selectedJob}>
              <Tabs
                value={selectedJob}
                onChange={handleJobChange}
                variant="scrollable"
                scrollButtons="auto"
              >
                {jobOptions.map((item) => <Tab key={item} className={classes.tab} label={t(`history.jobs.${item}`)} value={item} />) }
              </Tabs>
              { jobOptions.map((item) => (
                <TabPanel key={item} value={item}>
                  <Paper className={classes.paper}>
                    {!!projectHistory?.entries?.length && <HistoryTable data={projectHistory} onPageChange={pageChange} />}
                    {projectHistory?.entries?.length === 0 && <Box>{t('history.noData')}</Box>}
                  </Paper>
                </TabPanel>))}
            </TabContext>
          </Box>
        </DialogContent>
      </Container>
    </Dialog>
  );
};

HistoryDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  projectId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  projectName: PropTypes.string.isRequired,
};

HistoryDialog.defaultProps = {
  open: false,
};

export default React.memo(HistoryDialog);
