import { useResetRecoilState } from 'recoil';
import { getAuth, signOut } from 'firebase/auth';
import { firestoreTokenState, isFirestoreAuthenticatedState } from '~/src/Firestore/store';
import { currentFolderState } from '~/src/ProjectFolder/store';
import { projectListState, wereProjectsLoadedState } from '~/src/Projects/store';
import { authenticatedUserEmailState, isAuthenticatedState, isAuthorizedState } from '~/src/Auth/store';
import { validDomoAccount } from '~/src/Connectors/Domo/stores/domoData';

export const useClearLogout = () => {
  const resetProjects = useResetRecoilState(projectListState);
  const resetCurrentFolder = useResetRecoilState(currentFolderState);
  const resetWereProjectsLoaded = useResetRecoilState(wereProjectsLoadedState);
  const resetFirestoreToken = useResetRecoilState(firestoreTokenState);
  const resetIsFirestoreAuthenticated = useResetRecoilState(isFirestoreAuthenticatedState);
  const resetAuthenticatedUserEmail = useResetRecoilState(authenticatedUserEmailState);
  const resetAuthenticated = useResetRecoilState(isAuthenticatedState);
  const resetAuthorized = useResetRecoilState(isAuthorizedState);
  const resetValidDomoAccount = useResetRecoilState(validDomoAccount);

  const clearOnLogout = async () => {
    const auth = getAuth();

    resetProjects();
    resetWereProjectsLoaded();
    resetCurrentFolder();
    resetFirestoreToken();
    resetIsFirestoreAuthenticated();
    resetAuthenticatedUserEmail();
    resetAuthorized();
    resetAuthenticated();
    resetValidDomoAccount();
    await signOut(auth);
  };

  return clearOnLogout;
};
