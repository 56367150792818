import { useState, useEffect } from 'react';
import { ProjectReport, ProjectReports, ScriptNames } from '~/src/Project/store';
import { SCRIPTS_DATE_TO_SHOW } from '~/src/Projects/constants';

interface LastRunReport { report: ProjectReport, scriptName: ScriptNames }

const getLastRunReport = (reports: ProjectReports) => {
  if (!reports) return null;
  const result: LastRunReport = SCRIPTS_DATE_TO_SHOW.reduce((acc, scriptName: ScriptNames) => {
    const curReport = reports[scriptName];
    if (!acc.report?.updatedAt) {
      acc = { report: curReport, scriptName };
      return acc;
    }
    if (!curReport?.updatedAt) return acc;
    return new Date(curReport.updatedAt) > new Date(acc.report.updatedAt) ? { report: curReport, scriptName } : acc;
  }, { report: reports[ScriptNames.Preprocessor], scriptName: ScriptNames.Preprocessor } as LastRunReport);
  return result.report?.updatedAt ? result : null;
};

export const useLastRunReport = (reports: ProjectReports) => {
  const [lastRunReport, setLastRunReport] = useState(getLastRunReport(reports));

  useEffect(() => {
    setLastRunReport(getLastRunReport(reports));
  }, [reports]);

  return lastRunReport;
};
