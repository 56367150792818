/* eslint-disable no-empty */
import { Auth } from '@aws-amplify/auth';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { authenticatedUserEmailState } from '../store';

const useSetUserEmail = (authenticated) => {
  const [authenticatedUserEmail, setAuthenticatedUserEmail] = useRecoilState(authenticatedUserEmailState);

  useEffect(() => {
    if (authenticated && !authenticatedUserEmail) {
      (async () => {
        try {
          const {
            attributes: { email },
          } = (await Auth.currentUserInfo()) || { attributes: {} };
          setAuthenticatedUserEmail(email);
        } catch (err) {}
      })();
    }
  }, [authenticated]);
};

export default useSetUserEmail;
