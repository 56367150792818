import React from 'react';
import PropTypes from 'prop-types';
import Spinner from '~/src/assets/loader.svg';

interface LoaderProps {
  size?: number;
}

const Loader: React.FC<LoaderProps> = ({ size, ...rest }) => (
  <img src={Spinner} alt="spinner" width={size} className="squark-loader-img" {...rest} />  
);

Loader.propTypes = {
  size: PropTypes.number,
};

Loader.defaultProps = {
  size: 60,
};

export default Loader;
