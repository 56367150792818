import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ProjectReports, ScriptNames } from '~/src/Project/store';
import { useLastRunReport } from '../hooks/useLastRunReport';

const useStyles = makeStyles(({ palette }) => ({
  lastRunDate: {
    color: palette.text.secondary,
  },
}));

interface ProjectCardLastRunDateProps {
  reports: ProjectReports;
  className?: string;
}

const mapScriptName = {
  [ScriptNames.Preprocessor]: 'preprocessing',
  [ScriptNames.TimeSeriesPreprocessor]: 'preprocessing',
  [ScriptNames.Automodeler]: 'analyzing',
  [ScriptNames.TimeSeriesAutomodeler]: 'analyzing',
  [ScriptNames.Prediction]: 'prediction',
  [ScriptNames.TimeSeriesPrediction]: 'prediction',
};

const ProjectCardLastRunDate: React.FC<ProjectCardLastRunDateProps> = ({ reports, className }) => {
  const { t } = useTranslation('projects');
  const classes = useStyles();
  const lastRunReport = useLastRunReport(reports);
  const date = useMemo(() => (lastRunReport ? new Date(lastRunReport.report.updatedAt) : null), [lastRunReport]);

  return (lastRunReport
    && <Tooltip
      title={
        <Box>
          {t(
            'lastRunDate.tooltip',
            { process: t(`history.jobs.${mapScriptName[lastRunReport.scriptName]}`), date: date.toLocaleString() },
          )}
        </Box>
      }
      arrow
      placement="top-start"
    >
      <Typography variant='caption' component='time' className={cx(classes.lastRunDate, className)}>
        {date.toLocaleDateString()}
      </Typography>
    </Tooltip>
  );
};

ProjectCardLastRunDate.propTypes = {
  reports: PropTypes.any.isRequired,
  className: PropTypes.string,
};

ProjectCardLastRunDate.defaultProps = {
  className: '',
};

export default React.memo(ProjectCardLastRunDate);
