import { Auth } from '@aws-amplify/auth';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { isAuthenticatedState, isAuthorizedState, previousUrlState } from '../store';
import useRefreshSession from './useRefreshSession';

const useAuthenticated = () => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const [authenticated, setAuthenticated] = useRecoilState(isAuthenticatedState);
  const [authorized, setAuthorizedState] = useRecoilState(isAuthorizedState);
  const setPreviousUrl = useSetRecoilState(previousUrlState);
  const [refreshSession] = useRefreshSession();

  useEffect(() => {
    (async () => {
      try {
        await refreshSession();
        const session = await Auth.currentSession();
        const userGroups = session?.getIdToken().payload['cognito:groups'];

        if (userGroups?.includes(window.appConfig.Auth.groups?.users)) {
          setAuthorizedState(true);
        }
        await Auth.currentUserPoolUser();
        setAuthenticated(true);
      } catch (err) {
        console.error(err);
        setPreviousUrl(pathname);
        push('/login');
      }
    })();
  }, []);

  return { authenticated, authorized };
};

export default useAuthenticated;
