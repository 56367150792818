import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Card, makeStyles } from '@material-ui/core';

interface TileProps {
  children?: React.ReactNode;
  selectable?: boolean;
  selected?: boolean;
}

const useStyles = makeStyles(({ palette, shadows }) => ({
  selectedCard: {
    cursor: 'pointer',
    backgroundColor: palette.primary.main,
    borderColor: palette.primary.main,
  },
  selectableCard: {
    cursor: 'pointer',
    '&:hover': {
      boxShadow: shadows[9],
      borderColor: palette.primary.main,
    },
  },
}));

const SelectableCard: React.FC<TileProps> = ({ children, selected, selectable, ...rest }) => {
  const { selectedCard, selectableCard } = useStyles();
  return (
    <Card
      variant="outlined"
      className={cx({ [selectedCard]: selected, [selectableCard]: selectable })}
      {...rest}
    >
      {children}
    </Card>
  );
};

SelectableCard.propTypes = {
  children: PropTypes.node,
  selectable: PropTypes.bool,
  selected: PropTypes.bool,
};

SelectableCard.defaultProps = {
  children: null,
  selectable: false,
  selected: false,
};

export default React.memo(SelectableCard);
