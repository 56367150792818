import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, MenuItem, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { UserRole } from '~/src/Project/store';
import { EMAIL_REGEX } from '~/src/constants';
import { authenticatedUserEmailState } from '~/src/Auth/store';

const useStyles = makeStyles(({ spacing }) => ({
  shareNewUser: {
    display: 'flex',
    alignItems: 'flex-start',
    margin: spacing(2.5, 0, 4),
  },
  selectRole: {
    minWidth: 120,
    marginLeft: spacing(2),
  },
  shareButton: {
    minWidth: 75,
    height: 40,
    marginLeft: spacing(2),
  },
  comment: {
    width: '100%',
    marginBottom: spacing(4),
  },
}));

const ShareNewUser = ({ onShare, owner }) => {
  const { t } = useTranslation('projects');
  const classes = useStyles();
  const [role, setRole] = useState(UserRole.Editor);
  const [email, setEmail] = useState('');
  const [emailTouched, setEmailTouched] = useState(false);
  const [comment, setComment] = useState('');
  const authenticatedUserEmail = useRecoilValue(authenticatedUserEmailState);

  const handleUserEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleUserEmailBlur = () => {
    setEmailTouched(true);
  };

  const handleUserRoleChange = (event) => {
    setRole(event.target.value);
  };

  const handleShareProject = () => {
    onShare({ email: email.toLowerCase(), role, comment });
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const emailValidation = useMemo(
    () => {
      switch (true) {
        case !email.match(EMAIL_REGEX):
          return {
            valid: false,
            message: t('shareProject.errors.wrongEmailFormat'),
          };
        case authenticatedUserEmail.toLowerCase().split('@')[1] !== email.trim().toLowerCase().split('@')[1]:
          return {
            valid: false,
            message: t('shareProject.errors.sameCompany'),
          };
        case email.toLowerCase().trim() === authenticatedUserEmail.toLowerCase():
          return {
            valid: false,
            message: t('shareProject.errors.currentUserEmail'),
          };
        case email.toLowerCase().trim() === owner.toLowerCase():
          return {
            valid: false,
            message: t('shareProject.errors.ownerEmail'),
          };
        default: return { valid: true };
      }
    },
    [email, authenticatedUserEmail, owner],
  );

  const handleEnterKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && emailValidation.valid) {
      handleShareProject();
    }
  };

  return (
    <Box>
      <Box className={classes.shareNewUser}>
        <TextField
          label={t('shareProject.emailLabel')}
          helperText={emailTouched && emailValidation.message}
          variant="outlined"
          onChange={handleUserEmailChange}
          onKeyPress={handleEnterKeyPress}
          onBlur={handleUserEmailBlur}
          error={emailTouched && !emailValidation.valid}
        />
        <TextField
          select
          label={t('shareProject.selectRoleLabel')}
          className={classes.selectRole}
          variant="outlined"
          onChange={handleUserRoleChange}
          value={role}
        >
          {Object.values(UserRole).map((item) => item !== UserRole.Owner && (
            <MenuItem key={item} value={item}>
              {t(`shareProject.roles.${item}`)}
            </MenuItem>
          ))}
        </TextField>
        <Button
          onClick={handleShareProject}
          color="primary"
          variant="contained"
          className={classes.shareButton}
          disabled={!emailValidation.valid}
        >
          {t('shareProject.shareButton')}
        </Button>
      </Box>
      <TextField
        className={classes.comment}
        label={t('shareProject.comment')}
        helperText={(comment.length === 2000) && t('shareProject.errors.commentLength')}
        variant="outlined"
        onChange={handleCommentChange}
        onKeyPress={handleEnterKeyPress}
        inputProps={{ maxLength: 2000 }}
      />
    </Box>
  );
};

ShareNewUser.propTypes = {
  onShare: PropTypes.func.isRequired,
  owner: PropTypes.string.isRequired,
};

export default React.memo(ShareNewUser);
