/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  highlighted: {
    fontWeight: 700,
  },
}));

interface HighlightTextProps {
  text?: string;
  separator?: string;
  className?: string;
}

const HighlightText: React.FC<HighlightTextProps> = ({ text, separator, className }) => {
  const classes = useStyles();
  const [state, setState] = useState<string[]>([]);

  useEffect(() => {
    setState(text.split(separator));
  }, [text, separator]);

  return state.length < 2 ? (
    <>{text}</>
  ) : (
    <>
      {state.map((part, i) => (i % 2 !== 0 ? (
        <span key={i} className={cx(classes.highlighted, className)}>
          {part}
        </span>
      ) : (
        <React.Fragment key={i}>{part}</React.Fragment>
      )))}
    </>
  );
};

HighlightText.propTypes = {
  text: PropTypes.string,
  separator: PropTypes.string,
  className: PropTypes.string,
};

HighlightText.defaultProps = {
  text: '',
  separator: '**',
  className: '',
};

export default HighlightText;
