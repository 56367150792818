import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Paper,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import TabPanel from '../../Files/components/SelectFileTabPanel';
import ExportType from '~/src/ProjectResults/components/ExportConnectors/Enum/ExportType.enum';
import { ExportOptions } from '~/src/Project/store';

const useStyles = makeStyles(({ spacing }) => ({
  paper: {
    padding: spacing(2),
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  applyButton: {
    marginTop: spacing(4),
    minWidth: spacing(14),
    marginRight: spacing(4),
  },
}));

interface ExportPanelProps {
  value: string,
  options: ExportOptions,
  onChange: (name: ExportType, isEnabled: boolean)=>void,
  onSave: ()=>void,
  isChanged: boolean,
}

const ExportPanel: React.FC<ExportPanelProps> = ({
  value,
  options = {},
  onChange,
  onSave,
  isChanged,
}) => {
  const { applyButton, paper } = useStyles();
  const { t } = useTranslation('projects');

  return (
    <Box display="flex" alignItems="center">
      <TabPanel title={t('scheduler.schedulerDesc.export')} value={value}>
        <Paper className={paper}>
          {Object.keys(options)
            .map((item) => (<FormControlLabel
              key={item}
              control={
                <Checkbox
                  checked={!!options[item].isAutoExportEnabled || false}
                  onChange={(e) => { onChange(e.target.name as ExportType, e.target.checked); }}
                  name={item}
                />
              }
              label={t(`scheduler.exportOptions.${item}`)}
            />))}
        </Paper>
        <Button
          disabled={!isChanged}
          variant="contained"
          onClick={onSave}
          className={applyButton}
          color="primary"
        >
          {t('scheduler.saveExportButton')}
        </Button>
      </TabPanel>
    </Box>
  );
};

ExportPanel.propTypes = {
  value: PropTypes.string,
  options: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  isChanged: PropTypes.bool,
};

ExportPanel.defaultProps = {
  value: '',
  options: {},
  isChanged: false,
};

export default ExportPanel;
