import { Box } from '@material-ui/core';
import React from 'react';
import { useSetRecoilState } from 'recoil';
import Login from '~/src/Auth/components/Login';
import { domoAppId, domoUserEmail, domoUserId, domoUserName, isDomoFrame } from '../stores/domoData';

const DomoRedirect = () => {
  const setIsDomoIFrame = useSetRecoilState(isDomoFrame);
  const setDomoAppId = useSetRecoilState(domoAppId);
  const setDomoUserId = useSetRecoilState(domoUserId);
  const setDomoUserEmail = useSetRecoilState(domoUserEmail);
  const setDomoUserName = useSetRecoilState(domoUserName);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  setIsDomoIFrame(true);
  setDomoAppId(urlParams.get('app'));
  setDomoUserId(urlParams.get('user'));
  setDomoUserEmail(urlParams.get('email'));
  setDomoUserName(urlParams.get('name'));

  return (
    <Box>
      <Login />
    </Box>
  );
};

export default DomoRedirect;