/* eslint-disable consistent-return */
import useApiClient from '~/src/hooks/useApiClient';
import { SubscriptionType } from '../store';

const useEmailRemoveSubscription = () => {
  const apiClient = useApiClient();

  const handleRemoveSubscription = async (projectId, subscriptionType: SubscriptionType) => {
    try {
      const { data } = await apiClient.get(
        `/emails/${projectId}/subscriptions/${subscriptionType}`
      );
      if (data) {
        await apiClient.delete(
          `/emails/${projectId}/subscriptions/${subscriptionType}`
        );
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      return err;
    }
  };
  return [handleRemoveSubscription];
};

export default useEmailRemoveSubscription;
