import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import useApiClient from '~/src/hooks/useApiClient';
import { snackbarMessageSelector } from '~/src/Snackbar/store';
import { pendingShareProjectState } from '../store';
import { ShareParams } from '~/src/Project/store';
import { entriesState } from '~/src/Projects/store';
import { authenticatedUserEmailState } from '~/src/Auth/store';

const useShareProject = () => {
  const { t } = useTranslation('projects');
  const apiClient = useApiClient();
  const setPending = useSetRecoilState(pendingShareProjectState);
  const setSnackbarMessage = useSetRecoilState(snackbarMessageSelector);
  const setEntries = useSetRecoilState(entriesState);
  const currentUserEmail = useRecoilValue(authenticatedUserEmailState);

  const shareProject = async (projectId, params: ShareParams, isInFolder = false) => {
    setPending(true);
    try {
      const { data } = await apiClient.put(`/projects/${projectId}/share`, params);
      setSnackbarMessage({
        message: t('shareProject.successShare', { email: params.email }),
        severity: 'success',
      });
      setEntries((state) => (isInFolder
        ? state.filter((item) => item.project?._id !== projectId)
        : state.map((item) => (item.project?._id === projectId
          ? { ...item, users: data.users.map((el) => el.email), project: data }
          : item))));
    } catch (err) {
      console.error(err.response || err);
      setSnackbarMessage({
        message: t(`shareProject.errors.${err.response?.data?.errorCode || 'defaultShare'}`, { email: params.email }),
        severity: 'error',
      });
    } finally {
      setPending(false);
    }
  };

  const unshareProject = async (projectId, email) => {
    setPending(true);
    try {
      const { data } = await apiClient.put(`/projects/${projectId}/unshare`, { email });
      setSnackbarMessage({
        message: t('shareProject.successUnshare', { email }),
        severity: 'success',
      });
      setEntries((state) => (currentUserEmail === email
        ? state.filter((item) => (item.project?._id !== projectId))
        : state.map((item) => (item.project?._id === projectId
          ? { ...item, users: data.users.map((el) => el.email), project: data }
          : item))));
    } catch (err) {
      console.error(err.response || err);
      setSnackbarMessage({
        message: t('shareProject.errors.unshare', { email }),
        severity: 'error',
      });
    } finally {
      setPending(false);
    }
  };

  return { shareProject, unshareProject };
};

export default useShareProject;
