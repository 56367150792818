import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(() => ({
  scroll: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    overflow: 'auto',
  },
}));

const Scrollable = React.forwardRef<any, { children: any; [name: string]: any }>(({ children, ...restProps }, ref) => {
  const { scroll } = useStyles();
  return (
    <Box flex="auto" position="relative">
      <div className={scroll} {...restProps} ref={ref}>
        {children}
      </div>
    </Box>
  );
});

Scrollable.propTypes = {
  children: PropTypes.node,
};

Scrollable.defaultProps = {
  children: null,
};

export default Scrollable;
