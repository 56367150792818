import React, { useRef } from 'react';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Add from '@material-ui/icons/Add';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { useRecoilValue } from 'recoil';
import GetStarted from '~/src/assets/get-started.svg';
import useToggle from '~/src/hooks/useToggle';
import VideoDialog from '~/src/components/VideoDialog';
import { HOW_TO_START_VIDEO_ID } from '~/src/Helper/helpers-config';
import { isPaidUserSelector } from '~/src/Auth/store';

const useStyles = makeStyles(({ palette, shape, spacing, shadows }) => ({
  emptyIllustrationWrapper: {
    position: 'relative',
    '&:hover': {
      '& $highlight': {
        opacity: 0.6,
      },
    },
  },
  highlight: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    opacity: 0,
    filter: 'blur(160px)',
  },
  redHighlight: {
    right: '50%',
    width: 240,
    height: 150,
    backgroundColor: palette.secondary.main,
  },
  blueHighlight: {
    left: '50%',
    width: 320,
    height: 200,
    backgroundColor: palette.info.main,
  },
  illustration: {
    position: 'relative',
    zIndex: 10,
  },
  emptyTitle: {
    fontWeight: 700,
    fontSize: '1.75rem',
  },
  howToStartButton: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: shape.borderRadius * 25,
    zIndex: 100,
    paddingRight: spacing(4),
  },
  addProjectBtn: {
    boxShadow: shadows[9],
    '&:hover': {
      backgroundColor: palette.primary.main,
      color: palette.common.white,
    },
  },
}));

const playObjectElementAnimation: (arg0: HTMLObjectElement | any) => void = (obj) => {
  if (obj.contentDocument?.ks) {
    const { ks } = obj.contentDocument;
    if (ks.timelines()[0].state() !== 'running') {
      ks.timelines()[0].time(0);
    }
    ks.globalPlay();
  }
};

const EmptyList = () => {
  const { t } = useTranslation(['projects', 'helpers']);
  const classes = useStyles();
  const getStartedImgRef = useRef();
  const [isVideoDialogOpen, showVideoDialog, hideVideoDialog] = useToggle();
  const isPaid = useRecoilValue(isPaidUserSelector);

  return (
    <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" overflow="hidden" height='100%'>
      <Box className={classes.emptyIllustrationWrapper}>
        <Box className={cx(classes.highlight, classes.redHighlight)} />
        <Box className={cx(classes.highlight, classes.blueHighlight)} />
        <object
          className={classes.illustration}
          ref={getStartedImgRef}
          data={`${GetStarted}?global=paused`}
          type="image/svg+xml"
          onMouseEnter={() => playObjectElementAnimation(getStartedImgRef?.current)}
          aria-label="get started illustration"
        />
        <Button
          size="large"
          disableElevation={false}
          variant="contained"
          color="secondary"
          className={classes.howToStartButton}
          startIcon={<PlayCircleFilledIcon />}
          onClick={showVideoDialog}
        >
          {t('helpers:startProject.howToStartBtn')}
        </Button>
      </Box>
      <Box pt={3} zIndex="100">
        <Typography variant="h5" className={classes.emptyTitle}>
          {t(isPaid ? 'empty.title' : 'empty.titleCommunity')}
        </Typography>
      </Box>
      <Box p={3} zIndex="100">
        <Typography variant="body2" color="textSecondary">
          {t('empty.subtitle')}
        </Typography>
      </Box>
      <Box pt={4} zIndex="100">
        <Button
          className={classes.addProjectBtn}
          disableElevation={false}
          startIcon={
            <Box color="secondary.main" display="flex" alignItems="center">
              <Add color="inherit" />
            </Box>
          }
          component={Link}
          to="/new"
          variant="outlined"
          size="large"
        >
          <Box pr={3}>{t('empty.button')}</Box>
        </Button>
      </Box>
      <VideoDialog
        open={isVideoDialogOpen}
        onClose={hideVideoDialog}
        videoId={HOW_TO_START_VIDEO_ID}
        title={t('helpers:startProject.videoDialogTitle')}
      />
    </Box>
  );
};

export default React.memo(EmptyList);
