import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import { projectListState, wereProjectsLoadedState } from '../store';
import { currentFolderState, Folder } from '~/src/ProjectFolder/store';
import { searchStringState } from '~/src/FilterProjects/store';

export const useFolderSelect = () => {
  const resetResults = useResetRecoilState(projectListState);
  const [searchString, setSearchString] = useRecoilState(searchStringState);
  const setCurrentFolder = useSetRecoilState(currentFolderState);
  const setWereProjectsLoaded = useSetRecoilState(wereProjectsLoadedState);

  const handleFolderSelect = (folder: Folder) => {
    resetResults();
    setCurrentFolder(folder);
    setWereProjectsLoaded(false);
    if (searchString) {
      setSearchString('');
    }
  };

  return [handleFolderSelect];
};
