import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import cx from 'classnames';
import { Box, Typography, FormControlLabel, Checkbox, makeStyles } from '@material-ui/core';
import Help from '@material-ui/icons/Help';
import { ConditionControl, fastTrainingState, isReadOnlyProjectFamilySelector } from '~/src/Projects/store';
import TooltipWithTitle from '~/src/components/TooltipWithTitle';
import { ScriptNames } from '~/src/Project/store';
import useUpdateReport from '~/src/Project/hooks/useUpdateReport';

const useStyles = makeStyles(({ spacing }) => ({
  fastTrainingWrapperClass: {
    marginLeft: spacing(7),
  },
  fastTrainingLabelClass: {
    display: 'flex',
    alignItems: 'center',
    gap: spacing(1),
  },
}));

const FastTrainingSelector = ({ projectId, className }) => {
  const [fastTraining, setFastTraining] = useRecoilState(fastTrainingState(projectId));
  const isReadOnlyProject = useRecoilValue(isReadOnlyProjectFamilySelector(projectId));
  const classes = useStyles();
  const { t } = useTranslation('projects');
  const { updateReport, isLoading } = useUpdateReport(projectId);
  const handleChange = useCallback(async (event) => {
    const isFastTraining = event.target.checked ? ConditionControl.Enabled : ConditionControl.Disabled;
    try {
      await updateReport(
        ScriptNames.Automodeler,
        { isFastTraining },
        {
          success: t('project.updateReport.fastTraining.success', { state: t(`project.updateReport.fastTraining.${isFastTraining}`) }),
          error: t('project.updateReport.fastTraining.error'),
        },
      );
      setFastTraining(isFastTraining);
    } catch { /* empty */ }
  }, []);

  return (<FormControlLabel
    className={cx(classes.fastTrainingWrapperClass, className)}
    control={
      <Checkbox
        checked={fastTraining === ConditionControl.Enabled}
        onChange={handleChange}
        color="primary"
        disabled={isLoading || isReadOnlyProject}
      />
    }
    label={
      <Box className={classes.fastTrainingLabelClass}>
        <Typography component="span">{t('fastTraining.selector.label')}</Typography>
        <TooltipWithTitle title={t('fastTraining.selector.tooltipTitle')} content={t('fastTraining.selector.tooltip')}>
          <Help />
        </TooltipWithTitle>
      </Box>
    }
  />);
};

FastTrainingSelector.propTypes = {
  projectId: PropTypes.number.isRequired,
  className: PropTypes.string,
};

FastTrainingSelector.defaultProps = {
  className: '',
};

export default React.memo(FastTrainingSelector);
