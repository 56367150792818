import { atomFamily, selectorFamily } from 'recoil';
import { appConfigState } from '~/src/Auth/store';

export enum JobResultState {
  Completed = 'completed',
  Failed = 'failed',
}

export enum Job {
  Preprocessing = 'preprocessing',
  Analyzing = 'analyzing',
  Prediction = 'prediction',
  ShapSummary = 'shapSummary',
  ForceShap = 'forceShap',
}

export interface HistoryEntry {
  _id: 'string';
  result: JobResultState;
  job: Job;
  updatedAt: string;
}

export interface HistoryState {
  page: number,
  perPage: number,
  total: number,
  entries: HistoryEntry[]
}

export const projectHistoryState = atomFamily<HistoryState, number>({
  key: 'ProjectHistory/projectHistoryState',
  default: { page: 0, perPage: 8, total: null, entries: null },
});

export const isOutdatedProjectSelector = selectorFamily({
  key: 'ProjectHistory/isOutdatedProjectSelector',
  get: (projectId: number) => ({ get }) => {
    const appConfig = get(appConfigState);
    if (!appConfig.breakingDate) return false;
    const { entries } = get(projectHistoryState(projectId));
    const historyEntry = entries?.[0];
    if (historyEntry?.job !== Job.Analyzing) return false;
    return new Date(appConfig.breakingDate) > new Date(historyEntry.updatedAt);
  },
});
