import { atom, selector, selectorFamily } from 'recoil';
import { ScriptNames, displayName, isAutomodelerDoneState, isPredictionDoneState, pendingState, projectIdState } from '~/src/Project/store';
import { getLeaderbordColumnName } from '../results-helpers';
import { pendingTSPredictionDataState } from '../TimeSeries/time-series.store';
import { resultsTableDataSelector, resultPendingState, dependentVariableSelector } from './prediction-table.store';
import { projectSelector } from '~/src/Projects/store';
import { pendingTSPredictionState } from '~/src/TrainingFile/TimeSeries/time-series.store';

export const allLeaderbordPendingState = atom({
  key: 'Results/allLeaderbordPendingState',
  default: false,
});

export const leaderbordPendingState = atom({
  key: 'Results/leaderbordPendingState',
  default: false,
});

interface AllLeaderbordsTableData {
  headers: string[];
  DRF?: string[][];
  DeepLearning?: string[][];
  GBM?: string[][];
  GLM?: string[][];
  StackedEnsemble?: string[][];
  XGBoost?: string[][];
  XRT?: string[][];
}

export const allLeaderbordState = atom<AllLeaderbordsTableData>({
  key: 'Results/allLeaderbordState',
  default: null,
});

export const isAllLeaderbordUploadedState = atom({
  key: 'Results/isAllLeaderbordUploadedState',
  default: false,
});

export const leaderbordState = atom({
  key: 'Results/leaderbordState',
  default: null,
});

export const bestModelSelector = selector({
  key: 'Results/bestModelSelector',
  get: ({ get }) => {
    const data = get(leaderbordState);
    if (!data) return null;
    return data[0];
  },
});

export const modelNameMapper = {
  StackedEnsemble: 'STACKED ENSEMBLE',
  se: 'STACKED ENSEMBLE',
  GBM: 'GRADIENT BOOST MACHINE',
  gbm: 'GRADIENT BOOST MACHINE',
  DeepLearning: 'DEEP LEARNING',
  dl: 'DEEP LEARNING',
  dee: 'DEEP LEARNING',
  XRT: 'EXTREMELY RANDOMIZED TREES',
  xrt: 'EXTREMELY RANDOMIZED TREES',
  DRF: 'DISTRIBUTED RANDOM FOREST',
  drf: 'DISTRIBUTED RANDOM FOREST',
  GLM: 'GENERALIZED LINEAR',
  glm: 'GENERALIZED LINEAR',
  XGBoost: 'XGBOOST',
  xgb: 'XGBOOST',
  XGB: 'XGBOOST',
  SARIMA: 'SARIMA',
  'SARIMA CV': 'SARIMA CROSS VALIDATION',
  'ARIMA S': 'ARIMA SAMPLING',
  'ARIMA CV': 'ARIMA CROSS VALIDATION',
  LSTM: 'LONG SHORT-TERM MEMORY',
  FBP: 'FACEBOOK PROPHET',
  cvFBP: 'FACEBOOK PROPHET CROSS VALIDATION',
};

export const predictionStatsState = atom({
  key: 'Results/predictionStatsState',
  default: null,
});

export const pendingPredictionStatsState = atom({
  key: 'Results/pendingPredictionStatsState',
  default: false,
});

export const pendingRunningPredictionModelNameState = atom({
  key: 'Results/pendingRunningPredictionModelNameState',
  default: false,
});

export const runningPredictionModelNameState = atom({
  key: 'Results/runningPredictionModelNameState',
  default: null,
});

export const bestModelNameSelector = selector({
  key: 'Results/bestModelNameSelector',
  get: ({ get }) => {
    const projectId = get(projectIdState);
    const isPredictionDone = get(isPredictionDoneState(projectId));
    const isAutomodelerDone = get(isAutomodelerDoneState(projectId));
    const data = get(bestModelSelector);
    const predictionStats = get(predictionStatsState);
    if (!isAutomodelerDone || (isPredictionDone && !predictionStats) || !data) return '';
    return modelNameMapper[data.model_id] || data.model_id || '';
  },
});

export const projectReportsModelNameSelector = selectorFamily({
  key: 'Results/projectReportsModelNameSelector',
  get: (projectId: number) => ({ get }) => {
    const { reports } = get(projectSelector(projectId)) || {};
    let modelName = null;
    if (reports) {
      ({ modelName } = Object.entries(reports)
        .find(([key, value]) => [ScriptNames.Prediction, ScriptNames.Shap_summary, ScriptNames.TimeSeriesPrediction]
          .includes(key as ScriptNames) && !!value.modelName)?.[1] || {});
    }
    return modelName;
  },
});

export const isBestModelPredictedSelector = selector({
  key: 'Results/isBestModelPredictedSelector',
  get: ({ get }) => {
    const projectId = get(projectIdState);
    const projectReportsModelName = get(projectReportsModelNameSelector(projectId));
    // eslint-disable-next-line prefer-const
    let { predictionModelName, predictionModel, top_model: bestModel } = get(predictionStatsState) || {};
    predictionModelName = projectReportsModelName || predictionModelName || predictionModel;
    const bestModelName = get(bestModelSelector)?.original_model_id;
    bestModel = bestModel || bestModelName;
    return !predictionModelName && !predictionModelName ? true : predictionModelName === bestModel;
  },
});

export const pendingSelector = selector({
  key: 'Results/pendingSelector',
  get: ({ get }) => {
    const leaderbordPending = get(leaderbordPendingState);
    const resultPending = get(resultPendingState);
    const resultTSPending = get(pendingTSPredictionDataState);

    return resultPending || leaderbordPending || resultTSPending;
  },
});

export const pendingGetMojoState = atom({
  key: 'Results/pendingGetMojoState',
  default: false,
});

export enum Views {
  Business = 'business',
  DataScience = 'dataScience',
  // Advanced = 'advanced',
}

export const selectedViewState = atom<Views>({
  key: 'Results/selectedViewState',
  default: Views.Business,
});

export interface FileList {
  name: string;
  prefix: string;
}

export const mojoFileListState = atom<FileList[]>({
  key: 'Results/mojoFileListState',
  default: null,
});

export const modelPrefixMapper = {
  StackedEnsemble: 'Sta',
  se: 'Sta',
  GBM: 'GBM',
  gbm: 'GBM',
  DeepLearning: 'Dee',
  dl: 'Dee',
  Dee: 'Dee',
  XRT: 'XRT',
  xrt: 'XRT',
  DRF: 'DRF',
  drf: 'DRF',
  GLM: 'GLM',
  glm: 'GLM',
  XGBoost: 'XGB',
  xgb: 'XGB',
  XGB: 'XGB',
};

export const confusionMatrixState = atom<any[]>({
  key: 'Results/confusionMatrixState',
  default: null,
});

export const pendingConfusionMatrixState = atom({
  key: 'Results/pendingConfusionMatrixState',
  default: false,
});

export const currentModelSelector = selector({
  key: 'Results/currentModelSelector',
  get: ({ get }) => {
    const isBestModelPredicted = get(isBestModelPredictedSelector);
    if (isBestModelPredicted) {
      return null;
    }
    const projectId = get(projectIdState);
    const projectReportsModelName = get(projectReportsModelNameSelector(projectId));
    // eslint-disable-next-line prefer-const
    let { predictionModelName, predictionModel } = get(predictionStatsState) || {};
    predictionModelName = predictionModelName || predictionModel;
    const allLeaderbord = get(allLeaderbordState) || ({} as AllLeaderbordsTableData);
    const originalCurrentModelName = projectReportsModelName || predictionModelName;
    const currentName = originalCurrentModelName?.split('_')[0];

    const currentModelFamily = allLeaderbord[currentName];
    if (!currentModelFamily) {
      return null;
    }

    const currentRow = currentModelFamily.find((item) => item[0] === originalCurrentModelName);

    if (!currentRow) return null;

    const currentModel = allLeaderbord?.headers.reduce((acc, item, index) => {
      if (!index) {
        acc.model_id = currentName;
        acc.original_model_id = originalCurrentModelName;
      } else {
        acc[item.replace(/ /g, '_')] = currentRow[index];
      }
      return acc;
    }, {} as any);

    return currentModel;
  },
});

export const currentModelNameSelector = selector({
  key: 'Results/currentModelNameSelector',
  get: ({ get }) => {
    const data = get(currentModelSelector);
    if (!data) return '';
    return modelNameMapper[data.model_id] || data.model_id || '';
  },
});

export const modelSelector = selector({
  key: 'Results/modelSelector',
  get: ({ get }) => get(currentModelSelector) || get(bestModelSelector),
});

export const modelPrefixSelector = selector({
  key: 'Results/modelPrefixSelector',
  get: ({ get }) => {
    const isBestModelPredicted = get(isBestModelPredictedSelector);
    const data = get(isBestModelPredicted ? bestModelSelector : currentModelSelector);
    if (!data) return '';
    return modelPrefixMapper[data.model_id] || '';
  },
});

export const leaderbordColumnsSelector = selector<string[]>({
  key: 'Results/leaderbordColumnsSelector',
  get: ({ get }) => {
    const isBestModelPredicted = get(isBestModelPredictedSelector);
    const {
      model_id: modelId,
      original_model_id: originalId,
      ...result
    } = get(isBestModelPredicted ? bestModelSelector : currentModelSelector) || {};

    if (!result) return null;
    return Object.keys(result).filter((item) => !!item);
  },
});

export const modelMetricsDownloadDataSelector = selector({
  key: 'Results/modelMetricsDownloadDataSelector',
  get: ({ get }) => {
    const model = get(modelSelector);
    const leaderbordColumnsKeys = get(leaderbordColumnsSelector);
    const projectName = get(displayName);
    const fileName = `${projectName}_${modelNameMapper[model?.model_id]}_metrics`.replace(/ /g, '_');
    const data = leaderbordColumnsKeys.reduce((acc, key) => {
      acc.push([getLeaderbordColumnName(key), (+model[key])?.toFixed(4)]);
      return acc;
    }, []);
    return { data, fileName };
  },
});

const getDownloadName = (name: string) => name.toLowerCase().replace(/ /g, '_');

export const downloadFileNameSelector = selector({
  key: 'Results/downloadFileNameSelector',
  get: ({ get }) => {
    const projectName = get(displayName) || '';
    const isBestModelPredicted = get(isBestModelPredictedSelector);
    const modelName = get(isBestModelPredicted ? bestModelNameSelector : currentModelNameSelector) || '';
    const targetName = get(dependentVariableSelector)?.displayName || '';
    return getDownloadName(`${projectName}_${modelName}_${targetName}_prediction`);
  },
});

export const pendingForcePlotStartScriptState = atom({
  key: 'Results/pendingForcePlotStartScriptState',
  default: false,
});

export const selectedPredictionsTableRowIdState = atom({
  key: 'Results/selectedPredictionsTableRowIdState',
  default: null,
});

export const forcePlotPendingRowIdState = atom({
  key: 'Results/forcePlotPendingRowIdState',
  default: null,
});

export const rowPredictionValueSelector = selectorFamily({
  key: 'Results/rowPredictionValueSelector',
  get:
    (projectId: number) => ({ get }) => {
      const resultsTableData = get(resultsTableDataSelector(projectId));
      if (!resultsTableData.dataRow?.length) return null;
      const dependentVar = get(dependentVariableSelector);
      const selectedPredictionsTableRowId = get(selectedPredictionsTableRowIdState);
      return resultsTableData.dataRow[+selectedPredictionsTableRowId - 1]?.[dependentVar?.variableId || 'prediction'];
    },
});

export const pendingStartShapSummaryState = atom({
  key: 'Results/pendingStartShapSummaryState',
  default: false,
});

export const pendingLeaderboardSelector = selector({
  key: 'Results/pendingLeaderboardSelector',
  get: ({ get }) => get(pendingState) || get(pendingStartShapSummaryState) || get(pendingTSPredictionState),
});
