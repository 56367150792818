import React from 'react';
import { Link } from 'react-router-dom';
import AnchorLink from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { isPaidUserSelector } from '~/src/Auth/store';

const year = (new Date()).getFullYear();

const Footer = () => {
  const { t } = useTranslation('layout');
  const isPaid = useRecoilValue(isPaidUserSelector);

  return (
    <Box pl={8} pr={10} pb={3}>
      <Box mb={3}>
        <Divider />
      </Box>
      <Box display="flex" alignItems="center">
        <Button href="https://www.squarkai.com/terms-of-service/">
          <Typography color="textSecondary" variant="body2">
            {t('footer.terms')}
          </Typography>
        </Button>
        <Button href="https://www.squarkai.com/privacy-policy/">
          <Typography color="textSecondary" variant="body2">
            {t('footer.privacy')}
          </Typography>
        </Button>
        <Button href="https://www.squarkai.com/about/">
          <Typography color="textSecondary" variant="body2">
            {t('footer.about')}
          </Typography>
        </Button>
        <Button component={Link} to='/develop'>
          <Typography color="textSecondary" variant="body2">
            {t('footer.develop')}
          </Typography>
        </Button>
        <Box mx={4} flex="auto" />
        {!isPaid && (<>
          <Box>
            <Typography variant="body2" color="textSecondary">
              {t('footer.free')}
              <AnchorLink href="https://squarkai.com/eula" target="_blank">
                squarkai.com/eula
              </AnchorLink>
            </Typography>
          </Box>
          <Box mx={4} flex="auto" />
        </>)}
        <Typography variant="body2" color="textSecondary">
          {t('footer.copyright', { year })}
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
