import React from 'react';
import { Box } from '@material-ui/core';
import Layout from './Layout';

import LoaderOverlay from '~/src/components/LoaderOverlay';

const RegistrationLoader = () => (
    <Layout>
      <Box position='relative' width='100%'>
        <LoaderOverlay />
      </Box>
    </Layout>
);

export default React.memo(RegistrationLoader);
