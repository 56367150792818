import { atom, RecoilValueReadOnly, selector, selectorFamily } from 'recoil';
import { ProjectType } from '~/src/Project/store';
import { entryProjectSelector } from '~/src/Projects/store';
import { activeFiltersState, FilterVariant, TableFilter } from '~/src/TableFilters/store';
import { configuredVariablesState, ITrainingVariable, variablesState, VariableType } from '~/src/TrainingFile/store';

interface AGTableRow {
  [name: string]: string | number;
}

interface PredictionTableValues extends ITrainingVariable {
  value: string;
}

interface ResultsDataValues {
  prediction: string;
  probabilities: number[];
  rowId: number;
  values: Partial<PredictionTableValues>[];
}

export interface ResultsData {
  probabilities?: string[];
  total: number;
  values: ResultsDataValues[];
  variables: ITrainingVariable[];
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
  NONE = '',
}

export const resultDataState = atom<ResultsData>({
  key: 'Results/resultDataState',
  default: null,
});

export const resultsRequestFailedState = atom({
  key: 'Results/resultsRequestFailedState',
  default: false,
});

// loading data
export const resultPendingState = atom({
  key: 'Results/resultPendingState',
  default: false,
});

// page change
export const resultPendingPageChangeState = atom({
  key: 'Results/resultPendingPageChangeState',
  default: false,
});

// other table pending (e.g. table rendering on large data);
export const resultTablePendingState = atom({
  key: 'Results/resultTablePendingState',
  default: false,
});

export const resultTablePendingSelector = selector({
  key: 'Results/resultTablePendingSelector',
  get: ({ get }) => get(resultTablePendingState) || get(resultPendingPageChangeState),
});

export const resultTableSortDirectionState = atom<SortDirection>({
  key: 'Results/resultTableSortDirectionState',
  default: null,
});

export const resultTableSortColumnState = atom<string>({
  key: 'Results/resultTableSortColumnState',
  default: null,
});

export const showExcludedState = atom({
  key: 'Results/showExcludedState',
  default: false,
});

export const maxF1State = atom({
  key: 'Results/maxF1State',
  default: null,
});

export const algorithmStatsState = atom({
  key: 'Results/algorithmStatsState',
  default: null,
});

export const dependentVariableSelector = selector({
  key: 'Results/dependentVariableSelector',
  get: ({ get }) => {
    const data = get(resultDataState);
    if (!data) return null;
    return data.variables.find((item) => item.state === VariableType.Dependent);
  },
});

export const existExcludedSelector = selector({
  key: 'Results/existExcludedSelector',
  get: ({ get }) => {
    const data = get(resultDataState);
    if (!data) return null;
    return !!data.variables.find((item) => item.state === VariableType.Excluded);
  },
});

export const excludedVariablesSelector = selector({
  key: 'Results/excludedVariablesSelector',
  get: ({ get }) => {
    const data = get(resultDataState);
    const excludedInResults = data?.variables?.filter((item) => item.state === VariableType.Excluded);
    const variables = get(variablesState);
    const configVariables = get(configuredVariablesState);
    const excluded = configVariables.filter((item) => item.state === VariableType.Excluded);
    return excludedInResults?.length
      ? excludedInResults
      : excluded?.map((item) => ({
        ...item,
        displayName: variables.headers.find((elem) => elem.variableId === item.variableId)?.displayName,
      }));
  },
});

const getResultsDataRows = (values: ResultsDataValues[], showExcluded?: boolean, dependentVar?: ITrainingVariable) => values.map((row) => {
  const mappedRow = row.values.reduce((acc, item, i) => {
    if (item.state === VariableType.Independent || (item.state === VariableType.Excluded && showExcluded)) {
      acc[`${item.variableId}`] = row.values[i].value;
    }
    return acc;
  }, {} as AGTableRow);

  const predictionValue = row.prediction ? row.prediction : row.values.find((item) => item.state === VariableType.Dependent)?.value;
  mappedRow[dependentVar?.variableId || 'prediction'] = +predictionValue ? +predictionValue : predictionValue;

  row.probabilities?.forEach((value, i) => {
    mappedRow[`${i}`] = `${(value * 100)?.toFixed(2)}%`;
  });

  mappedRow.rowId = row.rowId;

  return mappedRow;
});

const getResultsColumns = (
  data: ResultsData,
  showExcluded: boolean,
  sortDir: SortDirection,
  sortCol: string,
  activeFilters: TableFilter[],
) => (
  data.variables.reduce((acc, variable) => {
    if (variable && (variable.state === VariableType.Independent || (variable.state === VariableType.Excluded && showExcluded))) {
      acc.push({
        headerName: variable.displayName,
        field: `${variable.variableId}`,
        suppressSizeToFit: true,
        sort: !sortDir || !sortCol.includes(variable.displayName) ? '' : sortDir.toLowerCase(),
        filterParams: {
          type: variable.type,
          activeFilters,
        },
        headerClass: variable.state === VariableType.Excluded ? 'seer-table-variable-excluded' : '',
        cellClass: variable.state === VariableType.Excluded ? 'seer-table-variable-excluded' : '',
      });
    }
    return acc;
  }, []));

const getResultsProbabilities = (data) => {
  const probabilityValues = data.values.map((item) => item.probabilities);
  return { labels: data.probabilities, values: probabilityValues };
};

export const resultsBinaryTableDataSelector = selector({
  key: 'Results/resultsBinaryTableDataSelector',
  get: ({ get }) => {
    const inputData = get(resultDataState);
    if (!inputData) return null;

    const showEcluded = get(showExcludedState);
    const dependentVar = get(dependentVariableSelector);
    const resultTableSortColumn = get(resultTableSortColumnState);
    const resultTableSortDirection = get(resultTableSortDirectionState);
    const activeFilters = get(activeFiltersState);
    const f1 = get(maxF1State);
    const dataRow = getResultsDataRows(inputData.values, showEcluded, dependentVar);
    const probabilities = getResultsProbabilities(inputData);
    const columns = getResultsColumns(inputData, showEcluded, resultTableSortDirection, resultTableSortColumn, activeFilters);

    columns.push({
      headerName: dependentVar?.displayName || 'Predict',
      field: `${dependentVar?.variableId || 'prediction'}`,
      pinned: 'right',
      suppressSizeToFit: true,
      sort: resultTableSortColumn === FilterVariant.PREDICTION ? resultTableSortDirection.toLowerCase() : '',
      filterParams: {
        type: dependentVar?.type,
        activeFilters,
        target: true,
        f1,
      },
      headerClass: 'seer-prediction-column-header',
    });

    inputData.probabilities.forEach((item, index) => {
      columns.push({
        headerName: item,
        field: `${index}`,
        pinned: 'right',
        sort: resultTableSortColumn === `${FilterVariant.PROBABILITIES}.${item}` ? resultTableSortDirection.toLowerCase() : '',
        suppressSizeToFit: true,
        sortable: false,
        filterParams: {
          probability: true,
          activeFilters,
        },
      });
    });
    return { dataRow, columns, probabilities, total: inputData.total };
  },
});

export const resultsMultiClassTableDataSelector = selector({
  key: 'Results/resultsMultiClassTableDataSelector',
  get: ({ get }) => {
    const inputData = get(resultDataState);
    if (!inputData) return null;

    const showEcluded = get(showExcludedState);
    const dependentVar = get(dependentVariableSelector);
    const resultTableSortColumn = get(resultTableSortColumnState);
    const resultTableSortDirection = get(resultTableSortDirectionState);
    const activeFilters = get(activeFiltersState);
    const f1 = get(maxF1State);
    const dataRow = getResultsDataRows(inputData.values, showEcluded, dependentVar);
    const probabilities = getResultsProbabilities(inputData);
    const columns = getResultsColumns(inputData, showEcluded, resultTableSortDirection, resultTableSortColumn, activeFilters);

    columns.push({
      headerName: dependentVar?.displayName || 'Predict',
      field: `${dependentVar?.variableId || 'prediction'}`,
      pinned: 'right',
      sort: resultTableSortColumn === FilterVariant.PREDICTION ? resultTableSortDirection.toLowerCase() : '',
      suppressSizeToFit: true,
      cellRenderer: 'cellWithTooltip',
      cellRendererParams: { probabilities },
      filterParams: {
        type: dependentVar?.type,
        activeFilters,
        target: true,
        f1,
      },
      headerClass: 'seer-prediction-column-header',
    });

    return { dataRow, columns, probabilities, total: inputData.total };
  },
});

export const resultsRegressionTableDataSelector = selector({
  key: 'Results/resultsRegressionTableDataSelector',
  get: ({ get }) => {
    const inputData = get(resultDataState);
    if (!inputData) return null;

    const showEcluded = get(showExcludedState);
    const dependentVar = get(dependentVariableSelector);
    const resultTableSortColumn = get(resultTableSortColumnState);
    const resultTableSortDirection = get(resultTableSortDirectionState);
    const activeFilters = get(activeFiltersState);
    const dataRow = getResultsDataRows(inputData.values, showEcluded, dependentVar);
    const columns = getResultsColumns(inputData, showEcluded, resultTableSortDirection, resultTableSortColumn, activeFilters);

    columns.push({
      headerName: dependentVar?.displayName || 'Predict',
      field: `${dependentVar?.variableId || 'prediction'}`,
      pinned: 'right',
      sort: resultTableSortColumn === FilterVariant.PREDICTION ? resultTableSortDirection.toLowerCase() : '',
      suppressSizeToFit: true,
      filterParams: {
        type: dependentVar?.type,
        activeFilters,
        target: true,
      },
      headerClass: 'seer-prediction-column-header',
    });

    return { dataRow, columns, total: inputData.total };
  },
});

export const resultsTableDataSelector = selectorFamily({
  key: 'Results/resultsTableDataSelector',
  get:
    (projectId: number) => ({ get }) => {
      const project = get(entryProjectSelector(projectId));
      if (!project) return null;
      const map = {
        [ProjectType.Binary]: resultsBinaryTableDataSelector,
        [ProjectType.MultiClass]: resultsMultiClassTableDataSelector,
        [ProjectType.Regression]: resultsRegressionTableDataSelector,
      };
      const dataSelector: RecoilValueReadOnly<{
        dataRow: any[];
        columns: any;
        probabilities: {
          labels: any;
          values: any;
        };
        total: number;
      }> = map[project.type];
      return get(dataSelector);
    },
});

export const resultsTablePageState = atom({
  key: 'Results/resultsTablePageState',
  default: 1,
});
