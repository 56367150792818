import { useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import useApiClient from '~/src/hooks/useApiClient';
import { snackbarMessageSelector } from '~/src/Snackbar/store';
import { pendingCopyProjectState } from '../store';
// import useFindOne from './useFindOne';

const useCopyProject = () => {
  const { t } = useTranslation('projects');
  const apiClient = useApiClient();
  const setPending = useSetRecoilState(pendingCopyProjectState);
  const setSnackbarMessage = useSetRecoilState(snackbarMessageSelector);
  // const [findOne] = useFindOne();

  const copyProject = async (projectId) => {
    setPending(true);
    try {
      const { data: newProject } = await apiClient.put(`/projects/${projectId}/copy`);
      if (newProject.projectId) {
      // await findOne(newProject.projectId);
        setSnackbarMessage({
          message: t('copyProject.successStart', { name: newProject.displayName }),
          severity: 'success',
        });
      } else {
        throw new Error(newProject);
      }
    } catch (err) {
      console.error(err);
      setSnackbarMessage({
        message: t('copyProject.errorStart'),
        severity: 'error',
      });
    } finally {
      setPending(false);
    }
  };

  return copyProject;
};

export default useCopyProject;
