import React, { useCallback, useEffect, useRef, useState } from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import InfiniteScroll from 'react-infinite-scroller';
import AnalyzingDialog from '~/src/AnalyzingDialog/components/AnalyzingDialog';
import ConfirmationDialog from '~/src/components/ConfirmationDialog';
import Scrollable from '~/src/components/Scrollable';
import Loader from '~/src/components/LoaderOverlay';
import Spinner from '~/src/components/Loader';
import useToggle from '~/src/hooks/useToggle';
import {
  needRestartProjectsPageState, pendingProjectsGridSelector, projectListState,
  ProjectMenuActions,
  selectedMenuActionState,
  selectedProjectIdState,
  selectedProjectNameState,
  isReadySelectedProjectState,
} from '../store';
import ProjectCard, { MenuSelectHandlerParams } from './ProjectCard';
import FolderCard from '../../ProjectFolder/components/FolderCard';
import { SCROLL_CONTAINER_ID } from '../constants';
import RootFolderCard from '../../ProjectFolder/components/RootFolderCard';
import { currentFolderState, Folder } from '~/src/ProjectFolder/store';
import useDeleteFolder from '~/src/ProjectFolder/hooks/useDeleteFolder';
import useLoadMore from '../hooks/useLoadMore';
import { useFolderSelect } from '../hooks/useFolderSelect';
import useProjectRedirect from '../hooks/useProjectRedirect';
import { isDomoFrame } from '~/src/Connectors/Domo/stores/domoData';
import useCopyProject from '../hooks/useCopyProject';
import { Project } from '~/src/Project/store';
import ConnectorsEnum from '~/src/Connectors/enums/ConnectorsEnum';
import ProjectMenuDialogs from './ProjectMenuDialogs';

const useStyles = makeStyles(({ spacing }) => ({
  infiniteScroll: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    marginBottom: spacing(8),
    padding: spacing(0, 8),
    width: '100%',
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: spacing(52),
    height: spacing(40),
  },
}));

const ProjectsGrid = () => {
  const classes = useStyles();
  const { push } = useHistory();
  const { t } = useTranslation('projects');
  const resultsData = useRecoilValue(projectListState);
  const pending = useRecoilValue(pendingProjectsGridSelector);
  const currentFolder = useRecoilValue(currentFolderState);
  const [selectedProjectId, setSelectedProjectId] = useRecoilState(selectedProjectIdState);
  const setSelectedProjectName = useSetRecoilState(selectedProjectNameState);
  const setSelectedMenuAction = useSetRecoilState(selectedMenuActionState);
  const setIsReadySelectedProject = useSetRecoilState(isReadySelectedProjectState);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [isAnalyzingModalOpen, showAnalyzing, hideAnalyzing] = useToggle();
  const [isConfirmDeleteFolderOpen, showConfirmDeleteFolder, hideConfirmDeleteFolder] = useToggle();
  const copyProject = useCopyProject();
  const [deleteFolder] = useDeleteFolder();
  const [getMoreEntries] = useLoadMore();
  const [handleFolderSelect] = useFolderSelect();
  const redirectTo = useProjectRedirect();
  const isDomoIFrame = useRecoilValue(isDomoFrame);
  const scrollWrapper = useRef<HTMLDivElement>();
  const [needRestartProjectsPage, setNeedRestartProjectsPage] = useRecoilState(needRestartProjectsPageState);

  const handleProjectSelect = useCallback((project: Project, isReady: boolean) => {
    if (isReady) {
      const path = redirectTo(project._id, project.state, project.type);
      push(path);
    } else {
      setSelectedProjectId(project._id);
      showAnalyzing();
    }
  }, []);

  const goToResults = () => {
    push(`/projects/${selectedProjectId}`);
  };

  const handleProjectMenuClick = useCallback(({ action, project, isReady }: MenuSelectHandlerParams) => {
    setSelectedProjectId(project._id);
    setSelectedProjectName(project.displayName);

    switch (action) {
      case ProjectMenuActions.ShowResults:
        handleProjectSelect(project, isReady);
        return;
      case ProjectMenuActions.CopyProject:
        copyProject(project._id);
        return;
      default:
        setSelectedMenuAction(action);
        setIsReadySelectedProject(isReady);
    }
  }, []);

  const handleFolderDelete = (folder: Folder) => {
    setSelectedFolder(folder);
    showConfirmDeleteFolder();
  };

  const handleConfirmFolderDelete = () => {
    deleteFolder(selectedFolder._id);
    hideConfirmDeleteFolder();
    setSelectedFolder(null);
  };

  useEffect(() => {
    if (needRestartProjectsPage) {
      setNeedRestartProjectsPage(false);
    }
  }, [needRestartProjectsPage]);

  return (
    <Box display="flex" flex="auto" height="100%" position="relative">
      {pending && <Loader />}
      {!needRestartProjectsPage && <Scrollable ref={scrollWrapper} id={SCROLL_CONTAINER_ID}>
        <InfiniteScroll
          className={classes.infiniteScroll}
          pageStart={0}
          loadMore={getMoreEntries}
          getScrollParent={() => scrollWrapper.current}
          hasMore={resultsData.hasMore}
          useWindow={false}
          loader={
            <Box key="infinite-loader" className={classes.spinner}>
              <Spinner />
            </Box>
          }
        >
          {currentFolder && <RootFolderCard key="projects-root-folder" onClick={() => handleFolderSelect(null)} />}
          {isDomoIFrame
            ? resultsData?.entries?.filter(
              (entry) => (entry.folder && !entry.project)
                || entry.project?.files?.training.connectorType === ConnectorsEnum.Domo
                || entry.project?.files?.training.connectorType === ConnectorsEnum.DomoLarge
                || entry.project?.files?.production.connectorType === ConnectorsEnum.Domo
                || entry.project?.files?.production.connectorType === ConnectorsEnum.DomoLarge
                || entry.project?.files?.training.name === null,
            )
              .map((item) => (item?.project ? (
                <ProjectCard
                  project={item.project}
                  key={`${item._id} project`}
                  onClick={handleProjectSelect}
                  onMenuSelect={handleProjectMenuClick}
                  trainingConnector={item?.project.files?.training.connectorType}
                  productionConnector={item?.project.files?.production.connectorType}
                  currTrainingSchedule={item?.project.files?.training.schedule}
                  currProductionSchedule={item?.project.files?.production.schedule}
                />
              ) : (
                !currentFolder && (
                  <FolderCard
                    key={`${item?._id} folder`}
                    folder={item.folder}
                    onClick={handleFolderSelect}
                    onDelete={handleFolderDelete}
                  />
                )
              )))
            : resultsData?.entries
              ?.filter((entry) => entry?.project?.files?.training.connectorType !== 'domo'
                && entry?.project?.files?.production.connectorType !== 'domo')
              .map((item) => (item?.project ? (
                <ProjectCard
                  project={item.project}
                  key={`${item._id} project`}
                  onClick={handleProjectSelect}
                  onMenuSelect={handleProjectMenuClick}
                  trainingConnector={item?.project.files?.training.connectorType}
                  productionConnector={item?.project.files?.production.connectorType}
                  currTrainingSchedule={item?.project.files?.training.schedule}
                  currProductionSchedule={item?.project.files?.production.schedule}
                />
              ) : (
                !currentFolder && item.folder && (
                  <FolderCard
                    key={`${item?._id} folder`}
                    folder={item.folder}
                    onClick={handleFolderSelect}
                    onDelete={handleFolderDelete}
                  />
                )
              )))}
        </InfiniteScroll>
      </Scrollable>}
      {selectedProjectId && isAnalyzingModalOpen && (
        <AnalyzingDialog
          projectId={selectedProjectId}
          open={isAnalyzingModalOpen}
          onPrimaryClick={hideAnalyzing}
          onSecondaryClick={goToResults}
        />
      )}
      <ProjectMenuDialogs />
      {selectedFolder && isConfirmDeleteFolderOpen && (
        <ConfirmationDialog
          open={isConfirmDeleteFolderOpen}
          onConfirm={handleConfirmFolderDelete}
          onClose={hideConfirmDeleteFolder}
          title={t('deleteFolderDialog.title')}
          content={t('deleteFolderDialog.content', {
            name: selectedFolder.displayName,
          })}
          confirmButtonLabel={t('deleteFolderDialog.confirmButton')}
          closeButtonLabel={t('deleteFolderDialog.cancelButton')}
          primaryButtonColor="secondary"
        />
      )}
    </Box>
  );
};

export default React.memo(ProjectsGrid);
