import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Paper,
  Switch,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Scheduller from '~/src/Schedulling/components/Scheduller';
import TabPanel from '../../Files/components/SelectFileTabPanel';
import { defaultSchedulePattern } from '../store';
import { localConnectors } from '~/src/Connectors/stores/store';

const useStyles = makeStyles(({ spacing }) => ({
  paper: {
    padding: spacing(2),
    minHeight: '100%',
  },
  applyButton: {
    marginTop: spacing(4),
    minWidth: spacing(14),
    marginRight: spacing(4),
  },
  root: {
    '& > *': {
      margin: spacing(1),
    },
  },
}));

const AnalyzeSchedulerTabPanel = ({
  value,
  onChange,
  onEmailSubscribe,
  onOptingAnalyzeSchedule,
  handleAnalyzeScheduleProject,
  doFilter,
  analyzeSchedulerValue,
  existingFileSchedule,
  connType,
}) => {
  const { applyButton, paper, root } = useStyles();
  const { t } = useTranslation('projects');
  const TabContent = (
    <Box>
      {!localConnectors.includes(connType) ? (
        <Box>
          {analyzeSchedulerValue && (
            <Scheduller
              onChange={onChange}
              cronPattern={analyzeSchedulerValue}
            />
          )}
          <Box>
            <FormGroup className={root} row>
              <FormControlLabel
                control={
                  <Switch
                    checked={doFilter}
                    onChange={onEmailSubscribe}
                    name="checkedB"
                    color="primary"
                  />
                }
                label={t('email.analyze')}
              />
            </FormGroup>
          </Box>
        </Box>
      ) : (
        <Box>
          <Typography>{t('scheduler.tabs.analyzeInfo.csv')}</Typography>
        </Box>
      )}
    </Box>
  );

  return (
    <Box display="flex" alignItems="center">
      <TabPanel title={t('scheduler.schedulerDesc.analyze')} value={value}>
        <Paper className={paper}>{TabContent}</Paper>
        <Button
          variant="contained"
          onClick={handleAnalyzeScheduleProject}
          className={applyButton}
          color="primary"
        >
          {t('scheduler.saveButton')}
        </Button>
        <Button
          variant="contained"
          onClick={onOptingAnalyzeSchedule}
          className={applyButton}
          color="primary"
          disabled={!existingFileSchedule}
        >
          {t('scheduler.deleteButton')}
        </Button>
      </TabPanel>
    </Box>
  );
};

AnalyzeSchedulerTabPanel.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onEmailSubscribe: PropTypes.func.isRequired,
  onOptingAnalyzeSchedule: PropTypes.func.isRequired,
  handleAnalyzeScheduleProject: PropTypes.func.isRequired,
  doFilter: PropTypes.bool,
  analyzeSchedulerValue: PropTypes.string,
  existingFileSchedule: PropTypes.string,
  connType: PropTypes.string.isRequired,
};

AnalyzeSchedulerTabPanel.defaultProps = {
  value: '',
  doFilter: true,
  analyzeSchedulerValue: defaultSchedulePattern,
  existingFileSchedule: null,
};

export default AnalyzeSchedulerTabPanel;
