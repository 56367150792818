import { atom, selector } from 'recoil';
import snowflake from '../../assets/connectors/snowflake.png';
import googlesheets from '../../assets/connectors/googlesheets.png';
import googleanalytics from '../../assets/connectors/googleanalytics.png';
import s3assumerole from '../../assets/connectors/s3assumerole.png';
import sqlserver from '../../assets/connectors/sqlserver.png';
import salesforce from '../../assets/connectors/salesforce.png';
import totango from '../../assets/connectors/totango.png';
import excel from '../../assets/connectors/excel.png';
import hubspot from '../../assets/connectors/hubspot.png';
import ConnectorsEnum from '../enums/ConnectorsEnum';
import bigquery from '../../assets/connectors/googlebigqueryservice-320px.png';
import csv from '../../assets/connectors/csv.svg';
import domo from '../../assets/connectors/domo.svg';
import domoSmall from '../../assets/connectors/domo-small.svg';
import domoLarge from '../../assets/connectors/domo-large.svg';
import existedFile from '../../assets/connectors/existed-file.svg';
import { projectIdState } from '~/src/Project/store';
import { entryFileSelector } from '~/src/Projects/store';
import { FileType, fileTypeState } from '~/src/Files/store';

// Connector Error
export const connectorError = atom({
  key: 'Connector/connectorError',
  default: '',
});

export const localConnectorTypeState = atom({
  key: 'Connector/localConnectorTypeState',
  default: null,
});

export const fileName = atom({
  key: 'Connector/fileName',
  default: '',
});

export const fileNamePatternMatchState = atom({
  key: 'Connector/fileNamePatternMatchState',
  default: true,
});

export const fileDescription = atom({
  key: 'Connector/fileDescription',
  default: '',
});

export const uploadButtonDisabled = atom({
  key: 'Connector/uploadButtonDisabled',
  default: false,
});

export enum QueryTypesEnum {
  WriteQuery = 'WriteQuery',
  ObjectQuery = 'ObjectQuery',
}

export const queryType = atom({
  key: 'Connector/queryType',
  default: QueryTypesEnum.ObjectQuery,
});

export const searchColumnNames = atom({
  key: 'Connector/searchColumnNames',
  default: [],
});

export const connectorColumns = atom({
  key: 'Connector/columns',
  default: [],
});

export const commonSelectedColumns = atom({
  key: 'Connector/commonselectcolumns',
  default: [],
});

export const selectCompletion = atom({
  key: 'Connector/selectCompletion',
  default: false,
});

export const checkAllState = atom({
  key: 'Connector/checkAllState',
  default: true,
});

export const dialogOpen = atom({
  key: 'Connector/dialogOpen',
  default: false,
});

export const trainingCsvConnectorConfirmed = atom({
  key: 'Connector/trainingCsvConnectorConfirmed',
  default: '',
});

export const productionCsvConnectorConfirmed = atom({
  key: 'Connector/productionCsvConnectorConfirmed',
  default: '',
});

export const shouldShowNextButtonState = selector({
  key: 'Connector/shouldShowNextButtonState',
  get: ({ get }) => {
    const currentConnectorType = get(localConnectorTypeState);
    const projectId = get(projectIdState);
    const fileType = get(fileTypeState);
    const entryFile = get(entryFileSelector(projectId));
    const entryFileConnectorType = fileType === FileType.Training
      ? entryFile?.trainingFile?.connectorType
      : entryFile?.productionFile?.connectorType;

    return currentConnectorType ? currentConnectorType === ConnectorsEnum.CSV : entryFileConnectorType === ConnectorsEnum.CSV;
  },
});

export const showProductionFileHandleAtom = atom({
  key: 'Connector/showProductionFileHandleAtom',
  default: false,
});

export const currConnectorState = atom<ConnectorsEnum | string>({
  key: 'Connector/currConnectorState',
  default: '',
});

export const connectorImageData = [
  { key: ConnectorsEnum.S3AssumeRole, value: s3assumerole },
  { key: ConnectorsEnum.GoogleSheets, value: googlesheets },
  { key: ConnectorsEnum.Snowflake, value: snowflake },
  { key: ConnectorsEnum.Salesforce, value: salesforce },
  { key: ConnectorsEnum.Totango, value: totango },
  { key: ConnectorsEnum.SQLServer, value: sqlserver },
  { key: ConnectorsEnum.GoogleAnalytics, value: googleanalytics },
  { key: ConnectorsEnum.Hubspot, value: hubspot },
  { key: ConnectorsEnum.Excel, value: excel },
  { key: ConnectorsEnum.BigQuery, value: bigquery },
  { key: ConnectorsEnum.CSV, value: csv },
  { key: ConnectorsEnum.Domo, value: domo },
  { key: ConnectorsEnum.DomoLarge, value: domo },
  { key: ConnectorsEnum.PreviousFiles, value: existedFile },
];

export const connectorPanelTileData = [
  { img: s3assumerole, title: ConnectorsEnum.S3AssumeRole },
  { img: googlesheets, title: ConnectorsEnum.GoogleSheets },
  { img: snowflake, title: ConnectorsEnum.Snowflake },
  { img: salesforce, title: ConnectorsEnum.Salesforce },
  { img: totango, title: ConnectorsEnum.Totango },
  { img: csv, title: ConnectorsEnum.CSV },
  { img: excel, title: ConnectorsEnum.Excel },
  { img: bigquery, title: ConnectorsEnum.BigQuery },
  { img: sqlserver, title: ConnectorsEnum.SQLServer },
  // {img: googleanalytics, title: ConnectorsEnum.GoogleAnalytics},
  { img: hubspot, title: ConnectorsEnum.Hubspot },
  { img: existedFile, title: ConnectorsEnum.PreviousFiles },
];

export const domoTileData = [
  {
    img: domoSmall,
    title: ConnectorsEnum.Domo,
  },
  {
    img: domoLarge,
    title: ConnectorsEnum.DomoLarge,
  },
];

export enum ConnectorsDisplayEnum {
  S3AssumeRole = 'S3 Assume Role',
  Snowflake = 'Snowflake',
  GoogleSheets = 'Google Sheet',
  SQLServer = 'Sql Server',
  Salesforce = 'Salesforce',
  Totango = 'Totango',
  Domo = 'Domo',
  OneDrive = 'Onedrive',
  Dropbox = 'Dropbox',
  Hubspot = 'Hubspot',
  Aws = 'AWS',
  Excel = 'Excel',
  OracleCloud = 'Oracle Cloud',
  IBMCloud = 'IBM Cloud',
  AlibabaCloud = 'Alibaba Cloud',
  ICloud = 'I Cloud',
  GoogleAnalytics = 'Google Analytics',
  BigQuery = 'Big Query',
  CSV = 'CSV',
  PreviousFiles = 'Existing files',
}

export const connectorsNameMap = {
  [ConnectorsEnum.S3AssumeRole]: ConnectorsDisplayEnum.S3AssumeRole,
  [ConnectorsEnum.GoogleSheets]: ConnectorsDisplayEnum.GoogleSheets,
  [ConnectorsEnum.Snowflake]: ConnectorsDisplayEnum.Snowflake,
  [ConnectorsEnum.Salesforce]: ConnectorsDisplayEnum.Salesforce,
  [ConnectorsEnum.Totango]: ConnectorsDisplayEnum.Totango,
  [ConnectorsEnum.SQLServer]: ConnectorsDisplayEnum.SQLServer,
  [ConnectorsEnum.GoogleAnalytics]: ConnectorsDisplayEnum.GoogleAnalytics,
  [ConnectorsEnum.Hubspot]: ConnectorsDisplayEnum.Hubspot,
  [ConnectorsEnum.Excel]: ConnectorsDisplayEnum.Excel,
  [ConnectorsEnum.BigQuery]: ConnectorsDisplayEnum.BigQuery,
  [ConnectorsEnum.CSV]: ConnectorsDisplayEnum.CSV,
  [ConnectorsEnum.Domo]: ConnectorsDisplayEnum.Domo,
  [ConnectorsEnum.DomoLarge]: ConnectorsDisplayEnum.Domo,
  [ConnectorsEnum.PreviousFiles]: ConnectorsDisplayEnum.PreviousFiles,
};

export const localConnectors = [ConnectorsEnum.CSV, ConnectorsEnum.Excel, ConnectorsEnum.PreviousFiles];
