import { useResetRecoilState } from 'recoil';
import {
  aggregateDuplicatesState,
  aggregateToHigherLevelState,
  aggregateToHigherLevelTypeState,
  dateFormatState,
  duplicatesState,
  isAnalyzingDialogOpenState,
  keepDuplicatesState,
  outliersState,
  predictionStepsState,
  selectedOutliersState,
  selectedTimeframeState,
  timeSeriesPreprocessingResultState,
} from '../time-series.store';

const useTimeSeriesPreprocessorReset = () => {
  const resetTimeSeriesPreprocessingResult = useResetRecoilState(timeSeriesPreprocessingResultState);
  const resetSelectedTimeframe = useResetRecoilState(selectedTimeframeState);
  const resetPredictionSteps = useResetRecoilState(predictionStepsState);
  const resetDateFormat = useResetRecoilState(dateFormatState);
  const resetDuplicates = useResetRecoilState(duplicatesState);
  const resetKeepDuplicates = useResetRecoilState(keepDuplicatesState);
  const resetAggregateDuplicates = useResetRecoilState(aggregateDuplicatesState);
  const resetOutliers = useResetRecoilState(outliersState);
  const resetSelectedOutliers = useResetRecoilState(selectedOutliersState);
  const resetAggregateToHigherLevel = useResetRecoilState(aggregateToHigherLevelState);
  const resetAggregateToHigherLevelType = useResetRecoilState(aggregateToHigherLevelTypeState);
  const resetIsAnalyzingDialogOpen = useResetRecoilState(isAnalyzingDialogOpenState);

  const resetTimeSeriesStates = () => {
    resetTimeSeriesPreprocessingResult();
    resetSelectedTimeframe();
    resetPredictionSteps();
    resetDateFormat();
    resetKeepDuplicates();
    resetAggregateDuplicates();
    resetOutliers();
    resetSelectedOutliers();
    resetDuplicates();
    resetAggregateToHigherLevel();
    resetAggregateToHigherLevelType();
    resetIsAnalyzingDialogOpen();
  };

  return resetTimeSeriesStates;
};

export default useTimeSeriesPreprocessorReset;
