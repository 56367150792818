import { Auth } from '@aws-amplify/auth';
import { useSetRecoilState } from 'recoil';
import useApiClient from '~/src/hooks/useApiClient';
import { isLoadingState, errorAuthState } from '../store';

const useSocialLogin = () => {
  const apiClient = useApiClient();
  const setIsLoading = useSetRecoilState(isLoadingState);
  const setError = useSetRecoilState(errorAuthState);

  const socialLogin = async (provider) => {
    try {
      setIsLoading(true);
      await Auth.federatedSignIn({ provider });
      await apiClient.post('/projects/users');
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return socialLogin;
};

export default useSocialLogin;
