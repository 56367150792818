import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { useRecoilState, useRecoilValue } from 'recoil';
import CloseButton from '~/src/components/DialogCloseButton';
import { folderNameState, folderNameValidSelector } from '../store';
import useCreateFolder from '../hooks/useCreateFolder';

const useStyles = makeStyles(({ spacing }) => ({
  header: {
    paddingRight: spacing(12),
  },
  content: {
    paddingBottom: spacing(4),
    minWidth: spacing(80),
  },
  nameInput: {
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      top: '100%',
    },
  },
}));

const CreateFolderDialog = ({ onClose, open }) => {
  const classes = useStyles();
  const { t } = useTranslation('folders');
  const [name, setName] = useRecoilState(folderNameState);
  const isNameValid = useRecoilValue(folderNameValidSelector);
  const [nameTouched, setNameTouched] = useState(false);
  const [createFolder] = useCreateFolder();

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNameTouched(true);
    setName(e.target.value);
  };

  const clearCreateStates = () => {
    setNameTouched(false);
    setName('');
  };

  const handleApplyClick = () => {
    createFolder();
    onClose();
    clearCreateStates();
  };

  const handleCloseDialog = () => {
    onClose();
    clearCreateStates();
  };

  return (
    <Dialog open={open} onClose={handleCloseDialog} scroll="paper">
      <DialogTitle className={classes.header}>
        <Typography component="strong">{t('createFolder.label')}</Typography>
        <CloseButton onClick={handleCloseDialog} />
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Box mb={6}>
          <FormControl fullWidth required>
            <FormLabel>{t('createFolder.folderNameLabel')}</FormLabel>
            <TextField
              className={classes.nameInput}
              autoComplete="off"
              autoFocus
              type="text"
              variant="filled"
              hiddenLabel
              placeholder={t('createFolder.folderNamePlaceholder')}
              value={name}
              onChange={handleNameChange}
              InputProps={{ disableUnderline: true }}
              FormHelperTextProps={{ variant: 'standard' }}
              error={(nameTouched && !name) || (nameTouched && name && !isNameValid)}
              helperText={
                (nameTouched && !name && t('createFolder.nameIsRequired')) ||
                (nameTouched && name && !isNameValid && t('createFolder.validName'))
              }
            />
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleApplyClick} color="primary" disabled={!isNameValid}>
          {t('createFolder.applyBtn')}
        </Button>
        <Button variant="contained" onClick={handleCloseDialog}>
          {t('createFolder.cancelBtn')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CreateFolderDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

CreateFolderDialog.defaultProps = {
  open: false,
};

export default CreateFolderDialog;
