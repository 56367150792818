import { useEffect, useState } from 'react';
import { Amplify } from '@aws-amplify/core';
import { initializeApp } from 'firebase/app';
import { useSetRecoilState } from 'recoil';
import useLoginConfig from './useLoginConfig';
import { appConfigState } from '../store';

const useConfigure = () => {
  const [configured, setConfigured] = useState(false);
  const getLoginConfig = useLoginConfig();
  const setAppConfig = useSetRecoilState(appConfigState);

  useEffect(() => {
    const configure = async () => {
      initializeApp(window.appConfig.firestore);
      Amplify.configure(window.appConfig);
      await getLoginConfig();
      setAppConfig(JSON.parse(JSON.stringify(window.appConfig)));
      setConfigured(true);
    };

    configure();
  }, []);

  return configured;
};

export default useConfigure;
