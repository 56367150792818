import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import ConfirmationDialog from '~/src/components/ConfirmationDialog';
import { isUserOwnerSelector, selectedProjectIdState, selectedProjectNameState } from '../store';
import SchedulingDialog from '~/src/SchedulingDialog/component/SchedulingDialog';
import HistoryDialog from '~/src/HistoryDialog/component/HistoryDialog';
import ShareDialog from '~/src/ShareDialog/component/ShareDialog';
import {
  useProjectGridDeleteDialog, useProjectGridSchedulingDialog, useProjectGridHistoryDialog,
  useProjectGridShareDialog, useProjectGridStopProcessDialog, useProjectGridPredictionExecute,
  useProjectGridTrainingDialog,
} from '../hooks/Dialogs';
import TrainingDialog from './TrainingDialog';

const ProjectMenuDialogs = () => {
  const { t } = useTranslation('projects');
  const { isConfirmDeleteModalOpen, hideConfirmDelete, handleConfirmProjectDelete } = useProjectGridDeleteDialog();
  const { isSchedulingModalOpen, hideScheduler } = useProjectGridSchedulingDialog();
  const { isHistoryModalOpen, hideHistory } = useProjectGridHistoryDialog();
  const { isShareDialogOpen, hideShareDialog } = useProjectGridShareDialog();
  const { isConfirmStopProcessModalOpen, hideConfirmStopProcess, stopProcess } = useProjectGridStopProcessDialog();
  const { isConfirmExecutePredictionModalOpen, hideConfirmExecutePrediction, executePrediction } = useProjectGridPredictionExecute();
  const { isTrainingDialogOpen, hideTrainingDialog } = useProjectGridTrainingDialog();
  const selectedProjectId = useRecoilValue(selectedProjectIdState);
  const selectedProjectName = useRecoilValue(selectedProjectNameState);
  const isOwner = useRecoilValue(isUserOwnerSelector(selectedProjectId));

  return (
    <>
      {selectedProjectId && isSchedulingModalOpen && (
        <SchedulingDialog
          projectId={selectedProjectId}
          projectName={selectedProjectName}
          open={isSchedulingModalOpen}
          onClose={hideScheduler}
        />
      )}
      {selectedProjectId && isHistoryModalOpen && (
        <HistoryDialog
          projectId={selectedProjectId}
          projectName={selectedProjectName}
          open={isHistoryModalOpen}
          onClose={hideHistory}
        />
      )}
      {selectedProjectId && selectedProjectName && isShareDialogOpen && (
        <ShareDialog
          projectId={selectedProjectId}
          projectName={selectedProjectName}
          open={isShareDialogOpen}
          onClose={hideShareDialog}
        />
      )}
      {/* Stop Process */}
      {selectedProjectId && selectedProjectName && isConfirmStopProcessModalOpen && (
        <ConfirmationDialog
          open={isConfirmStopProcessModalOpen}
          onConfirm={stopProcess}
          onClose={hideConfirmStopProcess}
          title={t('stopProcessDialog.title')}
          content={t('stopProcessDialog.content', {
            name: selectedProjectName,
          })}
          confirmButtonLabel={t('stopProcessDialog.confirmButton')}
          closeButtonLabel={t('stopProcessDialog.cancelButton')}
          primaryButtonColor="secondary"
        />
      )}
      {/* Delete Project */}
      {selectedProjectId && selectedProjectName && isConfirmDeleteModalOpen && (
        <ConfirmationDialog
          open={isConfirmDeleteModalOpen}
          onConfirm={handleConfirmProjectDelete}
          onClose={hideConfirmDelete}
          title={t('deleteProjectDialog.title')}
          content={`${t('deleteProjectDialog.content', { name: selectedProjectName })} ${!isOwner ? t('deleteProjectDialog.unshare') : ''}`}
          confirmButtonLabel={t('deleteProjectDialog.confirmButton')}
          closeButtonLabel={t('deleteProjectDialog.cancelButton')}
          primaryButtonColor="secondary"
        />
      )}
      {/* Run Training */}
      {selectedProjectName && selectedProjectId && isTrainingDialogOpen && (
        <TrainingDialog
          projectId={selectedProjectId}
          projectName={selectedProjectName}
          open={isTrainingDialogOpen}
          onClose={hideTrainingDialog}
        />
      )}
      {/* Execute Prediction */}
      {selectedProjectId && selectedProjectName && isConfirmExecutePredictionModalOpen && (
        <ConfirmationDialog
          open={isConfirmExecutePredictionModalOpen}
          onConfirm={executePrediction}
          onClose={hideConfirmExecutePrediction}
          title={t('executePredictionDialog.title')}
          content={t('executePredictionDialog.content', {
            name: selectedProjectName,
          })}
          confirmButtonLabel={t('executePredictionDialog.confirmButton')}
          closeButtonLabel={t('executePredictionDialog.cancelButton')}
          primaryButtonColor="primary"
        />
      )}
    </>
  );
};

export default React.memo(ProjectMenuDialogs);
