import { useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import useApiClient from '~/src/hooks/useApiClient';
import { snackbarMessageSelector } from '~/src/Snackbar/store';
import { credentialsListState, newCredentialsState, pendingCredentialsState } from '../store';

export const CREDENTIAL_URL = '/authentication/credentials';

const useCredentials = () => {
  const { t } = useTranslation('develop');
  const apiClient = useApiClient();
  const setPending = useSetRecoilState(pendingCredentialsState);
  const setSnackbarMessage = useSetRecoilState(snackbarMessageSelector);
  const setNewCredentials = useSetRecoilState(newCredentialsState);
  const setCredentialsList = useSetRecoilState(credentialsListState);

  const create = async () => {
    setPending(true);
    try {
      const { data } = await apiClient.post(CREDENTIAL_URL);
      setNewCredentials(data);
    } catch (err) {
      console.error(err);
      setSnackbarMessage({
        message: t('credentials.errors.create'),
        severity: 'error',
      });
    } finally {
      setPending(false);
    }
  };

  const read = async () => {
    setPending(true);
    try {
      const { data } = await apiClient.get(CREDENTIAL_URL);
      setCredentialsList(data.length ? data : null);
    } catch (err) {
      console.error(err);
      setSnackbarMessage({
        message: t('credentials.errors.get'),
        severity: 'error',
      });
    } finally {
      setPending(false);
    }
  };

  const deleteCredentials = async (clientId) => {
    setPending(true);
    try {
      await apiClient.delete(CREDENTIAL_URL, { params: { clientId } });
      setCredentialsList((list) => (list ? list.filter((item) => item.clientId !== clientId) : null));
      setSnackbarMessage({
        message: t('credentials.success.deleteCredentials'),
        severity: 'success',
      });
    } catch (err) {
      console.error(err);
      setSnackbarMessage({
        message: t('credentials.errors.delete', { clientId }),
        severity: 'error',
      });
    } finally {
      setPending(false);
    }
  };

  return { create, delete: deleteCredentials, read };
};

export default useCredentials;
