import { atom, selector } from 'recoil';
import { ProjectState } from '~/src/Project/store';

export enum FilterOptions {
  All = 'all',
  Complete = 'complete',
  Incomplete = 'incomplete',
}

export interface SearchParams {
  limit?: number;
  query?: string;
  filter?: string;
  skip?: number;
}

export const searchStringState = atom({
  key: 'FilterProject/searchStringState',
  default: null,
});

export const filtersState = atom<FilterOptions>({
  key: 'FilterProject/filtersState',
  default: FilterOptions.All,
});

export const isFilteredProjectsState = atom({
  key: 'FilterProject/isFilteredProjectsState',
  default: false,
});

export const queryFilterSelector = selector({
  key: 'FilterProject/queryFilterSelector',
  get: ({ get }) => {
    const filter = get(filtersState);

    switch (filter) {
      case FilterOptions.All:
        return null;
      case FilterOptions.Complete:
        return { $and: [{ projectState: { $in: [ProjectState.Ready, ProjectState.Done] } }, { folder: null }] };
      default:
        return { $and: [{ projectState: { $nin: [ProjectState.Ready, ProjectState.Done] } }, { folder: null }] };
    }
  },
});
