/* eslint-disable prefer-template */
import React, { useMemo, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  makeStyles,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import cx from 'classnames';
import Loader from '~/src/components/Loader';
import { projectState, ProjectState, ProjectType, ScriptNames } from '~/src/Project/store';
import ScriptStepInformation from '~/src/components/ScriptStepInformation';
import { entryProjectSelector } from '~/src/Projects/store';
import { UPLOAD_PRODUCTION_DATA } from '~/src/Project/constant';
import { uploadedPercentState } from '~/src/Files/UploadLocalFile/store';
import useEmailGetSubscription from '~/src/Emails/hooks/useEmailGetSubscription';
import useEmailAddSubscription from '~/src/Emails/hooks/useEmailAddSubscription';
import useEmailRemoveSubscription from '~/src/Emails/hooks/useEmailRemoveSubscription';
import { SubscriptionType } from '~/src/Emails/store';

const useStyles = makeStyles(({ spacing, palette }) => ({
  title: {
    fontWeight: 700,
  },
  modal: {
    '& .MuiDialog-paper': {
      width: spacing(100),
    },
    '& .MuiDialogActions-root': {
      justifyContent: 'flex-start',
      padding: `${spacing(4)}px ${spacing(6)}px ${spacing(8)}px`,
      '& .MuiButton-root': {
        minWidth: spacing(20),
      },
      '& .MuiButtonBase-root': {
        flex: '0 1 50%',
      },
    },
    '& .MuiFormControlLabel-root': {
      marginLeft: spacing(3),
    },
    '& .MuiFormControlLabel-label': {
      fontWeight: 700,
      color: palette.primary.main,
    },
  },
  resultsBtn: {
    backgroundColor: palette.warning.main,
    '&:hover': {
      backgroundColor: palette.warning.dark,
    },
  },
  bold: {
    fontWeight: 700,
  },
}));

const AnalyzingDialog = ({ className, open, onPrimaryClick, onSecondaryClick, projectId }) => {
  const { t } = useTranslation('projects');
  const { title, modal, resultsBtn, bold } = useStyles();
  const { state, type } = useRecoilValue(entryProjectSelector(projectId)) || {};
  const currentState = useRecoilValue(projectState);
  const uploadedPercent = useRecoilValue(uploadedPercentState(projectId));
  const isReadyResults = useMemo(() => state === ProjectState.Ready, [state]);
  const [getEmailSubscription] = useEmailGetSubscription();
  const [addEmailSubscription] = useEmailAddSubscription();
  const [removeEmailSubscription] = useEmailRemoveSubscription();
  const [isSubscribedToEmails, setIsSubscribedToEmails] = useState(false);
  const isTimeSeriesProject = useMemo(() => type === ProjectType.TimeSeriesForecast, [type]);

  useEffect(() => {
    const checkEmailSubscriptions = async () => {
      const [analyzing, prediction] = await Promise.all([
        getEmailSubscription(projectId, SubscriptionType.Analyzing),
        getEmailSubscription(projectId, SubscriptionType.Prediction),
      ]);

      if (analyzing && prediction) setIsSubscribedToEmails(true);
    };

    checkEmailSubscriptions();
  }, []);

  const onNotifyMeCheckboxClick = () => {
    if (isSubscribedToEmails) {
      removeEmailSubscription(projectId, SubscriptionType.Analyzing);
      removeEmailSubscription(projectId, SubscriptionType.Prediction);
      setIsSubscribedToEmails(false);
    } else {
      addEmailSubscription(projectId, SubscriptionType.Analyzing);
      addEmailSubscription(projectId, SubscriptionType.Prediction);
      setIsSubscribedToEmails(true);
    }
  };

  const handleCloseDialog = (e, reason: string) => {
    // eslint-disable-next-line no-useless-return
    if (reason === 'backdropClick') return;
  };

  return (
    <Dialog open={open} className={cx(modal, className)} onClose={handleCloseDialog}>
      <DialogTitle disableTypography>
        <Box display="flex" justifyContent="center" alignItems="center" height={200}>
          <Loader size={128} />
        </Box>
        <Typography variant="h5" className={title}>
          {t('analyzingDialog.title')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {currentState === ProjectState.UploadingProductionFile && (
          <>
            <Typography className={bold} color="secondary" variant="h6">
              {t(`stepName.${UPLOAD_PRODUCTION_DATA}`) + (uploadedPercent ? ` - ${uploadedPercent}%` : '')}
            </Typography>
            <Typography variant="body1">{t(`stepDescription.${UPLOAD_PRODUCTION_DATA}`)}</Typography>
          </>
        )}
        {currentState !== ProjectState.UploadingProductionFile && (
          <>
            <ScriptStepInformation projectId={projectId} scriptName={isTimeSeriesProject ? ScriptNames.TimeSeriesAutomodeler : null} />
            <Box mt={4}>
              <Typography>{t('analyzingDialog.content')}</Typography>
            </Box>
          </>
        )}
      </DialogContent>
      <FormControlLabel
        control={<Checkbox checked={isSubscribedToEmails} onChange={onNotifyMeCheckboxClick} color="primary" />}
        label={t('analyzingDialog.notifyMeCheckbox')}
      />
      <DialogActions>
        <Button variant="contained" onClick={onPrimaryClick} color="primary">
          {t('analyzingDialog.goToProjectsButton')}
        </Button>
        <Button
          variant="contained"
          onClick={onSecondaryClick}
          disabled={!isReadyResults || currentState === ProjectState.UploadingProductionFile}
          className={resultsBtn}
        >
          {t('analyzingDialog.resultsButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AnalyzingDialog.propTypes = {
  open: PropTypes.bool,
  onPrimaryClick: PropTypes.func.isRequired,
  onSecondaryClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  projectId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

AnalyzingDialog.defaultProps = {
  open: false,
  className: '',
};

export default AnalyzingDialog;
