import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import useApiClient from '~/src/hooks/useApiClient';
import { displayName, Project, projectType } from '~/src/Project/store';
import { entriesState, Entry, EntryKind, wereProjectsLoadedState } from '~/src/Projects/store';
import { snackbarMessageSelector } from '~/src/Snackbar/store';
import { pendingCreate, createError } from '../store';

const useCreate = () => {
  const { t } = useTranslation('projects');
  const { push } = useHistory();
  const apiClient = useApiClient();
  const setPending = useSetRecoilState(pendingCreate);
  const setError = useSetRecoilState(createError);
  const setMessage = useSetRecoilState(snackbarMessageSelector);
  const name = useRecoilValue(displayName);
  const type = useRecoilValue(projectType);
  const setWereLoaded = useSetRecoilState(wereProjectsLoadedState);
  const setEntries = useSetRecoilState(entriesState);

  const create = async () => {
    try {
      setError(null);
      setPending(true);
      const { data: newProject } = await apiClient.post<Project>('/projects', {
        displayName: name.trim(),
        type,
      });
      const { data } = await apiClient.get<Entry[]>('/search', { params: { filter: btoa(JSON.stringify({ project: newProject._id })) } });
      setEntries((entries) => {
        const firstProjectIndex = entries.findIndex((item) => item.project);
        const newEntries = [...entries];
        newEntries.splice(firstProjectIndex, 0, data[0]);
        return newEntries;
      });            
      push(`/projects/${data[0].project._id}/training-file`);
      setMessage({ message: t('new.createSuccessMessage', { name: name.trim() }), severity: 'success' });
    } catch (err) {
      console.error(err);
      setMessage({ message: t('new.createErrorMessage'), severity: 'error' });
    } finally {
      setPending(false);
    }
  };

  return [create];
};

export default useCreate;
