/* eslint-disable @typescript-eslint/naming-convention */
// Steps
export const AM_ANALYZING_DATA = 'AM_Analyzing_Data';
export const AM_ENGINEERING_DATA = 'AM_Engineering_Data';
export const AM_FACTORING_DATA = 'AM_Factoring_Data';
export const AM_NLP = 'AM_NLP';
export const AM_REDUCING_DATA = 'AM_Reducing_Data';
export const AM_SELECTING_DATA = 'AM_Selecting_Data';
export const AM_MODELING_DATA = 'AM_Modeling_Data';
export const AM_ID_BEST_MODEL = 'AM_ID_Best_Model';
export const AM_FINISHING_RESULTS = 'AM_Finishing_Results';

export const PRED_UPDATING_FILE = 'PRED_Updating_File';
export const PRED_GENERATING_RESULTS = 'PRED_Generating_Results';

export const SS_ANALYZING_DATA = 'SS_Analyzing_data';
export const SS_MODELING_DATA = 'SS_Modeling_Data';
export const SS_FINISHING_RESULTS = 'SS_Finishing_Results';

export const FS_ANALYZING_RESULTS = 'FS_Analyzing_Results';
export const FS_MODELING_DATA = 'FS_Modeling_Data';
export const FS_FINISHING_RESULTS = 'FS_Finishing_Results';

export const DP_CLEANING_DATA = 'DP_Cleaning_Data';
export const DP_ASSESSING_DATA = 'DP_Assessing_Data';
export const DP_PREPARING_DATA = 'DP_Preparing_Data';
export const DP_PROFILING_DATA = 'DP_Profiling_Data';
export const DP_FINISHING_RESULTS = 'DP_Finishing_Results';

export const UTS_DP_ID_DUPLICATES = 'UTS_DP_ID_Duplicates';
export const UTS_DP_ID_PERIODICITY = 'UTS_DP_ID_Periodicity';

export const UTS_AM_UPDATE_PERIODICITY = 'UTS_AM_Update_Periodicity';
export const UTS_AM_AGGREGATE_DATA = 'UTS_AM_Aggregate_Data';
export const UTS_AM_OUTLIER_CHECK = 'UTS_AM_Outlier_Check';
export const UTS_AM_MODEL_DATA = 'UTS_AM_Model_Data';
export const UTS_AM_ID_BEST_MODEL = 'UTS_AM_ID_Best_Model';

export const UTS_PRED_GENERATE_PREDICT_RESULTS = 'UTS_PRED_Generate_Predict_Results';
export const UTS_PRED_GENERATE_VIZ_RESULTS = 'UTS_PRED_Generate_Viz_Results';
export const UTS_PRED_FINISHING_RESULTS = 'UTS_PRED_Finishing_Results';

export const UPLOAD_TRAINING_DATA = 'Upload_Training_Data';
export const UPLOAD_PRODUCTION_DATA = 'Upload_Production_Data';
export const FILE_VALIDATING = 'File_Validating';

export const automodelerSteps = [
  AM_ANALYZING_DATA,
  AM_ENGINEERING_DATA,
  AM_FACTORING_DATA,
  AM_NLP,
  AM_REDUCING_DATA,
  AM_SELECTING_DATA,
  AM_MODELING_DATA,
  AM_ID_BEST_MODEL,
  AM_FINISHING_RESULTS,
];
export const preprocessorSteps = [DP_CLEANING_DATA, DP_ASSESSING_DATA, DP_PREPARING_DATA, DP_PROFILING_DATA, DP_FINISHING_RESULTS];
export const predictionSteps = [PRED_UPDATING_FILE, PRED_GENERATING_RESULTS];
export const shapSummarySteps = [SS_ANALYZING_DATA, SS_MODELING_DATA, SS_FINISHING_RESULTS];
export const forceShapSteps = [FS_ANALYZING_RESULTS, FS_MODELING_DATA, FS_FINISHING_RESULTS];
export const utsPreprocessorSteps = [UTS_DP_ID_DUPLICATES, UTS_DP_ID_PERIODICITY];
export const utsAutomodelerSteps = [
  UTS_AM_UPDATE_PERIODICITY,
  UTS_AM_OUTLIER_CHECK,
  UTS_AM_AGGREGATE_DATA,
  UTS_AM_MODEL_DATA,
  UTS_AM_ID_BEST_MODEL,
];
export const utsPredictionsSteps = [UTS_PRED_GENERATE_PREDICT_RESULTS, UTS_PRED_GENERATE_VIZ_RESULTS, UTS_PRED_FINISHING_RESULTS];
