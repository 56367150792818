import { useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import useApiClient from '~/src/hooks/useApiClient';
import { snackbarMessageSelector } from '~/src/Snackbar/store';
import { pendingDeleteFolderState } from '../store';
import { projectListState } from '~/src/Projects/store';

const useDeleteFolder = () => {
  const { t } = useTranslation('folders');
  const apiClient = useApiClient();
  const setPending = useSetRecoilState(pendingDeleteFolderState);
  const setSnackbarMessage = useSetRecoilState(snackbarMessageSelector);  
  const setEntries = useSetRecoilState(projectListState);

  const deleteProjectFolder = async (folderId) => {
    setPending(true);
    try {
      await apiClient.delete(`/folders/${folderId}`);      
      setEntries((data) => ({ ...data, entries: data.entries.filter((item) => item.folder?._id !== folderId) }));
      setSnackbarMessage({
        message: t('deleteFolder.success'),
        severity: 'success',
      });
    } catch (err) {
      console.error(err);
      setSnackbarMessage({
        message: t('errors.delete'),
        severity: 'error',
      });
    } finally {
      setPending(false);
    }
  };

  return [deleteProjectFolder];
};

export default useDeleteFolder;
