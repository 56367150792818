import React, {
  Fragment, ReactNode, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';

interface DelayedProps {
  delay?: number;
  children?: ReactNode;
}

const Delayed: React.FC<DelayedProps> = ({ children, delay }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => setShow(true), delay);
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <Fragment>
      {show ? children : null}
    </Fragment>
  );
};

Delayed.propTypes = {
  delay: PropTypes.number,
  children: PropTypes.node,
};

Delayed.defaultProps = {
  delay: 1000,
  children: null,
};

export default Delayed;
