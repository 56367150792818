import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { pageTitle } from '~/src/store';

const useTitle = (title) => {
  const { t } = useTranslation();
  const setTitle = useSetRecoilState(pageTitle);

  useEffect(() => {
    document.title = `${title || ''}${title && ' - '}${t('title')}`;
    setTitle(title);
  }, [title]);
};

export default useTitle;
