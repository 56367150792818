import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, makeStyles, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useConnectorIcon from '../hooks/useConnectorIcon';

const useStyles = makeStyles(({ spacing }) => ({
  img: {
    width: spacing(7),
    height: spacing(7),
    marginRight: spacing(2.5),
    borderRadius: spacing(6),
    boxShadow: '0 0 1px 1px #fff',
  },
  toolTipClass: {
    textTransform: 'capitalize',
  },
}));

const ProjectCardConnectorIcons = ({ trainingConnector, productionConnector, projectName }) => {
  const classes = useStyles();
  const { t } = useTranslation('projects');
  const selectIcon = useConnectorIcon();

  const trainingSelectedConnectorIcon = useMemo(() => selectIcon(trainingConnector), [trainingConnector]);
  const productionSelectedConnectorIcon = useMemo(() => selectIcon(productionConnector), [productionConnector]);

  return (
    <Box className={classes.toolTipClass}>
      {trainingConnector && (
        <Tooltip
          title={t('projects:projectIcons.training.connector', {
            connectorName: trainingSelectedConnectorIcon.displayConnectorName,
            projectName,
          })}
          arrow
          enterDelay={500}
          enterNextDelay={500}
        >
          <img className={classes.img} alt={trainingConnector} src={trainingSelectedConnectorIcon.connIcon} />
        </Tooltip>
      )}
      {productionConnector && (
        <Tooltip
          title={t('projects:projectIcons.production.connector', {
            connectorName: productionSelectedConnectorIcon.displayConnectorName,
            projectName,
          })}
          arrow
          enterDelay={500}
          enterNextDelay={500}
        >
          <img className={classes.img} alt={productionConnector} src={productionSelectedConnectorIcon.connIcon} />
        </Tooltip>
      )}
    </Box>
  );
};

ProjectCardConnectorIcons.propTypes = {
  trainingConnector: PropTypes.string,
  productionConnector: PropTypes.string,
  projectName: PropTypes.string,
};

ProjectCardConnectorIcons.defaultProps = {
  trainingConnector: '',
  productionConnector: '',
  projectName: '',
};

export default React.memo(ProjectCardConnectorIcons);
