/* eslint-disable no-empty */
/* eslint-disable default-case */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme, alpha } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from 'react-i18next';
import SaveIcon from '@material-ui/icons/SaveOutlined';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { useDrop } from 'react-dnd';
import cx from 'classnames';
import ProjectMenu from '~/src/Projects/components/ProjectMenu';
import { ProjectMenuActions } from '~/src/Projects/store';
import { Folder } from '../store';
import ProjectTags from '~/src/Tags/components/ProjectTags';
import useToggle from '~/src/hooks/useToggle';
import InputFieldWithAction from '~/src/components/InputFieldWithAction';
import useFolderUpdate from '../hooks/useFolderUpdate';

const useStyles = makeStyles(({ spacing, palette, shape, shadows, transitions }) => ({
  name: {
    position: 'relative',
    wordBreak: 'break-word',
    lineHeight: '1.5rem',
    fontSize: '1.125rem',
    fontWeight: 700,
    maxHeight: spacing(12),
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    cursor: 'text',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, .1)',
    },
    '& svg': {
      marginRight: spacing(2),
    },
  },
  type: {
    color: palette.text.secondary,
    textTransform: 'uppercase',
    fontWeight: 700,
  },
  menuButton: {
    marginLeft: 'auto',
    padding: 4,
  },
  folderWrapper: {
    position: 'relative',
    height: spacing(47),
    padding: spacing(2),
    display: 'flex',
    flexDirection: 'column',
    '&:hover': {
      '& $element2, $folder': {
        boxShadow: shadows[9],
      },
      '& $element1': {
        boxShadow: `0 -2px 4px 0 ${alpha(palette.common.black, 0.1)}`,
      },
    },
    '&$dropOver': {
      '& $element2, $folder': {
        boxShadow: shadows[15],
      },
      '& $element1': {
        boxShadow: `0 -2px 4px 0 ${alpha(palette.common.black, 0.1)}`,
      },
    },
  },
  folder: {
    marginTop: 'auto',
    position: 'relative',
    zIndex: 10,
    width: spacing(52.5),
    height: spacing(37.5),
    padding: spacing(5),
    border: `1px solid ${alpha(palette.primary.main, 0.2)}`,
    backgroundColor: palette.info.main,
    borderRadius: shape.borderRadius * 2,
    transition: `box-shadow ${transitions.duration.standard}ms ${transitions.easing.easeInOut}`,
    cursor: 'pointer',
  },
  folderIlustration: {
    position: 'absolute',
    zIndex: 9,
    width: spacing(52.5),
  },
  element1: {
    height: spacing(2.5),
    width: '25%',
    backgroundColor: palette.info.dark,
    borderRadius: `${shape.borderRadius * 2}px 0 0`,
    border: `solid ${alpha(palette.primary.main, 0.2)}`,
    borderWidth: '1px 0 0 1px',
    transition: `box-shadow ${transitions.duration.standard}ms ${transitions.easing.easeInOut}`,
    position: 'relative',
    zIndex: 2,
    '&:after': {
      content: '" "',
      position: 'absolute',
      top: -1,
      right: -spacing(2.5),
      width: spacing(4.5),
      height: spacing(2.5),
      backgroundColor: palette.info.dark,
      transform: 'skew(45deg)',
      border: `solid ${alpha(palette.primary.main, 0.2)}`,
      borderWidth: '1px 1px 0 0',
      borderRadius: '0 2px 0 0',
    },
  },
  element2: {
    position: 'relative',
    zIndex: 1,
    top: -1,
    height: spacing(5),
    backgroundColor: palette.info.dark,
    borderRadius: `0 ${shape.borderRadius * 2}px 0 0`,
    border: `1px solid ${alpha(palette.primary.main, 0.2)}`,
    transition: `box-shadow ${transitions.duration.standard}ms ${transitions.easing.easeInOut}`,
  },
  dropOver: {},
  moreTagsBtn: {
    backgroundColor: palette.info.light,
  },
  editNameInput: {
    padding: spacing(0),
    borderWidth: '0 0 1px',
    borderRadius: 0,

    '& input': {
      paddingTop: 0,
      paddingBottom: 0,
      lineHeight: '1.5rem',
      fontSize: '1.125rem',
      fontWeight: 700,
    },
    '& .MuiIconButton-root': {
      padding: 0,
    },
  },
}));

interface FolderCardProps {
  folder: Folder;
  onClick: (folder: Folder) => void;
  onDelete?: (folder: Folder) => void;
}

const FolderCard: React.FC<FolderCardProps> = ({ folder, onClick, onDelete }) => {
  const { t } = useTranslation('folders');
  const classes = useStyles();
  const { spacing } = useTheme();
  const [editNameModeEnabled, , disableEditNameMode, toggleEditNameMode] = useToggle(false);
  const [updateFolder] = useFolderUpdate();

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: 'project',
    drop: () => folder,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const handleFolderSelect = (e) => {
    if (e.target.closest('.seer-project-prevent-default')) return;
    onClick(folder);
  };

  const handleMenuClick = (action: ProjectMenuActions) => {
    switch (action) {
      // case ProjectMenuActions.EditTags:
      //   console.log(action);
      //   return;
      case ProjectMenuActions.DeleteProject:
        onDelete(folder);
    }
  };

  const handleChangeFolderName = async (newName) => {
    try {
      await updateFolder(folder._id, { displayName: newName }, t('errors.changeName'));
      toggleEditNameMode();
    } catch (error) { }
  };

  return (
    <div className={cx(classes.folderWrapper, { [classes.dropOver]: canDrop && isOver })} ref={drop}>
      <Box className={classes.folderIlustration}>
        <Box className={classes.element1} />
        <Box className={classes.element2} />
      </Box>
      <Box
        className={classes.folder}
        width={spacing(52)}
        height={spacing(35)}
        p={5}
        display="flex"
        flexDirection="column"
        onClick={handleFolderSelect}
      >
        <Box flex="auto">
          {!editNameModeEnabled && (
            <Tooltip title={t('projects:updateProject.name.clickToEdit')} arrow enterDelay={500} enterNextDelay={500}>
              <Box onClick={toggleEditNameMode}>
                <Typography
                  variant="h6"
                  color="inherit"
                  className={cx(classes.name, 'seer-project-prevent-default')}
                >
                  {folder.displayName}
                </Typography>
              </Box>
            </Tooltip>
          )}
          {editNameModeEnabled && (
            <ClickAwayListener onClickAway={disableEditNameMode}>
              <InputFieldWithAction
                autoFocus
                className={cx('seer-project-prevent-default', classes.editNameInput)}
                disableActionWithoutChanges
                value={folder.displayName}
                actionIcon={SaveIcon}
                disableActionOnEmpty
                actionTooltip={t('projects:updateProject.name.saveButtonTooltip')}
                onActionClick={handleChangeFolderName}
              />
            </ClickAwayListener>
          )}
        </Box>
        <Box display="flex" alignItems="center">
          {!!folder.tags?.length && <ProjectTags tags={folder.tags} moreTagsButtonClassName={classes.moreTagsBtn} />}
          <ProjectMenu className={classes.menuButton} onClick={handleMenuClick} isFolder />
        </Box>
      </Box>
    </div>
  );
};

FolderCard.propTypes = {
  folder: PropTypes.any.isRequired,
  onClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
};

FolderCard.defaultProps = {
  onDelete: () => { },
};

export default React.memo(FolderCard);
