import { useCallback, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import useToggle from '~/src/hooks/useToggle';
import { ProjectMenuActions, selectedMenuActionState } from '../../store';
import { useResetProjectMenuDialogs } from './useResetProjectMenuDialogs';

export const useProjectGridSchedulingDialog = () => {
  const menuAction = useRecoilValue(selectedMenuActionState);
  const [isSchedulingModalOpen, showScheduler, hideScheduler] = useToggle();
  const resetProjectMenuDialogs = useResetProjectMenuDialogs();

  useEffect(() => {
    if (menuAction === ProjectMenuActions.ScheduleJob) {
      showScheduler();
    }
  }, [menuAction]);

  const handleHideScheduler = useCallback(() => {
    hideScheduler();
    resetProjectMenuDialogs();
  }, []);

  return { isSchedulingModalOpen, showScheduler, hideScheduler: handleHideScheduler };
};

export default useProjectGridSchedulingDialog;
