/* eslint-disable no-useless-computed-key */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Box, StepIconProps } from '@material-ui/core';
import { CheckCircle, TripOrigin } from '@material-ui/icons';

const StepIcon: React.FC<StepIconProps> = ({ completed, active }) => (
  <Box
    color={cx({ ['success.main']: completed, ['secondary.main']: active, ['action']: !active && !completed })}
    display="flex"
    alignItems="center"
  >
    {completed ? <CheckCircle color="inherit" /> : <TripOrigin color="inherit" />}
  </Box>
);

StepIcon.propTypes = {
  completed: PropTypes.bool,
  active: PropTypes.bool,
};

StepIcon.defaultProps = {
  completed: false,
  active: false,
};

export default StepIcon;
