import { useResetRecoilState } from 'recoil';
import {
  autocorrelationChartDataState,
  confidenceWithTheBestChartDataState,
  histogramChartDataState,
  movingAverageChartDataState,
  shaplyChartDataState,
} from '~/src/Charts/store';

import {
  confusionMatrixState,
  fieldsState,
  forcePlotFileUrlState,
  importanceVariablesState,
  modelsCoefficientsState,
  maxF1State,
  pivotColumnsState,
  pivotRowsState,
  pivotTableDataState,
  pivotValuesState,
  predictionFileUrlState,
  predictionStatsState,
  resultDataState,
  resultsRequestFailedState,
} from '../store';
import { tsPredictionDataState } from '../TimeSeries/time-series.store';

const useResetPrediction = () => {
  const resetResults = useResetRecoilState(resultDataState);
  const resetResultsRequestFailed = useResetRecoilState(resultsRequestFailedState);
  const resetConfusionMatrix = useResetRecoilState(confusionMatrixState);
  const resetPredictionStats = useResetRecoilState(predictionStatsState);
  const resetImportanceVariables = useResetRecoilState(importanceVariablesState);
  const resetModelsCoefficients = useResetRecoilState(modelsCoefficientsState);
  const resetShaplyChartDataState = useResetRecoilState(shaplyChartDataState);
  const resetTSPredictionDataState = useResetRecoilState(tsPredictionDataState);
  const resetHistogramChartDataState = useResetRecoilState(histogramChartDataState);
  const resetMovingAverageChartDataState = useResetRecoilState(movingAverageChartDataState);
  const resetAutocorrelationChartDataState = useResetRecoilState(autocorrelationChartDataState);
  const resetConfidenceWithTheBestChartDataState = useResetRecoilState(confidenceWithTheBestChartDataState);
  const resetMaxF1State = useResetRecoilState(maxF1State);
  const resetPredictionFileUrl = useResetRecoilState(predictionFileUrlState);
  const resetForcePlotFileUrl = useResetRecoilState(forcePlotFileUrlState);

  const resetPivotTableDataState = useResetRecoilState(pivotTableDataState);
  const resetPivotRowsState = useResetRecoilState(pivotRowsState);
  const resetPivotColumnsState = useResetRecoilState(pivotColumnsState);
  const resetPivotValuesState = useResetRecoilState(pivotValuesState);
  const resetPivotFieldsState = useResetRecoilState(fieldsState);

  const resetPrediction = () => {
    resetResults();
    resetConfusionMatrix();
    resetPredictionStats();
    resetImportanceVariables();
    resetModelsCoefficients();
    resetShaplyChartDataState();
    resetTSPredictionDataState();
    resetMaxF1State();
    resetResultsRequestFailed();
    resetHistogramChartDataState();
    resetMovingAverageChartDataState();
    resetAutocorrelationChartDataState();
    resetConfidenceWithTheBestChartDataState();
    resetPredictionFileUrl();
    resetForcePlotFileUrl();
    resetPivotTableDataState();
    resetPivotRowsState();
    resetPivotColumnsState();
    resetPivotValuesState();
    resetPivotFieldsState();
  };

  return resetPrediction;
};

export default useResetPrediction;
