import React from 'react';
import { ProjectState, ProjectType } from '~/src/Project/store';
import LeaderboardHelperContent from './components/helpers-content/LeaderboardHelperContent';

export enum HelpersPlacesOptions {
  DataScience = 'dataScience',
  ModelDetails = 'modelDetails',
  ProductionFile = 'modelDetails',
  TimeSeriesTraininfFile = 'tsTrainingFile',
  None = '',
}

export const HOW_TO_START_VIDEO_ID = '2h3QGmF_ozM';

const bestModelConfig = {
  videoId: '2h3QGmF_ozM',
  title: 'results.bestModel.title',
  content: 'results.bestModel.content',
  placement: 'bottom-end',
};

const predictionConfig = {
  videoId: '2h3QGmF_ozM',
  title: 'results.prediction.title',
  content: 'results.prediction.content',
  placement: 'top-end',
};

const tsPredictionConfig = {
  videoId: '2h3QGmF_ozM',
  title: 'tsResults.prediction.title',
  content: 'tsResults.prediction.content',
  placement: 'top-start',
};

export const helpersOptions = {
  [ProjectState.PendingProjectName]: [
    { videoId: '2h3QGmF_ozM', title: 'projectName.title', content: 'projectName.content', placement: 'bottom-start' },
  ],
  [ProjectState.PendingProjectType]: [
    { videoId: 'Ft1AYs3yuGA', title: 'projectType.titleWhatType', content: 'projectType.contentWhatType', placement: 'top' },
  ],
  [ProjectState.PendingTrainingFile]: [
    {
      videoId: 'Epe4Crj85nU',
      title: 'pendingTrainingFile.titleWhatIs',
      content: 'pendingTrainingFile.contentWhatIs',
      placement: 'bottom-start',
    },
  ],
  [ProjectState.PendingDependentVariable]: [
    {
      videoId: '2h3QGmF_ozM',
      title: 'pendingVariables.title',
      content: 'pendingVariables.content',
      placement: 'top',
    },
  ],
  [ProjectState.PendingIndependentVariable]: [
    {
      videoId: '2h3QGmF_ozM',
      title: 'pendingIndependentVariable.title',
      content: 'pendingIndependentVariable.content',
      placement: 'top',
    },
  ],
  [ProjectState.PendingProductionFile]: [
    {
      videoId: '2h3QGmF_ozM',
      title: 'pendingProductionFile.title',
      content: 'pendingProductionFile.content',
      placement: 'bottom-start',
    },
  ],
  [ProjectState.Ready]: [bestModelConfig, predictionConfig],
  [ProjectState.Ready + HelpersPlacesOptions.ModelDetails]: [
    {
      videoId: '2h3QGmF_ozM',
      title: 'modelDetails.shaplyChart.title',
      content: 'modelDetails.shaplyChart.content',
      placement: 'bottom-start',
    },
    {
      videoId: '2h3QGmF_ozM',
      title: 'modelDetails.variableImportance.title',
      content: 'modelDetails.variableImportance.content',
      placement: 'top-start',
    },
  ],
  [ProjectState.Ready + HelpersPlacesOptions.DataScience]: [
    bestModelConfig,
    {
      videoId: '2h3QGmF_ozM',
      title: 'results.leaderboard.title',
      isTransContent: true,
      content: <LeaderboardHelperContent />,
      placement: 'top-start',
    },
    predictionConfig,
    {
      videoId: '2h3QGmF_ozM',
      title: 'results.matrix.title',
      content: 'results.matrix.content',
      placement: 'top-start',
    },
  ],
  [ProjectState.PendingDependentVariable + HelpersPlacesOptions.TimeSeriesTraininfFile]: [
    {
      videoId: '2h3QGmF_ozM',
      title: 'pendingTSVariables.title',
      content: 'pendingTSVariables.content',
      placement: 'top',
    },
  ],
  [ProjectState.PendingTimeSeriesPrediction]: [
    {
      title: 'pendingTSPrediction.timeframe.title',
      content: 'pendingTSPrediction.timeframe.content',
      placement: 'top',
    },
    {
      title: 'pendingTSPrediction.outliers.title',
      content: 'pendingTSPrediction.outliers.content',
      placement: 'top',
    },
    {
      title: 'pendingTSPrediction.duplicates.title',
      content: 'pendingTSPrediction.duplicates.content',
      placement: 'top',
    },
    {
      title: 'pendingTSPrediction.aggregation.title',
      content: 'pendingTSPrediction.aggregation.content',
      placement: 'top',
    },
  ],
  [ProjectState.Ready + ProjectType.TimeSeriesForecast]: [bestModelConfig, tsPredictionConfig],
  [ProjectState.Ready + ProjectType.TimeSeriesForecast + HelpersPlacesOptions.DataScience]: [
    bestModelConfig,
    {
      videoId: '2h3QGmF_ozM',
      title: 'results.leaderboard.title',
      isTransContent: true,
      content: <LeaderboardHelperContent />,
      placement: 'top-start',
    },
    tsPredictionConfig,
  ],
};
