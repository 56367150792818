import { useResetRecoilState } from 'recoil';
import { credentialsListState, devTokenState, newCredentialsState } from '../store';

const useResetDevelop = () => {
  const setNewCredentials = useResetRecoilState(newCredentialsState);
  const setCredentialsList = useResetRecoilState(credentialsListState);
  const setIdToken = useResetRecoilState(devTokenState);

  const resetDevelop = () => {
    setNewCredentials();
    setCredentialsList();
    setIdToken();
  };

  return resetDevelop;
};

export default useResetDevelop;
