import { useCallback, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import useToggle from '~/src/hooks/useToggle';
import { ProjectMenuActions, selectedMenuActionState } from '../../store';
import { useResetProjectMenuDialogs } from './useResetProjectMenuDialogs';

export const useProjectGridHistoryDialog = () => {
  const menuAction = useRecoilValue(selectedMenuActionState);
  const [isHistoryModalOpen, showHistory, hideHistory] = useToggle();
  const resetProjectMenuDialogs = useResetProjectMenuDialogs();

  useEffect(() => {
    if (menuAction === ProjectMenuActions.ProjectHistory) {
      showHistory();
    }
  }, [menuAction]);

  const handleHideHistory = useCallback(() => {
    hideHistory();
    resetProjectMenuDialogs();
  }, []);

  return { isHistoryModalOpen, showHistory, hideHistory: handleHideHistory };
};

export default useProjectGridHistoryDialog;
