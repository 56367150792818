import { useResetRecoilState } from 'recoil';
import { forcePlotChartDataState } from '~/src/Charts/store';
import { localConnectorTypeState, currConnectorState } from '~/src/Connectors/stores/store';
import { selectedFileNameState, selectedProductionFileNameState } from '~/src/Files/store';
import { selectedFileState } from '~/src/Files/UploadLocalFile/store';
import { currentProjectIdState, displayName, panelIndicatorState, projectIdState, projectState, projectType } from '../store';
import useResetPrediction from '~/src/ProjectResults/hooks/useResetPrediction';
import {
  allLeaderbordState,
  isAllLeaderbordUploadedState,
  leaderbordState,
  mojoFileListState,
  reportsState,
  resultsTablePageState,
  resultTableSortColumnState,
  resultTableSortDirectionState,
} from '~/src/ProjectResults/store';
import useTimeSeriesResultsReset from '~/src/ProjectResults/TimeSeries/hooks/useTimeSeriesResultsReset';
import { activeFiltersState, productionFileStatsState } from '~/src/TableFilters/store';
import { configuredVariablesState, variablesState } from '~/src/TrainingFile/store';
import useTimeSeriesPreprocessorReset from '~/src/TrainingFile/TimeSeries/hooks/useTimeSeriesPreprocessorReset';

const useResetProject = () => {
  const resetName = useResetRecoilState(displayName);
  const resetType = useResetRecoilState(projectType);
  const resetCurrentProjectId = useResetRecoilState(currentProjectIdState);
  const resetState = useResetRecoilState(projectState);
  const resetTrainingFileName = useResetRecoilState(selectedFileNameState);
  const resetProductionFileName = useResetRecoilState(selectedProductionFileNameState);
  const resetSelectedFileState = useResetRecoilState(selectedFileState);
  const resetCurrentConnectorTypeState = useResetRecoilState(localConnectorTypeState);
  const resetCurrConnectorState = useResetRecoilState(currConnectorState);
  const resetConfiguredVariables = useResetRecoilState(configuredVariablesState);
  const resetVariables = useResetRecoilState(variablesState);
  const resetLeaderbord = useResetRecoilState(leaderbordState);
  const resetMojoFileList = useResetRecoilState(mojoFileListState);
  const resetReports = useResetRecoilState(reportsState);
  const resetAllLeaderbord = useResetRecoilState(allLeaderbordState);
  const resetIsAllLeaderbordUploaded = useResetRecoilState(isAllLeaderbordUploadedState);
  const resetforcePlotChartData = useResetRecoilState(forcePlotChartDataState);
  const resetProjectIdState = useResetRecoilState(projectIdState);
  const resetResultsTablePage = useResetRecoilState(resultsTablePageState);
  const resetResultTableSortColumn = useResetRecoilState(resultTableSortColumnState);
  const resetResultTableSortDirection = useResetRecoilState(resultTableSortDirectionState);
  const resetActiveFiltersState = useResetRecoilState(activeFiltersState);
  const resetproductionFileStats = useResetRecoilState(productionFileStatsState);
  const resetPanelIndicatorState = useResetRecoilState(panelIndicatorState);
  const resetTimeSeriesPreprocessor = useTimeSeriesPreprocessorReset();
  const resetTimeSeriesResultsReset = useTimeSeriesResultsReset();
  const resetPrediction = useResetPrediction();

  const resetProject = () => {
    resetName();
    resetState();
    resetType();
    resetCurrentProjectId();
    resetTrainingFileName();
    resetProductionFileName();
    resetSelectedFileState();
    resetConfiguredVariables();
    resetCurrentConnectorTypeState();
    resetCurrConnectorState();
    resetVariables();
    resetPrediction();
    resetLeaderbord();
    resetMojoFileList();
    resetReports();
    resetAllLeaderbord();
    resetIsAllLeaderbordUploaded();
    resetforcePlotChartData();
    resetProjectIdState();
    resetResultsTablePage();
    resetResultTableSortColumn();
    resetResultTableSortDirection();
    resetActiveFiltersState();
    resetproductionFileStats();
    resetTimeSeriesPreprocessor();
    resetTimeSeriesResultsReset();
    resetPanelIndicatorState();
  };

  return resetProject;
};

export default useResetProject;
