/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, makeStyles, Step, StepContent, StepLabel, Stepper, TextField, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  displayName, displayNameTouched, displayNameValid, ProjectState, projectType, ProjectType, projectState,
} from '~/src/Project/store';
import StepIcon from '~/src/components/StepIcon';
import ProjectTypeCard from './ProjectTypeCard';
import HelperPopup from '~/src/Helper/components/HelperPopup';
import HelperButton from '~/src/Helper/components/HelperButton';
import useHelper from '~/src/Helper/hooks/useHelper';
import { askASeerState } from '~/src/Helper/store';

interface ProjectNameAndTypeProps {
  onNext: () => any;
}

const useInputStyles = makeStyles(() => ({
  root: {
    fontSize: '2.5rem',
  },
}));

const ProjectNameAndType: React.FC<ProjectNameAndTypeProps> = ({ onNext }) => {
  const { t } = useTranslation(['projects', 'helpers']);
  const [state, setProjectState] = useRecoilState(projectState);
  const types = [ProjectType.Binary, ProjectType.MultiClass, ProjectType.Regression, ProjectType.TimeSeriesForecast];
  const activeStep = useMemo(() => [ProjectState.PendingProjectName, ProjectState.PendingProjectType].indexOf(state), [state]);
  const classes = useInputStyles();
  const [name, setName] = useRecoilState(displayName);
  const [selectedType, setType] = useRecoilState(projectType);
  const [touched, setTouched] = useRecoilState(displayNameTouched);
  const nameValid = useRecoilValue(displayNameValid);
  const [refs, setRefs] = useState([]);
  const { helper, activeHelperIndex, helperConfigs, setActiveHelperIndex } = useHelper(state);

  // helpers refs
  const nameRef = useRef<HTMLDivElement>(null);
  const whatIsTypeRef = useRef<HTMLDivElement>(null);
  // const learnAboutTypeRef = useRef<HTMLDivElement>(null);
  // const askSeerRef = useRecoilValue(askASeerState);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
    setTouched(true);
  };

  const handleNextProjectNameClick = () => {
    setProjectState(ProjectState.PendingProjectType);
  };

  const handleEnterPressOnProjectName = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && nameValid) {
      setProjectState(ProjectState.PendingProjectType);
    }
  };

  const handleEnterPressOnProjectType = (event: React.KeyboardEvent<HTMLElement>, type: ProjectType) => {
    if (event.key === ' ') {
      setType(type);
    } else if (event.key === 'Enter' && selectedType === type) {
      onNext();
    }
  };

  useEffect(() => {
    setRefs(state === ProjectState.PendingProjectName ? [nameRef.current] : [whatIsTypeRef.current]);
  }, [state]);

  return (
    <>
      <Stepper activeStep={activeStep} orientation="vertical">
        <Step>
          <StepLabel StepIconComponent={StepIcon}>
            <Typography variant="h5" color="textPrimary">
              {t('nameAndType.name.step')}
            </Typography>
          </StepLabel>
          <StepContent>
            <TextField
              autoFocus
              ref={nameRef}
              error={touched && !nameValid}
              placeholder={t('nameAndType.name.placeholder')}
              variant="standard"
              value={name}
              onChange={handleNameChange}
              onKeyPress={handleEnterPressOnProjectName}
              InputProps={{ disableUnderline: true, classes }}
            />
            <Box py={6}>
              <Button
                color="primary"
                variant="contained"
                disabled={!nameValid}
                size="large"
                onClick={handleNextProjectNameClick}
              >
                <Box px={4}>{t('nameAndType.nextButton')}</Box>
              </Button>
            </Box>
          </StepContent>
        </Step>
        {!!activeStep && (
          <Step>
            <StepLabel StepIconComponent={StepIcon}>
              <Typography variant="h5" color="textPrimary">
                {t('nameAndType.type.step')}
              </Typography>
            </StepLabel>
            <StepContent>
              <Box display="flex" flexWrap="wrap">
                {types.map((type, i) => (
                  <Box
                    pr={4}
                    pb={4}
                    key={type}
                    onClick={() => setType(type)}
                    onKeyPress={(e) => { handleEnterPressOnProjectType(e, type); }}
                  >
                    <ProjectTypeCard type={type} selected={selectedType === type} ref={i === 1 ? whatIsTypeRef : null} tabIndex={i + 1} />
                  </Box>
                ))}
              </Box>
              <Box pb={6} pt={2} display="flex">
                <Button
                  color="primary"
                  disabled={!nameValid}
                  size="large"
                  onClick={() => {
                    setProjectState(ProjectState.PendingProjectName);
                  }}
                >
                  <Box px={4}>{t('nameAndType.backButton')}</Box>
                </Button>
                <Box p={1} />
                <Button color="primary" variant="contained" size="large" disabled={!selectedType} onClick={onNext}>
                  <Box px={4}>{t('nameAndType.nextButton')}</Box>
                </Button>
              </Box>
            </StepContent>
          </Step>
        )}
      </Stepper>
      {helper && (
        <HelperPopup
          anchorEl={refs[activeHelperIndex]}
          onClose={() => {
            setActiveHelperIndex(refs.length);
          }}
          placement={helperConfigs[activeHelperIndex]?.placement}
          length={refs.length}
          activeIndex={activeHelperIndex}
          onSwitchStepClick={setActiveHelperIndex}
          videoId={helperConfigs[activeHelperIndex]?.videoId}
          title={t(`helpers:${helperConfigs[activeHelperIndex]?.title}`)}
          content={t(`helpers:${helperConfigs[activeHelperIndex]?.content}`)}
        />
      )}
      <HelperButton />
    </>
  );
};

ProjectNameAndType.propTypes = {
  onNext: PropTypes.func.isRequired,
};

export default React.memo(ProjectNameAndType);
