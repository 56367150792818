import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState, useRecoilValue, useRecoilState } from 'recoil';
import useApiClient from '~/src/hooks/useApiClient';
import { snackbarMessageSelector } from '~/src/Snackbar/store';
import { userConfigState, authenticatedUserEmailState, isPendingUserConfigState } from '../store';

const useUserConfig = () => {
  const { t } = useTranslation('auth');
  const authenticatedUserEmail = useRecoilValue(authenticatedUserEmailState);
  const [userConfig, setUserConfig] = useRecoilState(userConfigState);
  const setMessage = useSetRecoilState(snackbarMessageSelector);
  const setPending = useSetRecoilState(isPendingUserConfigState);
  const apiClient = useApiClient();

  const getUserConfig = useCallback(async (email: string) => {
    try {
      setPending(true);
      const { data } = await apiClient.get(`/projects/users/${email}`);
      setUserConfig(data || null);
    } catch (err) {
      console.error(err);
      setMessage({ message: t('userConfig.errors.getUser'), severity: 'error' });
    } finally {
      setPending(false);
    }
  }, []);

  useEffect(() => {
    if (authenticatedUserEmail && !userConfig) {
      getUserConfig(authenticatedUserEmail);
    }
  }, [authenticatedUserEmail, userConfig]);
};

export default useUserConfig;
