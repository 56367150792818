import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import useConnectorUpload from '~/src/Connectors/hooks/useConnectorUpload';
import { FileType } from '~/src/Files/store';
import { snackbarMessageSelector } from '~/src/Snackbar/store';
import { entryFileSelector } from '../store';

const useExecuteProjectTraining = (projectId) => {
  const { t } = useTranslation('connectors');
  const entryFile = useRecoilValue(entryFileSelector(projectId));
  const setMessage = useSetRecoilState(snackbarMessageSelector);
  const [upload] = useConnectorUpload(projectId);

  const executeProjectTraining = async (uploadType = 'execute') => {
    const { connectorConfig, connectorType, name } = entryFile?.trainingFile || {};
    if (!connectorConfig) {
      setMessage({ message: t('connectorsTabPanel.missedConfigError'), severity: 'warning' });
      return false;
    }
    connectorConfig.uploadType = uploadType;

    const result = await upload(
      connectorConfig,
      `${connectorType}/upload`,
      name.split('.')[0],
      FileType.Training,
    );

    return result;
  };
  return [executeProjectTraining];
};

export default useExecuteProjectTraining;
