import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useRecoilValue } from 'recoil';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core';
import { timeOptionsSelector } from '../store';

interface SelectHourProps {
  [key: string]: any;
}

const useStyles = makeStyles(({ spacing }) => ({
  selectField: {
    '& .MuiSelect-root': {
      padding: spacing(2.5, 8, 2.5, 3),
      lineHeight: `${spacing(5)}px`,
      minWidth: spacing(30),
    },
  },
  pre: {
    whiteSpace: 'pre',
  },
}));

const SelectHour: React.FC<SelectHourProps> = ({ value, onChange, className, options, label, disabled, ...rest }) => {
  const classes = useStyles();
  const timeOptions = useRecoilValue(timeOptionsSelector);

  const handleChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <FormControl className={cx(className)} {...rest}>
      {label && <FormLabel>{label}</FormLabel>}
      <Select className={classes.selectField} variant="outlined" value={value} onChange={handleChange} disabled={disabled}>
        {(options || timeOptions).map((item) => (
          <MenuItem key={item.value} value={item.value}>
            <span className={classes.pre}>{item.label}</span>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

SelectHour.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  className: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

SelectHour.defaultProps = {
  className: '',
  label: null,
  value: 0,
  options: null,
  disabled: false,
};

export default SelectHour;
