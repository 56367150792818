/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { getFirestore, doc, onSnapshot } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import { entryProjectSelector, isNeedProjectUpdateSelector } from '~/src/Projects/store';
import {
  CopyEntities, CopyJobState, CopyNotification, copyProjectState, ProjectState, projectState,
  ScriptNames, ScriptState, ProjectScriptsState, Project, ScriptStatus,
} from '../store';
import { snackbarMessageSelector } from '~/src/Snackbar/store';
import { authenticatedUserEmailState } from '~/src/Auth/store';
import { SCRIPTS_DATE_TO_SHOW } from '~/src/Projects/constants';

const copyEntities = Object.values(CopyEntities);

const getCopyState = (copy: CopyNotification) => {
  if (copyEntities.some((entity) => !copy[entity])) return null;
  if (copyEntities.some((entity) => copy[entity] === CopyJobState.Failed)) return CopyJobState.Failed;
  if (copyEntities.every((entity) => copy[entity] === CopyJobState.Ready)) return CopyJobState.Ready;
  return null;
};

const getNotificationUpdates = (notificationData: ProjectScriptsState, project: Project) => {
  if (!project) return {};
  const scriptStatusList: { [key: string]: ScriptState } = Object.values(ScriptNames).reduce((acc, scriptName) => {
    acc[scriptName] = notificationData[scriptName];
    return acc;
  }, {});
  const [, scriptStatus] = Object.entries(scriptStatusList)
    .find(([key, value]) => value && value.error && value.error !== project[key]?.error) || [];

  const changedScript = Object.entries(scriptStatusList).find(
    ([key, value]) => value
      && (value.step !== project[key]?.step || value.state !== project[key]?.state || value.error !== project[key]?.error),
  );
  return { scriptStatusList, scriptStatus, changedScript };
};

const needProjectUpdate = (data: ProjectScriptsState, project: Project) => SCRIPTS_DATE_TO_SHOW.some(
  (item) => data[item]?.state
    && project[item]
    && (data[item].state === ScriptStatus.Ready)
    && (project[item].state !== ScriptStatus.Ready),
);

const useProjectState = (projectId: number, isProjectsScreen?: boolean) => {
  const { t } = useTranslation('projects');
  const setProjectState = useSetRecoilState(projectState);
  const [project, setProject] = useRecoilState(entryProjectSelector(projectId));
  const setNeedProjectUpdate = useSetRecoilState(isNeedProjectUpdateSelector(projectId));
  const setCopyState = useSetRecoilState(copyProjectState);
  const setMessage = useSetRecoilState(snackbarMessageSelector);
  const userEmail = useRecoilValue(authenticatedUserEmailState);

  useEffect(() => {
    let unsubscribeNotifications;

    const projectNotificationSubscriber = async () => {
      unsubscribeNotifications = onSnapshot(
        doc(getFirestore(), window.appConfig.env || 'develop', `${projectId}`, 'users', userEmail),
        (document) => {
          const { state, copy, ...notificationData } = document.data() || {};
          if (!state || !project) {
            return;
          }

          if (copy) {
            const copyState = getCopyState(copy);
            if (copyState) {
              setCopyState({
                state: copyState,
                projectIdNew: copy.projectIdNew,
                projectIdSource: projectId,
                projectNameSource: project.displayName,
              });
            }
          }

          const { scriptStatusList, scriptStatus, changedScript } = getNotificationUpdates(notificationData, project);
          // set UI project state
          if (!isProjectsScreen) {
            setProjectState(
              // prevent update inner-UI (ProjectState.PendingIndependentVariable) project state on new subscription
              (curState) => (curState === ProjectState.PendingIndependentVariable && state === ProjectState.PendingDependentVariable
                ? curState
                : state),
            );
          }

          if (needProjectUpdate(notificationData, project)) {
            setNeedProjectUpdate(true);
          } else {
            // show error if exist
            if (scriptStatus && !isProjectsScreen) {
              setMessage({
                message: t(`errorDescription.${scriptStatus.error}`, scriptStatus),
                severity: 'error',
                title: project.displayName,
              });
            }
            // update project in list of projects
            if (state !== project.state || changedScript || scriptStatus) {
              setProject({ ...project, state, ...scriptStatusList });
            }
          }
        },
        (error) => {
          console.error(error);
          setMessage({ message: t('subscription.errors.projectState', { name: project?.displayName || '' }), severity: 'error' });
        },
      );
    };

    if (!unsubscribeNotifications && userEmail && project) {
      projectNotificationSubscriber();
    }

    return () => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      unsubscribeNotifications && unsubscribeNotifications();
    };
  }, [userEmail, project]);
};

export default useProjectState;
