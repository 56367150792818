import { useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import useApiClient from '~/src/hooks/useApiClient';
import { snackbarMessageSelector } from '~/src/Snackbar/store';
import { pendingDeleteProject, projectListState } from '../store';

const useDeleteProject = () => {
  const { t } = useTranslation('projects');
  const apiClient = useApiClient();
  const setPending = useSetRecoilState(pendingDeleteProject);
  const setSnackbarMessage = useSetRecoilState(snackbarMessageSelector);
  const setProjects = useSetRecoilState(projectListState);

  const deleteProject = async (projectId) => {
    setPending(true);
    try {
      await apiClient.delete(`/projects/${projectId}`);
      setProjects((state) => {
        return {
          ...state,
          entries: state.entries.filter((item) => !item.project || item.project._id !== projectId),
        };
      });
      setSnackbarMessage({
        message: t('deleteProjectDialog.deleteSuccess'),
        severity: 'success',
      });
    } catch (err) {
      console.error(err);
      setSnackbarMessage({
        message: t('deleteProjectDialog.deleteError'),
        severity: 'error',
      });
    } finally {
      setPending(false);
    }
  };

  return [deleteProject];
};

export default useDeleteProject;
