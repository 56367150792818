import { atom, selector } from 'recoil';

export enum VariableValueType {
  Int = 'int',
  Enum = 'enum',
  Real = 'real',
  String = 'string',
  Date = 'date',
  Time = 'time',
}

export enum VariableType {
  Dependent = 'dependent',
  Independent = 'independent',
  Excluded = 'excluded',
  TimeSeriesDate = 'time',
  TimeSeriesValue = 'number',
}

export interface ITrainingVariableValue {
  valueId: number;
  value: string;
}

export interface ITrainingVariable {
  variableId: string;
  displayName: string;
  state: VariableType;
  totalValues: number;
  uniqueValues: number;
  missingValues: number;
  type: VariableValueType;
  trainingValues?: ITrainingVariableValue[];
}

export interface ITrainingConfigVariable {
  variableId: string;
  state: VariableType;
  type: VariableValueType;
}

export interface ITrainingTableData {
  headers: ITrainingVariable[];
  rows: ITrainingVariableValue[][];
}

export enum TrainingStep {
  TrainingFile = 0,
  DependentVariable = 1,
  ExcludedVariables = 2,
  Training = 3,
  None = -1,
}

export const shouldCollapseFileStepState = atom({
  key: 'TrainingFile/shouldCollapseFileStepState',
  default: false,
});

export const variablesState = atom<ITrainingTableData>({
  key: 'TrainingFile/variablesState',
  default: null,
});

export const configuredVariablesState = atom<ITrainingConfigVariable[]>({
  key: 'TrainingFile/configuredVariablesState',
  default: [],
});

export const trainingFilePending = atom({
  key: 'TrainingFile/trainingFilePending',
  default: false,
});

export const variablesPendingState = atom({
  key: 'TrainingFile/variablesPendingState',
  default: false,
});

export const dependentVariableSelector = selector({
  key: 'TrainingFile/dependentVariableSelector',
  get: ({ get }) => {
    const variables = get(variablesState);
    const config = get(configuredVariablesState);
    const dependentVariable = config?.find((item) => item.state === VariableType.Dependent) || ({} as ITrainingConfigVariable);
    const index = config?.findIndex((item) => item.state === VariableType.Dependent);
    const { displayName, missingValues } = variables?.headers.find((item) => item.variableId === dependentVariable.variableId) || {};
    return { ...dependentVariable, index, displayName, missingValues };
  },
});

export const excludedVariablesNamesSelector = selector({
  key: 'TrainingFile/excludedVariablesNamesSelector',
  get: ({ get }) => {
    const config = get(configuredVariablesState);
    const vars = get(variablesState);
    const excluded = config?.filter((item) => item.state === VariableType.Excluded);
    return excluded.map((item) => vars?.headers.find((variable) => variable.variableId === item.variableId)?.displayName);
  },
});

export const wasDependentChangedSelector = selector({
  key: 'TrainingFile/wasDependentChangedSelector',
  get: ({ get }) => {
    const config = get(configuredVariablesState);
    const vars = get(variablesState);
    const dependentVariableConfig = config?.find((item) => item.state === VariableType.Dependent);
    const dependentVariable = vars?.headers?.find((item) => item.state === VariableType.Dependent);
    return dependentVariableConfig?.variableId && dependentVariableConfig.variableId !== dependentVariable?.variableId;
  },
});

export const wasTypeChangedSelector = selector({
  key: 'TrainingFile/wasTypeChangedSelector',
  get: ({ get }) => {
    const config = get(configuredVariablesState);
    const vars = get(variablesState);
    return config?.some((item, i) => item.type !== vars.headers[i].type);
  },
});

export const wasExcludedChangedSelector = selector({
  key: 'TrainingFile/wasExcludedChangedSelector',
  get: ({ get }) => {
    const config = get(configuredVariablesState);
    const vars = get(variablesState);
    const excludedConfig = config?.filter((item) => item.state === VariableType.Excluded);
    const excluded = vars?.headers?.filter((item) => item.state === VariableType.Excluded);
    return (
      excludedConfig?.length !== excluded?.length
      || !excludedConfig.every((item) => !!excluded.find((variable) => variable.variableId === item.variableId))
    );
  },
});

export const showVariableStatsState = atom({
  key: 'TrainingFile/showVariableStatsState',
  default: false,
});

export const showMissingValuesWarningState = atom({
  key: 'TrainingFile/areMissingValuesInTargetState',
  default: false,
});
