import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import ConnectorsEnum from '~/src/Connectors/enums/ConnectorsEnum';
import useSaveExportConfig from '~/src/ExportConnectors/hooks/useSaveExportConfig';
import { ExportOptions } from '~/src/Project/store';
import ExportType from '~/src/ProjectResults/components/ExportConnectors/Enum/ExportType.enum';
import { exportConfigSelector } from '~/src/Projects/store';
import { snackbarMessageSelector } from '~/src/Snackbar/store';

const supportedExportConnectors = [
  ConnectorsEnum.GoogleSheets,
  ConnectorsEnum.Domo,
  ConnectorsEnum.DomoLarge,
  ConnectorsEnum.Snowflake,
  ConnectorsEnum.BigQuery,
  ConnectorsEnum.SQLServer,
  ConnectorsEnum.S3AssumeRole,
];
const forbiddenAutoExportTypes = [ExportType.forcePlot];

const useAutoExport = (projectId) => {
  const { t } = useTranslation('projects');
  const entryExportConfig = useRecoilValue(exportConfigSelector(projectId));
  const [exportConfig, setExportConfig] = useState<ExportOptions>({});
  const saveConfig = useSaveExportConfig(projectId);
  const setMessage = useSetRecoilState(snackbarMessageSelector);

  const changeExportConfig = (exportType: ExportType, isEnabled: boolean) => {
    setExportConfig((state) => (state
      ? { ...state, [exportType]: { ...state[exportType], isAutoExportEnabled: isEnabled } }
      : { [exportType]: { ...state[exportType], isAutoExportEnabled: isEnabled } }));
  };

  const isExportConfigChanged = useMemo(() => exportConfig
  && Object.keys(exportConfig).some((key) => !!exportConfig[key].isAutoExportEnabled !== !!entryExportConfig?.[key].isAutoExportEnabled),
  [entryExportConfig, exportConfig]);

  const saveExportConfig = async () => {
    try {
      const body = Object.entries(exportConfig).map(([exportType, value]) => ({ type: exportType as ExportType, exportConfig: value }));
      await saveConfig(body);
      setMessage({ message: t('scheduler.exportOptions.saveAutoExportSuccess'), severity: 'success' });
    // eslint-disable-next-line no-empty
    } catch (error) {
      setMessage({ message: t('scheduler.exportOptions.saveAutoExportError'), severity: 'error' });
    }
  };

  useEffect(() => {
    if (entryExportConfig) {
      const supportedExportConfig = Object.keys(entryExportConfig)
        .filter((exportType: ExportType) => (
          !forbiddenAutoExportTypes.includes(exportType) && supportedExportConnectors.includes(entryExportConfig[exportType].connectorType)
        ))
        .reduce((acc, exportType: ExportType) => {
          acc[exportType] = entryExportConfig[exportType];
          return acc;
        }, {} as ExportOptions);
      setExportConfig(supportedExportConfig);
    }
  }, [entryExportConfig]);

  return { exportConfig, changeExportConfig, saveExportConfig, isExportConfigChanged };
};

export default useAutoExport;
