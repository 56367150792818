import { atom, selectorFamily } from 'recoil';

export enum ReportType {
  ShaplyChart = 'shaplyСhart',
  VariableImportance = 'variablesImportance',
  Coefficients = 'coefficients',
  ExcludedColumns = 'excludedColumns',
  ModelMetrics = 'modelMetrics',
}

export enum TimeSeriesReportType {
  ModelMetrics = 'modelMetrics',
}

export enum ProbabilityReportType {
  ForcePlotChart = 'forcePlotChart',
  Probabilities = 'probabilities',
  F1 = 'maxF1',
}

export interface Report {
  reportId: number;
  type: ReportType;
  isSelected: boolean;
  isReady: boolean;
}

export const reportsState = atom<any[]>({
  key: 'Results/reportsState',
  default: null,
});

export const pendingReportsState = atom({
  key: 'Results/pendingReportsState',
  default: false,
});

export const isReportSelectedSelector = selectorFamily({
  key: 'Results/isReportSelectedSelector',
  get:
    (type: ReportType) => ({ get }) => {
      const reports = get(reportsState);
      return reports?.find((item) => item.type === type)?.isSelected;
    },
});
