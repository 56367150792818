import React, { Suspense } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { Box } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { useRecoilValue } from 'recoil';
import Login from './Auth/components/Login';
import Signup from './Auth/components/Signup';
import ConfirmAccount from './Auth/components/ConfirmAccount';
import ForgotPassword from './Auth/components/ForgotPassword';
import NewProject from './NewProject/components/NewProject';
import Projects from './Projects/components/Projects';
import Snackbar from './Snackbar/Snackbar';
import useConfigure from './Auth/hooks/useConfigure';
import Authenticated from './Auth/components/Authenticated';
import LoaderOverlay from './components/LoaderOverlay';
import Project from './Project/components/Project';
import { isTouchDeviceSelector } from './Projects/store';
import ShowCode from './Connectors/GoogleSheet/components/ShowCode';
import SalesforceCallback from './Connectors/Salesforce/components/SalesforceCallback';
import DomoRedirect from './Connectors/Domo/components/DomoRedirect';
import Develop from './Develop/components/Develop';

const App = () => {
  const configured = useConfigure();
  const isTouchDevice = useRecoilValue(isTouchDeviceSelector);

  return (
    <Box minHeight="100vh" width="100%" top={0} left={0} display="flex" position="absolute">
      <CssBaseline />
      {configured ? (
        <DndProvider backend={isTouchDevice ? TouchBackend : HTML5Backend} options={{ delayTouchStart: 100 }}>
          <Router basename="/">
            <Suspense fallback="Loading...">
              <Switch>
                <Route path="/Login">
                  <Login />
                </Route>
                <Route path="/signup">
                  <Signup />
                </Route>
                <Route path="/confirm-account">
                  <ConfirmAccount />
                </Route>
                <Route path="/forgot-password">
                  <ForgotPassword />
                </Route>
                <Route path="/new">
                  <Authenticated>
                    <NewProject />
                  </Authenticated>
                </Route>
                <Route path="/projects" exact>
                  <Authenticated>
                    <Projects />
                  </Authenticated>
                </Route>
                <Route path="/salesforce/oauthcallback">
                  <Authenticated>
                    <SalesforceCallback />
                  </Authenticated>
                </Route>
                <Route path="/domo/redirect">
                  <DomoRedirect />
                </Route>
                <Route path="/projects/:projectId">
                  <Authenticated>
                    <Project />
                  </Authenticated>
                </Route>
                <Route path="/google-sheet/redirect">
                  <ShowCode />
                </Route>
                <Route path="/hubspot/redirect">
                  <ShowCode />
                </Route>
                <Route path="/develop">
                  <Authenticated>
                    <Develop />
                  </Authenticated>
                </Route>
                <Redirect from="*" to="/projects" />
              </Switch>
            </Suspense>
          </Router>
        </DndProvider>
      ) : (
        <LoaderOverlay />
      )}
      <Snackbar />
    </Box>
  );
};

export default App;
