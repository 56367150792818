import { useEffect, useState } from 'react';
import useApiClient from '~/src/hooks/useApiClient';

const useUserRegistration = () => {
  const apiClient = useApiClient();
  const [pending, setPending] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        await apiClient.post('/projects/users');
      } catch (err) {
        console.error('User registration check failed:');
        console.error(err);
      } finally {
        setPending(false);
      }
    })();
  }, []);

  return pending;
};

export default useUserRegistration;
