import {
  Box,
  Button,
  FormControl,
  FormLabel,
  makeStyles,
  TextField,
  Typography,
  useTheme,
  Link,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  FormHelperText,
  Grid,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useRecoilState, useRecoilValue } from 'recoil';
import Delayed from '~/src/components/Delayed';
import LoaderOverlay from '~/src/components/LoaderOverlay';
import useSignup from '../hooks/useSignup';
import useClearEffect from '../hooks/useClearEffect';
import {
  emailState,
  emailValidSelector,
  passwordState,
  confirmPasswordState,
  passwordValidSelector,
  passwordsMatchSelector,
  isLoadingState,
  errorKeySelector,
  canSignUpSelector,
  firstNameState,
  lastNameState,
  companyNameState,
  industryState,
  positionState,
  loginConfigState,
} from '../store';
import { positionKeys, industriesKeys } from '../constants';
import Layout from './Layout';

const useStyles = makeStyles(({ spacing, palette }) => ({
  container: {
    width: 320,
    maxWidth: `calc(100% - ${spacing(8)}px)`,
    margin: '0 auto',
    '@media (min-width: 960px)': {
      width: 680,
      display: 'flex',
      alignItems: 'center',
      '& .MuiGrid-item': {
        paddingRight: '1.25%',
        '& +.MuiGrid-item': {
          paddingRight: 0,
          paddingLeft: '1.25%',
        },
      },
    },
  },
  signInLink: {
    color: palette.secondary.main,
    fontWeight: 600,
    lineHeight: 1.5,
    marginLeft: '5px',
  },
  industryError: {
    color: palette.secondary.main,
  },
  selectField: {
    '& .MuiSelect-root': {
      padding: `${spacing(2.5)}px ${spacing(3)}px`,
      lineHeight: `${spacing(5)}px`,
    },
  },
}));

const SignUp: React.FC = () => {
  const { container, signInLink, industryError, selectField } = useStyles();
  const { spacing } = useTheme();
  const { t } = useTranslation('auth');
  const { push } = useHistory();
  const [signup] = useSignup();
  const [user, setUser] = useRecoilState(emailState);
  const [pass, setPass] = useRecoilState(passwordState);
  const [firstName, setFirstName] = useRecoilState(firstNameState);
  const [lastName, setLastName] = useRecoilState(lastNameState);
  const [companyName, setCompanyName] = useRecoilState(companyNameState);
  const [industry, setIndustry] = useRecoilState(industryState);
  const [position, setPosition] = useRecoilState(positionState);
  const [confirmPass, setConfirmPass] = useRecoilState(confirmPasswordState);
  const { allowSignUp } = useRecoilValue(loginConfigState);
  const userValid = useRecoilValue(emailValidSelector);
  const isPasswordValid = useRecoilValue(passwordValidSelector);
  const doPasswordsMatch = useRecoilValue(passwordsMatchSelector);
  const signUpPending = useRecoilValue(isLoadingState);
  const canSignUp = useRecoilValue(canSignUpSelector);
  const errorKey = useRecoilValue(errorKeySelector);

  const [userTouched, setUserTouched] = useState(false);
  const [passTouched, setPassTouched] = useState(false);
  const [firstNameTouched, setFirstNameTouched] = useState(false);
  const [lastNameTouched, setLastNameTouched] = useState(false);
  const [companyNameTouched, setCompanyNameTouched] = useState(false);
  const [industryTouched, setIndustryTouched] = useState(false);
  const [confirmPassTouched, setConfirmPassTouched] = useState(false);
  const [agreement, setAgreement] = useState(false);

  useClearEffect();

  useEffect(() => {
    if (!allowSignUp) {
      push('/login');
    }
  }, [allowSignUp]);

  const handleUserChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserTouched(true);
    setUser(e.target.value);
  };

  const handlePassChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassTouched(true);
    setPass(e.target.value);
  };

  const handleConfirmPassChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassTouched(true);
    setConfirmPass(e.target.value);
  };

  const handleFirstNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFirstNameTouched(true);
    setFirstName(e.target.value);
  };

  const handleLastNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLastNameTouched(true);
    setLastName(e.target.value);
  };

  const handleCompanyNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyNameTouched(true);
    setCompanyName(e.target.value);
  };

  const handleIndustryChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIndustry(e.target.value);
  };

  const handleIndustryTouched = () => {
    setIndustryTouched(true);
  };

  const handlePositionChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPosition(e.target.value);
  };

  return (
    <Layout>
      <Box width={spacing(80)} className={container} position="relative">
        {signUpPending && (
          <Delayed>
            <LoaderOverlay />
          </Delayed>
        )}
        <Box flex="auto" py={10} display="flex" flexDirection="column">
          <Box pb={6}>
            <Box pb={3}>
              <Typography variant="h4">{t('signUp.title')}</Typography>
            </Box>
            <Typography variant="caption" color="textSecondary">
              {t('signUp.subtitle')}
            </Typography>
          </Box>
          {!!errorKey && (
            <Box pb={6}>
              <Alert severity="error">{t(errorKey)}</Alert>
            </Box>
          )}
          <Grid container>
            <Grid item xs={12} md={6}>
              <Box mb={6}>
                <FormControl fullWidth required>
                  <FormLabel>{t('signUp.firstName.label')}</FormLabel>
                  <TextField
                    autoComplete="off"
                    type="text"
                    variant="filled"
                    hiddenLabel
                    placeholder={t('signUp.firstName.placeholder')}
                    InputProps={{ disableUnderline: true }}
                    FormHelperTextProps={{ variant: 'standard' }}
                    value={firstName}
                    onChange={handleFirstNameChanged}
                    error={firstNameTouched && !firstName}
                    helperText={firstNameTouched && !firstName && t('signUp.firstName.required')}
                  />
                </FormControl>
              </Box>
              <Box mb={6}>
                <FormControl fullWidth required>
                  <FormLabel>{t('signUp.lastName.label')}</FormLabel>
                  <TextField
                    autoComplete="off"
                    type="text"
                    variant="filled"
                    hiddenLabel
                    placeholder={t('signUp.lastName.placeholder')}
                    InputProps={{ disableUnderline: true }}
                    FormHelperTextProps={{ variant: 'standard' }}
                    value={lastName}
                    onChange={handleLastNameChanged}
                    error={lastNameTouched && !lastName}
                    helperText={lastNameTouched && !lastName && t('signUp.lastName.required')}
                  />
                </FormControl>
              </Box>
              <Box mb={6}>
                <FormControl fullWidth required>
                  <FormLabel>{t('signUp.email.label')}</FormLabel>
                  <TextField
                    autoComplete="off"
                    type="email"
                    variant="filled"
                    hiddenLabel
                    placeholder={t('signUp.email.placeholder')}
                    InputProps={{ disableUnderline: true }}
                    FormHelperTextProps={{ variant: 'standard' }}
                    value={user}
                    onChange={handleUserChanged}
                    error={(userTouched && !user) || (userTouched && !!user && !userValid)}
                    helperText={
                      (userTouched && !user && t('signUp.email.required'))
                      || (userTouched && !!user && !userValid && t('signUp.email.validation'))
                    }
                  />
                </FormControl>
              </Box>
              <Box mb={6}>
                <FormControl fullWidth required>
                  <FormLabel>{t('signUp.companyName.label')}</FormLabel>
                  <TextField
                    autoComplete="off"
                    type="text"
                    variant="filled"
                    hiddenLabel
                    placeholder={t('signUp.companyName.placeholder')}
                    InputProps={{ disableUnderline: true }}
                    FormHelperTextProps={{ variant: 'standard' }}
                    value={companyName}
                    onChange={handleCompanyNameChanged}
                    error={companyNameTouched && !companyName}
                    helperText={companyNameTouched && !companyName && t('signUp.companyName.required')}
                  />
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box mb={6}>
                <FormControl fullWidth required>
                  <FormLabel>{t('signUp.industry.label')}</FormLabel>
                  <Select
                    className={selectField}
                    variant="outlined"
                    value={industry}
                    onChange={handleIndustryChanged}
                    onClick={handleIndustryTouched}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      {t('signUp.industry.select')}
                    </MenuItem>
                    {industriesKeys.map((item) => (
                      <MenuItem key={item} value={item}>
                        {t(`signUp.industry.${item}`)}
                      </MenuItem>
                    ))}
                  </Select>
                  {industryTouched && !industry && (
                    <FormHelperText className={industryError}>{t('signUp.industry.required')}</FormHelperText>
                  )}
                </FormControl>
              </Box>
              <Box mb={6}>
                <FormControl fullWidth>
                  <FormLabel>{t('signUp.position.label')}</FormLabel>
                  <Select className={selectField} variant="outlined" value={position} onChange={handlePositionChanged} displayEmpty>
                    <MenuItem value="" disabled>
                      {t('signUp.position.select')}
                    </MenuItem>
                    {positionKeys.map((item) => (
                      <MenuItem key={item} value={item}>
                        {t(`signUp.position.${item}`)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box mb={6}>
                <FormControl fullWidth required>
                  <FormLabel>{t('signUp.password.label')}</FormLabel>
                  <TextField
                    autoComplete="off"
                    type="password"
                    variant="filled"
                    placeholder={t('signUp.password.placeholder')}
                    hiddenLabel
                    InputProps={{ disableUnderline: true }}
                    FormHelperTextProps={{ variant: 'standard' }}
                    value={pass}
                    onChange={handlePassChanged}
                    error={(passTouched && !pass) || (!!pass && !isPasswordValid)}
                    helperText={
                      (passTouched && !pass && t('signUp.password.required'))
                      || (passTouched && pass && !isPasswordValid && t('signUp.password.validation'))
                    }
                  />
                </FormControl>
              </Box>
              <Box mb={6}>
                <FormControl fullWidth required>
                  <FormLabel>{t('signUp.confirmPassword.label')}</FormLabel>
                  <TextField
                    autoComplete="off"
                    type="password"
                    variant="filled"
                    placeholder={t('signUp.confirmPassword.placeholder')}
                    hiddenLabel
                    InputProps={{ disableUnderline: true }}
                    FormHelperTextProps={{ variant: 'standard' }}
                    value={confirmPass}
                    onChange={handleConfirmPassChanged}
                    error={(confirmPassTouched && !pass) || (confirmPassTouched && !!pass && !doPasswordsMatch)}
                    helperText={
                      (confirmPassTouched && !confirmPass && t('signUp.confirmPassword.required'))
                      || (confirmPassTouched && confirmPass && !doPasswordsMatch && t('signUp.confirmPassword.validation'))
                    }
                  />
                </FormControl>
              </Box>
            </Grid>
          </Grid>
          <Box mb={6}>
            <FormControlLabel
              control={
                <Checkbox checked={agreement} onChange={(event) => setAgreement(event.target.checked)} name="checkedB" color="primary" />
              }
              label={
                <>
                  <Typography component="span">{t('signUp.licenseAgreement.label')}</Typography>
                  <Typography>
                    <Link href="https://squarkai.com/EULA/" target="_blanc">
                      {t('signUp.licenseAgreement.link')}
                    </Link>
                  </Typography>
                </>
              }
            />
          </Box>
          <Box mb={10} display="flex">
            <Box flex="auto">
              <Button fullWidth variant="contained" color="primary" size="large" disabled={!canSignUp || !agreement} onClick={signup}>
                {t('signUp.buttons.signUp')}
              </Button>
            </Box>
          </Box>
          <Box mb={6} display="flex">
            <Typography component="span">{t('signUp.signIn.message')}</Typography>
            <Typography>
              <Link to="/login" component={RouterLink} className={signInLink}>
                {t('signUp.signIn.link')}
              </Link>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default SignUp;
