import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import useApiClient from '~/src/hooks/useApiClient';
import useProjectId from '~/src/hooks/useProjectId';
import { ProjectType, projectType } from '~/src/Project/store';
import { entryProjectSelector } from '~/src/Projects/store';
import { snackbarMessageSelector } from '~/src/Snackbar/store';
import { pendingFilenamesState, selectedFileNameState, selectedProductionFileNameState } from '../store';

const useGetProjectFileNames = () => {
  const { t } = useTranslation('files');
  const setTrainingFileName = useSetRecoilState(selectedFileNameState);
  const setProductionFileName = useSetRecoilState(selectedProductionFileNameState);
  const setPending = useSetRecoilState(pendingFilenamesState);
  const apiClient = useApiClient();
  const projectId = useProjectId();
  const typeOfProject = useRecoilValue(projectType);
  const project = useRecoilValue(entryProjectSelector(projectId));

  const setSnackbarMessage = useSetRecoilState(snackbarMessageSelector);

  const getFileNames = async () => {
    if (project?.files?.training?.name) {
      setTrainingFileName(project?.files.training.name || '');
      setProductionFileName(project?.files.production.name || '');
      return;
    }

    setPending(true);
    const filesPromises = [apiClient.get(`/projects/${projectId}/files/training`)];

    if (typeOfProject !== ProjectType.TimeSeriesForecast) {
      filesPromises.push(apiClient.get(`/projects/${projectId}/files/production`));
    }

    try {
      const [trainingRes, productionRes] = await Promise.all(filesPromises);
      setTrainingFileName(trainingRes?.data.name || '');
      setProductionFileName(productionRes?.data.name || '');
    } catch (err) {
      setSnackbarMessage({
        message: t('getFilesNames.error'),
        severity: 'error',
      });
    } finally {
      setPending(false);
    }
  };

  return [getFileNames];
};

export default useGetProjectFileNames;
