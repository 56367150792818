import React, { useRef } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import GetStarted from '~/src/assets/get-started.svg';

const useStyles = makeStyles(({ palette }) => ({
  noSearchResultsWrapper: {
    position: 'absolute',
    top: 0,
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  emptyIllustrationWrapper: {
    position: 'relative',
    '&:hover': {
      '& $highlight': {
        opacity: 0.6,
      },
    },
  },
  highlight: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    opacity: 0,
    filter: 'blur(160px)',
  },
  redHighlight: {
    right: '50%',
    width: 240,
    height: 150,
    backgroundColor: palette.secondary.main,
  },
  blueHighlight: {
    left: '50%',
    width: 320,
    height: 200,
    backgroundColor: palette.info.main,
  },
  illustration: {
    position: 'relative',
    zIndex: 10,
  },
  emptyTitle: {
    fontWeight: 700,
    fontSize: '1.75rem',
  },
}));

const playObjectElementAnimation: (arg0: HTMLObjectElement | any) => void = (obj) => {
  if (obj.contentDocument && obj.contentDocument.ks) {
    const { ks } = obj.contentDocument;
    if (ks.timelines()[0].state() !== 'running') {
      ks.timelines()[0].time(0);
    }
    ks.globalPlay();
  }
};

const NoResults = () => {
  const { t } = useTranslation('projects');
  const classes = useStyles();
  const getStartedImgRef = useRef();

  return (
    <Box className={classes.noSearchResultsWrapper}>
      <Box className={classes.emptyIllustrationWrapper}>
        <Box className={cx(classes.highlight, classes.redHighlight)} />
        <Box className={cx(classes.highlight, classes.blueHighlight)} />
        <object
          className={classes.illustration}
          ref={getStartedImgRef}
          data={`${GetStarted}?global=paused`}
          type="image/svg+xml"
          onMouseEnter={() => playObjectElementAnimation(getStartedImgRef?.current)}
          aria-label="get started illustration"
        />
      </Box>
      <Box pt={3} zIndex="100">
        <Typography variant="h5" className={classes.emptyTitle}>
          {t('filterProjects.emptySearchResults')}
        </Typography>
      </Box>
    </Box>
  );
};

export default React.memo(NoResults);
