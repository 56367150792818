// https://gist.github.com/orlandovallejos/ed9c79dd67e96c25cfcc8f1008df9489
const OFFSET = 200;
const PX_DIFF = 4;
let scrollIncrement = 0;
let isScrolling = false;
let sidebarElement = null;

const goUp = () => {
  scrollIncrement -= PX_DIFF;
  sidebarElement.scrollTop = scrollIncrement;

  if (isScrolling && scrollIncrement >= 0) {
    window.requestAnimationFrame(goUp);
  }
};

const goDown = () => {
  scrollIncrement += PX_DIFF;
  sidebarElement.scrollTop = scrollIncrement;

  if (isScrolling && scrollIncrement <= sidebarElement.scrollHeight) {
    window.requestAnimationFrame(goDown);
  }
};

const onDragOver = (event) => {
  const clientRect = sidebarElement.getBoundingClientRect();
  const isMouseOnTop = scrollIncrement >= 0 && event.clientY > clientRect.top && event.clientY < clientRect.top + OFFSET;
  const isMouseOnBottom =
    scrollIncrement <= sidebarElement.scrollHeight && event.clientY > window.innerHeight - OFFSET && event.clientY <= window.innerHeight;

  if (!isScrolling && (isMouseOnTop || isMouseOnBottom)) {
    isScrolling = true;
    scrollIncrement = sidebarElement.scrollTop;

    if (isMouseOnTop) {
      window.requestAnimationFrame(goUp);
    } else {
      window.requestAnimationFrame(goDown);
    }
  } else if (!isMouseOnTop && !isMouseOnBottom) {
    isScrolling = false;
  }
};

/**
 * The "throttle" method prevents executing the same function SO MANY times.
 * This needs lodash throttle
 */
// TODO const throttleOnDragOver = throttle(onDragOver, 300);

const addEventListenerForSidebar = (id) => {
  sidebarElement = document.getElementById(id);
  sidebarElement.addEventListener('dragover', onDragOver);
};

const removeEventListenerForSidebar = () => {
  sidebarElement.removeEventListener('dragover', onDragOver);
  isScrolling = false;
};

export default {
  addEventListenerForSidebar,
  removeEventListenerForSidebar,
};
