import React, { useMemo } from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { useRecoilState, useRecoilValue } from 'recoil';
import { navigationDrawerOpen } from '../store';
import { logo } from '~/src/store';
import { isPaidUserSelector } from '~/src/Auth/store';

const useStyles = makeStyles(({ spacing }) => ({
  largeLogo: {
    height: spacing(8),
  },
  smallLogo: {
    height: spacing(10),
  },
  communityLogo: {
    height: spacing(9),
  },
}));

const Logo = () => {
  const { largeLogo, smallLogo, communityLogo } = useStyles();
  const [open] = useRecoilState(navigationDrawerOpen);
  const isPaidUser = useRecoilValue(isPaidUserSelector);
  const { large, small, community } = useRecoilValue(logo);

  const src = useMemo(() => {
    switch (true) {
      case !open: return small;
      case open && isPaidUser: return large;
      default: return community;
    }
  }, [open, isPaidUser]);

  return (
    <img
      src={src}
      alt="logo"
      className={cx({ [largeLogo]: open, [smallLogo]: !open, [communityLogo]: open && !isPaidUser })}
    />
  );
};

export default React.memo(Logo);
