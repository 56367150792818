import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { entryProjectSelector } from '~/src/Projects/store';
import { getStepInfo, projectState, ProjectState, ScriptNames } from '~/src/Project/store';

const preprocessorsStates = [ProjectState.ProcessingTrainingFile, ProjectState.UploadingTrainingFile, ProjectState.PendingTrainingFile];

const useScriptSteps = (projectId, sriptType?: ScriptNames | string) => {
  const { t } = useTranslation('projects');
  const project = useRecoilValue(entryProjectSelector(projectId));
  const currentState = useRecoilValue(projectState);
  const script = useMemo(() => {
    switch (true) {
      case !!sriptType:
        return sriptType;
      case !!preprocessorsStates.includes(currentState !== ProjectState.PendingProjectName ? currentState : project?.state):
        return ScriptNames.Preprocessor;
      case (currentState !== ProjectState.PendingProjectName ? currentState : project?.state) === ProjectState.TimeSeriesPrediction:
        return ScriptNames.TimeSeriesAutomodeler;
      default:
        return ScriptNames.Automodeler;
    }
  }, [sriptType, project, currentState]);

  const stepInfo = useMemo(() => getStepInfo(project?.[script]?.step), [project]);

  return {
    step: project?.[script]?.step,
    name: t(`stepName.${project?.[script]?.step}`),
    number: stepInfo && t('stepNumber', stepInfo),
    description: t(`stepDescription.${project?.[script]?.step}`),
    placeholder: t(`stepDescription.${script}Placeholder`),
  };
};

export default useScriptSteps;
