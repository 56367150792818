enum ExportType {
    prediction = 'predictions',
    tsPrediction = 'ts-predictions',
    variableOfImportance = 'variable-of-importance',
    coefficients = 'coefficients',
    modelMetrics = 'model-metrics',
    outliers = 'outliers',
    confusionMatrix = 'confusion-matrix',
    shaply = 'shaply',
    forcePlot = 'forcePlot',
    leaderboard = 'leaderboard',
    confidenceInterval = 'confidence-interval',
    confidenceIntervalBest = 'confidence-interval-best',
    histogram = 'histogram',
    movingAverage = 'moving-average',
    autocorrelation = 'autocorrelation',
}
export default ExportType;
