import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import useApiClient from '~/src/hooks/useApiClient';
import { projectListState } from '~/src/Projects/store';
import { snackbarMessageSelector } from '~/src/Snackbar/store';
import { pendingCreateFolderState, Folder, folderNameState } from '../store';

const useCreateFolder = () => {
  const { t } = useTranslation('folders');
  const apiClient = useApiClient();
  const setPending = useSetRecoilState(pendingCreateFolderState);
  const setSnackbarMessage = useSetRecoilState(snackbarMessageSelector);
  const displayName = useRecoilValue(folderNameState);
  const setEntries = useSetRecoilState(projectListState);

  const createFolder = async () => {
    try {
      setPending(true);
      const { data: newFolder } = await apiClient.post<Folder>('/folders', { displayName: displayName.trim() });
      const { data } = await apiClient.get('/search', { params: { filter: btoa(JSON.stringify({ folder: newFolder._id })) } });
      setEntries((resultsState) => {
        return {
          ...resultsState,
          entries: [data[0], ...resultsState.entries],
        };
      });
    } catch (err) {
      setSnackbarMessage({
        message: t('errors.createFolder'),
        severity: 'error',
      });
    } finally {
      setPending(false);
    }
  };

  return [createFolder];
};

export default useCreateFolder;
