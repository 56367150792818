/* eslint-disable no-nested-ternary */
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useTranslation } from 'react-i18next';
import SelectHour from './SelectHour';
import { getTimeOptions } from '../store';

interface ActiveHoursProps {
  [key: string]: any;
}

enum ActiveHoursTypes {
  From = 'from',
  To = 'to',
}

const useStyles = makeStyles(({ spacing }) => ({
  activeHoursContainer: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  item: {
    marginLeft: spacing(3),
  },
  activeHoursLabel: {
    margin: 0,
  },
}));

const ActiveHours: React.FC<ActiveHoursProps> = ({ hour, onChange, className, disabled }) => {
  const classes = useStyles();
  const { t } = useTranslation('translation');
  const [isChecked, setIsChecked] = useState(hour !== '*');

  const { from, to } = useMemo(() => {
    const [start, end] = hour.split('-');
    if (start === '*') {
      return { from: '0', to: '23' };
    }
    return { from: start, to: !end || +end < +start ? start : end };
  }, [hour]);

  const toHoursOptions = useMemo(() => getTimeOptions(from), [from]);
  const handleCheckboxChangeActiveHours = (e) => {
    setIsChecked(e.target.checked);
    if (!e.target.checked) {
      onChange('*');
    }
  };

  const handleChangeActiveHours = (value, type: ActiveHoursTypes) => {
    const start = type === ActiveHoursTypes.From ? value : from;
    const end = type === ActiveHoursTypes.To ? value : to;
    const hourPattern = start === '0' && end === '23' ? '*' : +start >= +end ? `${start}` : `${start}-${end}`;
    onChange(hourPattern);
  };

  return (
    <Box className={cx(classes.activeHoursContainer, className)}>
      <FormControlLabel
        className={classes.activeHoursLabel}
        control={
          <Checkbox
            disabled={disabled}
            checked={isChecked}
            onChange={handleCheckboxChangeActiveHours}
            color="primary"
            name={t('scheduling.checkboxes.name')}
          />
        }
        label={t('scheduling.activeHours.label')}
      />
      <SelectHour
        disabled={!isChecked || disabled}
        value={from}
        className={classes.item}
        label={t('scheduling.activeHours.startHourLabel')}
        onChange={(data) => {
          handleChangeActiveHours(data, ActiveHoursTypes.From);
        }}
      />
      <SelectHour
        disabled={!isChecked || disabled}
        value={to}
        options={toHoursOptions}
        className={classes.item}
        label={t('scheduling.activeHours.endHourLabel')}
        onChange={(data) => {
          handleChangeActiveHours(data, ActiveHoursTypes.To);
        }}
      />
    </Box>
  );
};

ActiveHours.propTypes = {
  hour: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

ActiveHours.defaultProps = {
  className: '',
  hour: '0',
  disabled: false,
};

export default ActiveHours;
