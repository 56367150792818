import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';
import useApiClient from '~/src/hooks/useApiClient';
import useProjectId from '~/src/hooks/useProjectId';
import { entryProjectSelector } from '~/src/Projects/store';
import { snackbarMessageSelector } from '~/src/Snackbar/store';
import { pendingUpdateReportState, ProjectReport, ScriptNames } from '../store';

const useUpdateReport = (id?: number) => {
  const { t } = useTranslation('projects');
  const apiClient = useApiClient();
  const projectId = useProjectId();
  const [pending, setPending] = useRecoilState(pendingUpdateReportState);
  const setMessage = useSetRecoilState(snackbarMessageSelector);
  const setProject = useSetRecoilState(entryProjectSelector(id || projectId));

  const updateReport = async (
    type: ScriptNames | string,
    updates: Partial<ProjectReport>,
    messages?: { success?: string, error?: string },
  ) => {
    const { success, error } = messages || {};
    try {
      setPending(true);
      const { data } = await apiClient.patch<ProjectReport>(`/projects/${id || projectId}/reports/${type}`, updates);
      // updating project leads to triggering of redirect according to project state (e.g. to result page if project state===ready)
      setProject((state) => {
        state.reports = { ...state.reports, [type]: data };
        return state;
      });
      setMessage({ message: success || t('project.updateReport.success'), severity: 'success' });
    } catch (err) {
      setMessage({ message: error || t('project.updateReport.error'), severity: 'error' });
      throw err;
    } finally {
      setPending(false);
    }
  };

  return { updateReport, isLoading: pending };
};

export default useUpdateReport;
