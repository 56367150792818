import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TabList from '@material-ui/lab/TabList';
import Tab from '@material-ui/core/Tab';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import { useTranslation } from 'react-i18next';
import { SchedulerSources } from '../../store';

const useStyles = makeStyles(({ spacing, palette }) => ({
  tab: {
    minHeight: spacing(12),
    marginRight: spacing(1),
    borderRadius: `${spacing(2)}px ${spacing(2)}px 0 0`,
    '&.Mui-selected': {
      backgroundColor: palette.background.secondary,
      '& .MuiSvgIcon-root': {
        color: palette.secondary.main,
      },
    },
    '&:not(.Mui-selected)': {
      '&:hover': {
        backgroundColor: '#f8f8fb',
      },
    },
    '& .MuiTab-wrapper': {
      '& .MuiSvgIcon-root': {
        color: '#AEB0B7',
        margin: '0 5px 0 0',
      },
    },
  },
}));

const SelectSchedulerTypeTabs = ({ selectedTab, onChange, enableExportTab }) => {
  const { t } = useTranslation('projects');
  const { tab } = useStyles();

  return (
    <TabList value={selectedTab} onChange={onChange} scrollButtons="auto">
      <Tab label={t('scheduler.tabs.analyze')} icon={<ImageSearchIcon />} className={tab} value={SchedulerSources.Training} />
      <Tab label={t('scheduler.tabs.predict')} icon={<TrendingUpIcon />} className={tab} value={SchedulerSources.Production} />
      <Tab
        disabled={!enableExportTab}
        label={t('scheduler.tabs.export')}
        icon={<TrendingUpIcon />}
        className={tab}
        value={SchedulerSources.Export}
      />
    </TabList>
  );
};

SelectSchedulerTypeTabs.propTypes = {
  selectedTab: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  enableExportTab: PropTypes.bool.isRequired,
};

export default SelectSchedulerTypeTabs;
