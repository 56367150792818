import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Box, Divider, IconButton, useTheme } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import Logo from './Logo';

interface HeaderProps {
  children: React.ReactNode;
  onClose: () => any;
}

const Header: React.FC<HeaderProps> = ({ children, onClose }) => {
  const { spacing } = useTheme();
  return (
    <Box display="flex" alignItems="center" py={12}>
      <Box display="flex">
        <Link to="/projects">
          <Logo />
        </Link>
      </Box>
      <Box display="flex" px={8} height={spacing(8)}>
        <Divider orientation="vertical" />
      </Box>
      {children}
      <Box flex="auto" />
      <IconButton onClick={onClose}>
        <Close />
      </IconButton>
    </Box>
  );
};

Header.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Header;
