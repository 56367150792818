import { ScriptNames } from '../Project/store';

export const SCROLL_CONTAINER_ID = 'dnd-scroll-container';
export const ENTRIES_PER_PAGE = 35;
export const ROOT_FOLDER_DEFAULT_FILTER = {
  $or: [
    { folder: { $ne: null }, project: null },
    { project: { $ne: null }, folder: null },
  ],
};
export const SCRIPTS_DATE_TO_SHOW = [
  ScriptNames.Automodeler,
  ScriptNames.Prediction,
  ScriptNames.TimeSeriesAutomodeler,
  ScriptNames.TimeSeriesPrediction,
  ScriptNames.TimeSeriesPreprocessor,
];
