import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import useApiClient from '~/src/hooks/useApiClient';
import { snackbarMessageSelector } from '~/src/Snackbar/store';
import { DOMO_VALIDATE_API } from '../constants/DomoEnums';
import { domoUserId, validDomoAccount } from '../stores/domoData';

const useValidateDomoAccount = () => {
    const domoUserInfo = useRecoilValue(domoUserId);
    const apiClient = useApiClient();
    const setMessage = useSetRecoilState(snackbarMessageSelector);
    const { t } = useTranslation('connectors');
    const setValidDomoAccount = useSetRecoilState(validDomoAccount);
    const setIsValidDomo = useSetRecoilState(validDomoAccount);

    const isValidDomoAccount = async () => {
        let isStatus;
        try {
            const url = `${DOMO_VALIDATE_API}/${domoUserInfo}`;
            const { data: isValidAccount } = await apiClient.get(url);
            setValidDomoAccount(isValidAccount);
            isStatus = isValidAccount;
            if (!isValidAccount) {
                throw new Error(t('domo.errors.invalidDomoAccount'));
            } else {
                setIsValidDomo(true);
            }
        } catch (error) {
            console.error(error);
            setMessage({
                message: t('domo.errors.invalidDomoAccount'),
                severity: 'error',
            });
        } finally {
            // eslint-disable-next-line no-unsafe-finally
            return isStatus;
        }
    };
    return [isValidDomoAccount];
};

export default useValidateDomoAccount;