import { useCallback } from 'react';
import { ProjectState, ProjectType } from '~/src/Project/store';

const trainingPath = (projectId) => `/projects/${projectId}/training-file`;
const productionPath = (projectId) => `/projects/${projectId}/production-file`;
const nameTypePath = (projectId) => `/projects/${projectId}/name-and-type`;
const resultsPath = (projectId) => `/projects/${projectId}/results`;

const useProjectRedirect = () => {
  const redirectTo = useCallback((projectId, state, type) => {
    const mapStateToPath = {
      [ProjectState.PendingProjectName]: nameTypePath(projectId),
      [ProjectState.PendingProjectType]: nameTypePath(projectId),
      [ProjectState.PendingTrainingFile]: trainingPath(projectId),
      [ProjectState.UploadingTrainingFile]: trainingPath(projectId),
      [ProjectState.ProcessingTrainingFile]: trainingPath(projectId),
      [ProjectState.PendingDependentVariable]: trainingPath(projectId),
      [ProjectState.PendingIndependentVariable]: trainingPath(projectId),
      [ProjectState.PendingProductionFile]: productionPath(projectId),
      [ProjectState.UploadingProductionFile]: productionPath(projectId),
      [ProjectState.PendingTraining]: type === ProjectType.TimeSeriesForecast ? trainingPath(projectId) : productionPath(projectId),
      [ProjectState.Training]: productionPath(projectId),
      [ProjectState.Ready]: resultsPath(projectId),
      [ProjectState.TimeSeriesPreprocessing]: trainingPath(projectId),
      [ProjectState.PendingTimeSeriesPrediction]: trainingPath(projectId),
      [ProjectState.TimeSeriesPrediction]: resultsPath(projectId),
    };
    return mapStateToPath[state];
  }, []);

  return redirectTo;
};

export default useProjectRedirect;
