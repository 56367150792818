import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { Add } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface NewProjectsButtonProps {
  large?: boolean;
}

const useStyles = makeStyles(() => ({
  navigationButton: {
    justifyContent: 'flex-start',
  },
}));

const NewProjectsButton: React.FC<NewProjectsButtonProps> = ({ large }) => {
  const { navigationButton } = useStyles();
  const { t } = useTranslation('layout');

  return (
    <Button
      className={navigationButton}
      color="primary"
      size="large"
      variant="contained"
      component={Link}
      to="/new"
      fullWidth
      startIcon={
        <Box color="common.white" display="flex" alignItems="center">
          <Add color="inherit" />
        </Box>
      }
    >
      {large && t('navigation.new')}
    </Button>
  );
};

NewProjectsButton.propTypes = {
  large: PropTypes.bool,
};

NewProjectsButton.defaultProps = {
  large: false,
};

export default NewProjectsButton;
