import { Auth } from '@aws-amplify/auth';

const useRefreshSession = () => {
  const refreshSession = async () => {
    const currentSession = await Auth.currentSession();
    const user = await Auth.currentUserPoolUser();
    const refreshToken = currentSession.getRefreshToken();
    const session: any = await new Promise(
      (resolve, reject) => user.refreshSession(refreshToken, (err, newSession) => (err ? reject(err) : resolve(newSession))),
    );

    return session;
  };

  return [refreshSession];
};

export default useRefreshSession;
