import { useCallback, useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { CopyJobState, copyProjectState, pendingClearCopyState } from '../store';
import { snackbarMessageSelector } from '~/src/Snackbar/store';
import useFindOne from '~/src/Projects/hooks/useFindOne';
import { entryProjectSelector, projectListState } from '~/src/Projects/store';
import useApiClient from '~/src/hooks/useApiClient';

const useCopyState = (projectId: number) => {
  const { t } = useTranslation('projects');
  const [copy, setCopy] = useRecoilState(copyProjectState);
  const [pending, setPending] = useRecoilState(pendingClearCopyState);
  const setMessage = useSetRecoilState(snackbarMessageSelector);
  const newProject = useRecoilValue(entryProjectSelector(copy?.projectIdNew || 0));
  const setProjects = useSetRecoilState(projectListState);
  const [findOne] = useFindOne();
  const apiClient = useApiClient();

  const clearCopy = useCallback(async () => {
    setPending(true);
    try {
      await apiClient.delete(`/notifications/${projectId}/copy`);
    } catch (err) {
      console.error(err);
    } finally {
      setPending(false);
    }
  }, []);

  useEffect(() => {
    const handleCopyResult = async () => {
      if (copy.state === CopyJobState.Ready) {
        try {
          await findOne(copy.projectIdNew);
          await clearCopy();
          setMessage({ message: t('copyProject.successDone', { name: copy.projectNameSource }), severity: 'success' });
          setCopy(null);
        } catch (err) {
          console.error(err);
        }
        // clear copy in firestore
      } else if (copy.state === CopyJobState.Failed) {
        setMessage({ message: t('copyProject.errorDone', { name: copy.projectNameSource }), severity: 'error' });
        setCopy(null);
        try {
          await clearCopy();
          await apiClient.delete(`/projects/${copy.projectIdNew}`);
          setProjects((state) => ({
            ...state,
            entries: state.entries.filter((item) => !item.project || item.project._id !== copy.projectIdNew),
          }));
        } catch (error) {
          console.error(error);
        }
      }
    };

    if (copy?.projectIdSource === projectId && copy.projectIdNew && !newProject && !pending) {
      handleCopyResult();
    }
  }, [copy, newProject, pending]);
};

export default useCopyState;
