import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { Box, Divider } from '@material-ui/core';
import { useRecoilValue } from 'recoil';
import CloseButton from '~/src/components/DialogCloseButton';
import LoaderOverlay from '~/src/components/LoaderOverlay';
import ShareNewUser from './ShareNewUser';
import { ShareParams, UserRole } from '~/src/Project/store';
import { projectSelector } from '~/src/Projects/store';
import SharedUsersList from './SharedUsersList';
import { pendingShareProjectState } from '../store';
import useShareProject from '../hooks/useShareProject';

const useStyles = makeStyles(({ spacing, palette }) => ({
  modalDialog: {
    '& .MuiContainer-root': {
      padding: spacing(8, 6, 4),
    },
  },
  folderWarning: {
    marginBottom: spacing(2),
    color: palette.warning.main,
  },
}));

const ShareDialog = ({ open, onClose, projectId, projectName }) => {
  const { t } = useTranslation('projects');
  const classes = useStyles();
  const pending = useRecoilValue(pendingShareProjectState);
  const { users, folder } = useRecoilValue(projectSelector(projectId)) || {};
  const { shareProject, unshareProject } = useShareProject();

  const handleShare = useCallback(async (params: ShareParams) => {
    await shareProject(projectId, params, !!folder);
    if (folder) onClose();
  }, [users, folder]);

  const handleUnshare = useCallback((email: string) => {
    unshareProject(projectId, email);
  }, []);

  return (
    <Dialog open={open && !!users} onClose={onClose} className={classes.modalDialog}>
      <DialogTitle disableTypography>
        <Typography variant="h5">
          {t('shareProject.title', { projectName })}
        </Typography>
        <CloseButton onClick={onClose} />
      </DialogTitle>
      <DialogContent>
        {pending && <LoaderOverlay />}
        <Box mb={4}>
          {!!folder && <Typography className={classes.folderWarning} variant="subtitle2">
            {t('shareProject.projectInFolder')}
          </Typography>}
          <ShareNewUser onShare={handleShare} owner={users?.find((user) => user.role === UserRole.Owner)?.email || ''} />
          <Divider />
          <SharedUsersList onUnshare={handleUnshare} users={users} />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

ShareDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  projectId: PropTypes.number.isRequired,
  projectName: PropTypes.string.isRequired,
};

ShareDialog.defaultProps = {
  open: false,
};

export default React.memo(ShareDialog);
