import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { snackbarMessageSelector } from '~/src/Snackbar/store';
import { devTokenState, pendingCredentialsState } from '../store';
import { appConfigState } from '~/src/Auth/store';

export const CREDENTIAL_URL = '/authentication/credentials';

const useGetToken = () => {
  const { t } = useTranslation('develop');
  const setPending = useSetRecoilState(pendingCredentialsState);
  const setSnackbarMessage = useSetRecoilState(snackbarMessageSelector);
  const setIdTokenState = useSetRecoilState(devTokenState);
  const appConfig = useRecoilValue(appConfigState);

  const getToken = async (clientId, clientSecret) => {
    setPending(true);
    try {
      const authToken = window.btoa(`${clientId}:${clientSecret}`);
      const { data } = await axios.get(`${appConfig ? appConfig.api.baseUrl : window.appConfig.api.baseUrl}/authentication/token`, {
        headers: { Authorization: `Basic ${authToken}` },
      });
      setIdTokenState(data);
    } catch (err) {
      console.error(err);
      setSnackbarMessage({
        message: t(`credentials.errors.${err.response?.status === 401 ? 'getTokenUnauthorized' : 'getToken'}`),
        severity: 'error',
      });
    } finally {
      setPending(false);
    }
  };

  return getToken;
};

export default useGetToken;
