import React from 'react';
import PropTypes from 'prop-types';
import Close from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';

const useStyles = makeStyles(({ spacing, palette }) => ({
  closeButton: {
    position: 'absolute',
    right: spacing(1),
    top: spacing(1),
    color: palette.text.secondary,
  },
}));

interface DialogCloseButtonProps {
  className?: string;
  onClick: () => void;
  [key: string]: any;
}

const DialogCloseButton: React.FC<DialogCloseButtonProps> = ({ className, onClick, ...restProps }) => {
  const { closeButton } = useStyles();

  return (
    <IconButton aria-label="close button" className={cx(closeButton, className)} onClick={onClick} {...restProps}>
      <Close />
    </IconButton>
  );
};

DialogCloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

DialogCloseButton.defaultProps = {
  className: '',
};

export default DialogCloseButton;
