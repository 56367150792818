import { useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { ProjectState, projectType } from '~/src/Project/store';
import { helperState } from '../store';
import { helpersOptions, HelpersPlacesOptions } from '../helpers-config';

const useHelper = (state: ProjectState, option: HelpersPlacesOptions = HelpersPlacesOptions.None) => {
  const [activeHelperIndex, setActiveHelperIndex] = useState(0);
  const [helperConfigs, setHelperConfigs] = useState([]);
  const helper = useRecoilValue(helperState);
  const type = useRecoilValue(projectType);

  const currentHelperData = useMemo(() => helperConfigs[activeHelperIndex], [helperConfigs, activeHelperIndex]);

  useEffect(() => {
    if (!helper) {
      setActiveHelperIndex(0);
    }
  }, [helper]);

  useEffect(() => {
    setActiveHelperIndex(0);
    setHelperConfigs(
      helpersOptions[state + (type || '') + option] ||
        helpersOptions[state + option] ||
        helpersOptions[state + type] ||
        helpersOptions[state] ||
        []
    );
  }, [state, option]);

  return { currentHelperData, helperConfigs, helper, activeHelperIndex, setActiveHelperIndex };
};

export default useHelper;
