/* eslint-disable consistent-return */
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { FileType } from '~/src/Files/store';
import useApiClient from '~/src/hooks/useApiClient';
import { entryFileSelector, entryProjectSelector, entryScheduleSelector, JobType } from '~/src/Projects/store';
import { snackbarMessageSelector } from '~/src/Snackbar/store';
import { isScheduleActionState } from '../store';

const useDeleteSchedule = (projectId) => {
  const apiClient = useApiClient();
  let connectorType = '';
  let job = '';
  const { t } = useTranslation('projects');
  const entryFile = useRecoilValue(entryFileSelector(projectId));
  const setSchedulerActionLoading = useSetRecoilState(isScheduleActionState);
  const setSnackbarMessage = useSetRecoilState(snackbarMessageSelector);
  const [entrySchedule, setEntrySchedule] = useRecoilState(entryScheduleSelector(projectId));
  const setEntry = useSetRecoilState(entryProjectSelector(projectId));

  const deleteSchedule = async (type, projectName) => {
    try {
      let trainingSchedule = entrySchedule?.trainingSchedule;
      let productionSchedule = entrySchedule?.productionSchedule;
      setSchedulerActionLoading(true);
      if (type === FileType.Training) {
        job = JobType.Analyze;
        connectorType = entryFile?.trainingFile?.connectorType;
      } else {
        job = JobType.Predict;
        connectorType = entryFile?.productionFile?.connectorType;
      }
      await apiClient.delete(`/scheduling/${projectId}/${connectorType}/${job}`);
      if (type === FileType.Training) {
        trainingSchedule = null;
        setEntrySchedule({ projectId, trainingSchedule, productionSchedule });
        setEntry((entry) => ({
          ...entry,
          files: {
            training: {
              ...(entry?.files?.training || {}),
              schedule: trainingSchedule,
            },
            production: { ...entry.files.production },
          },
        }));
        setSnackbarMessage({
          message: t('scheduler.deleteSchedule.analyze', {
            name: projectName,
          }),
          severity: 'info',
        });
      } else {
        productionSchedule = null;
        setEntrySchedule({ projectId, trainingSchedule, productionSchedule });
        setEntry((entry) => ({
          ...entry,
          files: {
            production: {
              ...(entry?.files?.production || {}),
              schedule: productionSchedule,
            },
            training: { ...entry.files.training },
          },
        }));
        setSnackbarMessage({
          message: t('scheduler.deleteSchedule.predict', {
            name: projectName,
          }),
          severity: 'info',
        });
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      throw err;
    } finally {
      setSchedulerActionLoading(false);
    }
  };

  return [deleteSchedule];
};

export default useDeleteSchedule;
