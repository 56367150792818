import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import Box from '@material-ui/core/Box';
import cx from 'classnames';
import YouTube from 'react-youtube';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DialogCloseButton from '~/src/components/DialogCloseButton';

interface HelperPopupProps {
  [name: string]: any;
  anchorEl: Element;
  onClose: () => void;
  title: string;
  content: React.ReactNode;
}

const youtubeOptions = {
  containerClassName: 'seer-video-container',
  height: '152',
  width: '216',
};

const useStyles = makeStyles(({ spacing, palette, typography, transitions, shape }) => ({
  popover: {
    backgroundColor: palette.common.white,
    marginTop: -spacing(2),
    '&$bottom': {
      marginTop: spacing(2),
    },
    zIndex: 1001,
    borderRadius: shape.borderRadius * 2,
  },
  transition: {
    transition: `transform ${transitions.duration.shortest}ms`,
  },
  container: {
    position: 'relative',
    display: 'flex',
    padding: spacing(6),
    borderRadius: shape.borderRadius * 2,
  },
  arrow: {
    position: 'absolute',
    zIndex: 1,
    top: 'calc(100% - .5rem)',
    // !important is needed to overrride inline styles that is set by lib
    left: '50% !important',
    width: '1rem',
    height: '1rem',
    backgroundColor: palette.common.white,
    boxShadow: '4px 4px 5px 0px rgb(0, 0, 0, 0.15)',
    transform: 'rotate(45deg) translateX(-50%)',
    transformOrigin: 'left',
    '&$bottom': {
      top: '-0.5rem',
      boxShadow: '-3px -3px 3px 0px rgb(0, 0, 0, 0.04)',
    },
    '&$start, &$end': {
      left: '15% !important',
      transform: 'rotate(45deg)',
      transformOrigin: 'initial',
    },
    '&$end': {
      left: '82% !important',
    },
  },
  bottom: {},
  start: {},
  end: {},
  videoContainer: {
    marginRight: spacing(6),
    width: spacing(54),
    height: spacing(38),
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    width: spacing(70),
  },
  title: {
    fontWeight: typography.fontWeightBold,
    marginBottom: spacing(3),
    marginRight: spacing(5),
    lineHeight: 1,
  },
  content: {
    marginBottom: spacing(2),
    fontSize: '0.75rem',
  },
  actions: {
    marginTop: 'auto',
    display: 'flex',
  },
  steps: {
    display: 'flex',
    alignItems: 'center',
    marginRight: spacing(3),
  },
  stepNumber: {
    margin: spacing(0, 2),
  },
}));

const getModifiers = (opt) => ({
  flip: {
    enabled: false,
  },
  preventOverflow: {
    enabled: true,
    boundariesElement: 'scrollParent',
  },
  arrow: {
    enabled: opt.arrowEnable,
    element: opt.arrowElelent,
  },
});

const HelperPopup: React.FC<HelperPopupProps> = React.forwardRef<any, HelperPopupProps>(
  (
    {
      className,
      onClose,
      anchorEl,
      placement,
      length,
      activeIndex,
      onSwitchStepClick,
      title,
      content,
      videoId,
      disableArrow,
      ...restProps
    },
    ref
  ) => {
    const { t } = useTranslation('helpers');
    const classes = useStyles();
    const arrowRef = useRef<HTMLDivElement>(null);
    const open = Boolean(anchorEl);

    return (
      <Popper
        ref={ref}
        className={cx(classes.popover, { [classes.bottom]: !!placement.match('bottom'), [classes.transition]: length > 1 }, className)}
        open={open}
        modifiers={getModifiers({ arrowElement: arrowRef.current, arrowEnable: !disableArrow })}
        anchorEl={anchorEl || null}
        transition
        placement={placement}
        {...restProps}
      >
        {!disableArrow && (
          <div
            className={cx(classes.arrow, {
              [classes.bottom]: !!placement.match('bottom'),
              [classes.start]: !!placement.match('start'),
              [classes.end]: !!placement.match('end'),
            })}
            ref={arrowRef}
          />
        )}
        <Paper elevation={3} className={classes.container}>
          <DialogCloseButton onClick={onClose} />
          {videoId && (
            <Box className={cx(classes.videoContainer, 'seer-video-container')}>
              <YouTube videoId={videoId} opts={youtubeOptions} />
            </Box>
          )}
          <Box display="flex" flexDirection="column" className={classes.main}>
            <Typography variant="subtitle1" className={classes.title}>
              {title}
            </Typography>
            <Typography variant="body2" className={classes.content}>
              {content}
            </Typography>
            <Box className={classes.actions}>
              {length > 1 && (
                <Box className={classes.steps}>
                  <IconButton
                    disabled={!activeIndex}
                    onClick={() => {
                      onSwitchStepClick(activeIndex - 1);
                    }}
                    size="small"
                  >
                    <ArrowBackIcon />
                  </IconButton>
                  <Typography className={classes.stepNumber} component="strong">
                    {t('stepNumber', { active: activeIndex + 1 > length ? length : activeIndex + 1, total: length })}
                  </Typography>
                  <Button
                    variant="contained"
                    disabled={length === activeIndex + 1}
                    color="primary"
                    onClick={() => {
                      onSwitchStepClick(activeIndex + 1);
                    }}
                  >
                    {t('nextButton')}
                  </Button>
                </Box>
              )}
              <Button variant="contained" onClick={onClose}>
                {t('turnOffButton')}
              </Button>
            </Box>
          </Box>
        </Paper>
      </Popper>
    );
  }
);

HelperPopup.propTypes = {
  className: PropTypes.string,
  placement: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSwitchStepClick: PropTypes.func,
  anchorEl: PropTypes.instanceOf(Element),
  activeIndex: PropTypes.number,
  length: PropTypes.number,
  title: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  videoId: PropTypes.string,
  disableArrow: PropTypes.bool,
};

HelperPopup.defaultProps = {
  className: '',
  anchorEl: null,
  placement: 'bottom',
  activeIndex: 0,
  length: 1,
  onSwitchStepClick: () => {},
  videoId: null,
  disableArrow: false,
};

export default HelperPopup;
