import { atom } from 'recoil';

export const firestoreTokenState = atom({
  key: 'Firestore/firestoreTokenState',
  default: null,
});

export const getTokenPendingState = atom({
  key: 'Firestore/getTokenPendingState',
  default: null,
});

export const isFirestoreAuthenticatedState = atom({
  key: 'Firestore/isFirestoreAuthenticatedState',
  default: false,
});

export const firestoreAuthPendingState = atom({
  key: 'Firestore/firestoreAuthPendingState',
  default: null,
});
