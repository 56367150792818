import { Auth } from '@aws-amplify/auth';
import useRefreshSession from './useRefreshSession';

const useIdToken = () => {
  const [refreshSession] = useRefreshSession();
  const getIdToken = async () => {
    let currentSession = await Auth.currentSession();
    let idToken = currentSession.getIdToken();

    // Refresh token if expired
    if (idToken.getIssuedAt() + idToken.getExpiration() > Date.now()) {
      currentSession = await refreshSession();
      idToken = currentSession.getIdToken();
    }
    return idToken.getJwtToken();
  };

  return [getIdToken];
};

export default useIdToken;
