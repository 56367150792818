import { atom, constSelector, selector } from 'recoil';
import { projectIdState } from '~/src/Project/store';

export enum Sources {
  Local = 'local',
  Existing = 'existing',
  Connectors = 'connectors',
}

export enum FileType {
  Training = 'training',
  Production = 'production',
}

export const availableSources = constSelector([Sources.Local]);

export const selectedSourceState = atom<Sources>({
  key: 'Files/selectedSource',
  default: Sources.Connectors,
});

export const selectedFileNameState = atom({
  key: 'Files/selectedFileName',
  default: '',
});

export const selectedProductionFileNameState = atom({
  key: 'Files/selectedProductionFileNameState',
  default: '',
});

export const fileTypeState = atom<FileType>({
  key: 'Files/fileTypeState',
  default: null,
});

export const pendingUploadProjectState = atom({
  key: 'Files/pendingUploadProjectState',
  default: {},
});

export const pendingUploadState = selector({
  key: 'Files/pendingUploadState',
  get: ({ get }) => {
    const id = get(projectIdState);
    const states = get(pendingUploadProjectState);    
    return !!states[id];
  },
  set: ({ get, set }, value: boolean) => {
    const id = get(projectIdState);
    const states = get(pendingUploadProjectState);    
    set(pendingUploadProjectState, { ...states, [id]: value });
  },
});

export const pendingFilenamesState = atom({
  key: 'Files/pendingFilenamesState',
  default: false,
});

export const pendingFileUrlState = atom({
  key: 'Files/pendingFileUrlState',
  default: false,
});
