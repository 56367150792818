/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState, useRef, useMemo, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Divider, makeStyles } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import cx from 'classnames';
import { useLocation } from 'react-router';
import { logo } from '~/src/store';
import { ProjectState, ProjectType } from '~/src/Project/store';
import StepItem from './StepItem';
import { askASeerState } from '~/src/Helper/store';

export enum PanelIndicatorSteps {
  Name,
  TrainingFile,
  ProductionFile,
  Results,
}

const useStyles = makeStyles(({ shape, spacing, palette, breakpoints }) => ({
  wrapper: {
    flex: 1,
    position: 'relative',
    height: spacing(12),
    marginBottom: spacing(15),
  },
  container: {
    padding: 0,
    margin: '0 auto',
    width: '100%',
    maxWidth: breakpoints.values.lg - spacing(6),
  },
  sticky: {
    position: 'fixed',
    top: spacing(3),
    zIndex: 1000,
    height: spacing(12),
    width: `calc(100% - ${spacing(6)}px)`,
    backgroundColor: palette.common.white,
    boxShadow: '0px 2px 16px rgba(0, 0, 0, 0.1)',
    borderRadius: shape.borderRadius * 2,
  },
  logoIcon: {
    height: spacing(6),
  },
  buttonWrapper: {
    borderLeft: `1px solid ${palette.custom.border}`,
    padding: spacing(1),
  },
  askButton: {
    height: spacing(12),
  },
  steps: {
    border: `1px solid ${palette.custom.border}`,
    borderRadius: shape.borderRadius * 2,
  },
  stepDivider: {
    margin: `${spacing(3)}px 0 `,
  },
  stickySteps: {
    border: 0,
    borderRadius: 0,
  },
}));

const activeStepMap = {
  [ProjectState.PendingProjectName]: PanelIndicatorSteps.Name,
  [ProjectState.PendingProjectType]: PanelIndicatorSteps.Name,
  [ProjectState.PendingTrainingFile]: PanelIndicatorSteps.TrainingFile,
  [ProjectState.UploadingTrainingFile]: PanelIndicatorSteps.TrainingFile,
  [ProjectState.ProcessingTrainingFile]: PanelIndicatorSteps.TrainingFile,
  [ProjectState.PendingDependentVariable]: PanelIndicatorSteps.TrainingFile,
  [ProjectState.PendingIndependentVariable]: PanelIndicatorSteps.TrainingFile,
  [ProjectState.TimeSeriesPreprocessing]: PanelIndicatorSteps.TrainingFile,
  [ProjectState.PendingTimeSeriesPrediction]: PanelIndicatorSteps.TrainingFile,
  [ProjectState.TimeSeriesPrediction]: PanelIndicatorSteps.Results,
  [ProjectState.PendingProductionFile]: PanelIndicatorSteps.ProductionFile,
  [ProjectState.PendingTraining]: PanelIndicatorSteps.TrainingFile,
  [ProjectState.Training]: PanelIndicatorSteps.TrainingFile,
  [ProjectState.Ready]: PanelIndicatorSteps.Results,
  [ProjectState.Done]: PanelIndicatorSteps.Results,
};

const stepToStateMapDefault = [
  ProjectState.PendingProjectType,
  ProjectState.PendingDependentVariable,
  ProjectState.PendingProductionFile,
  ProjectState.Ready,
];

const timeSeriesStepToStateMap = [ProjectState.PendingProjectType, ProjectState.PendingTimeSeriesPrediction, ProjectState.Ready];

const binaryStepsLabelsKeys = [
  { label: 'panelSteps.nameAndType', stepNumber: 1 },
  { label: 'panelSteps.trainingFile', stepNumber: 2 },
  { label: 'panelSteps.productionFile', stepNumber: 3 },
  { label: 'panelSteps.results', stepNumber: 4 },
];
const timeSeriesStepsLabelsKeys = [
  { label: 'panelSteps.nameAndType', stepNumber: 1 },
  { label: 'panelSteps.trainingFile', stepNumber: 2 },
  null,
  { label: 'panelSteps.results', stepNumber: 3 },
];

const StepPanel = ({ projectState, onCancel, type, realState, onClick, skippedStep, panelIndicator }) => {
  const { t } = useTranslation('projects');
  const location = useLocation();
  const { wrapper, sticky, container, logoIcon, askButton, steps, stickySteps, buttonWrapper, stepDivider } = useStyles();
  const [isSticky, setSticky] = useState(false);
  const { small } = useRecoilValue(logo);
  const setAskSeer = useSetRecoilState(askASeerState);
  const panel = useRef<HTMLDivElement>(null);
  const askSeerRef = useRef<HTMLButtonElement>(null);

  const stepLabelsKeys = useMemo(
    () => ({
      [ProjectType.Binary]: binaryStepsLabelsKeys,
      [ProjectType.TimeSeriesForecast]: timeSeriesStepsLabelsKeys,
      [ProjectType.Regression]: binaryStepsLabelsKeys,
      [ProjectType.MultiClass]: binaryStepsLabelsKeys,
    }[type]),
    [type],
  );

  const stepToStateMap = useMemo(
    () => ({
      [ProjectType.Binary]: stepToStateMapDefault,
      [ProjectType.TimeSeriesForecast]: timeSeriesStepToStateMap,
      [ProjectType.Regression]: stepToStateMapDefault,
      [ProjectType.MultiClass]: stepToStateMapDefault,
    }[type]),
    [type],
  );

  const activeStep = useMemo(() => {
    let step: PanelIndicatorSteps | string;

    switch (true) {
      case type === ProjectType.TimeSeriesForecast:
        step = activeStepMap[projectState];
        break;
      case !!panelIndicator:
        step = panelIndicator;
        break;
      default: step = activeStepMap[projectState];
    }

    return step;
  }, [projectState, type, panelIndicator]);

  const handleScroll = () => {
    if (panel.current) {
      const dimensions = panel.current.getBoundingClientRect();
      setSticky(!!dimensions.height && dimensions.top <= 12);
    }
  };

  const handleItemClick = (stepNumber, isActive) => {
    if (isActive || !stepNumber || activeStepMap[realState] < stepNumber) {
      return;
    }
    if (onClick) {
      onClick(stepToStateMap[stepNumber]);
    }
  };

  useEffect(() => {
    if (askSeerRef.current) {
      setAskSeer({ ...askSeerRef });
    }
  }, []);

  useEffect(() => {
    if (activeStep !== PanelIndicatorSteps.Results) {
      window.addEventListener('scroll', handleScroll);
    } else {
      window.removeEventListener('scroll', handleScroll);
      setSticky(false);
    }

    return () => {
      if (activeStep !== PanelIndicatorSteps.Results) {
        window.removeEventListener('scroll', handleScroll);
      }
    };
  }, [activeStep, panel.current]);

  return (
    <div className={wrapper} ref={panel}>
      <Box display="flex" justifyContent="space-between" alignItems="stretch" className={cx(container, { [sticky]: isSticky })}>
        <Box display="flex" justifyContent="space-between" alignItems="stretch" className={cx(steps, { [stickySteps]: isSticky })}>
          {stepLabelsKeys?.map(
            (item, i) => item && (
                <Fragment key={item.stepNumber}>
                  <StepItem
                    isActive={activeStep === i}
                    isSkipped={skippedStep === i}
                    isCompleted={skippedStep !== i && (activeStepMap[realState] >= i || activeStep > i)}
                    stepNumber={item.stepNumber}
                    label={t(item.label)}
                    onClick={() => handleItemClick(item.stepNumber - 1, activeStep === i)}
                  />
                  {i < stepLabelsKeys.filter((el) => !!el).length - 1 && (
                    <Divider className={stepDivider} orientation="vertical" flexItem />
                  )}
                </Fragment>
              ),
          )}
        </Box>
        {activeStep !== PanelIndicatorSteps.Results && (
          <Box display="flex" alignItems="stretch">
            {/* <Box display="flex" alignItems="stretch" className={cx({ [buttonWrapper]: isSticky })}>
              <Button
                ref={askSeerRef}
                className={cx({ [askButton]: !isSticky })}
                variant={!isSticky ? 'outlined' : 'text'}
                startIcon={<img src={small} alt="logo-icon" className={logoIcon} />}
              >
                Ask a Seer
              </Button>
            </Box> */}
            {isSticky && (
              <Box display="flex" alignItems="stretch" className={cx({ [buttonWrapper]: isSticky })}>
                <Button variant={!isSticky ? 'outlined' : 'text'} startIcon={<Close />} onClick={onCancel}>
                  Cancel Project
                </Button>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </div>
  );
};

StepPanel.propTypes = {
  projectState: PropTypes.string.isRequired,
  realState: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  skippedStep: PropTypes.number,
  panelIndicator: PropTypes.oneOf(Object.values(PanelIndicatorSteps)),
};

StepPanel.defaultProps = {
  onClick: null,
  skippedStep: -1,
  panelIndicator: null,
};

export default React.memo(StepPanel);
