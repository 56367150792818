import { useEffect, useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import useToggle from '~/src/hooks/useToggle';
import { ProjectMenuActions, selectedMenuActionState, selectedProjectIdState } from '../../store';
import useStopProcess from '../useStopProcess';
import { useResetProjectMenuDialogs } from './useResetProjectMenuDialogs';

export const useProjectGridStopProcessDialog = () => {
  const selectedProjectId = useRecoilValue(selectedProjectIdState);
  const menuAction = useRecoilValue(selectedMenuActionState);
  const [isConfirmStopProcessModalOpen, showConfirmStopProcess, hideConfirmStopProcess] = useToggle();
  const [stopProcess] = useStopProcess();
  const resetProjectMenuDialogs = useResetProjectMenuDialogs();

  useEffect(() => {
    if (menuAction === ProjectMenuActions.StopProcess) {
      showConfirmStopProcess();
    }
  }, [menuAction]);

  const handleHideConfirmStopProcess = useCallback(() => {
    hideConfirmStopProcess();
    resetProjectMenuDialogs();
  }, []);

  const handleConfirmStopProcess = useCallback(async () => {
    await stopProcess(selectedProjectId);
    handleHideConfirmStopProcess();
  }, [selectedProjectId]);

  return {
    isConfirmStopProcessModalOpen,
    showConfirmStopProcess,
    hideConfirmStopProcess: handleHideConfirmStopProcess,
    stopProcess: handleConfirmStopProcess,
  };
};

export default useProjectGridStopProcessDialog;
