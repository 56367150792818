import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { Box, Divider, List, ListItem, ListItemText } from '@material-ui/core';
import { useRecoilValue } from 'recoil';
import CloseButton from '~/src/components/DialogCloseButton';
import LoaderOverlay from '~/src/components/LoaderOverlay';
import { entryProjectSelector, isTrainingConnectorUsedSelector } from '../store';
import FastTrainingSelector from '~/src/TrainingFile/Binary/components/FastTrainingSelector';
import useExecuteProjectTraining from '../hooks/useExecuteProjectTraining';
import { pendingUploadState } from '~/src/Files/store';
import useStartTraining from '~/src/Project/hooks/useStartTraining';
import { ProjectType, pendingState } from '~/src/Project/store';

const useStyles = makeStyles(({ spacing, palette }) => ({
  modalDialog: {
    '& .MuiContainer-root': {
      padding: spacing(8, 6, 4),
    },
  },
  folderWarning: {
    marginBottom: spacing(2),
    color: palette.warning.main,
  },
  fastTrainingClass: {
    marginLeft: 0,
  },
  dialogTitle: {
    paddingRight: spacing(12),
  },
}));

const TrainingDialog = ({ open, onClose, projectId, projectName }) => {
  const { t } = useTranslation('projects');
  const classes = useStyles();
  const isTrainingConnectorUsed = useRecoilValue(isTrainingConnectorUsedSelector(projectId));
  const [executeProjectTraining] = useExecuteProjectTraining(projectId);
  const [startTraining] = useStartTraining();
  const project = useRecoilValue(entryProjectSelector(projectId));
  const pendingConnector = useRecoilValue(pendingUploadState);
  const pendingLocal = useRecoilValue(pendingState);

  const isTimeSeriesProject = useMemo(() => project.type === ProjectType.TimeSeriesForecast, [project]);

  const handleUpdateDataAndTrain = useCallback(async () => {
    await executeProjectTraining();
    onClose();
  }, []);

  const handlePreviousDataAndTrain = useCallback(async () => {
    await startTraining(projectId);
    onClose();
  }, [projectId]);

  return (
    <Dialog open={open} onClose={onClose} className={classes.modalDialog}>
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Typography variant="h5">
          {t('executeTrainingDialog.title', { projectName })}
        </Typography>
        <CloseButton onClick={onClose} />
      </DialogTitle>
      <DialogContent>
        {(pendingConnector || pendingLocal) && <LoaderOverlay />}
        <Box mb={4}>
          {!isTimeSeriesProject && <FastTrainingSelector projectId={projectId} className={classes.fastTrainingClass} />}
          <Divider />
          <List aria-label="training options">
            <ListItem button disabled={!isTrainingConnectorUsed} onClick={handleUpdateDataAndTrain}>
              <ListItemText
                primary={t('executeTrainingDialog.updateData.primary')}
                secondary={t(
                  isTrainingConnectorUsed ? 'executeTrainingDialog.updateData.secondary' : 'executeTrainingDialog.updateData.unavailable',
                )}
              />
            </ListItem>
            <ListItem button onClick={handlePreviousDataAndTrain}>
              <ListItemText
                primary={t('executeTrainingDialog.previousData.primary')}
                secondary={t('executeTrainingDialog.previousData.secondary')}
              />
            </ListItem>
          </List>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

TrainingDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  projectId: PropTypes.number.isRequired,
  projectName: PropTypes.string.isRequired,
};

TrainingDialog.defaultProps = {
  open: false,
};

export default React.memo(TrainingDialog);
