export const ALGORITHMS_OUTPUT = 'algorithms_output';
export const ALGORITHMS_OUTPUT_MODELS = ['DRF', 'Dee', 'GBM', 'GLM', 'Sta', 'XGB', 'XRT'];
export const PREDICTION_COLUMN_CLASS_NAME = 'squark-time-series-prediction-column';
export const algorithmsOutputMapper = {
  stackedensemble: 'Sta',
  se: 'Sta',
  sta: 'Sta',
  gbm: 'GBM',
  deeplearning: 'Dee',
  dee: 'Dee',
  dl: 'Dee',
  xrt: 'XRT',
  drf: 'DRF',
  glm: 'GLM',
  xgboost: 'XGB',
  xgb: 'XGB',
};

export const ALL_LEADERBOARD_FILE = 'all_leaderboard.csv';
export const MOJO_MODELS_FOLDER = 'mojo_models';
export const PREDICTION_STATS_FILE = 'prediction_stats.json';
export const TS_PREDICTION_STATS_FILE = 'tsa_prediction.json';
export const POST_CLEANING_TRAINING_DATA_FILE = 'files/training/post_cleaning.csv';
export const LEADERBOARD_FILE = 'leaderboard.csv';
export const PREDICTION_FILE = 'predictions.csv';
export const RESULTS_TABLE_PER_PAGE = 24;
