import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { makeStyles, Tooltip, Box, Typography } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/SaveOutlined';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import InputFieldWithAction from '~/src/components/InputFieldWithAction';
import useToggle from '~/src/hooks/useToggle';
import useProjectUpdate from '../hooks/useProjectUpdate';

const useStyles = makeStyles(({ spacing, palette }) => ({
  name: {
    position: 'relative',
    wordBreak: 'break-word',
    lineHeight: '1.5rem',
    fontSize: '1.125rem',
    fontWeight: 700,
    maxHeight: spacing(12),
    overflow: 'hidden',
    cursor: 'text',
    '&:hover': {
      background: 'rgba(0, 0, 0, .035)',
    },
  },
  editNameInput: {
    padding: spacing(0),
    borderWidth: '0 0 1px',
    borderRadius: 0,

    '& input': {
      paddingTop: 0,
      paddingBottom: 0,
      lineHeight: '1.5rem',
      fontSize: '1.125rem',
      fontWeight: 700,
    },
    '& .MuiIconButton-root': {
      padding: 0,
    },
  },
  runningEditNameInput: {
    '& input': {
      color: palette.common.white,
    },
    '& .MuiSvgIcon-root': {
      color: palette.common.white,
    },
  },
}));

const ProjectCardProjectName = ({ projectId, projectName, isReady }) => {
  const { t } = useTranslation('projects');
  const classes = useStyles();
  const [updateProject] = useProjectUpdate();
  const [editNameModeEnabled, , disableEditNameMode, toggleEditNameMode] = useToggle(false);

  const handleChangeProjectName = useCallback(async (newName) => {
    try {
      await updateProject(projectId, { displayName: newName }, t('updateProject.errors.changeName'));
      toggleEditNameMode();
    } catch (error) { /* empty */ }
  }, [projectId]);

  return (
    <>
      {!editNameModeEnabled && (
        <Tooltip title={t('updateProject.name.clickToEdit')} arrow enterDelay={500} enterNextDelay={500}>
          <Box onClick={toggleEditNameMode}>
            <Typography
              variant="h6"
              color="inherit"
              className={cx(classes.name, 'seer-project-prevent-default')}
            >
              {projectName}
            </Typography>
          </Box>
        </Tooltip>
      )}

      {editNameModeEnabled && (
        <ClickAwayListener onClickAway={disableEditNameMode}>
          <InputFieldWithAction
            autoFocus
            className={cx('seer-project-prevent-default', classes.editNameInput, { [classes.runningEditNameInput]: !isReady })}
            disableActionWithoutChanges
            value={projectName}
            actionIcon={SaveIcon}
            disableActionOnEmpty
            actionTooltip={t('projects:updateProject.name.saveButtonTooltip')}
            onActionClick={handleChangeProjectName}
          />
        </ClickAwayListener>
      )}
    </>
  );
};

ProjectCardProjectName.propTypes = {
  projectId: PropTypes.number.isRequired,
  projectName: PropTypes.string.isRequired,
  isReady: PropTypes.bool.isRequired,
};

export default React.memo(ProjectCardProjectName);
