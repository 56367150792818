import { useEffect } from 'react';
import { useResetRecoilState } from 'recoil';
import {
  passwordState,
  confirmPasswordState,
  errorAuthState,
  firstNameState,
  lastNameState,
  companyNameState,
  industryState,
  positionState,
  resetPasswordActiveState,
  userConfigState,
} from '../store';

const useClearEffect = () => {
  const resetError = useResetRecoilState(errorAuthState);
  const resetPass = useResetRecoilState(passwordState);
  const resetConfirmPass = useResetRecoilState(confirmPasswordState);
  const resetFirstName = useResetRecoilState(firstNameState);
  const resetLastName = useResetRecoilState(lastNameState);
  const resetCompanyName = useResetRecoilState(companyNameState);
  const resetIndustry = useResetRecoilState(industryState);
  const resetPosition = useResetRecoilState(positionState);
  const resetPasswordActive = useResetRecoilState(resetPasswordActiveState);
  const resetUserConfig = useResetRecoilState(userConfigState);

  useEffect(
    () => () => {
      resetError();
      resetPass();
      resetConfirmPass();
      resetFirstName();
      resetLastName();
      resetCompanyName();
      resetIndustry();
      resetPosition();
      resetPasswordActive();
      resetUserConfig();
    },
    [],
  );
};

export default useClearEffect;
