import { atom, selector, selectorFamily } from 'recoil';
import ConnectorsEnum from '~/src/Connectors/enums/ConnectorsEnum';
import { selectedProductionFileNameState } from '~/src/Files/store';
import ExportType from '~/src/ProjectResults/components/ExportConnectors/Enum/ExportType.enum';
import { ConditionControl, projectSelector } from '~/src/Projects/store';
import { PanelIndicatorSteps } from '~/src/StepPanel/components/StepPanel';
import { Tag } from '~/src/Tags/store';
import {
  automodelerSteps,
  preprocessorSteps,
  utsAutomodelerSteps,
  utsPreprocessorSteps,
  predictionSteps,
  shapSummarySteps,
  forceShapSteps,
  utsPredictionsSteps,
} from './constant';

export const getStepInfo = (step: ScriptsSteps) => {
  switch (true) {
    case preprocessorSteps.includes(step):
      return { current: preprocessorSteps.indexOf(step) + 1, total: preprocessorSteps.length };
    case automodelerSteps.includes(step):
      return { current: automodelerSteps.indexOf(step) + 1, total: automodelerSteps.length };
    case predictionSteps.includes(step):
      return { current: predictionSteps.indexOf(step) + 1, total: predictionSteps.length };
    case shapSummarySteps.includes(step):
      return { current: shapSummarySteps.indexOf(step) + 1, total: shapSummarySteps.length };
    case forceShapSteps.includes(step):
      return { current: forceShapSteps.indexOf(step) + 1, total: forceShapSteps.length };
    case utsAutomodelerSteps.includes(step):
      return { current: utsAutomodelerSteps.indexOf(step) + 1, total: utsAutomodelerSteps.length };
    case utsPreprocessorSteps.includes(step):
      return { current: utsPreprocessorSteps.indexOf(step) + 1, total: utsPreprocessorSteps.length };
    case utsPredictionsSteps.includes(step):
      return { current: utsPredictionsSteps.indexOf(step) + 1, total: utsPredictionsSteps.length };
    default:
      return null;
  }
};

export enum ProjectType {
  Binary = 'binary',
  Regression = 'regression',
  MultiClass = 'multiClass',
  TimeSeriesForecast = 'timeSeries',
}

export enum ProjectState {
  PendingProjectName = 'pendingProjectName',
  PendingProjectType = 'pendingProjectType',
  PendingTrainingFile = 'pendingTrainingFile',
  UploadingTrainingFile = 'uploadingTrainingFile',
  UploadingProductionFile = 'uploadingProductionFile',
  ProcessingTrainingFile = 'parsing',
  PendingDependentVariable = 'pendingVariables',
  PendingIndependentVariable = 'pendingIndependentVariable',
  Training = 'training',
  PendingTraining = 'pendingTraining',
  TimeSeriesPreprocessing = 'timeSeriesPreprocessing',
  PendingTimeSeriesPrediction = 'pendingTimeSeriesPrediction',
  PendingProductionFile = 'pendingProductionFile',
  TimeSeriesPrediction = 'timeSeriesPrediction',
  Processing = 'predicting',
  Ready = 'ready',
  Done = 'done',
}

export enum ScriptsSteps {
  AM_ANALYZING_DATA = 'AM_Analyzing_Data',
  AM_ENGINEERING_DATA = 'AM_Engineering_Data',
  AM_FACTORING_DATA = 'AM_Factoring_Data',
  AM_NLP = 'AM_NLP',
  AM_REDUCING_DATA = 'AM_Reducing_Data',
  AM_SELECTING_DATA = 'AM_Selecting_Data',
  AM_MODELING_DATA = 'AM_Modeling_Data',
  AM_ID_BEST_MODEL = 'AM_ID_Best_Model',
  AM_FINISHING_RESULTS = 'AM_Finishing_Results',

  PRED_UPDATING_FILE = 'PRED_Updating_File',
  PRED_GENERATING_RESULTS = 'PRED_Generating_Results',

  SS_ANALYZING_DATA = 'SS_Analyzing_data',
  SS_MODELING_DATA = 'SS_Modeling_Data',
  SS_FINISHING_RESULTS = 'SS_Finishing_Results',

  FS_ANALYZING_RESULTS = 'FS_Analyzing_Results',
  FS_MODELING_DATA = 'FS_Modeling_Data',
  FS_FINISHING_RESULTS = 'FS_Finishing_Results',

  DP_CLEANING_DATA = 'DP_Cleaning_Data',
  DP_ASSESSING_DATA = 'DP_Assessing_Data',
  DP_PREPARING_DATA = 'DP_Preparing_Data',
  DP_PROFILING_DATA = 'DP_Profiling_Data',
  DP_FINISHING_RESULTS = 'DP_Finishing_Results',

  UTS_DP_ID_DUPLICATES = 'UTS_DP_ID_Duplicates',
  UTS_DP_ID_PERIODICITY = 'UTS_DP_ID_Periodicity',

  UTS_AM_UPDATE_PERIODICITY = 'UTS_AM_Update_Periodicity',
  UTS_AM_AGGREGATE_DATA = 'UTS_AM_Aggregate_Data',
  UTS_AM_OUTLIER_CHECK = 'UTS_AM_Outlier_Check',
  UTS_AM_MODEL_DATA = 'UTS_AM_Model_Data',
  UTS_AM_ID_BEST_MODEL = 'UTS_AM_ID_Best_Model',
  UTS_PRED_GENERATE_PREDICT_RESULTS = 'UTS_PRED_Generate_Predict_Results',
  UTS_PRED_GENERATE_VIZ_RESULTS = 'UTS_PRED_Generate_Viz_Results',
  UTS_PRED_FINISHING_RESULTS = 'UTS_PRED_Finishing_Results',
}

export enum ScriptStatus {
  Ready = 'ready',
  Pending = 'pending',
  Failed = 'failed',
}

export enum ScriptNames {
  Automodeler = 'automodeler',
  Preprocessor = 'preprocessor',
  Prediction = 'prediction',
  Shap_summary = 'shap_summary',
  ForceShap = 'forceShap',
  ValidationCSV = 'validationCSV',
  TimeSeriesPreprocessor = 'tsa_preprocessor',
  TimeSeriesAutomodeler = 'tsa_automodeler',
  TimeSeriesPrediction = 'tsa_prediction',
  UploadFile = 'uploadFile',
}

export interface ScriptState {
  step?: ScriptsSteps;
  error?: string;
  state?: ScriptStatus;
  [key: string]: any;
}

export interface ProjectFile {
  name?: string;
  connectorConfig?: any;
  connectorType?: ConnectorsEnum;
  schedule?: string;
}

export interface FileInfo {
  training: ProjectFile;
  production: ProjectFile;
}

export interface ExportConfig {
  connectorType: ConnectorsEnum;
  params: any; // any data that is needed for different connectors
  isAutoExportEnabled?: boolean;
}

export type ExportOptions = Partial<Record<ExportType, ExportConfig>>;

export enum CopyJobState {
  Failed = 'failed',
  Ready = 'ready',
}

interface CopyProjectState {
  projectIdSource?: number;
  projectIdNew?: number;
  projectNameSource?: string;
  projectNameNew?: string;
  state: CopyJobState
}

export enum CopyEntities {
  Variables = 'variables',
  Predictions = 'predictions',
  Files = 'files',
}

export type CopyNotification = Partial<Record<CopyEntities, CopyJobState>>;

export type ProjectScriptsState = Partial<Record<ScriptNames, ScriptState>>;

export enum UserRole {
  Owner = 'owner',
  Editor = 'editor',
  Viewer = 'viewer',
  Demo = 'demo',
}

export interface ProjectUser {
  email: string,
  role: UserRole
}

export interface ShareParams extends ProjectUser {
  comment?: string
}

export interface ProjectReport {
  isSelected?: boolean;
  state?: ScriptStatus;
  isFastTraining?: ConditionControl;
  modelName?: string
  updatedAt?: string;
}

export type ProjectReports = Record<string, ProjectReport>;

export interface Project extends ProjectScriptsState {
  _id: number;
  displayName: string;
  description?: string;
  type: ProjectType;
  state: ProjectState;
  files?: FileInfo;
  reports?: ProjectReports;
  users?: ProjectUser[];
  export?: ExportOptions;
  tags?: Tag[];
  folder?: number;
}

export const projectState = atom({
  key: 'ProjectDetails/projectState',
  default: ProjectState.PendingProjectName,
});

export const displayName = atom({
  key: 'ProjectDetails/displayName',
  default: '',
});

export const displayNameValid = selector({
  key: 'ProjectDetails/displayNameValid',
  get: ({ get }) => /^[A-Za-z0-9\-\s+]{1,30}$/.test(get(displayName)),
});

export const displayNameTouched = atom({
  key: 'ProjectDetails/displayNameTouched',
  default: false,
});

export const projectType = atom<ProjectType>({
  key: 'ProjectDetails/projectType',
  default: null,
});

export const currentProjectIdState = atom({
  key: 'ProjectDetails/currentProjectId',
  default: null,
});

export const pendingState = atom({
  key: 'ProjectDetails/pendingState',
  default: false,
});

export const isSkippedProductionFileState = selectorFamily({
  key: 'ProjectDetails/isSkippedProductionFileState',
  get:
    (projectId: number) => ({ get }) => {
      const project = get(projectSelector(projectId));
      if (!project) return false;
      const fileName = get(selectedProductionFileNameState);
      return project.type !== ProjectType.TimeSeriesForecast
        && project.state === ProjectState.Ready
        && !fileName
        && !project[ScriptNames.Prediction]?.missingColumns;
    },
});

export const isPredictionDoneState = selectorFamily({
  key: 'ProjectDetails/isPredictionDoneState',
  get:
    (projectId: number) => ({ get }) => {
      const project = get(projectSelector(projectId));
      return project?.prediction?.state === ScriptStatus.Ready;
    },
});

export const isAutomodelerDoneState = selectorFamily({
  key: 'ProjectDetails/isAutomodelerDoneState',
  get:
    (projectId: number) => ({ get }) => {
      const project = get(projectSelector(projectId));
      return (
        (project?.state === ProjectState.Ready && project?.automodeler?.state === ScriptStatus.Ready)
        || project?.tsa_automodeler?.state === ScriptStatus.Ready
      );
    },
});

export const projectIdState = atom({
  key: 'ProjectDetails/projectIdState',
  default: null,
});

export const panelIndicatorState = atom<PanelIndicatorSteps>({
  key: 'ProjectDetails/panelIndicatorState',
  default: null,
});

export const copyProjectState = atom<CopyProjectState>({
  key: 'ProjectDetails/copyProjectState',
  default: null,
});

export const pendingClearCopyState = atom({
  key: 'ProjectDetails/pendingClearCopyState',
  default: false,
});

export const pendingUpdateReportState = atom({
  key: 'ProjectDetails/pendingUpdateReportState',
  default: false,
});
