import googleLogo from '~/src/assets/google-logo.svg';
import oktaLogoWhite from '~/src/assets/okta-logo-white.svg';
import hubspotLogo from '~/src/assets/hubspotlogo-web-color.svg';
import epicGamesLogo from '~/src/assets/epic-games-logo.svg';

export const loginProvidersLogos = {
  Google: googleLogo,
  HubspotOKTA: oktaLogoWhite,
  OKTA: oktaLogoWhite,
};

export const customerLogos = {
  hubspot: hubspotLogo,
  epicGames: epicGamesLogo,
};

export const customerLinks = {
  hubspot: 'https://hubspot.com',
  epicGames: 'https://www.epicgames.com/',
};

export const industriesKeys = [
  'accounting',
  'aerospace',
  'agency',
  'agriculture',
  'architecture',
  'automotive',
  'banking',
  'biotechnology',
  'business',
  'communications',
  'computer',
  'construction',
  'consulting',
  'education',
  'energy',
  'entertainment',
  'environmental',
  'financial',
  'government',
  'healthcare',
  'higherEducation',
  'hospitality',
  'clinics',
  'information',
  'insurance',
  'legal',
  'leisure',
  'logistics',
  'manufacturing',
  'media',
  'mining',
  'notProfit',
  'other',
  'pharmaceuticals',
  'printing',
  'publishing',
  'realEstate',
  'retail',
  'staffing',
  'technology',
  'transportation',
  'utilities',
  'ventureCapital',
  'wellness',
];

export const positionKeys = [
  'analyst',
  'architect',
  'CFO',
  'CIO',
  'CMO',
  'COO',
  'CSO',
  'chiefSupply',
  'coordinator',
  'developer',
  'engineer',
  'independentContractor',
  'manager',
  'president',
  'scientist',
  'seniorDirector',
  'seniorManager',
  'seniorVP',
  'specialist',
  'VP',
];
