import { atom, atomFamily, selector } from 'recoil';
import { FileType, fileTypeState } from '../store';

export const selectedTrainingFileState = atom<File>({
  key: 'UploadLocalFile/selectedTrainingFileState',
  default: null,
});

export const selectedProductionFileState = atom<File>({
  key: 'UploadLocalFile/selectedProductionFileState',
  default: null,
});

export const selectedFileState = selector({
  key: 'UploadLocalFile/selectedFileState',
  get: ({ get }) => {
    const type = get(fileTypeState);
    return type === FileType.Training ? get(selectedTrainingFileState) : get(selectedProductionFileState);
  },
  set: ({ get, set }, file: File) => {
    const type = get(fileTypeState);
    const fileState = type === FileType.Training ? selectedTrainingFileState : selectedProductionFileState;
    set(fileState, file);
  },
});

export const uploadedPercentState = atomFamily({
  key: 'UploadLocalFile/uploadedPercentState',
  default: (projectId: number) => 0,
});
