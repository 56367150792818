import { atom } from 'recoil';

export const pendingCredentialsState = atom({
  key: 'Develop/pendingCredentialsState',
  default: false,
});

export const credentialsListState = atom({
  key: 'Develop/credentialsListState',
  default: null,
});

export const newCredentialsState = atom<{ clientId: string; clientSecret: string }>({
  key: 'Develop/newCredentialsState',
  default: null,
});

export const devTokenState = atom<{ expiresIn: number; idToken: string; refreshToken: string }>({
  key: 'Develop/devTokenState',
  default: null,
});
