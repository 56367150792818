import { ForcePlotData } from '~/src/Charts/store';
import { TableFilter, TableFilterOperators } from '~/src/TableFilters/store';
import { ALGORITHMS_OUTPUT } from './constants';
import { ImportanceData } from './result.types';

/* eslint-disable prefer-destructuring */
export const getLeaderbordColumnName = (key: string) => {
  if (!key) return '';
  if (!key.includes('_')) {
    return key.toUpperCase();
  }
  const result = key.replace(/_(\w{1})/g, (_, wordStart) => ` ${wordStart.toUpperCase()}`);
  return result[0].toUpperCase() + result.slice(1);
};

type LeaderboardMap = { headers: string[] } & { [key: string]: string[][] };

export const getLeaderbordTableData = (data: string[][]) => data.reduce((acc, item, index) => {
  if (!index) {
    acc.headers = item.map((headerLabel) => getLeaderbordColumnName(headerLabel));
    return acc;
  }
  const modelData = item[0].split('_');
  const model = modelData[0];
  if (!acc[model]) {
    acc[model] = [];
  }
  acc[model].push(item);
  return acc;
}, {} as LeaderboardMap);

interface LeaderbordData {
  model_id: string, original_model_id: string, [key: string]: string
}

export const getLeaderbordData = (data: string[][]) => data.reduce((acc, item, index) => {
  if (!index) {
    return acc;
  }
  const res = {} as LeaderbordData;
  res.model_id = item[0].split(/_| /g)[0];
  res.original_model_id = item[0];
  item.forEach((elem, i) => {
    if (i) {
      res[data[0][i]] = elem;
    }
  });
  acc.push(res);
  return acc;
}, [] as LeaderbordData[]);

interface FilterApiParam {
  [variableName: string]: TableFilterOperators;
}

const escapeRegExp = (string) => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

export const getFiltersParams = (filters: TableFilter[]) => {
  const paramsObject = filters.reduce((acc, item) => {
    const { name, variant, ...operators } = item;

    switch (true) {
      case item.variant === 'prediction':
        acc[variant] = operators;
        break;
      case item.variant === 'probabilities':
        acc[`${variant}.${name}`] = Object.keys(operators).reduce((res, op) => {
          res[op] = +operators[op] / 100;
          return res;
        }, {} as TableFilterOperators);
        break;
      case !!operators.$regex:
        acc[`${variant}.${name}`] = { $regex: escapeRegExp(operators.$regex) };
        break;
      default:
        acc[`${variant}.${name}`] = operators;
    }

    return acc;
  }, {} as FilterApiParam);

  return paramsObject;
};

export const getBase64 = (data: any) => (typeof data === 'string' ? btoa(data) : btoa(JSON.stringify(data)));

export const saveDataAsFile = (data: any, filename: string = 'data.csv', type: string = 'csv') => {
  const dataString = typeof data === 'string' ? data : JSON.stringify(data, null, 2);
  const blob = new Blob([dataString], { type: `text/${type}` });
  const link = document.createElement('a');

  link.download = filename;
  link.href = window.URL.createObjectURL(blob);
  link.dataset.downloadurl = [`text/${type}`, link.download, link.href].join(':');

  const evt = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  });

  link.dispatchEvent(evt);
  link.remove();
};

export const saveObjectAsJSONFile = (data: any, filename: string = 'file.json') => {
  saveDataAsFile(data, filename, 'json');
};

// convert Object to Array for next CSV export
export const convertForcePlotDataToArray = (data: ForcePlotData) => {
  if (!data) return null;
  const result: (string | number)[][] = data.class
    ? [['Feature', 'Feature Value', 'SHAP Value', 'Class', 'Base Value', 'Calculated SHAP']]
    : [['Feature', 'Feature Value', 'SHAP Value', 'Base Value', 'Calculated SHAP']];
  if (data.class) {
    Object.keys(data.class).forEach((classKey) => {
      Object.keys(data.feature).forEach((featureName) => {
        const shapValue = data.shap[classKey][featureName];
        const row = [
          featureName, data.feature[featureName],
          shapValue, data.class[classKey],
          data.base_value[classKey], data.calculated_shap[classKey],
        ];
        result.push(row);
      });
    });
  } else {
    Object.keys(data.feature).forEach((featureName) => {
      const shapValue = data.shap[featureName] as string;
      const row = [featureName, data.feature[featureName], shapValue, data.base_value as number, data.calculated_shap as number];
      result.push(row);
    });
  }
  // eslint-disable-next-line consistent-return
  return result;
};

export const getFractionNumberFormatter = (fractionDigits = 2) => (
  new Intl.NumberFormat(window.navigator.language, { maximumFractionDigits: fractionDigits })
);

export const definePrecision = (data: number[], defaultPrecision = 1, maxPrecision = 12) => {
  if (defaultPrecision > maxPrecision) return maxPrecision;
  const precisedData = data.map((item) => item.toFixed(defaultPrecision));
  const dataSet = new Set();
  precisedData.forEach((item) => dataSet.add(item));
  if (dataSet.size > 0.33 * data.length) return defaultPrecision; // it is checking: is at least 33% uniq values with current precision
  return definePrecision(data, defaultPrecision + 1);
};

export const getFractationFormatterForArray = (data: number[], defaultPrecision = 1, maxPrecision = 12) => {
  const precision = definePrecision(data, defaultPrecision, maxPrecision);
  return getFractionNumberFormatter(precision);
};

export const getElementWidth = (content: string, paddings = 0, isBold = false) => {
  const div = document.createElement('div');
  div.appendChild(document.createTextNode(content));
  div.style.position = 'absolute';
  div.style.visibility = 'hidden';
  if (isBold) {
    div.style.fontWeight = '700';
  }
  document.body.appendChild(div);
  const elWidth = div.getBoundingClientRect()?.width ?? 0;
  div.remove();
  return elWidth + paddings;
};

export const getVariableImportanceFilePath = (model) => `${ALGORITHMS_OUTPUT}/${model}/${model}_stats.json`;

export const extendImportanceData = (data) => ({
  ...data,
  stackedensemble: data.Sta,
  se: data.Sta,
  deeplearning: data.Dee,
  dl: data.Dee,
  xgboost: data.XGB,
});

export const parseVariableImportanceData = (data: ImportanceData, algorithmsModel: string) => {
  if (!data) {
    return null;
  }

  let Intercept;
  let coefficients;
  const { varimp } = data;

  if (algorithmsModel === 'GLM') {
    ({ Intercept, ...coefficients } = data.coef_on_training_file);
  } else if (algorithmsModel === 'Sta') {
    ({ Intercept, ...coefficients } = data.metalearner_coef_norm_on_cross_val);
  }

  return { varimp, coefficients };
};
