import {
  Box,
  Button,
  TextField,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Paper,
  Typography,
  Tooltip,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import DeleteIcon from '@material-ui/icons/Delete';
import CopyIcon from '@material-ui/icons/FileCopy';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import HighlightText from '~/src/components/HighlightText';
import HomeLayout from '~/src/HomeLayout/components/HomeLayout';
import useTitle from '~/src/hooks/useTitle';
import useCredentials from '../hooks/useCredentials';
import { credentialsListState, devTokenState, newCredentialsState, pendingCredentialsState } from '../store';
import useResetDevelop from '../hooks/useResetDevelop';
import useGetToken from '../hooks/useGetToken';
import LoaderOverlay from '~/src/components/LoaderOverlay';

const useStyles = makeStyles(({ spacing }) => ({
  paper: {
    padding: 20,
    marginBottom: spacing(8),
  },
  title: {
    marginBottom: spacing(4),
  },
  credsLabel: {
    fontWeight: 700,
    marginRight: spacing(2),
    minWidth: 125,
  },
  formControl: {
    display: 'flex',
    flexDirection: 'column',
    '& .MuiFormControl-root': {
      margin: spacing(0, 0, 2),
    },
  },
  submitBtn: {
    alignSelf: 'flex-start',
  },
  listActions: {
    display: 'flex',
    alignSelf: 'stretch',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flex: '1 0 80px',
    marginLeft: spacing(2),
    '& button + button': {
      marginLeft: spacing(2),
    },
  },
  token: {
    wordBreak: 'break-all',
    marginRight: spacing(4),
  },
  copyTokenButton: {
    alignSelf: 'center',
    marginTop: spacing(2),
  },
  mlAuto: {
    marginLeft: 'auto',
  },
}));

const clientIdFormName = 'clientId';
const clientSecretFormName = 'clientSecret';

// eslint-disable-next-line react/prop-types
const CopyClipboardButton: React.FC<{ tooltip: string, text: string, [key: string]: any }> = ({ tooltip, text, ...rest }) => (
  <Tooltip title={tooltip}>
    <IconButton
      size="small"
      edge="end"
      aria-label="copy"
      onClick={() => window.navigator.clipboard.writeText(text)}
      {...rest}
    >
      <CopyIcon />
    </IconButton>
  </Tooltip>);

const Develop = () => {
  const { t } = useTranslation('develop');
  const classes = useStyles();
  const credentials = useCredentials();
  const getToken = useGetToken();
  const [newCreds, setNewCreds] = useRecoilState(newCredentialsState);
  const [devToken, setDevToken] = useRecoilState(devTokenState);
  const list = useRecoilValue(credentialsListState);
  const pendingCredentials = useRecoilValue(pendingCredentialsState);
  const resetDevelop = useResetDevelop();
  const [clientIdGetToken, setClientIdGetToken] = useState('');
  const [clientSecretGetToken, setClientSecretGetToken] = useState('');

  const handleDeleteCredentials = (clientId) => {
    credentials.delete(clientId);
    setNewCreds(null);
    setDevToken(null);
    setClientIdGetToken('');
    setClientSecretGetToken('');
  };

  const handleClientIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setClientIdGetToken(event.target.value);
  };

  const handleClientSecretChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setClientSecretGetToken(event.target.value);
  };

  const handleGetToken = (event) => {
    event.preventDefault();
    getToken(clientIdGetToken, clientSecretGetToken);
  };

  const handleEnterKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && clientIdGetToken && clientSecretGetToken) {
      getToken(clientIdGetToken, clientSecretGetToken);
    }
  };

  useTitle('Develop');

  useEffect(() => {
    credentials.read();
  }, []);

  useEffect(() => () => {
    resetDevelop();
    setClientIdGetToken('');
    setClientSecretGetToken('');
  }, []);

  return (
    <HomeLayout>
      <Grid>
        <Grid item xs={12}>
          <Box py={8} px={5} width="100%" minHeight="100px" position='relative'>
            {pendingCredentials && <LoaderOverlay />}
            <Box>
              <Paper elevation={2} className={classes.paper}>
                <Typography variant="h5" className={classes.title}>
                  {t('credentials.newCredentials')}
                </Typography>
                <Button disabled={pendingCredentials || !!list?.length} variant="contained" onClick={() => credentials.create()}>
                  {t('credentials.buttons.create')}
                </Button>
                {newCreds && (
                  <Box my={4}>
                    <Alert severity="warning">
                      <HighlightText text={t('credentials.warningClientSecret')} />
                    </Alert>
                    <Box mt={4}>
                      <Box display="flex" alignItems="center" mb={3}>
                        <Typography className={classes.credsLabel}>{t('credentials.clientId')}: </Typography>
                        <Typography className={classes.token}>{newCreds.clientId}</Typography>
                        <CopyClipboardButton
                          tooltip={t('credentials.buttons.copy')}
                          text={newCreds.clientId}
                          className={classes.mlAuto}
                        />
                        <Tooltip title={t('credentials.buttons.delete')}>
                          <IconButton
                            size="small"
                            edge="end"
                            aria-label="delete"
                            onClick={() => handleDeleteCredentials(newCreds.clientId)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                      <Box display="flex" alignItems="center">
                        <Typography className={classes.credsLabel}>{t('credentials.clientSecret')}: </Typography>
                        <Typography className={classes.token}>{newCreds.clientSecret}</Typography>
                        <CopyClipboardButton
                          tooltip={t('credentials.buttons.copySecret')}
                          text={newCreds.clientSecret}
                          className={classes.mlAuto}
                        />
                      </Box>
                    </Box>
                  </Box>
                )}
              </Paper>
              {!!list?.length && !newCreds && (
                <Paper elevation={2} className={classes.paper}>
                  <Typography variant="h5" className={classes.title}>
                    {t('credentials.listTitle')}
                  </Typography>
                  <List>
                    {list.map((item, i) => (
                      <React.Fragment key={item.clientId}>
                        <ListItem>
                          <ListItemText primary={item.clientId} className={classes.token} />
                          <Box className={classes.listActions}>
                            <CopyClipboardButton tooltip={t('credentials.buttons.copy')} text={item.clientId} />
                            <Tooltip title={t('credentials.buttons.delete')}>
                              <IconButton
                                size="small"
                                edge="end"
                                aria-label="delete"
                                onClick={() => handleDeleteCredentials(item.clientId)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </ListItem>
                        {i + 1 < list.length && <Divider component="li" />}
                      </React.Fragment>
                    ))}
                  </List>
                </Paper>
              )}
              <Paper elevation={2} className={classes.paper}>
                <Typography variant="h5" className={classes.title}>
                  {t('credentials.getToken')}
                </Typography>
                <form noValidate autoComplete="off">
                  <Box className={classes.formControl}>
                    <TextField
                      autoComplete="off"
                      name={clientIdFormName}
                      variant="outlined"
                      hiddenLabel
                      placeholder={t('credentials.clientIdPlaceholder')}
                      onChange={handleClientIdChange}
                      value={clientIdGetToken}
                    />
                    <TextField
                      autoComplete="off"
                      name={clientSecretFormName}
                      variant="outlined"
                      hiddenLabel
                      placeholder={t('credentials.clientSecretPlaceholder')}
                      onChange={handleClientSecretChange}
                      onKeyPress={handleEnterKeyPress}
                      value={clientSecretGetToken}
                    />
                    <Button
                      disabled={!clientIdGetToken || !clientSecretGetToken}
                      className={classes.submitBtn}
                      variant="contained"
                      disableElevation
                      onClick={handleGetToken}
                    >
                      {t('credentials.getToken')}
                    </Button>
                  </Box>
                </form>
                {devToken && (
                  <Box mt={4}>
                    <Box display="flex" alignItems="center" mb={3}>
                      <Box display="flex" flexDirection="column">
                        <Typography className={classes.credsLabel}> {t('credentials.tokenId')}</Typography>
                        <CopyClipboardButton
                          tooltip={t('credentials.buttons.copyToken')}
                          text={devToken.idToken}
                          className={classes.copyTokenButton}
                        />
                      </Box>
                      <Typography className={classes.token}>{devToken.idToken}</Typography>
                    </Box>
                    <Box display="flex" alignItems="center" mb={3}>
                      <Box display="flex" flexDirection="column">
                        <Typography className={classes.credsLabel}>{t('credentials.refreshToken')}</Typography>
                        <CopyClipboardButton
                          tooltip={t('credentials.buttons.copyToken')}
                          text={devToken.refreshToken}
                          className={classes.copyTokenButton}
                        />
                      </Box>
                      <Typography className={classes.token}>{devToken.refreshToken}</Typography>
                    </Box>
                    <Box display="flex" alignItems="center" mb={3}>
                      <Typography className={classes.credsLabel}>{t('credentials.expiredIn')} </Typography>
                      <Typography>
                        {new Date(devToken.expiresIn * 1000).toLocaleString()} (timestamp: {devToken.expiresIn * 1000})
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Paper>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </HomeLayout>
  );
};

export default React.memo(Develop);
