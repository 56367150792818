import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import useScriptSteps from '~/src/Project/hooks/useScriptSteps';

const useStyles = makeStyles(({ typography, palette }) => ({
  bold: {
    fontWeight: typography.fontWeightBold,
  },
  stepNumber: {
    color: palette.text.secondary,
  },
}));

interface ScriptStepInformationProps {
  className?: string;
  scriptName?: string;
  projectId: number | string;
}

const ScriptStepInformation: React.FC<ScriptStepInformationProps> = ({ className, projectId, scriptName }) => {
  const classes = useStyles();
  const stepData = useScriptSteps(projectId, scriptName);

  return !stepData?.number ? (
    <Box className={className}>
      <Typography variant="body1">{stepData.placeholder}</Typography>
    </Box>
  ) : (
    <Box className={className}>
      <Typography component="div" className={classes.stepNumber} variant="body2">
        {stepData.number}
      </Typography>
      <Typography className={classes.bold} color="secondary" variant="h6">
        {stepData.name}
      </Typography>
      <Typography variant="body1">{stepData.description}</Typography>
    </Box>
  );
};

ScriptStepInformation.propTypes = {
  className: PropTypes.string,
  scriptName: PropTypes.string,
  projectId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

ScriptStepInformation.defaultProps = {
  className: '',
  scriptName: null,
};

export default ScriptStepInformation;
