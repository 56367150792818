import { atom } from 'recoil';

export const helperState = atom({
  key: 'Helper/helperState',
  default: false,
});

export const askASeerState = atom({
  key: 'Helper/askASeerState',
  default: null,
});

export const targetColumnRefState = atom({
  key: 'Helper/targetColumnRefState',
  default: null,
});

export const excludedColumnsRefState = atom({
  key: 'Helper/excludedColumnsRefState',
  default: null,
});

export const timeframeRefState = atom({
  key: 'Helper/timeframeRefState',
  default: null,
});

// export const outliersRefState = atom({
//   key: 'Helper/outliersRefState',
//   default: null,
// });

// export const duplicatesRefState = atom({
//   key: 'Helper/duplicatesRefState',
//   default: null,
// });
