import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const useStyles = makeStyles(({ typography }) => ({  
  switcherLabel: {
    '& .MuiFormControlLabel-label': typography.body2,
  },
}));

const Switcher = ({ className, checked, onChange, label }) => {
  const {switcherLabel} = useStyles();
  return (
    <FormControlLabel
      className={cx(className, switcherLabel)}
      control={<Switch color="default" checked={checked} onChange={onChange} />}
      label={label}
    />
  );
};

Switcher.propTypes = {
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  checked: PropTypes.bool,
};

Switcher.defaultProps = {
  className: '',
  label: '',
  checked: false,
};

export default Switcher;
