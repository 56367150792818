import React from 'react';
import { useTranslation } from 'react-i18next';

const LeaderboardHelperContent = () => {
  const { t } = useTranslation('helpers');

  return (
    <>
      {t('results.leaderboard.content1')}
      <br />
      {t('results.leaderboard.content2')} <a href="https://squarkai.com/glossary/">https://squarkai.com/glossary</a>
    </>
  );
};

export default LeaderboardHelperContent;
