import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import Loader from './Loader';

const useStyles = makeStyles(() => ({
  overlay: {
    position: 'absolute',
    zIndex: 2020,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
}));

const LoaderOverlay = () => {
  const { overlay } = useStyles();

  return (
    <Box className={overlay}>
      <Loader />
    </Box>
  );
};

export default LoaderOverlay;
