import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { TabContext } from '@material-ui/lab';
import { Container, Grid } from '@material-ui/core';
import { ProjectState } from '~/src/Project/store';
import Tabs from './SelectSchedulerTypeTabs';
import { SchedulerSources } from '../../store';
import AnalyzeSchedulerTabPanel from './AnalyzeSchedulerTabPanel';
import PredictSchedulerTabPanel from './PredictSchedulerTabPanel';
import CloseButton from '~/src/components/DialogCloseButton';
import useToggle from '~/src/hooks/useToggle';
import ConfirmationDialog from '~/src/components/ConfirmationDialog';
import useScheduleProject from '../hooks/useScheduleProject';
import useEmailAddSubscription from '~/src/Emails/hooks/useEmailAddSubscription';
import { SubscriptionType } from '~/src/Emails/store';
import { entryFileSelector, entryProjectSelector, entryScheduleSelector } from '~/src/Projects/store';
import useDeleteSchedule from '../hooks/useDeleteSchedule';
import useEmailRemoveSubscription from '~/src/Emails/hooks/useEmailRemoveSubscription';
import { defaultSchedulePattern, isScheduleActionState, isScheduleFetchActionState } from '../store';
import Delayed from '~/src/components/Delayed';
import LoaderOverlay from '~/src/components/LoaderOverlay';
import ConnectorsEnum from '~/src/Connectors/enums/ConnectorsEnum';
import ExportPanel from './ExportPanel';
import useAutoExport from '../hooks/useAutoExport';

const useStyles = makeStyles(({ spacing }) => ({
  title: {
    fontWeight: 700,
    justifyContent: 'center',
  },
  dialogWrapper: {
    '& .MuiDialogActions-root button': {
      minWidth: 100,
    },
    '& .MuiDialogActions-root': {
      padding: spacing(4, 6),
    },
  },
  modalDialog: {
    '& .MuiContainer-root': {
      padding: `${spacing(4)}px ${spacing(6)}px ${spacing(8)}px`,
    },
  },
}));

const SchedulingDialog = ({ open, onClose, projectId, projectName }) => {
  const { t } = useTranslation('projects');
  const { title, dialogWrapper, modalDialog } = useStyles();
  const [selectedSource, setSelectedSource] = useState(SchedulerSources.Training);
  const { state } = useRecoilValue(entryProjectSelector(projectId)) || {};
  const isReadyResult = useMemo(() => state === ProjectState.Ready, [state]);
  const [isConfirmDeleteModalOpen, showConfirmDelete, hideConfirmDelete] = useToggle();
  const [isConfirmTabChangeModalOpen, showConfirmTabChange, hideConfirmTabChange] = useToggle();
  const [deletableSchedule, setDeletableSchedule] = useState('');
  const [scheduleProject] = useScheduleProject(projectId);
  const [pattern, setPattern] = useState('');
  const [doFilter, setDoFilter] = useState(true);
  const [analyzeSchedulerValue, setAnalyzeSchedulerValue] = useState(defaultSchedulePattern);
  const [predictSchedulerValue, setPredictSchedulerValue] = useState(defaultSchedulePattern);
  const [doPredictFilter, setDoPredictFilter] = useState(true);
  const entryFile = useRecoilValue(entryFileSelector(projectId));
  const [handleRemoveSubscription] = useEmailRemoveSubscription();
  const [handleAddSubscription] = useEmailAddSubscription();
  const [deleteSchedule] = useDeleteSchedule(projectId);
  const [newExpectedTab, setNewExpectedTab] = useState(SchedulerSources.Production);
  const schedulerActionPending = useRecoilValue(isScheduleActionState);
  const schedulerFetchActionLoading = useRecoilValue(isScheduleFetchActionState);
  const entrySchedule = useRecoilValue(entryScheduleSelector(projectId));
  const autoExport = useAutoExport(projectId);

  const handleAnalyzeEmailSubscription = async () => {
    setDoFilter(!doFilter);
    if (doFilter) {
      handleRemoveSubscription(projectId, SubscriptionType.Analyzing);
    }
  };

  const handleAnalyzeScheduleProject = async () => {
    const analyzeSch = await scheduleProject(selectedSource, pattern, projectName);
    setAnalyzeSchedulerValue(analyzeSch);
    setPattern('');
    if (doFilter) handleAddSubscription(projectId, SubscriptionType.Analyzing);
  };

  const handlePredictScheduleProject = async () => {
    const predictSch = await scheduleProject(selectedSource, pattern, projectName);
    setPredictSchedulerValue(predictSch);
    setPattern('');
    if (doPredictFilter) handleAddSubscription(projectId, SubscriptionType.Prediction);
  };

  const handlePredictEmailSubscription = async () => {
    setDoPredictFilter(!doPredictFilter);
    if (doPredictFilter) {
      handleRemoveSubscription(projectId, SubscriptionType.Prediction);
    }
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: SchedulerSources) => {
    if (newValue === selectedSource) {
      return;
    }
    if (
      (newValue === SchedulerSources.Production
        && (entryFile?.trainingFile?.connectorType === ConnectorsEnum.CSV || !pattern || (!!pattern && pattern === analyzeSchedulerValue)))
      || (newValue === SchedulerSources.Training
        && (entryFile?.productionFile?.connectorType === ConnectorsEnum.CSV
          || !(isReadyResult && !!entryFile?.productionFile?.connectorType)
          || !pattern
          || (!!pattern && pattern === predictSchedulerValue)))
          || newValue === SchedulerSources.Export
    ) {
      setSelectedSource(newValue);
    } else {
      setNewExpectedTab(newValue);
      showConfirmTabChange();
    }
  };

  const handleTabChangeConfirmation = async () => {
    setPattern('');
    setSelectedSource(newExpectedTab);
    hideConfirmTabChange();
  };

  const saveConfirmTabChange = async () => {
    if (selectedSource === SchedulerSources.Training) {
      await handleAnalyzeScheduleProject();
    } else if (selectedSource === SchedulerSources.Production) {
      await handlePredictScheduleProject();
    }
    setPattern('');
    setSelectedSource(newExpectedTab);
    hideConfirmTabChange();
  };

  const handleDeleteSchedule = async () => {
    setDeletableSchedule(projectId);
    showConfirmDelete();
  };

  const handleDeleteConfirmation = async () => {
    try {
      if (deletableSchedule) {
        await deleteSchedule(selectedSource, projectName);
      }
      if (selectedSource === SchedulerSources.Training) {
        setAnalyzeSchedulerValue(defaultSchedulePattern);
      } else {
        setPredictSchedulerValue(defaultSchedulePattern);
      }
      // eslint-disable-next-line no-empty
    } catch (err) {}
    hideConfirmDelete();
  };

  const handleAnalyzeScheduleFetch = async () => {
    let sch = entrySchedule?.trainingSchedule;
    if (!sch) {
      sch = defaultSchedulePattern;
    }
    return sch;
  };

  const handlePredictScheduleFetch = async () => {
    let sch = entrySchedule?.productionSchedule;
    const { connectorType } = entryFile?.productionFile;
    if (isReadyResult && connectorType) {
      if (!sch) {
        sch = defaultSchedulePattern;
      }
    }
    return sch;
  };

  useEffect(() => {
    (async () => {
      const analyzesch = await handleAnalyzeScheduleFetch();
      if (analyzesch !== analyzeSchedulerValue) {
        setAnalyzeSchedulerValue(analyzesch);
      }

      const predictsch = await handlePredictScheduleFetch();
      if (predictsch !== predictSchedulerValue) {
        setPredictSchedulerValue(predictsch);
      }
    })();
  }, []);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl" className={modalDialog}>
      <Container maxWidth="md">
        <DialogTitle disableTypography>
          <Grid container spacing={10}>
            <Grid className={title} item>
              <Typography variant="h5">
                {t('scheduler.title')} {projectName}
              </Typography>
            </Grid>
            <Grid item>
              <CloseButton onClick={onClose} />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <TabContext value={selectedSource}>
            <Tabs selectedTab={selectedSource} onChange={handleTabChange} enableExportTab={!!Object.keys(autoExport.exportConfig).length} />
            <AnalyzeSchedulerTabPanel
              value={SchedulerSources.Training}
              onChange={setPattern}
              onEmailSubscribe={handleAnalyzeEmailSubscription}
              onOptingAnalyzeSchedule={handleDeleteSchedule}
              handleAnalyzeScheduleProject={handleAnalyzeScheduleProject}
              doFilter={doFilter}
              analyzeSchedulerValue={analyzeSchedulerValue}
              existingFileSchedule={entrySchedule?.trainingSchedule}
              connType={entryFile?.trainingFile?.connectorType}
            />
            <PredictSchedulerTabPanel
              value={SchedulerSources.Production}
              onChange={setPattern}
              onEmailSubscribe={handlePredictEmailSubscription}
              isReady={isReadyResult && !!entryFile?.productionFile?.connectorType}
              onOptingPredictSchedule={handleDeleteSchedule}
              handlePredictScheduleProject={handlePredictScheduleProject}
              doPredictFilter={doPredictFilter}
              predictSchedulerValue={predictSchedulerValue}
              existingFileSchedule={entrySchedule?.productionSchedule}
              connType={entryFile?.productionFile?.connectorType}
            />
            {!!Object.keys(autoExport.exportConfig).length && <ExportPanel
              value={SchedulerSources.Export}
              options={autoExport.exportConfig}
              onChange={autoExport.changeExportConfig}
              onSave={autoExport.saveExportConfig}
              isChanged={autoExport.isExportConfigChanged}
            />}
          </TabContext>
          <ConfirmationDialog
            open={isConfirmTabChangeModalOpen}
            onClose={handleTabChangeConfirmation}
            onConfirm={saveConfirmTabChange}
            title={t('scheduler.tabChange.title')}
            content={t('scheduler.tabChange.confirmation', {
              name: projectName,
            })}
            confirmButtonLabel={t('scheduler.tabChange.confirmationBox.yes')}
            closeButtonLabel={t('scheduler.tabChange.confirmationBox.no')}
            className={dialogWrapper}
          />
          <ConfirmationDialog
            open={isConfirmDeleteModalOpen}
            onClose={hideConfirmDelete}
            onConfirm={handleDeleteConfirmation}
            title={t('scheduler.deleteSchedule.title')}
            content={t('scheduler.deleteSchedule.confirmation', {
              name: projectName,
            })}
            confirmButtonLabel={t('scheduler.deleteSchedule.confirmationBox.yes')}
            closeButtonLabel={t('scheduler.deleteSchedule.confirmationBox.no')}
            className={dialogWrapper}
          />
          {(schedulerActionPending || schedulerFetchActionLoading) && (
            <Delayed>
              <LoaderOverlay />
            </Delayed>
          )}
        </DialogContent>
      </Container>
    </Dialog>
  );
};

SchedulingDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  projectId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  projectName: PropTypes.string.isRequired,
};

SchedulingDialog.defaultProps = {
  open: false,
};

export default React.memo(SchedulingDialog);
