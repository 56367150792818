import { useParams } from 'react-router';

interface ProjectRouteMatch {
  projectId: string
}

const useProjectId = () => {
  const { projectId } = useParams<ProjectRouteMatch>();    
  return Number(projectId);
};

export default useProjectId;
