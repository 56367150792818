import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import useApiClient from '~/src/hooks/useApiClient';
import { currentFolderState } from '~/src/ProjectFolder/store';
import { ENTRIES_PER_PAGE, ROOT_FOLDER_DEFAULT_FILTER } from '~/src/Projects/constants';
import { needRestartProjectsPageState, pendingFind, projectListState } from '~/src/Projects/store';
import { snackbarMessageSelector } from '~/src/Snackbar/store';
import { queryFilterSelector, SearchParams } from '../store';

const useProjectsSearch = () => {
  const { t } = useTranslation('projects');
  const apiClient = useApiClient();
  const setSnackbarMessage = useSetRecoilState(snackbarMessageSelector);
  const setPending = useSetRecoilState(pendingFind);
  const setProjects = useSetRecoilState(projectListState);
  const setCurrentFolder = useSetRecoilState(currentFolderState);
  const setNeedRestartProjectsPage = useSetRecoilState(needRestartProjectsPageState);
  const filters = useRecoilValue(queryFilterSelector);

  const getFilteredProjects = async (searchString = '') => {
    try {
      setPending(true);
      const params: SearchParams = { limit: ENTRIES_PER_PAGE, query: searchString };
      if (filters) {
        params.filter = btoa(JSON.stringify(filters));
      } else if (!searchString) {
        params.filter = btoa(JSON.stringify(ROOT_FOLDER_DEFAULT_FILTER));
      }
      const { data } = await apiClient.get('/search', { params });
      if (data) {
        setCurrentFolder(null);
        setProjects({
          entries: data,
          hasMore: data.length === ENTRIES_PER_PAGE,
        });
        setNeedRestartProjectsPage(true);
      }
    } catch (err) {
      setSnackbarMessage({
        message: t('filterProjects.errors.search'),
        severity: 'error',
      });
    } finally {
      setPending(false);
    }
  };

  return [getFilteredProjects];
};

export default useProjectsSearch;
