import React from 'react';
import { useTheme } from '@material-ui/core';
import { useRecoilValue } from 'recoil';
import { logo } from '~/src/store';
import { isPaidUserSelector } from '~/src/Auth/store';

const Logo = () => {
  const { spacing } = useTheme();
  const isPaidUser = useRecoilValue(isPaidUserSelector);
  const { large, community } = useRecoilValue(logo);

  return (
    <img
      src={isPaidUser ? large : community}
      alt="logo"
      height={isPaidUser ? spacing(8) : spacing(9)}
    />
  );
};

export default Logo;
