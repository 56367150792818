import { useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import useApiClient from '~/src/hooks/useApiClient';
import { snackbarMessageSelector } from '~/src/Snackbar/store';
import { stoppingProcessState } from '../store';

const useStopProcess = () => {
  const { t } = useTranslation('projects');
  const apiClient = useApiClient();
  const setPending = useSetRecoilState(stoppingProcessState);
  const setSnackbarMessage = useSetRecoilState(snackbarMessageSelector);

  const stopProcess = async (projectId: number) => {
    setPending((state) => ({ ...state, [projectId]: true }));
    try {
      await apiClient.patch(`/projects/${projectId}/rollback`);
      setSnackbarMessage({
        message: t('stopProcessDialog.stopSuccess'),
        severity: 'success',
      });
    } catch (err) {
      setSnackbarMessage({
        message: t('stopProcessDialog.stopError'),
        severity: 'error',
      });
    } finally {
      setPending((state) => ({ ...state, [projectId]: false }));
    }
  };

  return [stopProcess];
};

export default useStopProcess;
