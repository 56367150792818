import { useMemo } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import { FileType, fileTypeState, selectedFileNameState, selectedProductionFileNameState } from '../store';

const useFileName = () => {
  const [trainingFileName, setTrainingFileName] = useRecoilState(selectedFileNameState);
  const [productionFileName, setProductionFileName] = useRecoilState(selectedProductionFileNameState);
  const fileType = useRecoilValue(fileTypeState);

  const fileName = useMemo(() => (fileType === FileType.Training ? trainingFileName : productionFileName), [
    fileType,
    trainingFileName,
    productionFileName,
  ]);

  const setFileName = useMemo(() => (fileType === FileType.Training ? setTrainingFileName : setProductionFileName), [fileType]);

  return [fileName, setFileName] as const;
};

export default useFileName;
