import axios from 'axios';
import useIdToken from '~/src/Auth/hooks/useIdToken';

const useApiClient = () => {
  const [getIdToken] = useIdToken();
  const apiClient = axios.create();

  apiClient.interceptors.request.use(async (config) => {
    const idToken = await getIdToken();
    config.headers.Authorization = `Bearer ${idToken}`;
    return config;
  });

  apiClient.defaults.baseURL = window.appConfig.api.baseUrl;

  return apiClient;
};

export default useApiClient;
