/* eslint-disable default-case */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import SubdirectoryArrowLeftIcon from '@material-ui/icons/SubdirectoryArrowLeft';
import { useDrop } from 'react-dnd';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(({ spacing, palette, shadows }) => ({
  folderWrapper: {
    position: 'relative',
    padding: spacing(2),
    display: 'flex',
    flexDirection: 'column',
    '&$dropOver': {
      '& $card': {
        boxShadow: shadows[15],
      },
    },
  },
  title: {
    position: 'relative',
    wordBreak: 'break-word',
    lineHeight: '1.5rem',
    fontSize: '1.125rem',
    fontWeight: 700,
    maxHeight: spacing(12),
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: spacing(2),
    },
  },
  card: {
    padding: spacing(5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: spacing(43),
    width: spacing(52.5),
    backgroundColor: palette.info.light,
    cursor: 'pointer',
  },
  dropOver: {},
}));

interface RootFolderCardProps {
  onClick: (arg01: any) => void;
}

const RootFolderCard: React.FC<RootFolderCardProps> = ({ onClick }) => {
  const classes = useStyles();
  const { t } = useTranslation('folders');

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: 'project',
    drop: () => ({ _id: null }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <div className={cx(classes.folderWrapper, { [classes.dropOver]: canDrop && isOver })} ref={drop}>
      <Card variant="outlined" onClick={onClick} className={classes.card}>
        <Typography variant="h6" className={classes.title}>
          <SubdirectoryArrowLeftIcon />
          <span>{t('backToRootTitle')}</span>
        </Typography>
        <Typography variant="caption" align="center">
        {t('backToRootHint')}
        </Typography>
      </Card>
    </div>
  );
};

RootFolderCard.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default RootFolderCard;
