import { atom } from 'recoil';

export const defaultSchedulePattern = '0 0 * * *';

export const isScheduleActionState = atom({
  key: 'SchedulingDialog/isScheduleActionLoading',
  default: false,
});

export const isScheduleFetchActionState = atom({
  key: 'SchedulingDialog/isScheduleFetchActionState',
  default: false,
});
