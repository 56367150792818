import { Box } from '@material-ui/core';
import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import Loader from '~/src/components/LoaderOverlay';
import NoSearchResults from '~/src/FilterProjects/components/NoResults';
import { queryFilterSelector, searchStringState } from '~/src/FilterProjects/store';
import HomeLayout from '~/src/HomeLayout/components/HomeLayout';
import useTitle from '~/src/hooks/useTitle';
import { currentFolderState } from '~/src/ProjectFolder/store';
import useFind from '../hooks/useFind';
import { hasEntriesSelector, pendingFind, wereProjectsLoadedState } from '../store';
import EmptyList from './EmptyList';
import ProjectsGrid from './ProjectsGrid';

const Projects = () => {
  const { t } = useTranslation('layout');
  useTitle(t('navigation.projects'));
  const hasEntries = useRecoilValue(hasEntriesSelector);
  const wereProjectsLoaded = useRecoilValue(wereProjectsLoadedState);
  const currentFolder = useRecoilValue(currentFolderState);
  const filters = useRecoilValue(queryFilterSelector);
  const searchString = useRecoilValue(searchStringState);
  const pending = useRecoilValue(pendingFind);
  const [getProjects] = useFind();
  const previousFolder = useRef(null);

  useEffect(() => {
    if (!wereProjectsLoaded) {
      getProjects();
    }
  }, [wereProjectsLoaded]);

  useEffect(() => {
    if (previousFolder.current !== currentFolder && wereProjectsLoaded) {
      getProjects();
      previousFolder.current = currentFolder;
    }
  }, [currentFolder]);

  const Content = useMemo(() => {
    switch (true) {
      case !pending && !hasEntries && !filters && !searchString && !currentFolder:
        return <EmptyList />;
      case !pending && !hasEntries && (filters || searchString):
        return <NoSearchResults />;
      default: return <ProjectsGrid />;
    }
  }, [pending, hasEntries, filters, searchString, currentFolder]);

  return (
    <HomeLayout>
      <Box width="100%" height="100%">
        {pending && (
          <Box width="calc(100% - 40px)" height="100%" position="absolute">
            <Loader />
          </Box>
        )}
        {Content}
      </Box>
    </HomeLayout>
  );
};

export default React.memo(Projects);
